import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';
import schema from './Step3ValidationSchema';
// import RadioGroup from '../../RadioGroup/RadioGroup';
import InputField from '../../InputField/InputField';
import FormUpload from '../../FormUpload/FormUpload';
import { StyledForm } from '../../FormComponents.styles';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';

const Step3Form = ({ onSubmit, previousStep, state, formState, send }) => {
  // NEED TO CHECK IF FILES UPLOADED MATCH NUMBER OF FILES FIELD and disable button if need be

  const documentsCount = formState.context.step3.data?.documents.length;
  let buttonNotDisabled = false;
  let btnTitleText = '';
  const incomingPageCount = state.pageCount > 0 ? state.pageCount : null;
  const incomingFileCount = state.fileCount > 0 ? state.fileCount : null;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reqDocsSendingType: state.documentsSentBy || 'With application',
      totalFiles: incomingFileCount,
      totalPages: incomingPageCount,
    },
  });
  const watchSendType = watch('reqDocsSendingType');
  const filesEntered = watch('totalFiles');

  let displayDropZone = false;

  // CHECKING WHICH OPTION IS CHOOSEN AND IF IT IS WITH APPLICATION THEN SHOW DROPZONE AND MAKE SURE FILE COUNT === DOCUMENTS UPLOADED
  if (watchSendType === 'With application' || watchSendType === undefined) {
    displayDropZone = true;
    buttonNotDisabled = !(filesEntered === documentsCount.toString());
    btnTitleText = 'Uploaded files and total number of files dont match';
  }
  let showMailAddress = '';
  let numberFilesDontMatch = '';

  useEffect(() => {
    if (filesEntered === '') {
      setValue('totalFiles', null);
    }
  }, [filesEntered]);

  switch (watchSendType) {
    case 'Mail':
      showMailAddress = (
        <div className="req-docs__address">
          <h2 className="SF-segment-title">
            You should mail the documents to:
          </h2>
          <p>GCE, Inc.</p>
          <p>3515 - B Longmire Drive PMB 323</p>
          <p>College Station, TX 77845</p>
        </div>
      );
      break;

    case 'Email':
      showMailAddress = (
        <div className="req-docs__address">
          <h2 className="SF-segment-title">
            You should email the documents to:
          </h2>
          <p>documents@gceus.com</p>
        </div>
      );
      break;

    default:
      break;
  }

  if (documentsCount > 0 && !(filesEntered === documentsCount.toString())) {
    numberFilesDontMatch = (
      <div className="error">
        <p>
          Please make sure the number of files field matches how many files you
          uploaded.
        </p>
      </div>
    );
  }

  return (
    <StyledForm>
      <div className="temp-width">
        <Directions className="SF-directions">
          <p className="D-title">Documents</p>
          <p className="D-content ">
            Click{' '}
            <a
              target="_blank"
              href="https://www.gceus.com/get-started/"
              rel="noreferrer"
            >
              here
            </a>{' '}
            for required Documents
          </p>
        </Directions>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* radio work */}
          <div className="input-group">
            <div role="group" aria-labelledby="How you will send documents">
              <div>
                <OptionLabel htmlFor="reqDocsSendingType_With application">
                  <Option
                    id="reqDocsSendingType_With application"
                    name="reqDocsSendingType"
                    type="radio"
                    value="With application"
                    {...register('reqDocsSendingType')}
                  />
                  With application
                </OptionLabel>

                <OptionLabel htmlFor="reqDocsSendingType_Mail">
                  <Option
                    id="reqDocsSendingType_Mail"
                    name="reqDocsSendingType"
                    type="radio"
                    value="Mail"
                    {...register('reqDocsSendingType')}
                    disabled={documentsCount > 0}
                  />
                  Mail
                </OptionLabel>
                <OptionLabel htmlFor="reqDocsSendingType_Email">
                  <Option
                    id="reqDocsSendingType_Email"
                    name="reqDocsSendingType"
                    type="radio"
                    value="Email"
                    {...register('reqDocsSendingType')}
                    disabled={documentsCount > 0}
                  />
                  Email
                </OptionLabel>
              </div>
              <ErrorMessage
                errors={errors}
                name="reqDocsSendingType"
                as="span"
                className="error"
              />
            </div>
          </div>
          {/* Radio work end */}

          {displayDropZone && (
            <div>
              {documentsCount > 0 && (
                <p className="input-group--title input-group--bold">
                  To switch to other type please delete documents uploaded.
                </p>
              )}
              <FormUpload
                formState={formState}
                send={send}
                currentSavedFiles={formState.context.step3.data?.documents}
              />
              {documentsCount > 0 &&
                !(filesEntered === documentsCount.toString()) &&
                numberFilesDontMatch}
            </div>
          )}
          {watchSendType === 'Mail' && (
            <InputField
              register={register}
              errors={errors}
              dataRef="totalPages"
              title="Total number of pages"
              type="text"
              required
            />
          )}
          {watchSendType !== 'Mail' && (
            <div className="sbs page-count">
              <InputField
                register={register}
                errors={errors}
                dataRef="totalPages"
                title="Total number of pages"
                type="text"
                required
              />
              <InputField
                register={register}
                errors={errors}
                validation={{
                  required:
                    'Please enter the total number of files you are uploading.',
                }}
                dataRef="totalFiles"
                title="Total number of files"
                type="text"
                required
                inputID="totalFileNumber"
              />
            </div>
          )}
          {showMailAddress}
          <hr />
          <NextPrevStepButtons
            submitButton
            prevStep={previousStep}
            disabledValue={
              buttonNotDisabled &&
              formState.matches('editing.stepThree.waiting')
            }
            titleText={btnTitleText}
          />
        </form>
      </div>
    </StyledForm>
  );
};

export default Step3Form;

const Directions = styled.div`
  .D-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #14527f;
  }
  .D-content {
    font-size: 22px;
    font-weight: 700;
    color: #2e2e2e;
  }
`;

const Option = styled.input`
  margin-right: 8px;
  &:disabled {
    cursor: not-allowed;
  }
`;

const OptionLabel = styled.label`
  margin-right: 16px;
  color: ${props => (props.isDisabled ? 'lightgrey' : 'unset')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
