import { assign } from 'xstate';
import { useApolloClient } from '@apollo/client';

import {
  returnServiceByType,
  servicesByCountry,
} from '../../../shared/functions';

import queries from './queries';
import mutations from './mutations';

function createServices() {
  const client = useApolloClient();

  const { getServices, createCart } = queries(client);
  const { createInvoice, voidInvoice } = mutations(client);

  return {
    guards: {},

    services: {
      getAppAndServices: ctx => {
        return getServices(ctx.applicationId);
      },

      createCart: ctx => {
        return createCart({
          applicationId: ctx.applicationId,
          serviceId: ctx.serviceSelected,
        });
      },

      createInvoice: ctx => {
        return createInvoice({
          applicationId: ctx.applicationId,
          serviceId: ctx.serviceSelected,
        });
      },

      voidInvoice: ctx => {
        return voidInvoice({
          applicationId: ctx.applicationId,
          invoiceId: ctx.finalizedInvoice.id,
        });
      },
    },

    actions: {
      addAddressAndServices: assign({
        services: (_, e) => {
          const mailAddressLocation = e.data?.address?.country || '';

          let services = returnServiceByType(e.data.services, 'RUSH').filter(
            service => {
              // gets rid of option for no rush service
              return !service.displayName.toLowerCase().includes('no rush');
            },
          );

          // filter services based on mail location
          if (mailAddressLocation) {
            services = servicesByCountry(services, mailAddressLocation);
          }

          return services;
        },

        mailAddress: (_, e) => e.data.address,
      }),

      onSubmit: assign({
        serviceSelected: (_, e) => e.shippingMethod,
      }),

      createCartSuccess: assign({
        cart: (_, e) => e.data,
      }),

      createInvoiceSuccess: assign({
        finalizedInvoice: (_, e) => e.data.finalized,
        invoiceId: (_, e) => e.data.id,
      }),

      voidInvoiceSuccess: assign({
        finalizedInvoice: () => null,
        cart: () => null,
      }),
    },
  };
}

export default createServices;
