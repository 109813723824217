import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import dayjs from 'dayjs';

import TextInput from '../../../UI/FormInputs/TextInput/TextInput';
import { FormFields } from '../../../../shared/styles';
import Button from '../../../UI/Button';
import ViewDocumentsComponent from '../ViewDocumentsComponent/ViewDocumentsComponent';

const schema = yup.object().shape({
  refNum: yup.string(),
  evaluatorNotes: yup.string(),
});

const EditEvaluationDetails = ({ application, send, formState }) => {
  const [buttonClicked, setButtonClicked] = useState(null);
  let { evaluationCompleteDate } = application;
  const {
    id: applicationID,
    refNumber,
    applicantInfo: {
      firstName,
      middleName,
      lastName,
      differentFirstName,
      differentMiddleName,
      differentLastName,
    },
    notes,
    appDocumentsInfo,
  } = application;
  evaluationCompleteDate = evaluationCompleteDate
    ? dayjs(evaluationCompleteDate).format('MM-DD-YYYY hh:mm A')
    : null;

  // FORM DEFAULT VALUES
  const formDefaultValues = {
    firstName,
    middleName,
    lastName: lastName.toUpperCase(),
    differentFirstName,
    differentMiddleName,
    differentLastName: differentLastName?.toUpperCase(),
    refNum: refNumber,
    evaluationCompleteDate,
    evaluatorNotes: notes?.text || '',
  };

  // FORM INITIATE
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
  });

  // SUBMIT FUNCTION FOR FORM
  const onSubmit = formData => {
    if (buttonClicked === 1) {
      const formDataUpdate = { ...formData, appID: applicationID };
      send('SAVE_DETAILS', { formData: formDataUpdate });
    }
    if (buttonClicked === 2) {
      const formDataUpdate = {
        ...formData,
        appID: applicationID,
      };
      send('COMPLETE_DETAILS', { formData: formDataUpdate });
    }
    if (buttonClicked === 3) {
      const formDataUpdate = { ...formData, appID: applicationID };
      send('COMPLETE_DETAILS_SIGN', {
        formData: formDataUpdate,
      });
    }
  };

  // SAVING TEXT
  let savingText = '';
  switch (true) {
    case formState.matches('editEvaluationDetails.savingDetails'):
      savingText = 'Saving Content';
      break;
    case formState.matches('editEvaluationDetails.savingDetailsComplete'):
      savingText = 'Saving Content and Completed';
      break;
    default:
      break;
  }

  return (
    <>
      <Header>
        <div className="__buttons">
          <Button text="Back to Overview" onClick={() => send('OVERVIEW')} />
        </div>
        <p>Edit evaluation details</p>
      </Header>
      <FormFields onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <p className="__dateCompleted">
            <strong>Date Completed:</strong> {evaluationCompleteDate ?? '--'}
          </p>
          <TextInput
            register={register}
            dataRef="refNum"
            type="text"
            title="Reference Number:"
            errors={errors}
          />
          <TextInput
            register={register}
            dataRef="evaluatorNotes"
            type="text-area"
            rows="8"
            title="Evaluator Notes:"
            errors={errors}
          />
        </FormContainer>
        <ViewDocumentsComponent
          documents={appDocumentsInfo}
          applicationId={applicationID}
          send={send}
          formState={formState}
        />
        <ButtonGroup>
          <Button
            text="Save and Continue"
            blue
            type="submit"
            onClick={() => setButtonClicked(() => 1)}
          />
          <Button
            text="Save and Mail"
            type="submit"
            onClick={() => setButtonClicked(() => 2)}
            title="Save changes and set to be mailed"
          />
          <Button
            text="Save and Complete"
            type="submit"
            onClick={() => setButtonClicked(() => 3)}
            title="Save changes and set to completed"
          />
        </ButtonGroup>
        <p>{savingText}</p>
      </FormFields>
      {formState.matches('editEvaluationDetails.downloadDocument') && (
        <p>Fetching File</p>
      )}
      {formState.matches('editEvaluationDetails.uploadDocument') && (
        <p>Uploading File</p>
      )}
      {formState.matches('editEvaluationDetails.deleteDocument') && (
        <p>Deleting File</p>
      )}
    </>
  );
};

export default EditEvaluationDetails;

// const InputGroup = styled.div`
//   display: flex;
//   margin: 0 -10px;
//   .input-group {
//     margin: 0 10px 30px;
//   }
// `;

const ButtonGroup = styled.div`
  margin: 30px -10px;
  display: flex;
  button {
    margin: 0 10px;
  }
`;

const Header = styled.div`
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .__buttons {
    display: flex;
    margin-bottom: 20px;
    button {
      margin-right: 10px;
    }
  }
`;

const FormContainer = styled.div`
  max-width: 700px;
  margin-top: 30px;
  .__dateCompleted {
    margin: 10px 0;
  }
`;
