import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';

import dayjs from 'dayjs';
import InputField from '../../../../form-components/InputField/InputField';
import DateInput from './DateInput/DateInput';
import schema from './SchoolFormValidationSchema';
import FormDropdown from '../../../../form-components/FormDropdown/FormDropdown';

// import ValidationErrors from '../../../ValidationErrors/ValidationErrors';

// THIS IS A COPY OF THE SCHOOL FORM FROM THE FRONTEND APPLICATION. THAT WAS MADE EARLY ON AND NEEDS TO BE REVAMPED TO THIS ONE DOWN THE ROAD. THE WAY DATA IS PASSED TO IT IS CONFUSING. THIS ONE HANDLES IT BETTER.

const SchoolForm = ({ submitHandle, formValues }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues: { ...formValues } || {},
    resolver: yupResolver(schema),
  });
  const onSubmit = data => {
    submitHandle(data);
    reset();
  };

  const watchAttenStartMonth = watch('startDate.month');
  const watchAttenStartYear = watch('startDate.year');
  const watchAttenEndMonth = watch('endDate.month');
  const watchAttenEndYear = watch('endDate.year');
  const watchAwardMonth = watch('degreeDate.month');
  const watchAwardYear = watch('degreeDate.year');

  const watchDegreeNA = watch('degreeNA');

  // USED TO UPDATE THE START DATE TIME HIDDEN FIELD SO THE FORM CAN CHECK IF THE START DATE IS BEFORE THE END DATE
  // UPDATE START DATE TIME WHEN START DATE MONTH AND YEAR ARE POPULATED
  useEffect(() => {
    const { startDate } = getValues();
    if (startDate.month !== '' && startDate.year !== '') {
      // MAKE UNIX MILLISECONDS OUT OF START MONTH AND YEAR
      const date = dayjs(`${startDate.year}-01-${startDate.month}`).valueOf();
      setValue('startDateTime', date);
    } else {
      setValue('startDateTime', 0);
    }
  }, [watchAttenStartMonth, watchAttenStartYear]);
  // UPDATE END DATE TIME WHEN END DATE MONTH AND YEAR ARE POPULATED
  useEffect(() => {
    const {
      endDate: { month, year },
    } = getValues();
    if (month !== '' && year !== '') {
      // MAKE UNIX MILLISECONDS OUT OF START MONTH AND YEAR
      const date = dayjs(`${year}-01-${month}`).valueOf();
      setValue('endDateTime', date);
    } else {
      setValue('endDateTime', 0);
    }
  }, [watchAttenEndMonth, watchAttenEndYear]);

  useEffect(() => {
    const { degreeDate } = getValues();
    if (!degreeDate?.month && !degreeDate?.year) {
      setValue('degreeDateTime', 0);
    } else {
      // MAKE UNIX MILLISECONDS OUT OF START MONTH AND YEAR
      const date = dayjs(
        `${degreeDate?.year}-01-${degreeDate?.month}`,
      ).valueOf();
      setValue('degreeDateTime', date);
    }
  }, [watchAwardMonth, watchAwardYear]);

  useEffect(() => {
    const { degreeNA } = getValues();
    if (degreeNA) {
      setValue('degreeDate.month', null);
      setValue('degreeDate.year', null);
      setValue('degreeDateTime', 0);
    }
  }, [watchDegreeNA]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <InputField
        register={register}
        dataRef="country"
        type="text"
        placeholder=""
        title="Country"
        errors={errors}
        required
      />
       */}
      <FormDropdown
        dataRef="country"
        register={register}
        errors={errors}
        title="Country"
        content="country"
        required
      />
      <InputField
        register={register}
        dataRef="school"
        type="text"
        placeholder=""
        title="School/Institution"
        errors={errors}
        required
      />
      <InputField
        register={register}
        dataRef="study"
        type="text"
        placeholder=""
        title="Area Of Study"
        errors={errors}
        required
      />
      <h2 className="SF-segment-title">Attendance start date</h2>
      <DateInput
        dataPrefix="startDate"
        errors={errors}
        register={register}
        date={formValues?.startDate}
        excludeDay
      />
      <ErrorMessage
        errors={errors}
        name="startDateTime"
        as="span"
        className="error"
      />
      <div className="checkbox-group">
        <label>
          <input
            type="hidden"
            readOnly
            name="startDateTime"
            {...register('startDateTime')}
            defaultValue="0"
          />
        </label>
      </div>

      <h2 className="SF-segment-title">Attendance end date</h2>
      <DateInput
        dataPrefix="endDate"
        errors={errors}
        register={register}
        date={formValues?.endDate}
        monthSelected={watchAttenEndMonth}
        excludeDay
      />
      <ErrorMessage
        errors={errors}
        name="endDateTime"
        as="span"
        className="error"
      />
      <div className="checkbox-group">
        <label>
          <input
            type="hidden"
            readOnly
            name="endDateTime"
            {...register('endDateTime')}
            defaultValue="0"
          />
        </label>
      </div>
      <InputField
        register={register}
        dataRef="degree"
        type="text"
        placeholder=""
        title="Title of Degree/Diploma in native language"
        errors={errors}
        required={!watchDegreeNA}
      />
      <div className="checkbox-group">
        <label>
          <input type="checkbox" name="degreeNA" {...register('degreeNA')} />
          Not applicable
        </label>
      </div>
      {!watchDegreeNA && (
        <>
          <h2 className="SF-segment-title">Degree/diploma award date</h2>
          <DateInput
            dataPrefix="degreeDate"
            errors={errors}
            register={register}
            date={formValues?.degreeDate}
            monthSelected={watchAwardMonth}
            excludeDay
          />
          <ErrorMessage
            errors={errors}
            name="degreeDateTime"
            as="span"
            className="error"
          />
          <div className="checkbox-group">
            <label>
              <input
                type="hidden"
                readOnly
                name="degreeDateTime"
                {...register('degreeDateTime')}
                defaultValue="0"
              />
            </label>
          </div>
        </>
      )}

      {/* <ValidationErrors errors={errors} /> */}
      <button className="btn btn--blue" type="submit">
        Save Education
      </button>
      <hr />
    </form>
  );
};

export default SchoolForm;
