import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import getStripe from '../../shared/stripe';
import InvoiceSummary from './InvoiceSummary';
import PaymentForm from './PaymentForm';

export default function CollectPayment({
  cart,
  finalizedInvoice,
  onPaid,
  onCancelled,
}) {
  const stripe = getStripe();
  const clientSecret = finalizedInvoice.payment_intent.client_secret;

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  return (
    <>
      {clientSecret && (
        <>
          <h2 className="title">Payment Summary</h2>
          <InvoiceSummary finalizedInvoice={finalizedInvoice} cart={cart} />
          <Elements options={options} stripe={stripe}>
            <PaymentForm
              finalizedInvoice={finalizedInvoice}
              onPaid={onPaid}
              onCancelled={onCancelled}
            />
          </Elements>
        </>
      )}
    </>
  );
}
