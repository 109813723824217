import React from 'react';

const Loading = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <style>
      {`{margin: auto, background: rgb(255, 255, 255); display: block; shape-rendering: auto;}`}
    </style>
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#14527f"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export default Loading;
