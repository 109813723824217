/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_AND_FINALIZE = gql`
  mutation createAndFinalize($applicationId: String!, $copyRequestId: String!) {
    copyRequest {
      invoices(applicationId: $applicationId, copyRequestId: $copyRequestId) {
        createAndFinalize {
          id
          finalized
        }
      }
    }
  }
`;

export const VOID_INVOICE = gql`
  mutation VOID_INVOICE($applicationId: String!, $copyRequestId: String!) {
    copyRequest {
      invoices(applicationId: $applicationId, copyRequestId: $copyRequestId) {
        invoice {
          void
        }
      }
    }
  }
`;
