function createConfig(applicationId) {
  return {
    id: 'evaluationReview',
    initial: 'init',
    context: {
      applicationInReview: applicationId || null,
      application: {},
    },

    states: {
      init: {
        id: 'init',
        invoke: {
          id: 'initialLoad',
          src: 'load',
          onDone: {
            actions: ['setInitData'],
            target: 'overview',
          },
          onError: 'loadingFailed',
        },
      },
      overview: {
        id: 'overview',
        initial: 'waiting',
        states: {
          waiting: {},
          saving: {
            invoke: {
              id: 'overviewSaving',
              src: 'saveOverview',
              onDone: {
                target: '#init',
                actions: ['saveOverviewToContext'],
              },
              onError: { target: '#loadingFailed', actions: ['logError'] },
            },
          },
        },
        on: {
          SAVE_OPTIONS: {
            target: '.saving',
          },
        },
      },
      applicantInfo: {},
      editApplicationDetails: {
        on: {
          BACK_TO_OVERVIEW: { target: '#init' },
          EDIT_SCHOOL: {
            actions: ['setEditDeleteSchool'],
            target: '#editSchool',
          },
          DELETE_SCHOOL: {
            actions: ['setEditDeleteSchool'],
            target: '#deleteSchool',
          },
          IDLE_STATE: '#editApplicationDetailsIdle',
          ADD_SCHOOL_BUTTON: {
            target: '#addSchool',
            actions: ['removeEditDeleteSchool'],
          },
        },
        initial: 'idle',
        states: {
          idle: {
            id: 'editApplicationDetailsIdle',
            on: {
              SAVE_DETAILS: {
                target: 'savingDetails',
              },
            },
          },
          savingDetails: {
            invoke: {
              id: 'savingDetails',
              src: 'stateSaveApplicationDetails',
              onDone: {
                target: '#init',
              },
              onError: '#loadingFailed',
            },
          },
          editSchool: {
            id: 'editSchool',
            initial: 'idle',
            on: {
              SUBMIT_SCHOOL: '#editSchoolSaving',
            },
            states: {
              idle: {},
              savingSchool: {
                id: 'editSchoolSaving',
                invoke: {
                  src: 'saveSchoolToDB',
                  onDone: {
                    actions: ['updateSchoolListInContext'],
                    target: '#editApplicationDetailsIdle',
                  },
                  onError: '#loadingFailed',
                },
              },
            },
          },
          addSchool: {
            id: 'addSchool',
            initial: 'idle',
            on: {
              SUBMIT_SCHOOL: {
                target: '#addSchoolSaving',
              },
            },
            states: {
              idle: {},
              savingSchool: {
                id: 'addSchoolSaving',
                invoke: {
                  src: 'saveSchoolToDB',
                  onDone: {
                    actions: ['updateSchoolListInContext'],
                    target: '#editApplicationDetailsIdle',
                  },
                  onError: '#loadingFailed',
                },
              },
            },
          },
          deleteSchool: {
            id: 'deleteSchool',
            on: {
              CONFIRM_DELETE_SCHOOL: {
                target: 'confirmDeleteSchool',
              },
            },
          },
          confirmDeleteSchool: {
            invoke: {
              id: 'confirmDeleteSchool',
              src: 'deleteSchoolFromDB',
              onDone: {
                actions: ['updateSchoolListInContext'],
                target: '#editApplicationDetailsIdle',
              },
              onError: '#loadingFailed',
            },
          },
        },
      },
      editEvaluationDetails: {
        id: 'editEvaluationDetails',
        initial: 'idle',
        states: {
          idle: {
            on: {
              SAVE_DETAILS: {
                target: 'savingDetails',
              },
              COMPLETE_DETAILS: {
                target: 'savingDetailsComplete',
              },
              COMPLETE_DETAILS_SIGN: {
                target: 'savingDetailsSignAndComplete',
              },
              DOWNLOAD_DOCUMENT: {
                target: 'downloadDocument',
              },
              UPLOAD_DOCUMENT: [
                {
                  cond: 'checkFileTypes',
                  target: '#invalidDocumentType',
                },
                {
                  cond: 'checkForDuplicateFileNames',
                  target: '#duplicateFileName',
                },
                { target: 'uploadDocument' },
              ],
              DELETE_DOCUMENT: {
                target: 'deleteDocument',
              },
            },
          },
          savingDetails: {
            invoke: {
              id: 'savingDetails',
              src: 'saveEvaluationDetails',
              onDone: {
                target: '#init',
              },
              onError: '#loadingFailed',
            },
          },
          savingDetailsComplete: {
            invoke: {
              id: 'savingDetailsComplete',
              src: 'saveEvaluationDetailsComplete',
              onDone: {
                target: '#init',
              },
              onError: '#loadingFailed',
            },
          },
          savingDetailsSignAndComplete: {
            invoke: {
              id: 'savingDetailsSignAndComplete',
              src: 'saveEvaluationDetailsSignAndComplete',
              onDone: {
                target: '#init',
              },
              onError: '#loadingFailed',
            },
          },
          downloadDocument: {
            invoke: {
              id: 'downloadDocument',
              src: 'downloadDocument',
              onDone: {
                target: 'idle',
              },
              onError: '#loadingFailed',
            },
          },
          uploadDocument: {
            invoke: {
              id: 'uploadDocument',
              src: 'uploadDocument',
              onDone: {
                actions: ['saveDocumentsToContext'],
                target: '#editEvaluationDetails',
              },
              onError: '#loadingFailed',
            },
          },
          invalidDocumentType: {
            id: 'invalidDocumentType',
            after: {
              2000: { target: '#editEvaluationDetails' },
            },
          },
          duplicateFileName: {
            id: 'duplicateFileName',
            after: {
              2000: { target: '#editEvaluationDetails' },
            },
          },
          deleteDocument: {
            invoke: {
              id: 'deleteDocument',
              src: 'deleteDocument',
              onDone: {
                actions: ['saveDocumentsToContextAfterDelete'],
                target: '#editEvaluationDetails',
              },
              onError: '#loadingFailed',
            },
          },
        },
      },
      loadingFailed: {
        id: 'loadingFailed',
      },
    },
    on: {
      APPLICATION_DETAILS: {
        target: 'applicantInfo',
      },
      EDIT_APPLICATION: {
        target: 'editApplicationDetails',
      },
      EVALUATION_DETAILS: {
        target: 'editEvaluationDetails',
      },
      OVERVIEW: {
        target: 'overview',
      },
    },
  };
}

export default createConfig;
