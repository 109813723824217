import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';

import InputField from '../InputField/InputField';
import FormDropdownEnum from '../FormDropdownEnum/FormDropdownEnum';

import servicesEditForm from '../../../shared/formSchema';
import {
  UPDATE_SERVICE,
  ADD_SERVICE,
} from '../../../queries_mutations/mutations';
import { GET_ALL_SERVICES } from '../../../queries_mutations/queries';
import { FormFields } from '../../../shared/styles';

// STILL NEED ERROR HANDLING

const EditServiceForm = ({
  currentService,
  setModalOpen,
  serviceEnum,
  newForm,
}) => {
  let formAttributes = { resolver: yupResolver(servicesEditForm) };
  if (!newForm) {
    formAttributes = {
      defaultValues: currentService,
      resolver: yupResolver(servicesEditForm),
    };
  }
  const serviceOptions = serviceEnum;

  // MUTATIONS SETUP
  const [update, { loading: updateServiceLoading, error: updateServiceError }] =
    useMutation(UPDATE_SERVICE);

  const [addService] = useMutation(ADD_SERVICE);

  // FORM SETUP
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    ...formAttributes,
  });
  const editFormSubmitHandler = data => {
    // SENDING TO DATABASE
    if (currentService) {
      const serviceData = {
        ...data,
        __typename: undefined,
      };
      delete serviceData.show;
      delete serviceData.name;
      delete serviceData.id;
      update({
        variables: {
          serviceId: currentService.id,
          updatedService: {
            ...serviceData,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_SERVICES,
          },
        ],
      });
      reset();
      setModalOpen(false);
    } else {
      addService({
        variables: {
          addService: { ...data },
        },
        refetchQueries: [
          {
            query: GET_ALL_SERVICES,
          },
        ],
      });
      reset();
      setModalOpen(false);
    }
  };
  return (
    <FormFields onSubmit={handleSubmit(data => editFormSubmitHandler(data))}>
      {updateServiceError && <p>Something went wrong.</p>}
      <InputField
        register={register}
        dataRef="displayName"
        type="text"
        title="Service Name"
        errors={errors}
        required
      />
      {/* <InputField
        register={register}
        dataRef="boldTitle"
        type="text"
        title="Bold Title"
        errors={errors}
      /> */}
      <InputField
        register={register}
        dataRef="description"
        type="text-area"
        title="Description"
        errors={errors}
      />
      <InputField
        register={register}
        dataRef="price"
        type="text"
        title="Price"
        errors={errors}
        required
      />
      <FormDropdownEnum
        dataRef="serviceType"
        register={register}
        errors={errors}
        title="Service Type"
        required
        content={serviceOptions || ''}
      />
      <button className="btn btn--blue" type="submit">
        Save
      </button>
      {updateServiceLoading && <p>Sending...</p>}
    </FormFields>
  );
};

export default EditServiceForm;
