import { Machine, assign } from 'xstate';
import { useApolloClient } from '@apollo/client';

// IMPORT MACHINE CONFIG
import machineConfig from './MachineConfig';

// IMPORT QUERIES AND MUTATIONS
import queries from './queries';

function createServices() {
  // GET APOLLO CLIENT TO SEND TO MUTATIONS
  const client = useApolloClient();

  // GET QUERIES AND MUTATIONS FROM IMPORT
  const { getApplication } = queries(client);

  return {
    guards: {},

    services: {
      getApplication: ctx => {
        return getApplication(ctx.applicationId);
      },
    },

    actions: {
      setApplicationToContext: assign({
        application: (_, { data }) => {
          return data.application;
        },
        services: (_, { data }) => {
          return data.services;
        },
      }),
    },
  };
}

function create(id) {
  // CREATE ALL SERVICES FROM ABOVE
  const services = createServices();

  // RETURN MACHINE WITH CONFIG AND SERVICES
  return Machine({ ...machineConfig(id) }, { ...services });
}

export default create;
