import React from 'react';
import styled from 'styled-components';
import Termsofservice from '../form-components/FormSteps/ReviewApplication/Terms/TermsOfService';

const TermsPage = () => (
  <StyledContainer>
    <Termsofservice agreed />
  </StyledContainer>
);

export default TermsPage;

const StyledContainer = styled.div``;
