import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import EvaluationReview from '../../components/dashboard-pages/Evaluation/index';

const ReviewEvaluation = () => {
  return (
    <DashboardTemplate>
      <EvaluationReview />
    </DashboardTemplate>
  );
};

export default ReviewEvaluation;
