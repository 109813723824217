import { gql } from '@apollo/client';
import gqlc from '../../shared/gqlc';

export const FETCH_SERVICES = gql`
  query FETCH_SERVICES {
    services {
      all {
        id
        name
        displayName
        description
        price
        serviceType
      }
    }
  }
`;

const CREATE_CART = gql`
  query CREATE_CART($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          cart {
            review(serviceId: $serviceId) {
              applicationId
              total
              billTo {
                firstName
                lastName
                company
                phoneNumber
                address {
                  line1
                  city
                  state
                  postalCode
                  country
                }
              }
              lineItems {
                name
                id
                displayName
                description
                price
                serviceType
                quantity
              }
            }
          }
        }
      }
    }
  }
`;

export default client => {
  const { query } = gqlc(client);

  function fetchServices() {
    return query(FETCH_SERVICES).then(data => data.services.all);
  }

  function createCart({ applicationId, serviceId }) {
    return query(CREATE_CART, {
      applicationId,
      serviceId,
    }).then(data => data.applications.me.application.cart.review);
  }

  return {
    fetchServices,
    createCart,
  };
};
