import React from 'react';
import Constants from '../../../../../shared/Constants';
import {
  constructAddress,
  returnService,
  returnServiceByType,
  servicesByCountry,
} from '../../../../../shared/functions';
import { Hr, ListBreak } from './styles';

const DeliveryInstructions = ({ formState, send }) => {
  const {
    step5: {
      additionalReportsMailedAddresses,
      additionalPDFEmails,
      emailAddressToReturn,
      rushApplicationServiceID,
      returnDocumentsOption,
      otherRetunDocsAddress,
      returnDocumentsEmail,
    },
    services,
    addressOnFile,
  } = formState.context;

  // EMAIL SERVICE OBJECT:
  const emailService = services.find(
    service => service.serviceType === 'ADDITIONAL_EMAIL',
  );

  // RETURN DOCS BY EMAIL PRICE:
  const returnDocsEmailPrice =
    services.find(
      service =>
        service.serviceType === Constants.SERVICE.RETURNING_DOCUMENTS &&
        service.displayName === Constants.ADDRESS.EMAIL,
    )?.price || '--';

  // GET RUSH SERVICE INFO:
  const { displayName: rushName, price: rushPrice } = returnService(
    services,
    rushApplicationServiceID,
  );

  // RETURN DOCUMENTS OPTION AND ADDRESS
  let returnDocsAddress;
  let returnDocsByEmail = false;
  if (returnDocumentsOption === Constants.ADDRESS.ADDRESS_ON_APPLICATION) {
    const shippingMethod = servicesByCountry(
      returnServiceByType(services, Constants.SERVICE.RETURNING_DOCUMENTS),
      addressOnFile.country,
    )[0];

    returnDocsAddress = { ...addressOnFile, shippingMethod: shippingMethod.id };
  }
  if (returnDocumentsOption === Constants.ADDRESS.OTHER_ADDRESS) {
    returnDocsAddress = otherRetunDocsAddress;
  }
  if (returnDocumentsOption === Constants.ADDRESS.EMAIL) {
    returnDocsByEmail = true;
  }

  // ADDITIONAL REPORTS MAILED INFO:
  const addReportsCount = additionalReportsMailedAddresses?.length;

  // ADDITIONAL REPORTS EMAILED
  const emailedReportsCount = additionalPDFEmails?.length;

  return (
    <div className="section">
      <div className="section__header">
        <h2>05. Delivery Instructions</h2>
        <button
          aria-label="Edit Delivery Instructions"
          className="edit-button"
          type="button"
          onClick={() => send('STEP_FIVE')}
        >
          Edit
        </button>
      </div>
      <hr />
      <div className="section__content">
        <div className="section__content-block">
          <div>
            <p>
              <strong>Email to send report: </strong>{' '}
              <span>{emailAddressToReturn}</span>
            </p>
          </div>

          <p>
            <strong>Rush Service:</strong> {rushName}{' '}
            <strong>
              <em>&nbsp;({`$${rushPrice}`})</em>
            </strong>
          </p>
          {returnDocsAddress && (
            <div>
              <strong>Return original mailed documents:</strong>{' '}
              {constructAddress(returnDocsAddress, true, services)}{' '}
            </div>
          )}
          {returnDocsByEmail && (
            <div>
              <strong>Return original mailed documents by email:</strong>{' '}
              {returnDocumentsEmail}
              {returnDocsEmailPrice !== '--' && (
                <strong>
                  <em>&nbsp;({`$${returnDocsEmailPrice}`})</em>
                </strong>
              )}
            </div>
          )}

          {/* ADDITIONAL REPORTS SECTION WILL SHOW ONLY IF THERE ARE MORE REPORTS ORDERED */}
          {addReportsCount > 0 && (
            <div>
              <Hr />
              <p>
                <strong>
                  <em>Additional reports</em>
                </strong>
              </p>
              <p>
                <strong>Number of reports:</strong> {addReportsCount}
              </p>

              {additionalReportsMailedAddresses.map((address, index) => {
                let hr = false;
                if (index !== 0) {
                  hr = true;
                }
                const {
                  price: addReportsPrice,
                  description: addReportsDescription,
                } = returnService(services, address.shippingMethod);
                return (
                  <div key={address.id}>
                    {hr && <ListBreak />}
                    <p>
                      <strong>Mailing instructions:</strong>{' '}
                      {constructAddress(address, true)}
                    </p>
                    <p>
                      <strong>Shipping:</strong> {addReportsDescription}{' '}
                      <strong>
                        <em>({`$${addReportsPrice}`})</em>
                      </strong>
                    </p>
                  </div>
                );
              })}
            </div>
          )}

          {/* ADDITIONAL EMAILED PDFS WILL ONLY SHOW IF THERE ARE ADDITIONAL EMAILS ADDED. */}
          {emailedReportsCount > 0 && (
            <div>
              <Hr />
              <p>
                <strong>Number of copies:</strong> {emailedReportsCount}{' '}
                <strong>
                  <em>&nbsp;({`$${emailService?.price} each`})</em>
                </strong>
              </p>
              <p>
                <strong>Email addresses:</strong>{' '}
                {additionalPDFEmails?.map(({ email }, index) => (
                  <span key={email}>
                    {' '}
                    {email}
                    {additionalPDFEmails?.length !== index + 1 && ','}&nbsp;
                  </span>
                ))}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryInstructions;
