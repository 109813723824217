import { gql } from '@apollo/client';

export const UPDATE_STATUS = gql`
  mutation copyRequestUpdateStatus($id: String) {
    copyRequest {
      updatePayStatus(id: $id)
    }
  }
`;

export default client => {
  const approvePending = id => {
    return client
      .mutate({
        mutation: UPDATE_STATUS,
        variables: { id },
      })
      .then(({ data }) => {
        const results = { ...data };
        return results;
      });
  };

  return {
    approvePending,
  };
};
