import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { applicationFields } from '../sharedVariables';

export const SAVE_EVALUATION_OPTIONS = gql`
  mutation saveOptions($appID: String!, $evaluator: String!, $status: String!, $notes: String!){
    evaluation{
      saveOptions(appID: $appID, evaluator:$evaluator, status:$status, notes: $notes){
        ${applicationFields}
      }
    }
  }
`;

export const SAVE_APPLICATION_DETAILS = gql`
  mutation saveApplicantDetails(
   $updatedFields: AdminUpdateApplicationInput!,
   $applicationId: String!
  ) {
    applications {
      application(id: $applicationId) {
        saveApplicantDetails(
          updatedFields: $updatedFields
        ) {
          ${applicationFields}
        }
      }
    }
  }
`;

export const SAVE_EVALUATION_DETAILS = gql`
  mutation saveDetails($appID: String!, $firstName: String, $middleName: String, $lastName: String, $differentFirstName:String, $differentMiddleName: String,  $differentLastName: String,   $evaluatorNotes: String,   $evaluationCompleteDate: DateTime, $refNum: String){
    evaluation{
      saveDetails(appID: $appID, firstName: $firstName, middleName: $middleName, lastName: $lastName, differentFirstName: $differentFirstName, differentMiddleName: $differentMiddleName,  differentLastName: $differentLastName,   evaluatorNotes: $evaluatorNotes,   evaluationCompleteDate: $evaluationCompleteDate, refNum: $refNum){
        ${applicationFields}
      }
    }
  }
`;

export const COMPLETE_EVALUATION = gql`
  mutation completeEvaluation($appID: String, $admin: String){
    evaluation{
      completeEvaluation(appID: $appID, admin:$admin){
        ${applicationFields}
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation appUpdateStatus($appID: String!) {
    evaluation {
      signAndCompleteEvaluation(appID: $appID)
    }
  }
`;

export const DELETE_SCHOOL = gql`
  mutation deleteSchool($id: String!, $applicationId: String!) {
    applications {
      application(id: $applicationId) {
        adminDeleteSchoolInfo(id: $id) {
          country
          degree
          degreeNA
          endDate
          school
          startDate
          study
          id
          degreeDate
        }
      }
    }
  }
`;
export const SAVE_SCHOOL = gql`
  mutation saveSchool(
    $school: AdminUpdateAddSchoolInput!
    $applicationId: String!
  ) {
    applications {
      application(id: $applicationId) {
        saveSchoolInfo(schoolInfo: $school) {
          country
          degree
          degreeNA
          endDate
          school
          startDate
          study
          id
          degreeDate
        }
      }
    }
  }
`;

export default client => {
  const saveOverview = incomingData => {
    const { evaluator, notes, status, appID } = incomingData;
    const variables = { appID, evaluator, notes, status };
    return client
      .mutate({
        mutation: SAVE_EVALUATION_OPTIONS,
        variables,
        errorPolicy: 'all',
      })
      .then(({ data, errors }) => {
        if (errors) {
          // do we throw?
          errors.forEach(error => {
            Sentry.captureException(new Error(error.message));
          });
        }
        return data;
      });
  };

  const saveApplicationDetails = incomingData => {
    const formData = { ...incomingData };
    const {
      id,
      line1,
      line2,
      city,
      state,
      country,
      postalCode,
      mailCity,
      mailCountry,
      mailLine1,
      mailLine2,
      mailPostalCode,
      mailState,
      mailCompany,
      mailContact,
    } = formData;
    // clean data object to match graphQL input object
    delete formData.id;
    delete formData.line1;
    delete formData.line2;
    delete formData.city;
    delete formData.state;
    delete formData.country;
    delete formData.postalCode;
    delete formData.mailCity;
    delete formData.mailContact;
    delete formData.mailCompany;
    delete formData.mailState;
    delete formData.mailCountry;
    delete formData.mailLine1;
    delete formData.mailLine2;
    delete formData.mailPostalCode;
    delete formData.mailPostalState;
    delete formData.fileCount;
    delete formData.pageCount;
    const variables = {
      updatedFields: {
        ...formData,
        address: {
          id,
          line1,
          line2,
          city,
          state,
          country,
          postalCode,
        },
        evaluationDeliveryAddress: {
          city: mailCity,
          country: mailCountry,
          line1: mailLine1,
          line2: mailLine2,
          postalCode: mailPostalCode,
          state: mailState,
          company: mailCompany,
          contact: mailContact,
        },
      },
      applicationId: formData.applicationId,
    };
    return client
      .mutate({
        mutation: SAVE_APPLICATION_DETAILS,
        variables,
      })
      .then(data => {
        if (data.errors) {
          // do we throw?
          data.errors.forEach(error => {
            Sentry.captureException(new Error(error.message));
            throw new Error(error.message);
          });
        }
        return data;
      });
  };

  const saveEvaluationDetails = incomingData => {
    const variables = {
      ...incomingData,
      evaluationCompleteDate: undefined,
    };
    return client
      .mutate({
        mutation: SAVE_EVALUATION_DETAILS,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          // do we throw?
          errors.forEach(error => {
            Sentry.captureException(new Error(error.message));
          });
        }
        return data;
      });
  };

  const updateAssignedTo = (appID, admin) => {
    return client
      .mutate({
        mutation: COMPLETE_EVALUATION,
        variables: { appID, admin },
      })
      .then(({ data, errors }) => {
        if (errors) {
          // do we throw?
          errors.forEach(error => {
            Sentry.captureException(new Error(error.message));
          });
        }
        return data;
      });
  };

  const signAndCompleteEvaluation = ({ appID }) => {
    return client.mutate({
      mutation: UPDATE_STATUS,
      variables: {
        appID,
      },
    });
  };
  const saveSchool = (school, applicationId) => {
    return client
      .mutate({
        mutation: SAVE_SCHOOL,
        variables: {
          school,
          applicationId,
        },
      })
      .then(({ data, error }) => {
        if (error) {
          throw new Error();
        }
        return data.applications.application.saveSchoolInfo;
      });
  };

  const deleteSchool = (id, applicationId) => {
    return client
      .mutate({
        mutation: DELETE_SCHOOL,
        variables: {
          id,
          applicationId,
        },
      })
      .then(({ data, error }) => {
        if (error) {
          throw new Error();
        }
        return data.applications.application.adminDeleteSchoolInfo;
      });
  };

  return {
    saveOverview,
    saveApplicationDetails,
    saveEvaluationDetails,
    updateAssignedTo,
    signAndCompleteEvaluation,
    saveSchool,
    deleteSchool,
  };
};
