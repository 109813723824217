import React from 'react';
import FrontendHeader from '../components/client-hero/client-hero.component';
import WholeForm from '../components/form-components/WholeForm';

const ClientApplicationPage = () => {
  return (
    <>
      <FrontendHeader />
      <WholeForm />
    </>
  );
};

export default ClientApplicationPage;
