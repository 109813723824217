import styled from 'styled-components';

export const StyledForm = styled.div`
  max-width: 1420px;
  margin: 0 auto;
  padding: 30px;
  .temp-width {
    max-width: 550px;
    margin: 0 auto;
  }
  .sbs {
    display: flex;
    margin: 0 -10px;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
    &.page-count {
      margin-top: 30px;
    }
    .input-group {
      width: 50%;
      margin: 0 10px 30px;
      @media only screen and (max-width: 500px) {
        width: auto;
      }
      input {
        @media only screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }
  .errorMessage {
    color: #d24012;
  }
  .input-group,
  .SF-directions,
  .SF-segment-title,
  .temp-message {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    label {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  .error {
    color: #d24012;
    input {
      border-color: red;
    }
  }
  /* on step 5 of form */
  .price-block {
    h3 {
      margin-bottom: 10px;
    }
    margin-bottom: 30px;
  }
  .btn {
    font-weight: 600;
    padding: 20px 30px;
    min-width: 200px;
    cursor: pointer;
    text-align: center;
    display: block;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    &--blue {
      background-color: #14527f;
      color: white;
      transition: all ease 0.3s;
      &:hover {
        background-color: #071e2e;
      }
    }
  }
  .input-group {
    margin-bottom: 30px;
    &--title {
      margin-bottom: 15px;
      .number {
        color: #68b6ec;
      }
    }
    &--bold {
      font-weight: 700;
    }
    &--stacked {
      display: flex;
      div {
        margin-right: 25px;
        input {
          margin-right: 10px;
        }
      }
    }
    &--wrapped {
      flex-wrap: wrap;
    }
  }
  .SF-side-by-side {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
      align-items: unset;
    }
    .SF-form-group:nth-child(1) {
      margin-right: 24px;
    }
    &-3 {
      margin: 0 -1.5% 30px;
      @media only screen and (max-width: 500px) {
        margin: 0;
        margin-bottom: 30px;
      }
      .input-group {
        width: 30%;
        margin-bottom: 0;
        @media only screen and (max-width: 500px) {
          width: 100%;
          margin: 0;
          margin-bottom: 30px;
        }
      }
      .SF-form-group:nth-child(1),
      .SF-form-group:nth-child(2) {
        margin-right: 24px;
      }
      .error-block {
        margin: 10px 1.5% 0;
        width: 100%;
      }
    }
    &-2 {
      .input-group {
        width: 45%;
        margin: 0;
        @media only screen and (max-width: 500px) {
          width: 100%;
          margin: 0;
          margin-bottom: 30px;
        }
        &:nth-child(1) {
          margin-right: 30px;
          @media only screen and (max-width: 500px) {
            margin-right: 0;
          }
        }
      }
    }
  }
  //FORM GROUPS ARE INPUT WITH LABEL
  .SF-form-group {
    padding: 10px 0 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    label {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 12px;
    }
  }
  .SF-segment-title {
    font-size: 18px;
    font-weight: 700;
    padding: 0px 0 20px;
    &--big {
      font-size: 22px;
    }
  }
  hr {
    margin: 50px auto;
    background-color: #cbd5e0;
    border: solid 1px #cbd5e0;
    &.option-sep {
      margin: 30px 0;
    }
  }
  .SF-collapsable {
    overflow: hidden;
    transition: all ease 0.3s;
    max-height: 900px;
    &.SF-collapsed {
      max-height: 2px;
      transition: all ease 0.3s;
    }
  }
  //RADIO GROUPS
  .RS-radio-group {
    .RS-title {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 16px;
      &--bold {
        font-weight: 700;
      }
    }
    .RS-radio-cont {
      display: flex;
      &--block {
        flex-direction: column;
      }
      label {
        margin-right: 24px;
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;
        input {
          margin-right: 10px;
          background-color: #14527f;
        }
      }
    }
  }

  .SF-directions {
    margin-bottom: 20px;
  }

  input,
  select {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    border: solid 2px #c0c0c0;
    width: 100%;
    &.error {
      border-color: #d24012;
      color: #d24012;
    }
  }
  input[type='radio'] {
    width: auto;
  }
  select {
    cursor: pointer;
  }
  .required {
    color: #d24012 !important;
  }

  .na-checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    input {
      width: auto;
      margin-right: 10px;
    }
  }

  .document-upload {
    width: 100%;
    background-color: blue;
    height: 500px;
    border-radius: 15px;
    margin: 20px 0;
  }
  .checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    input {
      display: inline-block;
      width: unset;
      margin-right: 10px;
    }
    label {
      margin-bottom: 0;
    }
  }
  .req-docs__address {
    p {
      margin-bottom: 5px;
    }
  }

  .additional-address {
    position: relative;
    &--header {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    &--button {
      background-color: transparent;
      color: #14527f;
      border: none;
      cursor: pointer;
      font-weight: 500;
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #14527f;
        border-radius: 100%;
        color: white;
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }
      svg {
        fill: #14527f;
        transition: all ease 0.3s;
        margin-right: 3px;
      }
      &:hover {
        color: #071e2e;
        span {
          background-color: #071e2e;
        }
        svg {
          fill: #68b6ec;
        }
      }
    }
    &__remove {
      margin-left: 3px;
      svg {
        margin-right: 5px;
      }
    }
  }
  .formErrors {
    margin-bottom: 10px;
  }
`;

export const TempSchoolComponent = styled.div`
  width: 70%;
  background-color: blue;
  margin: 30px auto;
  border-radius: 15px;
  height: 300px;
`;
