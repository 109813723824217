import { useAuth0 } from '@auth0/auth0-react';

import posthog from 'posthog-js';

const Logout = () => {
  const { logout } = useAuth0();

  posthog.reset();

  logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
};

export default Logout;
