import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import InputField from '../../../InputField/InputField';
import DateInput from '../../../DateInput/DateInput';
import schema from './SchoolFormValidationSchema';
import ValidationErrors from '../../../ValidationErrors/ValidationErrors';

import FormDropdown from '../../../FormDropdown/FormDropdown';

function month2number(month) {
  let returnMonth;
  switch (month) {
    case 'Jan':
      returnMonth = '01';
      break;
    case 'Feb':
      returnMonth = '02';
      break;
    case 'Mar':
      returnMonth = '03';
      break;
    case 'Apr':
      returnMonth = '04';
      break;
    case 'May':
      returnMonth = '05';
      break;
    case 'Jun':
      returnMonth = '06';
      break;
    case 'Jul':
      returnMonth = '07';
      break;
    case 'Aug':
      returnMonth = '08';
      break;
    case 'Sep':
      returnMonth = '09';
      break;
    case 'Oct':
      returnMonth = '10';
      break;
    case 'Nov':
      returnMonth = '11';
      break;
    default:
      returnMonth = '12';
      break;
  }
  return returnMonth;
}

const Step2 = ({ submitHandle, formValues }) => {
  // CHECKING BROWSER FOR ISSUE WITH DATE CONVERSION
  const userAgentString = navigator.userAgent;
  const chromeAgent = userAgentString.indexOf('Chrome') > -1;
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  if (chromeAgent && safariAgent) safariAgent = false;
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ...formValues,
      startDateTime: '0',
      endDateTime: '0',
      awardDateTime: '0',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = data => {
    submitHandle(data);
    reset();
  };

  const watchAttenStartMonth = watch('startDate.month');
  const watchAttenStartYear = watch('startDate.year');
  const watchAttenEndMonth = watch('endDate.month');
  const watchAttenEndYear = watch('endDate.year');
  const watchAwardMonth = watch('awardDate.month');
  const watchAwardYear = watch('awardDate.year');
  const watchDegreeNA = watch('degree.notApplicable');

  // Used to show state form when edited
  // useEffect(() => {
  //   const sub = watch(d => {
  //     console.log(d);
  //   });
  //   return () => {
  //     sub.unsubscribe();
  //   };
  // }, [watch]);

  // UPDATE START DATE TIME WHEN START DATE MONTH AND YEAR ARE POPULATED
  useEffect(() => {
    const { startDate } = getValues();
    if (startDate.month !== '' && startDate.year !== '') {
      // MAKE UNIX MILLISECONDS OUT OF START MONTH AND YEAR
      const date = dayjs(
        `${month2number(startDate?.month)}/01/${startDate?.year}`,
      ).valueOf();
      setValue('startDateTime', date);
    } else {
      setValue('startDateTime', 0);
    }
  }, [watchAttenStartMonth, watchAttenStartYear]);
  // UPDATE END DATE TIME WHEN END DATE MONTH AND YEAR ARE POPULATED
  useEffect(() => {
    const {
      endDate: { month, year },
    } = getValues();
    if (month !== '' && year !== '') {
      // MAKE UNIX MILLISECONDS OUT OF START MONTH AND YEAR
      const date = dayjs(`${month2number(month)}/01/${year}`).valueOf();
      setValue('endDateTime', date);
    } else {
      setValue('endDateTime', 0);
    }
  }, [watchAttenEndMonth, watchAttenEndYear]);

  // UPDATE AWARD DATE TIME WHEN AWARD DATE MONTH AND YEAR ARE POPULATED
  useEffect(() => {
    const {
      awardDate: { month, year },
    } = getValues();
    if (!month && !year) {
      setValue('awardDateTime', 0);
    } else if (month !== '' && year !== '') {
      // MAKE UNIX MILLISECONDS OUT OF START MONTH AND YEAR
      const date = dayjs(`${month2number(month)}/01/${year}`).valueOf();
      setValue('awardDateTime', date);
    } else {
      setValue('awardDateTime', 0);
    }
  }, [watchAwardMonth, watchAwardYear]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormDropdown
        dataRef="country"
        register={register}
        errors={errors}
        title="Country"
        content="country"
        required
      />
      <InputField
        register={register}
        dataRef="name"
        type="text"
        placeholder=""
        title="School/Institution"
        errors={errors}
        required
      />
      <InputField
        register={register}
        dataRef="areaOfStudy"
        type="text"
        placeholder=""
        title="Area Of Study"
        errors={errors}
        required
      />
      <h2 className="SF-segment-title">Attendance start date</h2>
      <DateInput
        dataPrefix="startDate"
        errors={errors}
        register={register}
        date={formValues?.startDate}
        monthSelected={watchAttenStartMonth}
        excludeDay
      />
      <ErrorMessage
        errors={errors}
        name="startDateTime"
        as="span"
        className="error"
      />
      <div className="checkbox-group">
        <label>
          <input
            readOnly
            name="startDateTime"
            {...register('startDateTime')}
            defaultValue="0"
            hidden
            type="hidden"
          />
        </label>
      </div>

      <h2 className="SF-segment-title">Attendance end date</h2>
      <DateInput
        dataPrefix="endDate"
        errors={errors}
        register={register}
        date={formValues?.endDate}
        monthSelected={watchAttenEndMonth}
        excludeDay
      />
      <ErrorMessage
        errors={errors}
        name="endDateTime"
        as="span"
        className="error"
      />
      <div className="checkbox-group">
        <label>
          <input
            readOnly
            name="endDateTime"
            {...register('endDateTime')}
            defaultValue="0"
            hidden
            type="hidden"
          />
        </label>
      </div>
      <InputField
        register={register}
        dataRef="degree.title"
        type="text"
        placeholder=""
        title="Title of Degree/Diploma in native language"
        errors={errors}
        required={!watchDegreeNA}
      />
      <div className="checkbox-group">
        <label>
          <input
            type="checkbox"
            name="degree.notApplicable"
            {...register('degree.notApplicable')}
          />
          Not applicable
        </label>
      </div>
      {!watchDegreeNA && (
        <>
          <h2 className="SF-segment-title">Degree/diploma award date</h2>
          <DateInput
            dataPrefix="awardDate"
            errors={errors}
            register={register}
            date={formValues?.awardDate}
            monthSelected={watchAwardMonth}
            excludeDay
          />
          <ErrorMessage
            errors={errors}
            name="awardDateTime"
            as="span"
            className="error"
          />
          <div className="checkbox-group">
            <label>
              <input
                readOnly
                name="awardDateTime"
                {...register('awardDateTime')}
                defaultValue="0"
                hidden
                type="hidden"
              />
            </label>
          </div>
        </>
      )}

      <ValidationErrors errors={errors} />
      <button className="btn btn--blue" type="submit">
        Save Education
      </button>
      <hr />
    </form>
  );
};

export default Step2;
