import React, { useContext } from 'react';
import styled from 'styled-components';

import { ProfileContext } from '../auth/ValidateProfileAddContext';
import UserDropdownMenu from '../UserDropdownMenu/UserDropdownMenu';
import { ClientHeader } from './client-dashboard-header.styles';

const ClientDashboardHeader = ({ onApplication, toggleSideBar }) => {
  const userContext = useContext(ProfileContext);

  return (
    <ClientHeader className={onApplication && `on-application`}>
      {!onApplication && (
        <StyledHamburgerButton type="button" onClick={toggleSideBar}>
          <span />
          <span />
          <span />
        </StyledHamburgerButton>
      )}
      {!onApplication && (
        <h2 className="welcomeMessage">Welcome {userContext?.firstName}!</h2>
      )}
      <UserDropdownMenu onApplication={onApplication} />
    </ClientHeader>
  );
};

export default ClientDashboardHeader;

const StyledHamburgerButton = styled.button`
  width: 30px;
  height: 20px;
  border: none;
  box-sizing: border-box;
  display: none;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: 1300px) {
    display: flex;
  }
  span {
    width: 100%;
    background-color: #14527f;
    height: 4px;
    transition: all ease 0.3s;
  }
  &:active,
  &:hover,
  &:focus {
    cursor: pointer;
    span {
      background-color: #68b6ec;
    }
  }
`;
