import { gql } from '@apollo/client';

// ADMIN MUTATIONS

export const CREATE_USER = gql`
  mutation createUser($user: UserCreateInput!) {
    users {
      create(user: $user) {
        id
        email
        firstName
        lastName
        role
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation updateService(
    $serviceId: String!
    $updatedService: ApplicationServiceUpdateInput!
  ) {
    services {
      service(serviceId: $serviceId) {
        update(service: $updatedService) {
          id
          name
          serviceType
        }
      }
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation deleteService($serviceId: String!) {
    services {
      service(serviceId: $serviceId) {
        delete {
          id
        }
      }
    }
  }
`;

export const ADD_SERVICE = gql`
  mutation addService($addService: ApplicationServiceCreateInput) {
    services {
      create(service: $addService) {
        id
        serviceType
      }
    }
  }
`;

/// CLIENT APPLICATION MUTATIONS

export const CREATE_APPLICATION = gql`
  mutation createApplication($appData: ApplicationCreateInput!) {
    applications {
      me {
        create(application: $appData) {
          id
          createdAt
          appDocumentsInfo {
            id
          }
          deliveryInstructions {
            id
          }
        }
      }
    }
  }
`;

export const APPLICANT_INFO_CREATE = gql`
  mutation applicantInfoUpdate(
    $applicationId: String!
    $info: ApplicationApplicantInfoCreateInput!
  ) {
    applications {
      application(id: $applicationId) {
        createApplicantInfo(info: $info) {
          id
          address {
            id
            line1
            line2
            city
            state
            country
            postalCode
            contact
            company
          }
        }
      }
    }
  }
`;

export const APPLICANT_INFO_UPDATE = gql`
  mutation applicantInfoUpdate(
    $applicationId: String!
    $info: ApplicationApplicantInfoUpdateInput!
  ) {
    applications {
      application(id: $applicationId) {
        updateApplicantInfo(info: $info) {
          id
          address {
            id
            line1
            line2
            city
            state
            country
            postalCode
            contact
            company
          }
        }
      }
    }
  }
`;

export const CREATE_SCHOOL = gql`
  mutation createSchool(
    $appId: String!
    $schoolInfo: ApplicationSchoolInfoCreateInput!
  ) {
    applications {
      application(id: $appId) {
        createSchoolInfo(schoolInfo: $schoolInfo) {
          id
          country
          school
          study
          degree
          startDate
          endDate
          degreeDate
          degreeNA
        }
      }
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation updateSchool(
    $appId: String!
    $schoolInfo: ApplicationSchoolInfoUpdateInput!
  ) {
    applications {
      application(id: $appId) {
        updateSchoolInfo(schoolInfo: $schoolInfo) {
          id
          country
          school
          study
          degree
          startDate
          endDate
          degreeDate
          degreeNA
        }
      }
    }
  }
`;

export const DELETE_SCHOOL = gql`
  mutation deleteSchool($appId: String!, $id: String!) {
    applications {
      application(id: $appId) {
        deleteSchoolInfo(id: $id) {
          id
          country
          school
          study
          degree
          startDate
          endDate
          degreeDate
          degreeNA
        }
      }
    }
  }
`;

export const CREATE_DOUCMENTS = gql`
  mutation createDocuments(
    $appId: String!
    $info: ApplicationDocumentsInfoCreateInput!
  ) {
    applications {
      application(id: $appId) {
        createDocumentsInfo(documentsInfo: $info) {
          id
          documentsSentBy
          pageCount
          fileCount
        }
      }
    }
  }
`;

export const UPDATE_DOCUMENTS = gql`
  mutation updateDocuments(
    $appId: String!
    $info: ApplicationDocumentsInfoUpdateInput!
  ) {
    applications {
      application(id: $appId) {
        updateDocumentsInfo(documentsInfo: $info) {
          id
          documentsSentBy
          pageCount
          fileCount
        }
      }
    }
  }
`;

export const UPDATE_APPLICATION_SERVICE_TYPE = gql`
  mutation updateServiceType($appId: String!, $serviceId: String!) {
    applications {
      application(id: $appId) {
        updateApplicationType(service: $serviceId) {
          applicationType {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_DELIVERY_INSTRUCTIONS = gql`
  mutation updateStep5(
    $appId: String!
    $data: ApplicationDeliveryInstructionsUpdateInput!
  ) {
    applications {
      application(id: $appId) {
        updateDeliveryInstructions(instructions: $data) {
          id
        }
      }
    }
  }
`;

export const INITIATE_DELIVERY_INSTRUCTIONS = gql`
  mutation createStep5($appId: String!) {
    applications {
      application(id: $appId) {
        initiateDeliveryInstructions {
          id
        }
      }
    }
  }
`;

export const ADD_OTHER_ADDRESS = gql`
  mutation addOtherAddress($address: MailAddressInput!, $appId: String!) {
    applications {
      application(id: $appId) {
        addOtherAddress(address: $address) {
          id
        }
      }
    }
  }
`;

export const ADD_OTHER_SEND_ADDRESS = gql`
  mutation addOtherAddress($address: MailAddressInput!, $appId: String!) {
    applications {
      application(id: $appId) {
        addOtherAddress(address: $address) {
          id
          company
          contact
          line1
          line2
          city
          state
          country
          postalCode
        }
      }
    }
  }
`;

export const DELETE_OTHER_ADDRESS = gql`
  mutation deleteOtherAddress($addressId: String!, $appId: String!) {
    applications {
      application(id: $appId) {
        deleteOtherAddress(id: $addressId) {
          id
        }
      }
    }
  }
`;

export const REMOVE_SEND_ADDRESS_ON_ADDRESS = gql`
  mutation removeSendAddressOnAddress($addressId: String!, $appId: String!) {
    applications {
      application(id: $appId) {
        removeSendAddress(id: $addressId) {
          id
        }
      }
    }
  }
`;

export const REMOVE_RETURN_DOCS_ADDRESS_ON_ADDRESS = gql`
  mutation removeReturnDocsAddressOnAddress(
    $addressId: String!
    $appId: String!
  ) {
    applications {
      application(id: $appId) {
        removeReturnDocsAddress(id: $addressId) {
          id
        }
      }
    }
  }
`;

export const ADD_ADDITIONAL_REPORT_ADDRESS = gql`
  mutation addAdditionalReportAddress(
    $appId: String!
    $address: MailAddressInput!
    $instructionsId: String!
  ) {
    applications {
      application(id: $appId) {
        addAdditionalReportAddress(
          address: $address
          instructionsId: $instructionsId
        ) {
          id
          line1
          line2
          city
          state
          postalCode
          country
          company
          contact
          shippingMethod
        }
      }
    }
  }
`;

export const DELETE_ADDITIONAL_REPORT_ADDRESS = gql`
  mutation addAdditionalReportAddress(
    $appId: String!
    $addressId: String!
    $instructionsId: String!
  ) {
    applications {
      application(id: $appId) {
        deleteAdditionalReportAddress(
          addressId: $addressId
          instructionsId: $instructionsId
        ) {
          id
          line1
          line2
          city
          state
          postalCode
          country
          company
          contact
          shippingMethod
        }
      }
    }
  }
`;

export const UPDATE_TERMS = gql`
  mutation updateTerms($appId: String!, $initials: String!) {
    applications {
      application(id: $appId) {
        updateTerms(initials: $initials) {
          termsAgreement
          termsSignedDate
        }
      }
    }
  }
`;

export const SAVE_DOCUMENT_DATA = gql`
  mutation saveDocumentData(
    $appId: String!
    $data: ApplicationDocumentsInfoCreateInput!
  ) {
    applications {
      application(id: $appId) {
        appDocumentsInfo {
          createOrUpdate(documentsInfo: $data) {
            id
            documentsSentBy
            pageCount
            fileCount
            documents {
              id
              name
              url
              applicationDocumentsId
            }
          }
        }
      }
    }
  }
`;
