import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import DashboardTemplate from './Templates/DashboardTemplate';
import Button from '../components/UI/Button';

const Content = styled.div`
  p {
    margin-top: 2rem;
    font-size: 18px;
    max-width: 50rem;
    line-height: 1.5;
  }
  button {
    margin-top: 2rem;
  }
`;

const VerifyEmailPage = () => {
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    logout({ logoutParams: { returnTo: window.location.href } });
  }

  return (
    <DashboardTemplate>
      <Content>
        <h2>Unverified Email Address</h2>
        <p>
          Before you can continue your email address must first be verified. If
          you were previously logged in, you've now been logged out.
        </p>

        <p>
          Please check your email, and potentially spam, requesting you to
          verify your email address. Once verified you can login and continue.
        </p>

        <Button
          title="Login"
          text="Login"
          onClick={() => loginWithRedirect()}
        />
      </Content>
    </DashboardTemplate>
  );
};

export default VerifyEmailPage;
