/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { useUser } from '../../shared/useUserHook';
import Loading from '../shared/Loading';

export const ProfileContext = React.createContext(null);

export const ValidateProfileAddContext = ({ children }) => {
  const userProfile = useUser();

  // we don't have the userProfile from the DB yet.
  if (userProfile === null) return <Loading />;

  // user must have a completed profile to start
  if (userProfile === undefined) return <Navigate to="/create-profile" />;

  return (
    <ProfileContext.Provider value={userProfile}>
      {children || <Outlet />}
    </ProfileContext.Provider>
  );
};
