import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Trash } from '../../assets/trashicon.svg';
import { ReactComponent as Reply } from '../../assets/reply-all-fill.svg';
import { ReactComponent as Eye } from '../../assets/eye.svg';
import { ReactComponent as Confirm } from '../../assets/file-earmark-check-fill.svg';
import { camelCase } from '../../shared/functions';

const IncompleteApplicationItem = ({ application, send }) => {
  const navigate = useNavigate();

  const {
    createdAt,
    applicant: { id: userID },
    id,
    applicantInfo,
  } = application;
  let step;
  switch (true) {
    case application.applicantInfo === null:
      step = '-';
      break;
    case application.education.length === 0:
      step = 1;
      break;
    case application.appDocumentsInfo === null:
      step = 2;
      break;
    case application.applicationType === null:
      step = 3;
      break;
    case application.deliveryInstructions === null:
      step = 4;
      break;
    default:
      step = 5;
      break;
  }
  // IF NOTHING HAS BEEN FILLED OUT
  if (step === '-') {
    return null;
  }
  return (
    <tr>
      <td>{dayjs(createdAt).format('MM/DD/YYYY')}</td>
      <td>
        {applicantInfo?.firstName ? camelCase(applicantInfo?.firstName) : '-'}
      </td>
      <td>{applicantInfo?.lastName.toUpperCase() || '-'}</td>
      <td>{step}</td>
      <td>
        <button
          type="button"
          aria-label="delete"
          onClick={() => send('DELETE_ITEM', { data: application })}
        >
          <Trash />
        </button>
        <button
          type="button"
          aria-label="send email"
          onClick={() => send('SEND_REMINDER', { userID })}
        >
          <Reply /> Send Reminder
        </button>
        <button
          type="button"
          aria-label="view"
          onClick={() => navigate(`/admin/incomplete-application/${id}`)}
        >
          <Eye />
          View
        </button>
        {step === 5 && (
          <button
            type="button"
            aria-label="confirm application"
            onClick={() =>
              send('CONFIRM_APPLICATION', { applicationID: application.id })
            }
          >
            <Confirm />
            Confirm Application
          </button>
        )}
      </td>
    </tr>
  );
};

export default IncompleteApplicationItem;
