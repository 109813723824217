import React from 'react';

import styled from 'styled-components';

import { ErrorMessage } from '@hookform/error-message';
import AdditionalReports from './AdditionalReports';
import { returnServiceByType } from '../../../../../../shared/functions';
import Constants from '../../../../../../shared/Constants';

const Additionaladdresses = ({
  register,
  errors,
  watchItem,
  context,
  additionalDocsRegister,
  additionalDocserrors,
  send,
  formState,
  additionalShippingDocsErrors,
  additionalDocsShippingRegister,
}) => {
  const additionalReportsService = returnServiceByType(
    context.services,
    Constants.SERVICE.ADDITIONAL_REPORT_SHIPPING,
  );
  return (
    <>
      <div className="input-group">
        <p
          className="input-group--title input-group--bold"
          id="additionalReportsMailedOption_group"
        >
          <span className="number">03</span>
          Do you need additional reports mailed?
          <span className="required"> *</span>
        </p>
        <div
          className="input-group--stacked"
          role="group"
          aria-labelledby="additional Reports Mailed Option"
        >
          <div key="No">
            <Option
              id="additionalReportsMailedOption_No"
              name="additionalReportsMailedOption"
              type="radio"
              value="No"
              {...register('additionalReportsMailedOption')}
            />
            <OptionLabel htmlFor="additionalReportsMailedOption_No">
              No
            </OptionLabel>
          </div>
          <div key="Yes">
            <Option
              id="additionalReportsMailedOption_Yes"
              name="additionalReportsMailedOption"
              type="radio"
              value="Yes"
              {...register('additionalReportsMailedOption')}
            />
            <OptionLabel htmlFor="additionalReportsMailedOption_Yes">
              Yes
            </OptionLabel>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="additionalReportsMailedOption"
          as="span"
          className="error"
        />
      </div>

      {watchItem === 'Yes' && (
        <>
          <div className="price-block">
            <h3>Prices:</h3>
            {additionalReportsService.map(({ id, displayName, price }) => {
              return <p key={id}>{`${displayName} $${price}.00`}</p>;
            })}
          </div>
          <AdditionalReports
            register={additionalDocsRegister}
            errors={additionalDocserrors}
            toggle={watchItem}
            formId="additionalReportsForm"
            applicationID={context.applicationID}
            send={send}
            additionalAddresses={context.step5.additionalReportsMailedAddresses}
            services={additionalReportsService}
            formState={formState}
            additionalShippingDocsErrors={additionalShippingDocsErrors}
            additionalDocsShippingRegister={additionalDocsShippingRegister}
            context={context}
          />
        </>
      )}
    </>
  );
};

export default Additionaladdresses;

const Option = styled.input`
  &:disabled {
    cursor: not-allowed;
  }
`;

const OptionLabel = styled.label`
  color: ${props => (props.isDisabled ? 'lightgrey' : 'unset')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
