import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';

// import TextInput from '../../UI/FormInputs/TextInput/TextInput';
import SelectInput from '../../UI/FormInputs/SelectInput/SelectInput';
// import RadioGroup from '../../UI/FormInputs/RadioGroup/RadioGroup';

import { Button, FormFields } from '../../../shared/styles';

const schema = yup.object().shape({
  enableRushService: yup.string(),
  newAppHandler: yup.string().required('Please choose an option'),
  copyRequestHandler: yup.string().required('Please choose an option'),
  mailAdminHandler: yup.string().required('Please choose an option'),
  completedFraud: yup.string().required('Please choose an option'),
});

const ConfigureForm = ({ onSubmit, state, formState }) => {
  const {
    admins: adminList,
    enableRushService: rushBool,
    newAppHandler,
    copyRequestHandler,
    mailAdminHandler,
    completedFraud,
  } = state;
  const formDefaultValues = {
    enableRushService: rushBool ? 'Yes' : 'No',
    newAppHandler,
    copyRequestHandler,
    mailAdminHandler,
    completedFraud,
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
  });
  return (
    <FormFields onSubmit={handleSubmit(onSubmit)}>
      <div className="input-group">
        <p
          className="input-group--title input-group--bold"
          id="enableRushService_group"
        >
          Enable Rush Service <span className="required">*</span>
        </p>
        <div
          className="input-group--stacked"
          role="group"
          aria-labelledby="enableRushService_group"
        >
          <div>
            <input
              id="enableRushService_yes"
              name="enableRushService"
              type="radio"
              value="Yes"
              {...register('enableRushService')}
            />
            <label
              className="radio-label"
              htmlFor="enableRushService_yes"
              title="Enable Rush Service"
            >
              Yes
            </label>
          </div>
          <div>
            <input
              id="enableRushService_no"
              name="enableRushService"
              type="radio"
              value="No"
              {...register('enableRushService')}
            />
            <label
              className="radio-label"
              htmlFor="enableRushService_no"
              title="Enable Rush Service"
            >
              No
            </label>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="enableRushService"
          as="span"
          className="error"
        />
      </div>
      <SelectInput
        errors={errors}
        register={register}
        dataRef="newAppHandler"
        title="Default user for new applications"
        required
        data={adminList}
      />
      <span>{errors?.newAppHandler?.message}</span>
      <SelectInput
        errors={errors}
        register={register}
        dataRef="copyRequestHandler"
        title="Default user for copy requests"
        required
        data={adminList}
      />
      <SelectInput
        errors={errors}
        register={register}
        dataRef="mailAdminHandler"
        title="Admin that is assigned after completed evaluation"
        required
        data={adminList}
      />
      <SelectInput
        errors={errors}
        register={register}
        dataRef="completedFraud"
        title="Admin that is assigned for completed or fraud applications"
        required
        data={adminList}
      />
      <Button type="submit">Save Settings</Button>
      {formState.matches('saved') && <p>Settings Updated</p>}
    </FormFields>
  );
};

export default ConfigureForm;
