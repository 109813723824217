import { gql } from '@apollo/client';
// import { applicationFields } from '../sharedVariables';
import { COPY_REQUESTS } from './queries';

export const UPDATE_STATUS = gql`
  mutation copyRequestUpdateStatus($status: String, $id: String) {
    copyRequest {
      updateStatus(status: $status, id: $id) {
        id
      }
    }
  }
`;

export default client => {
  const updateStatus = incomingData => {
    const { status, id } = incomingData;
    return client
      .mutate({
        mutation: UPDATE_STATUS,
        variables: { status, id },
        refetchQueries: [
          {
            query: COPY_REQUESTS,
          },
        ],
      })
      .then(({ data }) => {
        const results = { ...data };
        return results;
      });
  };

  return {
    updateStatus,
  };
};
