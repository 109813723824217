import styled from 'styled-components';

export const ClientHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: ${props => (props.application ? 'solid 2px #e2e8f0' : 'none')};
  padding: 30px;
  border-bottom: solid #e2e8f0 1px;
  &.on-application {
    border-bottom: none;
  }
  @media print {
    display: none;
  }

  p {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  svg {
    margin-left: 7px;
  }
`;

export default ClientHeader;
