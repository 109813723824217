function createConfig() {
  return {
    id: 'copyRequests',
    initial: 'init',
    context: {
      items: [],
      currentPage: 1,
      pages: null,
      itemsToShow: 1000,
      sortRef: null,
      sortDatePaid: true,
      sortName: null,
      sortStatus: null,
    },

    states: {
      init: {
        invoke: {
          id: 'initialLoad',

          src: 'load',
          onDone: {
            actions: ['loadSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      waiting: {
        on: {
          INIT: 'init',
          APPROVE_COPY_REQUEST: { target: 'approveCopyRequest' },
        },
      },

      approveCopyRequest: {
        invoke: {
          id: 'approveCopyRequest',

          src: 'approveCopyRequest',
          onDone: {
            target: 'init',
          },
          onError: 'loadingFailed',
        },
      },
      loadingFailed: {},
    },
    on: {},
  };
}

export default createConfig;
