import * as Sentry from '@sentry/react';

function create(client) {
  function mutate(gql, variables) {
    return client
      .mutate({
        mutation: gql,
        variables,
      })
      .then(({ data }, errors) => {
        if (errors) {
          Sentry.captureException(
            new Error('GraphQL error', { extra: errors }),
          );

          throw new Error('Error Calling Mutation.');
        }

        return data;
      })
      .catch(networkError => {
        Sentry.captureException(
          new Error(`Network error: ${networkError.message}`),
        );

        throw new Error('Network Error Calling Mutation.');
      });
  }

  function query(gql, variables) {
    return client
      .query({
        query: gql,
        variables,
      })
      .then(({ data }, errors) => {
        if (errors) {
          Sentry.captureException(
            new Error('GraphQL error', { extra: errors }),
          );

          throw new Error('Error Calling Query.');
        }

        return data;
      })
      .catch(networkError => {
        Sentry.captureException(
          new Error(`Network error: ${networkError.message}`),
        );

        throw new Error('Network Error Calling Query.');
      });
  }

  return { mutate, query };
}

export default create;
