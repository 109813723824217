import * as yup from 'yup';
import Constants from '../../../../shared/Constants';

import { CompanyFormFieldsSchema } from '../shared/schemas';

export const step5Schema = yup.object().shape({
  returnDocumentsEmail: yup
    .string()
    .when('returnDocumentsOption', {
      is: Constants.ADDRESS.EMAIL,
      then: schema =>
        schema
          .email(Constants.FORM_ERROR_MESSAGES.VALID_EMAIL)
          .required(Constants.FORM_ERROR_MESSAGES.EMAIL)
          .nullable(),
    })
    .nullable(),
  emailAddressToReturn: yup
    .string()
    .email(Constants.FORM_ERROR_MESSAGES.VALID_EMAIL)
    .required(Constants.FORM_ERROR_MESSAGES.EMAIL),
  rushApplicationServiceID: yup
    .string()
    .typeError(Constants.FORM_ERROR_MESSAGES.SELECTION)
    .required(Constants.FORM_ERROR_MESSAGES.SELECTION),
  returnDocumentsOption: yup
    .string()
    .typeError(Constants.FORM_ERROR_MESSAGES.SELECTION)
    .required(Constants.FORM_ERROR_MESSAGES.SELECTION),
  returnDocsOtherAddressValidationInput: yup
    .string()
    .when('returnDocumentsOption', {
      is: Constants.ADDRESS.OTHER_ADDRESS,
      then: schema =>
        schema
          .min(1, Constants.FORM_ERROR_MESSAGES.ALT_ADDRESS)
          .typeError(Constants.FORM_ERROR_MESSAGES.ALT_ADDRESS)
          .required(Constants.FORM_ERROR_MESSAGES.ALT_ADDRESS),
    }),

  additionalPDFOption: yup.boolean(),

  emailArray: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email('Please enter a valid email')
        .required('Please enter an email'),
    }),
  ),
});

// export const createSchema = id => {
//   return yup.object().shape({
//     deliveryOptionApplicationServiceID: yup
//       .string()
//       .typeError('Please choose an option')
//       .required('Please choose an option'),

//     mailOption: yup.string().when('deliveryOptionApplicationServiceID', {
//       is: deliveryOptionApplicationServiceID =>
//         deliveryOptionApplicationServiceID !== id,
//       then: yup
//         .string()
//         .typeError('Please choose an option')
//         .required('Please choose an option'),
//     }),

//     sendMailAddressId: yup.string().when('sendReportAddressOption', {
//       is: 'Address on application',
//       then: yup.string().required('please enter an ID'),
//     }),

//     sendMailOtherAddressId: yup.string().when('sendReportAddressOption', {
//       is: 'Other address',
//       then: yup.string().required('please enter an alternate address'),
//     }),

//     emailAddressToReturn: yup
//       .string()
//       .when('deliveryOptionApplicationServiceID', {
//         is: deliveryOption => deliveryOption === id,
//         then: yup
//           .string()
//           .email('Please enter a valid email')
//           .required('Please enter an email'),
//       }),

//     rushApplicationServiceID: yup
//       .string()
//       .typeError('Please choose if you need rush service')
//       .required('Please choose if you need rush service'),

//     returnDocsLocation: yup.string().when('returnDocumentsOption', {
//       is: 'Other Address',
//       then: yup
//         .string()
//         .typeError('please choose an option')
//         .required('please choose an option')
//         .nullable(),
//     }),

//     returnDocumentsOption: yup
//       .string()
//       .typeError('Please choose an option')
//       .required(),

//     returnDocumentsAddressId: yup.string().when('returnDocumentsOption', {
//       is: 'Address on application',
//       then: yup.string().required('please enter an id'),
//     }),
//     returnDocsOtherAddressId: yup.string().when('returnDocumentsOption', {
//       is: 'Other address',
//       then: yup.string().required('please enter an id'),
//     }),

//     additionalReportsMailedOption: yup.string(),

//     additionalReports: yup
//       .array()
//       .of(yup.object().shape(CompanyFormFieldsSchema)),

//     additionalPDFOption: yup.boolean(),

//     additionalPdfsEmails: yup.array().of(
//       yup.object().shape({
//         email: yup
//           .string()
//           .email('Please enter a valid email')
//           .required('Please enter an email'),
//       }),
//     ),
//   });
// };

export const sendMail = yup.object().shape({
  mailAddress: yup.object().shape(CompanyFormFieldsSchema),
});
export const shippingMethod = yup.object().shape({
  shippingMethod: yup
    .string()
    .typeError('Please select a shipping method')
    .required('Please select a shipping method'),
});

export const sendAdditionalMail = yup.object().shape({
  mailAddress: yup.object().shape(CompanyFormFieldsSchema),
  shippingMethod: yup
    .string()
    .typeError('Please select a shipping method')
    .required('Please select a shipping method'),
});

// export const createFormSchema = emailID => {
//   return createSchema(emailID);
// };
