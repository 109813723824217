import React, { useState } from 'react';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useMachine } from '@xstate/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';

import { debug } from '../../../envConfig';
import Machine from './MachineSettings';
import InputField from '../../form-components/InputField/InputField';
import CopyRequestReviewPayment from './CopyRequestReviewPayment';

import { addressSchema, emailSchema } from './validationSchemas';
import { ReactComponent as TrashIcon } from '../../../assets/trashicon.svg';
import { ReactComponent as XCircle } from '../../../assets/x-circle-fill.svg';
import { constructAddress, returnService } from '../../../shared/functions';
import { Button, FormFields } from '../../../shared/styles';
import Formdropdown from '../../form-components/FormDropdown/FormDropdown';
// import RadioGroup from '../../UI/FormInputs/RadioGroup/RadioGroup';

const CopyRequestForm = () => {
  const [addressFormShow, setAddressFormShow] = useState(true);
  const { id } = useParams();
  const [formState, send] = useMachine(Machine(id), {
    devTools: debug.xstate,
  });
  const {
    addresses,
    services,
    emailService,
    emailPDFS,
    returnDocsServices,
    returnDocsEmailService,
  } = formState.context;

  const navigate = useNavigate();

  // FOR THE ADDRESS FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(addressSchema),
  });

  const sumbitAddress = data => {
    send('ADD_ADDRESS', data);
    setAddressFormShow(false);
    reset();
  };

  const submitForm = data => {
    toast.dismiss();
    send('SUBMIT', data);
  };
  const removeAddress = index => {
    send('REMOVE_ADDRESS', index);
  };

  // USED FOR THE SUBMIT REQUEST BUTTON, TAKES ADDITIONAL EMAILS
  const {
    register: overallRegister,
    formState: { errors: overallErrors },
    handleSubmit: overallSubmit,
    control,
    watch: overallWatch,
  } = useForm({
    resolver: yupResolver(emailSchema),
  });

  // const emailPDFAddresses = {};
  const {
    fields: emailFields,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    control,
    name: 'ADDITIONAL_PDFS_EMAILS',
  });

  const watchLocation = watch('location', 'US address');
  const watchReturnDocs = overallWatch('return_docs_option', 'mail');
  const watchReturnDocsToggle = overallWatch('return_docs_checkbox');
  const watchCopyRequestMailedToggle = overallWatch('copyRequestMailedBool');
  const watchCopyRequestEmailedToggle = overallWatch('copyRequestEmailedBool');
  const watchReturnDocsMailLocation = overallWatch(
    'return_docs.location',
    'US address',
  );

  // useEffect(() => {
  //   const subscription = overallWatch((value, { name, type }) =>
  //     console.log(value, name, type),
  //   );
  //   return () => subscription.unsubscribe();
  // }, [overallWatch]);

  if (formState.matches('waiting')) {
    return (
      <CopyRequestSyle>
        <h1>Evaluation report request</h1>
        <StyledOptions>
          <p>Please choose all the options you need:</p>
          <ul className="buttonOptions">
            <li>
              <label
                htmlFor="copyRequestEmailedBool"
                className={`formOption ${
                  watchCopyRequestEmailedToggle && 'active'
                } btn btn--blue`}
              >
                Email Report
                <input
                  type="checkbox"
                  name="copyRequestEmailedBool"
                  id="copyRequestEmailedBool"
                  {...overallRegister('copyRequestEmailedBool')}
                />
              </label>
            </li>
            <li>
              <label
                htmlFor="copyRequestMailedBool"
                className={`formOption ${
                  watchCopyRequestMailedToggle && 'active'
                } btn btn--blue`}
              >
                Mail Report
                <input
                  type="checkbox"
                  name="copyRequestMailedBool"
                  id="copyRequestMailedBool"
                  {...overallRegister('copyRequestMailedBool')}
                />
              </label>
            </li>

            <li>
              <label
                htmlFor="returnDocs"
                className={`formOption ${
                  watchReturnDocsToggle && 'active'
                } btn btn--blue`}
              >
                Return Submitted Educational Documents
                <input
                  type="checkbox"
                  name="returnDocs"
                  id="returnDocs"
                  {...overallRegister('return_docs_checkbox')}
                />
              </label>
            </li>
          </ul>
          {(watchCopyRequestEmailedToggle ||
            watchCopyRequestMailedToggle ||
            watchReturnDocsToggle) && <p>Scroll down to see form(s).</p>}
          <hr />
        </StyledOptions>
        {addresses.length > 0 && (
          <>
            <p className="__form-header">
              Sending to {addresses.length > 1 ? 'addresses' : 'address'}
            </p>
            {addresses.map((address, index) => {
              let shippingService;
              if (services.length > 0) {
                shippingService = returnService(
                  services,
                  address.shippingMethod,
                );
              }
              return (
                <div className="__address-cont" key={Math.random()}>
                  <div className="__address">
                    {addresses.length > 1 && <p>Address #{index + 1}</p>}
                    {constructAddress(address)}
                    <p>
                      Shipping Method: {shippingService?.displayName}{' '}
                      <strong>
                        <span>$</span>
                        {shippingService?.price}.00
                      </strong>
                    </p>
                  </div>
                  <button
                    className="__remove-btn"
                    type="button"
                    aria-label="Remove Address"
                    onClick={() => {
                      reset();
                      removeAddress(index);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              );
            })}
            {!addressFormShow && (
              <button
                className="__add-btn"
                type="button"
                onClick={() => setAddressFormShow(true)}
              >
                <XCircle /> Add Other Address
              </button>
            )}
          </>
        )}
        {watchCopyRequestMailedToggle &&
          (addressFormShow || addresses.length === 0) && (
            <>
              <p className="__form-header">
                Please enter the address you want to send to:
              </p>
              <FormFields onSubmit={handleSubmit(sumbitAddress)}>
                <div className="input-group">
                  <div className="input-group--stacked">
                    <div>
                      <input
                        id="location_US"
                        name="location"
                        type="radio"
                        value="US address"
                        {...register('location')}
                        defaultChecked
                      />
                      <label className="radio-label" htmlFor="location_US">
                        {' '}
                        US address
                      </label>
                    </div>
                    <div>
                      <input
                        id="location_international"
                        name="location"
                        type="radio"
                        value="International address"
                        {...register('location')}
                      />
                      <label
                        className="radio-label"
                        htmlFor="location_international"
                      >
                        {' '}
                        International address
                      </label>
                    </div>
                  </div>
                </div>

                <InputField
                  register={register}
                  dataRef="company"
                  type="text"
                  placeholder=""
                  title="Company"
                  errors={errors}
                />
                <InputField
                  register={register}
                  dataRef="contact"
                  type="text"
                  placeholder=""
                  title="Contact name/department"
                  errors={errors}
                  required
                />
                <InputField
                  register={register}
                  dataRef="line1"
                  type="text"
                  placeholder=""
                  title="Address (line 1)"
                  errors={errors}
                  required
                />
                <InputField
                  register={register}
                  dataRef="line2"
                  type="text"
                  placeholder=""
                  title="Address (line 2)"
                  errors={errors}
                />
                <InputField
                  register={register}
                  dataRef="city"
                  type="text"
                  placeholder=""
                  title="City"
                  errors={errors}
                  required
                />
                {watchLocation === 'US address' ? (
                  <div className="group">
                    <Formdropdown
                      register={register}
                      dataRef="state"
                      errors={errors}
                      title="State"
                      required
                      content="states"
                    />
                    <InputField
                      register={register}
                      dataRef="postalCode"
                      type="text"
                      placeholder=""
                      title="Zip Code"
                      errors={errors}
                      required
                    />
                  </div>
                ) : (
                  <>
                    <div className="group">
                      <InputField
                        register={register}
                        dataRef="state"
                        type="text"
                        placeholder=""
                        title="State/Province"
                        errors={errors}
                      />
                      <InputField
                        register={register}
                        dataRef="country"
                        type="text"
                        placeholder=""
                        title="Country"
                        errors={errors}
                        required
                      />
                    </div>
                    <InputField
                      register={register}
                      dataRef="postalCode"
                      type="text"
                      placeholder=""
                      title="Zip Code"
                      errors={errors}
                      required
                    />
                  </>
                )}

                <div className="radio-group">
                  <p>
                    <strong>
                      What shipping method do you prefer?{' '}
                      <span className="error">*</span>
                    </strong>
                  </p>
                  <div
                    className="__options"
                    role="group"
                    aria-labelledby="mailing services"
                  >
                    {watchLocation === 'US address'
                      ? services.map(
                          ({
                            id: serviceID,
                            name,
                            displayName,
                            description,
                            price,
                          }) => {
                            if (!displayName.includes('International')) {
                              return (
                                <div key={serviceID}>
                                  <input
                                    id={name}
                                    name="shippingMethod"
                                    type="radio"
                                    value={serviceID}
                                    {...register('shippingMethod')}
                                  />
                                  <label htmlFor={name}>
                                    {description}{' '}
                                    <strong>
                                      (<span>$</span>
                                      {price}.00)
                                    </strong>
                                  </label>
                                </div>
                              );
                            }
                            return null;
                          },
                        )
                      : services.map(
                          ({
                            id: serviceID,
                            name,
                            displayName,
                            description,
                            price,
                          }) => {
                            if (displayName.includes('International')) {
                              return (
                                <div key={serviceID}>
                                  <input
                                    id={name}
                                    name="shippingMethod"
                                    type="radio"
                                    value={serviceID}
                                    {...register('shippingMethod')}
                                  />
                                  <label htmlFor={name}>
                                    {description}{' '}
                                    <strong>
                                      (<span>$</span>
                                      {price}.00)
                                    </strong>
                                  </label>
                                </div>
                              );
                            }
                            return null;
                          },
                        )}
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="shippingMethod"
                    as="span"
                    className="error"
                  />
                </div>
                <button type="submit" className="btn btn--blue">
                  Save Address
                </button>
              </FormFields>
              <hr />
            </>
          )}

        {watchCopyRequestEmailedToggle && (
          <>
            <p className="__form-header">
              Do You need an official PDF sent via email? ($
              {emailService?.price}) each
            </p>
          </>
        )}

        <FormFields onSubmit={overallSubmit(submitForm)}>
          {watchCopyRequestEmailedToggle && (
            <>
              {emailFields.map(({ id: emailID }, index) => (
                <div key={emailID} className="input-group additional-address">
                  <label htmlFor={`ADDITIONAL_PDFS_EMAILS.${index}`}>
                    Email {index + 1} <span className="required" />
                  </label>
                  <input
                    id={`ADDITIONAL_PDFS_EMAILS.${index}`}
                    type="text"
                    {...overallRegister(
                      `ADDITIONAL_PDFS_EMAILS.${index}.email`,
                    )}
                    defaultChecked={emailPDFS ? emailPDFS[index]?.index : ''}
                  />
                  <ErrorMessage
                    errors={overallErrors}
                    name={`ADDITIONAL_PDFS_EMAILS.${index}.email`}
                    as="span"
                    className="error"
                  />
                  <button
                    className="additional-address__remove"
                    type="button"
                    aria-label="Remove Email"
                    onClick={() => {
                      emailRemove(index);
                    }}
                  >
                    <XCircle />
                  </button>
                </div>
              ))}
              <button
                className="additional-address--button"
                type="button"
                onClick={() => {
                  emailAppend({});
                }}
              >
                <XCircle /> Add {emailFields.length === 0 ? 'an' : 'another'}{' '}
                email
              </button>
              <hr />
            </>
          )}

          {/* <div className="input-group">
            <label className="radio-label" htmlFor="return_docs_checkbox">
              <input
                type="checkbox"
                id="return_docs_checkbox"
                {...overallRegister('return_docs_checkbox')}
              />{' '}
              Yes
            </label>
          </div> */}
          {watchReturnDocsToggle && (
            <>
              <p className="__form-header">
                How do you need the documents sent?
              </p>
              <div className="input-group">
                <div className="input-group--stacked">
                  <div>
                    <input
                      id="return_docs_mail"
                      name="return_docs"
                      type="radio"
                      value="mail"
                      {...overallRegister('return_docs_option')}
                      defaultChecked={watchReturnDocsMailLocation}
                    />
                    <label className="radio-label" htmlFor="return_docs_mail">
                      Mailed
                    </label>
                  </div>
                  <div>
                    <input
                      id="return_docs_email"
                      name="return_docs"
                      type="radio"
                      value="email"
                      {...overallRegister('return_docs_option')}
                    />
                    <label className="radio-label" htmlFor="return_docs_email">
                      Emailed
                    </label>
                  </div>
                </div>
              </div>

              {watchReturnDocs === 'mail' ? (
                <>
                  <div className="input-group">
                    <div className="input-group--stacked">
                      <div>
                        <input
                          id="return_docs_mail_location_us"
                          name="return_docs_mail_location"
                          type="radio"
                          value="US address"
                          {...overallRegister('return_docs.location')}
                          defaultChecked
                        />
                        <label
                          className="radio-label"
                          htmlFor="return_docs_mail_location_us"
                        >
                          US address
                        </label>
                      </div>
                      <div>
                        <input
                          id="return_docs_mail_international"
                          name="return_docs_mail_location"
                          type="radio"
                          value="International address"
                          {...overallRegister('return_docs.location')}
                        />
                        <label
                          className="radio-label"
                          htmlFor="return_docs_mail_international"
                        >
                          International address
                        </label>
                      </div>
                    </div>
                  </div>
                  <InputField
                    register={overallRegister}
                    dataRef="return_docs.company"
                    type="text"
                    placeholder=""
                    title="Company"
                    errors={overallErrors}
                  />
                  <InputField
                    register={overallRegister}
                    dataRef="return_docs.contact"
                    type="text"
                    placeholder=""
                    title="Contact name/department"
                    errors={overallErrors}
                  />
                  <InputField
                    register={overallRegister}
                    dataRef="return_docs.address1"
                    type="text"
                    placeholder=""
                    title="Address (line 1)"
                    errors={overallErrors}
                  />
                  <InputField
                    register={overallRegister}
                    dataRef="return_docs.address2"
                    type="text"
                    placeholder=""
                    title="Address (line 2)"
                    errors={overallErrors}
                  />
                  <InputField
                    register={overallRegister}
                    dataRef="return_docs.city"
                    type="text"
                    placeholder=""
                    title="City"
                    errors={overallErrors}
                  />
                  {watchReturnDocsMailLocation === 'US address' ? (
                    <div className="group">
                      <Formdropdown
                        register={overallRegister}
                        dataRef="return_docs.state"
                        errors={overallErrors}
                        title="State"
                        required
                        content="states"
                      />
                      <InputField
                        register={overallRegister}
                        dataRef="return_docs.postalCode"
                        type="text"
                        placeholder=""
                        title="Zip Code"
                        errors={overallErrors}
                        required
                      />
                    </div>
                  ) : (
                    <>
                      <InputField
                        register={overallRegister}
                        dataRef="return_docs.state"
                        type="text"
                        placeholder=""
                        title="State/Province"
                        errors={overallErrors}
                      />
                      <div className="group">
                        <InputField
                          register={overallRegister}
                          dataRef="return_docs.country"
                          type="text"
                          placeholder=""
                          title="Country"
                          errors={overallErrors}
                          required
                        />

                        <InputField
                          register={overallRegister}
                          dataRef="return_docs.postalCode"
                          type="text"
                          placeholder=""
                          title="Zip Code"
                          errors={overallErrors}
                          required
                        />
                      </div>
                    </>
                  )}

                  <div className="radio-group">
                    <p>
                      <strong>
                        What shipping method do you prefer?{' '}
                        <span className="error">*</span>
                      </strong>
                    </p>
                    <div
                      className="__options"
                      role="group"
                      aria-labelledby="mailing services"
                    >
                      {watchReturnDocsMailLocation === 'US address'
                        ? returnDocsServices.map(
                            ({
                              id: serviceID,
                              name,
                              displayName,
                              description,
                              price,
                            }) => {
                              if (!displayName.includes('International')) {
                                return (
                                  <div key={`${serviceID}-returnDocs`}>
                                    <input
                                      id={`${name}-returnDocs`}
                                      name="return_docs_shipping_method"
                                      type="radio"
                                      value={serviceID}
                                      {...overallRegister(
                                        'return_docs.shipping_method',
                                      )}
                                    />
                                    <label htmlFor={`${name}-returnDocs`}>
                                      {description}{' '}
                                      <strong>
                                        (<span>$</span>
                                        {price}.00)
                                      </strong>
                                    </label>
                                  </div>
                                );
                              }
                              return null;
                            },
                          )
                        : returnDocsServices.map(
                            ({
                              id: serviceID,
                              name,
                              displayName,
                              description,
                              price,
                            }) => {
                              if (displayName.includes('International')) {
                                return (
                                  <div key={`${serviceID}-returnDocs`}>
                                    <input
                                      id={`${name}-returnDocs`}
                                      name="return_docs_shipping_method"
                                      type="radio"
                                      value={serviceID}
                                      {...overallRegister(
                                        'return_docs.shipping_method',
                                      )}
                                    />
                                    <label htmlFor={`${name}-returnDocs`}>
                                      {description}{' '}
                                      <strong>
                                        (<span>$</span>
                                        {price}.00)
                                      </strong>
                                    </label>
                                  </div>
                                );
                              }
                              return null;
                            },
                          )}
                    </div>
                    <ErrorMessage
                      errors={overallErrors}
                      name="return_docs_shipping_method"
                      as="span"
                      className="error"
                    />
                  </div>
                </>
              ) : (
                <>
                  <InputField
                    register={overallRegister}
                    dataRef="return_docs_email"
                    type="email"
                    placeholder=""
                    title="Email"
                    errors={overallErrors}
                    required
                  />
                  <p>
                    Cost: <strong>${returnDocsEmailService.price}.00</strong>
                  </p>
                </>
              )}
            </>
          )}

          <div className="buttons">
            <Button
              type="submit"
              disabled={
                !(
                  addresses.length > 0 ||
                  emailFields.length > 0 ||
                  watchReturnDocsToggle
                )
              }
            >
              Submit Request
            </Button>
            <Button
              outline
              type="button"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel Request
            </Button>
          </div>
        </FormFields>
      </CopyRequestSyle>
    );
  }

  if (formState.matches('loadingError')) {
    return <p>There was an internal error please reload and try again.</p>;
  }

  if (formState.matches('awaitingPayment')) {
    return <CopyRequestReviewPayment formState={formState} send={send} />;
  }

  // todo: @theClarkSell this will show during the other states... need to handle better.
  return <p>Loading</p>;
};

export default CopyRequestForm;

const StyledOptions = styled.div`
  max-width: 1000px;
  p {
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 20px;
    justify-content: center;
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  li {
    display: block;

    height: 100%;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  label {
    display: block;
    min-height: 50px;
    padding: 10px 40px;
    text-align: center;
    cursor: pointer;
    background-color: #14527f;
    color: white;
    border-radius: 3px;
    outline: none;
    border: solid 1px #14527f;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    font-weight: 600;
    font-size: 16px;
    &.active {
      background-color: #68b6ec;
    }
    &:hover,
    &:active {
      background-color: #0c4973;
    }
  }
  input {
    display: none;
  }
`;

const CopyRequestSyle = styled.div`
  h1 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .__address-cont {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    .__address p {
      margin-bottom: 5px;
    }
    .__remove-btn {
      background-color: transparent;
      border: none;
      transition: all ease 0.3s;
      width: 30px;
      height: 30px;
      &:hover,
      &:active {
        fill: red;
        cursor: pointer;
      }
    }
  }
  .__add-btn {
    background-color: transparent;
    border: none;
    transition: all ease 0.3s;
    color: #14527f;
    font-size: 16px;
    display: flex;
    align-items: center;
    svg {
      fill: #14527f;
      transition: all ease 0.3s;
    }
    &:hover,
    &:active {
      color: #68b6ec;
      cursor: pointer;
      svg {
        fill: #68b6ec;
      }
    }
  }
  .__form-header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  form {
    max-width: 600px;
    margin: 0 0 30px;
    .radio-group {
      margin-bottom: 40px;
      .__options {
        margin-top: 22px;
        div {
          margin-bottom: 5px;
          input,
          label {
            margin-right: 10px;
            &:hover,
            &:active {
              cursor: pointer;
            }
          }
        }
      }
    }
    .group {
      margin-bottom: 40px;
    }
  }
  hr {
    margin: 40px 0;
  }
  input[type='checkbox'] {
    width: auto;
    cursor: pointer;
  }
  .radio-label {
    cursor: pointer;
  }
  .buttons {
    display: flex;
    margin-top: 20px;
    gap: 20px;
  }
`;
