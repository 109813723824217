import styled from 'styled-components';

export const UserMenu = styled.div`
  position: relative;
  .menuButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 100%;
    transition: all ease 0.3s;
    font-weight: 700;
    svg {
      transition: all ease 0.3s;
      transform: ${({ menu }) =>
        menu === 'true' ? 'rotateX(180deg)' : 'rotateX(0)'};
    }
    &:hover,
    &:focus,
    &:active {
      svg {
        fill: #14527f;
      }
      color: #14527f;
    }
    &--application {
      margin-left: auto;
      color: white;
      svg {
        fill: white;
      }
      &:hover,
      &:focus,
      &:active {
        svg {
          fill: black;
        }
        color: black;
      }
    }
  }
  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 200px;
    list-style: none;
    border: solid black 1px;
    padding: 20px;
    background-color: white;
    button {
      background-color: transparent;
      border: none;
      font-size: 18px;
      margin-bottom: 5px;
      width: 100%;
      cursor: pointer;
      text-align: left;
    }
    hr {
      margin: 5px 0;
    }
  }
`;

export default UserMenu;
