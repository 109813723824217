import React, { useRef, useEffect, useCallback } from 'react';
import ReactDom from 'react-dom';

import { Background, ModalWrapper } from './styles.Modal';

const ModalXState = ({ machineState, modalOpenState, onClose, children }) => {
  const modalBackground = useRef();
  const titleRef = useRef();
  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      // Do whatever when esc is pressed
      onClose();
    }
  }, []);
  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  const closeModal = e => {
    if (modalBackground.current === e.target) {
      onClose();
    }
  };

  if (machineState.matches(modalOpenState)) {
    return ReactDom.createPortal(
      <>
        <Background ref={modalBackground} onClick={closeModal}>
          <ModalWrapper>
            <div className="__content">
              <button
                aria-label="close popup"
                className="__closeModal"
                type="button"
                onClick={onClose}
                ref={titleRef}
                tabIndex="0"
              >
                X
              </button>
              {children}
            </div>
          </ModalWrapper>
        </Background>
      </>,
      document.getElementById('modalPortal'),
    );
  }
  return null;
};

export default ModalXState;
