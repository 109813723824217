import { gql } from '@apollo/client';
import gqlc from '../../../shared/gqlc';

const CREATE_INVOICE = gql`
  mutation CREATE_INVOICE($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          invoices {
            createAndFinalizeAddRushInvoice(serviceId: $serviceId) {
              id
              finalized
            }
          }
        }
      }
    }
  }
`;

const VOID_INVOICE = gql`
  mutation VOID_INVOICE($invoiceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          invoices {
            voidInvoice(invoiceId: $invoiceId) {
              id
              finalized
            }
          }
        }
      }
    }
  }
`;

export default client => {
  const { mutate } = gqlc(client);

  function createInvoice({ applicationId, serviceId }) {
    return mutate(CREATE_INVOICE, {
      applicationId,
      serviceId,
    }).then(data => {
      return data.applications.me.application.invoices
        .createAndFinalizeAddRushInvoice;
    });
  }

  function voidInvoice({ applicationID, invoiceId }) {
    return mutate(VOID_INVOICE, {
      applicationID,
      invoiceId,
    }).then(data => {
      return data.applications.me.application.invoices.voidInvoice;
    });
  }

  return { createInvoice, voidInvoice };
};
