import React from 'react';
import { useMachine } from '@xstate/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import createMachine from './machine';
import { debug } from '../../../envConfig';
import { constructAddress } from '../../../shared/functions';
import { FormFields } from '../../../shared/styles';
import upgradeRushSchema from './validationSchemas';
import Payment from '../../Invoice/Payment';
import {
  paymentConfig,
  StyledToastMessage,
} from '../../../shared/notifications/toast';

export default function UpgradeRush() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formState, send] = useMachine(createMachine(id), {
    devTools: debug.xstate,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(upgradeRushSchema),
  });

  const submitForm = () => {
    toast.dismiss();
    send('SUBMIT');
  };

  const onPaid = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Thank you for your payment.</h2>
        <p>
          Rush services have been added to your application and will be
          processed shortly.
        </p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('COMPLETED');
    navigate('/dashboard');
  };

  const onCancelled = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Your payment was cancelled.</h2>
        <p>
          You have canceled upgrading your application to include rush
          services..
        </p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('CANCELLED');
  };

  if (formState.matches('init')) {
    return <p>Loading...</p>;
  }

  // todo: @theClarkSell we should redirect to the error page here if there is a problem.
  if (formState.matches(['loadingError', 'paymentError'])) {
    return <p>There was an internal error please reload and try again.</p>;
  }

  if (formState.matches('waiting')) {
    return (
      <StyledUpgradeRush className="styledUpgradeRush">
        <FormFields onSubmit={handleSubmit(submitForm)}>
          {formState.context.mailAddress && (
            <>
              <p className="input-group--title input-group--bold">
                Address Mailing to:
              </p>
              {constructAddress(formState.context.mailAddress)}
              <hr />
            </>
          )}
          <div className="radio-group">
            <p className="input-group--title input-group--bold">
              What Rush method do you prefer? <span className="error">*</span>
            </p>
            <div
              className="__options"
              role="group"
              aria-labelledby="mailing services"
            >
              {formState.context.services.map(
                ({ id: serviceID, name, displayName, price }) => {
                  if (!displayName.includes('International')) {
                    return (
                      <div className="option" key={serviceID}>
                        <input
                          id={name}
                          name="shippingMethod"
                          type="radio"
                          value={serviceID}
                          {...register('shippingMethod')}
                        />
                        <label htmlFor={name}>
                          {' '}
                          {displayName}{' '}
                          <strong>
                            (<span>$</span>
                            {price}.00)
                          </strong>
                        </label>
                      </div>
                    );
                  }
                  return null;
                },
              )}
            </div>
            <ErrorMessage
              errors={errors}
              name="shippingMethod"
              as="span"
              className="error"
            />
          </div>
          <button type="submit" className="btn btn--blue">
            Continue to Payment
          </button>
        </FormFields>
      </StyledUpgradeRush>
    );
  }

  if (formState.matches('awaitingPayment')) {
    return (
      <Payment
        cart={formState.context.cart}
        finalizedInvoice={formState.context.finalizedInvoice}
        onPaid={onPaid}
        onCancelled={onCancelled}
      />
    );
  }

  return (
    <>
      <h2 className="title">Loading Payment Summary</h2>
      <Skeleton height={600} baseColor="#F7FAFC" />
    </>
  );
}

const StyledUpgradeRush = styled.div`
  max-width: 500px;
  hr {
    margin: 50px 0;
  }
  button {
    margin-top: 30px;
  }
  .option {
    padding: 5px 0;
  }
  input,
  label {
    cursor: pointer;
  }
`;
