import { gql } from '@apollo/client';
import { applicationFields } from '../sharedVariables';
//
// QUERIES FOR ALL EVALUATIONS
//

export const INCOMPLETE_APPLICATIONS_INIT = gql`
  query incompleteApplicationsInit($take:Int) {
    applications{
      all{
        getIncomplete(take: $take){
          ${applicationFields}
        }
        getAllIncompleteCount
      }
    }
}
`;

export const SEARCH_INCOMPLETE_APPLICATIONS = gql`
  query search($searchText: String, $take:Int) {
    applications {
      all{
        searchIncomplete(searchText:$searchText, take:$take){
          ${applicationFields}
        }
      }
    }
  }
`;

export const SEND_EMAIL_REMINDER = gql`
  query sendReminder($userID: String!) {
    emailEvents {
      sendReminder(userID: $userID)
    }
  }
`;

export default client => {
  const init = take => {
    return client
      .query({
        query: INCOMPLETE_APPLICATIONS_INIT,
        variables: { take },
      })
      .then(({ data }) => {
        const result = {
          applications: data.applications.all.getIncomplete,
          allCount: data.applications.all.getAllIncompleteCount,
        };
        return result;
      });
  };
  const search = (searchText, take) => {
    // console.log('iniside search');
    return client
      .query({
        query: SEARCH_INCOMPLETE_APPLICATIONS,
        variables: { searchText, take },
      })
      .then(data => {
        // console.log('data: ', data.data.applications.all.searchIncomplete);
        return data.data.applications.all.searchIncomplete;
      });
  };
  const sendEmailReminder = userID => {
    return client
      .query({
        query: SEND_EMAIL_REMINDER,
        variables: { userID },
      })
      .then(data => {
        return data.data.emailEvents.sendReminder;
      });
  };

  return {
    init,
    search,
    sendEmailReminder,
  };
};
