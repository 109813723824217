import * as yup from 'yup';

const servicesEditForm = yup.object().shape({
  displayName: yup.string().required('Please enter a Service Name'),
  boldTitle: yup.string(),
  description: yup.string(),
  price: yup.number().required('Please enter a Price'),
});

export default servicesEditForm;
