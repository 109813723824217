import { useEffect } from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    Sentry.captureException(error);
    navigate('/error-application');
  }, [error]);

  return <></>;
};

export default ErrorBoundary;
