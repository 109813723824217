import styled from 'styled-components';

export const Button = styled.button`
  min-height: ${({ thinStyle }) => (thinStyle ? 'unset' : '50px')};
  padding: ${({ thinStyle }) => (thinStyle ? '10px' : '10px 40px')};
  text-align: center;
  cursor: pointer;
  background-color: ${({ outline }) => (outline ? 'transparent' : '#14527f')};
  color: ${({ outline }) => (outline ? '#14527f' : 'white')};
  border-radius: 3px;
  outline: none;
  border: solid 1px #14527f;
  transition: all ease 0.3s;
  font-weight: 600;
  font-size: 16px;
  &.previous {
    background-color: transparent;
    color: #14527f;
  }
  &:hover,
  &:focus {
    background-color: #071e2e;
    border: solid 1px #071e2e;
    color: white;
  }
  &:first-child {
    margin-right: 20px;
  }
  &:disabled {
    background-color: transparent;
    border: 1px solid #c0c0c0;
    color: #c0c0c0;
  }
`;

export const ButtonsCont = styled.div`
  display: flex;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
  button {
    margin: 0 10px;
    @media only screen and (max-width: 500px) {
      margin: 0;
      margin-bottom: 10px;
    }
    &:first-child {
      margin-right: 10px;
      @media only screen and (max-width: 500px) {
        margin-right: 0;
      }
    }
  }
`;

export const StepErrors = styled.div`
  padding: 10px 0;
  p {
    color: red;
    text-transform: uppercase;
  }
`;

export const FormFields = styled.form`
  width: 100%;
  margin: 0 auto;
  .errorMessage {
    color: #d24012;
  }
  .input-group {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    label {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  .error {
    color: #d24012;
    input,
    textarea {
      border-color: red;
    }
  }
  .btn {
    font-weight: 600;
    padding: 20px 30px;
    min-width: 200px;
    cursor: pointer;
    text-align: center;
    display: block;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    &--blue {
      background-color: #14527f;
      color: white;
      transition: all ease 0.3s;
      &:hover {
        background-color: #071e2e;
      }
    }
  }
  .input-group {
    margin-bottom: 30px;
    &--title {
      margin-bottom: 15px;
      .number {
        color: #68b6ec;
      }
    }
    &--bold {
      font-weight: 700;
    }
    &--stacked {
      display: flex;
      div {
        margin-right: 25px;
        input,
        textarea {
          margin-right: 10px;
        }
      }
    }
    input[type='radio'],
    .radio-label {
      &:hover {
        cursor: pointer;
      }
    }
  }

  hr {
    margin: 50px auto;
    background-color: #cbd5e0;
    border: solid 1px #cbd5e0;
    &.option-sep {
      margin: 30px 0;
    }
    &#__header-hr {
      margin: 0 auto;
    }
  }

  input,
  select,
  textarea {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    border: solid 2px #c0c0c0;
    width: 100%;
    &.error {
      border-color: #d24012;
      color: #d24012;
    }
  }
  input[type='radio'] {
    width: auto;
  }

  .required {
    color: #d24012 !important;
  }

  .group {
    display: flex;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    .input-group {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  .additional-address {
    position: relative;
    &--button {
      background-color: transparent;
      border: none;
      transition: all ease 0.3s;
      color: #14527f;
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        fill: #14527f;
        transition: all ease 0.3s;
      }
      &:hover,
      &:active {
        color: #68b6ec;
        cursor: pointer;
        svg {
          fill: #68b6ec;
        }
      }
    }
    &__remove {
      position: absolute;
      right: 20px;
      top: 43px;
      background-color: transparent;
      border: none;
      z-index: 1;
      transform: rotate(45deg);
      width: 40px;
      height: 40px;
      svg {
        fill: #c0c0c0;
        transition: all ease 0.3s;
      }
      &:hover,
      &:active {
        cursor: pointer;
        svg {
          fill: #d24012;
        }
      }
    }
  }
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transition: all ease 0.3s;
  }

  &:hover,
  &:active {
    svg {
      fill: #0c4973;
    }
  }
`;

export const ClipboardButton = styled.div`
  display: flex;
  align-items: center;
  p {
    opacity: 0;
    transition: opacity ease 0.3s;
    &.active {
      opacity: 1;
    }
  }
`;

export const InternalNotesStyled = styled.div`
  p {
    margin-bottom: 15px;
  }

  textarea {
    min-height: 150px;
    width: 500px;
    padding: 10px;
    font-size: 16px;
  }
`;

export const ConfirmationStyled = styled.div`
  margin-top: 10px;
  p {
    color: green;
  }
`;

export const ProfileFormContainer = styled.div`
  max-width: 550px;
  margin-top: 30px;
`;
export const SectionTitle = styled.h1`
  font-size: 25px;
`;
