import * as yup from 'yup';

const DateSchemaNotRequired = {
  month: yup.string(),
  day: yup.string(),
  year: yup.string().matches(/^(19|20)\d{2}$/, 'Please enter year ex. 1994'),
};

const yupSchema = yup.object().shape({
  country: yup.string().required('Please enter the country of the school'),
  school: yup
    .string()
    .required('Please enter name of the school or institution.'),
  study: yup.string().required('Please enter an area of study'),
  startDate: yup.object().shape(DateSchemaNotRequired),
  startDateTime: yup
    .number()
    .typeError('Please enter a valid date')
    .required('Please enter a valid date'),
  degree: yup.string().when('degreeNA', {
    is: false,
    then: schema => schema.required('Please enter a Degree/Diploma title'),
  }),
  // degreeNA: yup.string().required('Please enter a Degree/Diploma title'),
  degreeNA: yup.boolean(),
  endDate: yup.object().shape(DateSchemaNotRequired),
  endDateTime: yup
    .number()
    .min(yup.ref('startDateTime'), 'End date must be after start date'),
  degreeDate: yup.object().when('degree.notApplicable', {
    is: false,
    then: schema => schema.shape(DateSchemaNotRequired),
  }),
  degreeDateTime: yup.number().when('degreeNA', {
    is: false,
    then: schema =>
      schema
        .required('Please enter an award date')
        .min(yup.ref('endDateTime'), 'Award date must be after end date'),
  }),
});

export default yupSchema;
