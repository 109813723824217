import React, { useEffect } from 'react';
import dayjs from 'dayjs';

import ApplicantInformation from './ApplicantInformation/ApplicantInformation';
import EducationalHistory from './EducationHistory/EducationalHistory';
import Documents from './Documents/Documents';
import ApplicationType from './ApplicationType/ApplicationType';
import DeliveryInstructions from './DeliveryInstructions/DeliveryInstructions';
import Terms from './Terms/Terms';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';

import ReviewStyled from './styles.ReviewApplication';
import { returnService } from '../../../../shared/functions';

const ReviewApplication = ({ formState, onSubmit, previousStep, send }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    created,
    termsSignedDate,
    services,
    step4: { serviceId },
    step5: { rushApplicationServiceID },
  } = formState.context;
  const orderType = returnService(services, serviceId) || null;
  const rushService = returnService(services, rushApplicationServiceID) || null;

  return (
    <ReviewStyled>
      <h2 className="title">Review application and order</h2>
      <div className="application-details">
        <ul>
          <li>
            <strong>Created on: {dayjs(created).format('MM/DD/YYYY')}</strong>{' '}
          </li>
          <li>
            <strong>Payment status:</strong> Not received
          </li>
          {orderType && (
            <li>
              <strong>Order type:</strong> Evaluation - {orderType.name}
            </li>
          )}
          {rushService && (
            <li>
              <strong>Processing method:</strong> {rushService.displayName}
            </li>
          )}
        </ul>
      </div>
      <ApplicantInformation send={send} formState={formState} />
      <EducationalHistory send={send} formState={formState} />
      <Documents send={send} formState={formState} />
      <ApplicationType send={send} formState={formState} />
      <DeliveryInstructions send={send} formState={formState} />
      <Terms send={send} formState={formState} />
      <NextPrevStepButtons
        altText="View Payment Summary"
        nextStep={onSubmit}
        prevStep={previousStep}
        disabledValue={!termsSignedDate}
      />
    </ReviewStyled>
  );
};

export default ReviewApplication;
