import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import {
  months,
  countries,
  states,
  DaysThirty,
  DaysThirtyOne,
  DaysTwentyNine,
} from '../DateInput/CountryDateData';

const Formdropdown = ({
  dataRef,
  content,
  register,
  errors,
  title,
  required,
  defaultValue,
  monthSelected,
}) => {
  let data = [];
  if (content === 'days') {
    if (
      monthSelected === 'Sep' ||
      monthSelected === 'Apr' ||
      monthSelected === 'Jun' ||
      monthSelected === 'Sep' ||
      monthSelected === 'Nov'
    ) {
      data = DaysThirty;
    } else if (monthSelected === 'Feb') {
      data = DaysTwentyNine;
    } else {
      data = DaysThirtyOne;
    }
  }
  if (content === 'month') {
    data = months;
  }
  if (content === 'country') {
    data = countries;
  }

  if (content === 'state' || content === 'states') {
    data = states;
  }

  return (
    <div className={`input-group ${errors[dataRef] ? 'error' : ''}`}>
      <label htmlFor={dataRef}>
        {title}
        <span className="required">{required && '*'}</span>
      </label>
      {content === 'country' || content === 'states' ? (
        <select
          name={dataRef}
          {...register(dataRef)}
          defaultValue={defaultValue}
          id={dataRef}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option key={`blank${Math.random()}`}> </option>
          {data.map(option => {
            return (
              <option key={option.name + Math.random()} value={option.name}>
                {option.name}
              </option>
            );
          })}
        </select>
      ) : (
        <select
          name={dataRef}
          {...register(dataRef)}
          defaultValue={defaultValue}
          id={dataRef}
        >
          {data.map(option => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      )}
      <ErrorMessage
        errors={errors}
        name={dataRef}
        as="span"
        className="error"
      />
    </div>
  );
};

export default Formdropdown;
