import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DashboardTemplate from './Templates/DashboardTemplate';
import ApplicationOutline from '../components/ApplicationOutiline/ApplicationOutline';

export const FORM_MACHINE_GET_APPLICATION = gql`
  query application($appId: String!) {
    services {
      all {
        price
        id
        name
        description
        displayName
        serviceType
      }
    }
    applications {
      me {
        application(id: $appId) {
          createdAt
          applicant {
            firstName
            lastName
          }
          applicantInfo {
            id
            prevEvaluation
            prevEvaluationRefNumber
            prevEvaluationDate
            gender
            firstName
            middleName
            lastName
            differentFirstName
            differentMiddleName
            differentLastName
            address {
              id
              line1
              line2
              city
              state
              postalCode
              country
            }
            phoneNumber
            email
            birthday
            birthCountry
            applicationId
          }
          education {
            id
            country
            school
            study
            startDate
            endDate
            degree
            degreeNA
            degreeDate
          }
          appDocumentsInfo {
            id
            documentsSentBy
            pageCount
            fileCount
            documents {
              id
              name
              url
            }
          }
          applicationType {
            id
            name
            displayName
            description
            price
            serviceType
          }
          deliveryInstructions {
            id
            emailAddressToReturn
            rushApplicationServiceID
            returnDocumentsOption
            returnDocumentsEmail
            returnDocumentsAddress {
              id
              line1
              line2
              city
              state
              postalCode
              country
              shippingMethod
            }
            additionalReportsMailedAddresses {
              id
              line1
              line2
              city
              state
              postalCode
              country
              shippingMethod
            }
            additionalPDFOption
            additionalPDFEmails
          }
          termsAgreement
          termsSignedDate
        }
      }
    }
  }
`;

const ApplicationView = () => {
  const { id } = useParams();
  const { loading, data } = useQuery(FORM_MACHINE_GET_APPLICATION, {
    variables: { appId: id },
  });
  if (loading) {
    return (
      <DashboardTemplate>
        <div>Loading...</div>
      </DashboardTemplate>
    );
  }

  return (
    <DashboardTemplate>
      <ApplicationOutline
        application={data.applications.me.application}
        services={data.services.all}
      />
    </DashboardTemplate>
  );
};

export default ApplicationView;
