export const address = `
  id
  line1
  line2
  city
  state
  postalCode
  country
  location
  company
  contact
  shippingMethod
`;
export const applicationFields = `
          id
          createdAt
          paidDate
          paid
          status
          refNumber
          createdAt
          internalNotes
          evaluationCompleteDate
          addedRush
          lastUpdatedBy{
            id
            firstName
            lastName
            role
          }
          lastUpdatedAt
          termsAgreement
          termsSignedDate
          paidDate
          status
          refNumber
          applicant {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            getPaidCount
            applications{
              id
              paid
              paidDate
              refNumber
              lastUpdatedAt
              evaluator{
                firstName
                lastName
              }
            }
          }
          evaluator{
            id
            firstName
            lastName
            role
          }
          applicantInfo{
            prevEvaluation
            prevEvaluation
            prevEvaluationRefNumber
            prevEvaluationDate
            gender
            firstName
            middleName
            lastName
            differentFirstName
            differentMiddleName
            differentLastName
            address{
              ${address}
            }
            phoneNumber
            email
            birthday
            birthCountry
          }
          education{
            country
            id
            school
            study
            startDate
            endDate
            degree
            degreeNA
            degreeDate
          }
          appDocumentsInfo{
            id
            pageCount
            fileCount
            documentsSentBy
            documents{
              id
              name
              url
            }
          }
          applicationType{
            id
            name
            displayName
            description
            price
          }
          deliveryInstructions{
            mailOption
            deliveryOptionApplicationServiceID
            emailAddressToReturn
            mailAddress{
              ${address}
            }
            rushApplicationServiceID
            returnDocumentsAddress{
              ${address}
            }
            additionalReportsMailedAddresses{
              ${address}
            }
            additionalPDFOption
            additionalPDFEmails
            id
            returnDocumentsEmail
          }
          notes{
            id
            text
          }
          internalNotes
          auditHistory{
            createdAt
            before
            after
            notes
          }

`;

export const statusOptions = [
  { id: 2, option: 'Processing' },
  { id: 3, option: 'Review' },
  { id: 4, option: 'Mailing' },
  { id: 5, option: 'Complete' },
  { id: 6, option: 'Document Problem' },
  { id: 7, option: 'Canceled' },
  { id: 8, option: 'Forged Documents' },
];
export const copyRequestStatusOptions = [
  { id: 1, option: 'New' },
  { id: 2, option: 'Complete' },
];

export const Constants = {
  ApplicationStatus: {
    ApplicationSubmitted: 'Application Submitted',
    Processing: 'Processing',
    Mail: 'To Be Mailed',
    Completed: 'Completed',
    DocProblem: 'Document Problem',
    ForgedDocuments: 'Forged Documents',
    Proof: 'Proof',
    Canceled: 'Canceled',
    Review: 'Review',
  },
};
