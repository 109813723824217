import { gql } from '@apollo/client';

export const GET_ADMINS = gql`
  query getAdmins {
    users {
      admins {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_ALL_SERVICES = gql`
  query Query {
    services {
      all {
        price
        id
        name
        description
        displayName
        serviceType
        show
      }
    }
    workflow {
      all {
        id
        name
        adminId
      }
    }
  }
`;

export const GET_SERVICE_BY_TYPE = gql`
  query serviceByName($serviceType: String) {
    services {
      byType(service: $serviceType) {
        price
        id
        name
        description
        displayName
        serviceType
      }
    }
  }
`;

export const SERVICE_TYPES = gql`
  query GetServiceTypes {
    ServiceType: __type(name: "ServiceType") {
      enumValues {
        name
        description
      }
    }
  }
`;

export const MY_APPLICATIONS = gql`
  query my_applications {
    applications {
      me {
        all {
          id
          createdAt
          applicationType {
            id
            displayName
            name
          }
          applicantInfo {
            firstName
            lastName
          }
          paid
          paidDate
          getStepComplete
          refNumber
          deliveryInstructions {
            rushApplicationServiceID
          }
          evaluationCompleteDate
          requestReview
          status
        }
      }
    }
  }
`;

export const FORM_MACHINE_GET_APPLICATION = gql`
  query application($appId: String!) {
    applications {
      me {
        application(id: $appId) {
          createdAt
          applicant {
            firstName
            lastName
          }
          applicantInfo {
            id
            prevEvaluation
            prevEvaluationRefNumber
            prevEvaluationDate
            gender
            firstName
            middleName
            lastName
            differentFirstName
            differentMiddleName
            differentLastName
            address {
              id
              line1
              line2
              city
              state
              postalCode
              country
            }
            phoneNumber
            email
            birthday
            birthCountry
            applicationId
          }
          education {
            id
            country
            school
            study
            startDate
            endDate
            degree
            degreeNA
            degreeDate
          }
          appDocumentsInfo {
            id
            documentsSentBy
            pageCount
            fileCount
            documents {
              id
              name
              url
            }
          }
          applicationType {
            id
            name
            displayName
            description
            price
            serviceType
          }
          deliveryInstructions {
            id
            emailAddressToReturn
            rushApplicationServiceID
            returnDocumentsOption
            returnDocumentsEmail
            returnDocumentsAddress {
              id
              line1
              line2
              city
              state
              postalCode
              country
              shippingMethod
            }
            additionalReportsMailedAddresses {
              id
              line1
              line2
              city
              state
              postalCode
              country
              shippingMethod
            }
            additionalPDFOption
            additionalPDFEmails
          }
          termsAgreement
          termsSignedDate
        }
      }
    }
  }
`;

export const GET_ADDITIONAL_REPORTS_ADDRESSES = gql`
  query getAdditionalReportsAddresses($appId: String!) {
    applications {
      application(id: $appId) {
        deliveryInstructions {
          id
          additionalReportsMailedAddresses {
            id
            line1
            line2
            city
            state
            country
            postalCode
            company
            contact
          }
        }
      }
    }
  }
`;

export const GET_ADDRESS = gql`
  query getAddress($addressId: String!) {
    addresses {
      address(id: $addressId) {
        line1
        line2
        contact
        company
        state
        postalCode
        country
        city
      }
    }
  }
`;
