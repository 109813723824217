/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_ME = gql`
  query GET_ME {
    users {
      me {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
