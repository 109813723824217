function createConfig() {
  return {
    id: 'copyRequests',
    initial: 'init',
    context: {
      items: [],
      take: 100,
      currentPage: 1,
      pages: null,
      itemsToShow: 20,
      sortRef: null,
      sortDatePaid: true,
      sortName: null,
      sortStatus: null,
      message: null,
    },

    states: {
      init: {
        invoke: {
          id: 'initialLoad',

          src: 'load',
          onDone: {
            actions: ['loadSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      waiting: {
        on: {
          NEXT_PAGE: { actions: 'nextPage' },
          UPDATE_STATUS: { target: 'updateStatus' },
          PREV_PAGE: { actions: 'prevPage' },
          GO_TO_PAGE: { actions: 'goToPage' },
          SORT: { actions: ['sort'] },
          SEARCH: 'search',
          INIT: 'init',
        },
      },
      search: {
        invoke: {
          id: 'searching',
          src: 'search',
          onDone: {
            actions: ['searchSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      updateStatus: {
        invoke: {
          id: 'updateStatus',

          src: 'updateStatus',
          onDone: {
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      loadingFailed: {},
    },
    on: {},
  };
}

export default createConfig;
