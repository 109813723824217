import React from 'react';

const FormDropdownEnum = ({
  dataRef,
  content,
  register,
  errors,
  title,
  required,
}) => {
  const data = content;
  return (
    <div className={`input-group ${errors[dataRef] ? 'error' : ''}`}>
      <label htmlFor={dataRef}>
        {title}
        <span className="required">{required && '*'}</span>
      </label>
      <select name={dataRef} {...register(dataRef)}>
        {data &&
          data.map(({ name, description }) => {
            return (
              <option key={name} value={name}>
                {description}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default FormDropdownEnum;
