/* eslint-disable import/prefer-default-export */
import { useAuth0 } from '@auth0/auth0-react';

export function isInRole(role) {
  const { user } = useAuth0();
  return user['https://gceus-api-usmkrvnr3q-uc.a.run.app/roles'].includes(role);
}

export function isAdmin() {
  const { user } = useAuth0();

  return user['https://gceus-api-usmkrvnr3q-uc.a.run.app/roles'].includes(
    'ADMIN',
  );
}
