import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import ServicesPage from '../../components/dashboard-pages/ServicesPage/ServicesPage';

const AdminServicesSettings = () => {
  return (
    <DashboardTemplate>
      <ServicesPage />
    </DashboardTemplate>
  );
};

export default AdminServicesSettings;
