function createConfig(id) {
  return {
    id: '',
    initial: 'init',
    context: {
      applicationId: id,
      application: '',
    },

    states: {
      init: {
        invoke: {
          src: 'getApplication',
          onDone: {
            actions: ['setApplicationToContext'],
            target: 'loaded',
          },
          onError: {
            target: 'loadingFailed',
          },
        },
      },
      loaded: {
        type: 'final',
      },
      loadingFailed: {},
    },
    on: {},
  };
}

export default createConfig;
