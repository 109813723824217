import * as yup from 'yup';
import { CopyRequestFormFieldsSchema } from '../../form-components/FormSteps/shared/schemas';

// FORM VALIDATAION SCHEMAS
export const addressSchema = yup.object().shape({
  ...CopyRequestFormFieldsSchema,
});

export const emailSchema = yup.object().shape({
  ADDITIONAL_PDFS_EMAILS: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email('Please enter a valid email')
        .required('Please enter an email'),
    }),
  ),
  return_docs_option: yup.string(),
  return_docs_checkbox: yup.boolean(),
  return_docs: yup
    .object()
    .when(['return_docs_checkbox', 'return_docs_option'], {
      // eslint-disable-next-line camelcase
      is: (return_docs_checkbox, return_docs_option) =>
        // eslint-disable-next-line camelcase
        return_docs_checkbox && return_docs_option === 'mail',
      then: () =>
        yup.object().shape({
          company: yup.string(),
          contact: yup.string().required('Please enter a name'),
          address1: yup.string().required('Please enter an address'),
          address2: yup.string(),
          city: yup.string().required('Please enter a city'),
          state: yup.string().when('location', {
            is: 'US address',
            then: () =>
              yup
                .string()
                .required('Please select a state')
                .test(
                  'is-state',
                  'Please select a state',
                  value => value !== '--' || value !== '',
                ),
          }),
          country: yup.string().when('location', {
            is: 'International address',
            then: () => yup.string().required('Please enter a country'),
          }),
          postalCode: yup
            .string()
            .required('Please enter a postal code')
            .typeError('Please enter a valid zip/postal code'),
          shipping_method: yup
            .string()
            .typeError('Please Select an option')
            .required('Please Select an option'),
        }),
    }),
  return_docs_email: yup.string().when('return_docs_option', {
    is: 'email',
    then: () =>
      yup
        .string()
        .required('Please enter an email')
        .email('Please enter a valid email'),
  }),
});

export const returnDocsAddressSchema = yup.object().shape({});
