import React from 'react';
import DashboardTemplate from './Templates/DashboardTemplate';
import ContactPage from '../components/dashboard-pages/contactPage';

const DashboardApplicationView = () => (
  <DashboardTemplate>
    <ContactPage />
  </DashboardTemplate>
);

export default DashboardApplicationView;
