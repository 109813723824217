import styled from 'styled-components';

const TermsOfService = styled.div`
  padding-top: 60px;
  max-width: 550px;
  margin: 0 auto;

  @media only screen and (max-width: 1300px) {
    padding-top: 0;
  }
  h2 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  p {
    line-height: 2;
    &.__title {
      color: #14527f;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 8px;
    }
    &.__bolded {
      color: #2e2e2e;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.5;
      margin-bottom: 35px;
    }
    .__number {
      color: #14527f;
      font-weight: 700;
    }
    &.__underText {
      margin-top: 44px;
    }
  }
  hr {
    margin: 50px 0;
  }
  form {
    .input-group {
      width: 50%;
    }
    label {
      display: block;
      font-size: 18px;
      margin-bottom: 4px;
      span {
        color: #d24012;
      }
    }
    input {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      padding: 20px;
      border: solid 2px #c0c0c0;
      width: 100%;
      &.error {
        border-color: #d24012;
        color: #d24012;
      }
    }
  }
`;

export default TermsOfService;
