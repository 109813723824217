import React from 'react';
import styled from 'styled-components';

const Directions = styled.div`
  .D-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #14527f;
  }
  .D-content {
    font-size: 22px;
    font-weight: 700;
    color: #2e2e2e;
  }
`;

const getCDTTime = () => {
  const localTime = new Date();
  const utc = localTime.getTime() + localTime.getTimezoneOffset() * 60000;
  const newTime = new Date(utc + 3600000 * -5.0);
  return newTime.toLocaleTimeString([], {
    hour12: 'true',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const FormDirections = ({ title, time, content }) => {
  return (
    <Directions className="SF-directions">
      <p className="D-title">{title?.toUpperCase()}</p>
      {time ? (
        <p className="D-content">
          All applications submitted after 12:00 PM CST will be processed the
          following business day. The current time is {getCDTTime()} CST U.S.
        </p>
      ) : null}
      {content ? <p className="D-content ">{content}</p> : null}
    </Directions>
  );
};

export default FormDirections;
