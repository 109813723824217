import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { returnService, constructAddress } from '../../shared/functions';
import RoundedBlock from '../UI/RoundedBlock/RoundedBlock';
import SchoolList from '../SchoolList/SchoolList';
import TermsOfService from '../form-components/FormSteps/ReviewApplication/Terms/TermsOfService';

const ApplicationOutline = ({ application, services }) => {
  const {
    deliveryInstructions,
    applicantInfo,
    education,
    termsAgreement,
    termsSignedDate,
    appDocumentsInfo,
    applicationType,
  } = application;

  const {
    prevEvaluation,
    prevEvaluationRefNumber,
    prevEvaluationDate,
    gender,
    firstName,
    middleName,
    lastName,
    differentFirstName,
    differentMiddleName,
    differentLastName,
    address: { line1, line2, city, state, postalCode, country },
    phoneNumber,
    email,
    birthday,
    birthCountry,
  } = applicantInfo;

  const {
    rushApplicationServiceID,
    returnDocumentsAddress,
    additionalReportsMailedAddresses,
    additionalPDFEmails,
    emailAddressToReturn,
    returnDocumentsEmail,
  } = deliveryInstructions;
  const appType = returnService(services, applicationType.id);
  const rushServiceType = returnService(services, rushApplicationServiceID);

  const additionalEmailsArray = additionalPDFEmails
    ? JSON.parse(additionalPDFEmails)
    : [];

  // eslint-disable-next-line react/destructuring-assignment
  const emailService = services.find(service =>
    service.displayName.includes('Additional Email'),
  );
  return (
    <>
      <Segment>
        <RoundedBlock title="01. Applicant information">
          <ApplicantDetails>
            <p>
              <strong>Previous Evaluation:</strong>{' '}
              {prevEvaluation ? prevEvaluationRefNumber : 'No'}
            </p>
            {prevEvaluation && (
              <p>
                <strong>Previous Evaluation Date:</strong>{' '}
                {dayjs(prevEvaluationDate).format('MM/DD/YYYY')}
              </p>
            )}
            <div className="__sbs">
              <p>
                <strong>Gender:</strong>
                {gender}
              </p>
              <p>
                <strong>Name:</strong>
                {firstName} {middleName} {lastName?.toUpperCase()}
              </p>
            </div>
            {(differentFirstName ||
              differentLastName ||
              differentMiddleName) && (
              <p>
                <strong>
                  Name that appears on foreign educational documents:
                </strong>
                {differentFirstName} {differentMiddleName}{' '}
                {differentLastName?.toUpperCase()}
              </p>
            )}
            <hr />
            <div className="__sbs print__address">
              <p>
                <strong>Personal Address 1:</strong>
                {line1}
              </p>
              {line2 && (
                <p>
                  <strong>Personal Address 2:</strong>
                  {line2}
                </p>
              )}
              <p>
                <strong>City:</strong>
                {city}
              </p>
              <p>
                <strong>State:</strong>
                {state}
              </p>
              <p>
                <strong>Country:</strong>
                {country}
              </p>
              <p>
                <strong>Zipcode:</strong>
                {postalCode}
              </p>
            </div>
            <hr />
            <div className="__sbs">
              <p>
                <strong>Phone number:</strong> {phoneNumber}
              </p>
              <p>
                <strong>Email:</strong> {email}
              </p>
            </div>
            <div className="__sbs">
              <p>
                <strong>Date of birth:</strong>{' '}
                {dayjs(birthday.slice(0, -2)).format('MM/DD/YYYY')}
              </p>
              <p>
                <strong>Birth country:</strong> {birthCountry}
              </p>
            </div>
          </ApplicantDetails>
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="02. Educational History">
          <SchoolList schools={education} fromDB />
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="03. Documents">
          <p>
            <strong>Documents submitted via:</strong>{' '}
            {appDocumentsInfo.documentsSentBy}
          </p>
          <div>
            <p>
              <strong>Total number of pages:</strong>{' '}
              {appDocumentsInfo.pageCount}
            </p>
            <p>
              <strong>Total number of files:</strong>{' '}
              {appDocumentsInfo.fileCount}
            </p>
          </div>
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="04. Application type & fees">
          <p>
            <strong>Service type:</strong> {appType.displayName}{' '}
            <strong>
              <em>
                (<span>$</span>
                {appType.price})
              </em>
            </strong>
          </p>
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="05. Delivery instructions">
          <div>
            <p>
              <strong>Email to send report: </strong>{' '}
              <span>{emailAddressToReturn}</span>
            </p>
          </div>
          {rushServiceType && (
            <p>
              <strong>Rush Service:</strong> {rushServiceType?.displayName}{' '}
              <strong>
                <em>
                  &nbsp;(<span>$</span>
                  {rushServiceType.price})
                </em>
              </strong>
            </p>
          )}
          {returnDocumentsAddress && (
            <div>
              <strong>Return original mailed documents:</strong>{' '}
              {constructAddress(returnDocumentsAddress, true, services)}{' '}
            </div>
          )}
          {returnDocumentsEmail && (
            <div>
              <strong>Email original documents:</strong> {returnDocumentsEmail}{' '}
            </div>
          )}
          {/* ADDITIONAL REPORTS SECTION WILL SHOW ONLY IF THERE ARE MORE REPORTS ORDERED */}
          {additionalReportsMailedAddresses.length > 0 && (
            <div>
              <hr />
              <p>
                <strong>
                  <em>Additional reports</em>
                </strong>
              </p>
              <p>
                <strong>Number of reports:</strong>{' '}
                {additionalReportsMailedAddresses.length}
              </p>

              {additionalReportsMailedAddresses.map((address, index) => {
                let hr = false;
                if (index !== 0) {
                  hr = true;
                }
                const {
                  price: addReportsPrice,
                  description: addReportsDescription,
                } = returnService(services, address.shippingMethod);
                return (
                  <div key={address.id}>
                    {hr && <hr />}
                    <p>
                      <strong>Mailing instructions:</strong>{' '}
                      {constructAddress(address, true, services)}
                    </p>
                    <p>
                      <strong>Shipping:</strong> {addReportsDescription}{' '}
                      <strong>
                        <em>
                          (<span>$</span>
                          {addReportsPrice})
                        </em>
                      </strong>
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {/* ADDITIONAL EMAILED PDFS WILL ONLY SHOW IF THERE ARE ADDITIONAL EMAILS ADDED. */}
          {additionalEmailsArray.length > 0 && (
            <div>
              <hr />
              <p>
                <strong>
                  <em>Official emailed PDF</em>
                </strong>
              </p>
              <p>
                <strong>Number of copies:</strong>{' '}
                {additionalEmailsArray.length}{' '}
                <strong>
                  <em>
                    &nbsp;(<span>$</span>
                    {emailService.price} each)
                  </em>
                </strong>
              </p>
              <div className="__addedEmails">
                <p>
                  <strong>Email addresses:</strong>
                </p>
                <div className="__emails-cont">
                  {additionalEmailsArray.map(addEmail => (
                    <p key={Math.random()}>{addEmail.email}</p>
                  ))}
                </div>
              </div>
            </div>
          )}
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="06. Terms and submission">
          <TermsOfService agreed />
          <p>
            <strong>Initials:</strong> {termsAgreement || 'not signed yet'}
          </p>
          <p>
            <strong>Affirmation Date:</strong>{' '}
            {termsSignedDate
              ? dayjs(termsSignedDate).format('MM/DD/YYYY hh:mm:ss a')
              : 'not signed yet'}
          </p>
        </RoundedBlock>
      </Segment>
    </>
  );
};

export default ApplicationOutline;
const Segment = styled.div`
  margin: 30px 0;
  p {
    strong {
      margin-right: 10px;
    }
  }
  .__sbs {
    display: flex;
    p {
      margin-right: 22px;
    }
  }
  .__addedEmails {
    display: flex;
    .__emails-cont {
      display: flex;
      margin-bottom: 15px;
    }
  }
  @media print {
    margin: 0 0 10px;
    padding: 0;

    .__title {
      font-size: 18px !important;
    }
  }
`;
const ApplicantDetails = styled.div`
  hr {
    margin: 20px 0;
  }

  @media print {
    .print__address {
      display: block;
      p {
        margin-bottom: 5px;
      }
    }
    .__sbs {
      display: block;
    }
  }
`;
