import React from 'react';
import { useMachine } from '@xstate/react';
import { debug } from '../../../envConfig';
import ModalXState from '../../Modal/ModalXState';
import BlockTable from '../../UI/RoundedBlock/BlockTable/BlockTable';
import RoundedBlock from '../../UI/RoundedBlock/RoundedBlock';
import EmailTemplateForm from './EmailTemplateForm';
import machine from './EmailTemplatesMachineActions';
import { ReactComponent as Gear } from '../../../assets/gear.svg';
import { ReactComponent as Trash } from '../../../assets/trashicon.svg';

const EmailTemplates = () => {
  const [formState, send] = useMachine(machine(), {
    devTools: debug.xstate,
  });
  const { context } = formState;

  return (
    <>
      <ModalXState
        modalOpenState="itemUpdate"
        onClose={() => send('CLOSE_MODAL')}
        machineState={formState}
      >
        <EmailTemplateForm send={send} state={context} formState={formState} />
        {formState.matches('itemUpdate.saved') && <p>Template info Saved!</p>}
        {formState.matches('itemUpdate.savingNew') ||
          (formState.matches('itemUpdate.saving') && <p>Saving...</p>)}
      </ModalXState>

      <RoundedBlock
        title="Manage Email Templates"
        btnText="Add Template"
        btnHandler={() => send('ADD_ITEM')}
      >
        <BlockTable headers={['EMAIL NAME', 'SUBJECT', 'ACTION']}>
          {context.emailTemplates?.map(({ id, templateID, name, subject }) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{subject}</td>
              <td>
                <div className="button-cont">
                  <button
                    type="button"
                    onClick={() =>
                      send('EDIT_ITEM', { id, name, subject, templateID })
                    }
                  >
                    <Gear />
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => send('DELETE_ITEM', { id })}
                  >
                    <Trash />
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </BlockTable>
      </RoundedBlock>
      {formState.matches('deleteItem') && <p>Deleting Item...</p>}
    </>
  );
};

export default EmailTemplates;
