import { gql } from '@apollo/client';

const applicationFields = `
          id
          createdAt
          paidDate
          status
          refNumber
          addedRush
          applicant {
            firstName
            lastName
            getPaidCount
          }
          evaluator{
            firstName
            lastName
            id
          }
          applicantInfo{
            firstName
            lastName
            birthday
          }
          deliveryInstructions{
            rushApplicationServiceID
          }
`;

//
// QUERIES FOR ALL EVALUATIONS
//

export const QUERY_PAID_APPLICATIONS_INITAL = gql`
  query getAllPaidApplicationsInitial($take: Int) {
    users{
      admins{
        firstName
        lastName
        id
      }
    }
    evaluations {
      all(take:$take){
          ${applicationFields}
      }
      getCount
    }
    services{
      all{
        id
        displayName
      }
    }

  }
`;

//
// QUERIES FOR MY EVALUATIONS:
//

export const QUERY_MY_PAID_APPLICATIONS_INITAL = gql`
  query getMyPaidApplicationsInitial($take: Int) {
    users{
      admins{
        firstName
        lastName
        id
      }
    }
    evaluations {
      myEvaluations(take:$take){
        ${applicationFields}
      }
      myEvaluationsCount
    }
    services{
      all{
        id
        displayName
      }
    }

  }
`;

export const SEARCH_EVALUATIONS = gql`
  query search($searchText: String, $take:Int) {
    evaluations {
      search(searchText:$searchText, take:$take){
        ${applicationFields}
      }
    }
  }
`;

export const SEARCH_MY_EVALUATIONS = gql`
  query searchMy($searchText: String, $take:Int) {
    evaluations {
      searchMyEvaluations(searchText:$searchText, take:$take){
        ${applicationFields}
      }
    }
  }
`;

export default client => {
  const queryPaidEvaluations = take => {
    return client
      .query({
        query: QUERY_PAID_APPLICATIONS_INITAL,
        variables: { take },
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        const result = {
          applications: data.data.evaluations.all,
          count: data.data.evaluations.getCount,
          admins: data.data.users.admins,
          services: data.data.services.all,
        };

        return result;
      });
  };

  // "MY" QUERIES
  const queryMyPaidEvaluationsInit = take => {
    return client
      .query({
        query: QUERY_MY_PAID_APPLICATIONS_INITAL,
        variables: { take },
      })
      .then(({ data, error }) => {
        if (error) {
          throw new Error();
        }
        const result = {
          applications: data.evaluations.myEvaluations,
          count: data.evaluations.myEvaluationsCount,
          admins: data.users.admins,
          services: data.services.all,
        };

        return result;
      });
  };

  // SEARCHING
  const search = (searchText, take) => {
    return client
      .query({
        query: SEARCH_EVALUATIONS,
        variables: { searchText, take },
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        // console.log('return data: ', data.data.evaluations.search);
        const results = {
          applications: data.data.evaluations.search,
        };
        return results;
      });
  };
  const searchMy = (searchText, take) => {
    return client
      .query({
        query: SEARCH_MY_EVALUATIONS,
        variables: { searchText, take },
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        const results = {
          applications: data.data.evaluations.searchMyEvaluations,
        };
        return results;
      });
  };

  return {
    queryPaidEvaluations,
    queryMyPaidEvaluationsInit,
    search,
    searchMy,
  };
};
