import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';

import {
  constructAddress,
  returnServiceByType,
  servicesByCountry,
} from '../../../../../../shared/functions';
// import InputField from '../../../../InputField/InputField';
import Constants from '../../../../../../shared/Constants';
import AddressForm from '../../../../AddressForm/AddressForm';

export default function ReturnDocuments({
  errors,
  register,
  watch,
  context,
  returnDocsAddressErrors,
  returnDocsAddressRegister,
  deleteAddress,
}) {
  const watchReturnDocsOption = watch('returnDocumentsOption');
  const {
    addressOnFile,
    step5: { otherRetunDocsAddress },
  } = context;
  let returnDocsEmailPrice = '--';
  const returnDocsEmailService = context?.services?.find(
    item =>
      item.serviceType === Constants.SERVICE.RETURNING_DOCUMENTS &&
      item.displayName === Constants.ADDRESS.EMAIL,
  );
  if (returnDocsEmailService) {
    returnDocsEmailPrice = returnDocsEmailService?.price;
  }

  /**
   * GET THE SERVICE FOR THE ADDRESS ON FILE.
   */
  const addressOnFileService = servicesByCountry(
    returnServiceByType(
      context.services,
      Constants.SERVICE.RETURNING_DOCUMENTS,
    ),
    addressOnFile.country,
  )[0];

  const options = [
    Constants.ADDRESS.NOT_REQUIRED,
    Constants.ADDRESS.ADDRESS_ON_APPLICATION,
    Constants.ADDRESS.OTHER_ADDRESS,
    Constants.ADDRESS.EMAIL,
  ];
  const returnDocDataRef = 'returnDocumentsOption';

  return (
    <>
      <div className="input-group">
        <p
          className="input-group--title input-group--bold"
          id="returnDocumentsOption_group"
        >
          <span className="number">02 </span>
          Do you want your original foreign educational documents returned?
          <span className="required"> *</span>
        </p>
        <div
          className="input-group--stacked input-group--wrapped"
          role="group"
          aria-labelledby="returnDocumentsOption_group"
        >
          {options.map(option => (
            <div key={option}>
              <Option
                id={`${returnDocDataRef}_${option}`}
                name={returnDocDataRef}
                type="radio"
                value={option}
                {...register(returnDocDataRef)}
                defaultChecked={
                  watchReturnDocsOption === null &&
                  option === Constants.ADDRESS.NOT_REQUIRED
                }
              />
              <OptionLabel htmlFor={`${returnDocDataRef}_${option}`}>
                {option}
              </OptionLabel>
            </div>
          ))}
        </div>
      </div>
      {watchReturnDocsOption === Constants.ADDRESS.EMAIL &&
        returnDocsEmailPrice !== '--' && (
          <>
            <div
              className={`input-group ${
                errors.emailAddressToReturnDocuments ? 'error' : ''
              }`}
            >
              <label htmlFor="returnDocumentsEmail">
                Email: <span className="required">*</span>
              </label>
              <input
                type="text"
                name="returnDocumentsEmail"
                {...register('returnDocumentsEmail')}
                className={errors.returnDocumentsEmail && 'error'}
              />
              <ErrorMessage
                errors={errors}
                name="returnDocumentsEmail"
                as="span"
                className="error"
              />
            </div>

            <p>
              Returning by Email:
              <strong>
                <em> (${returnDocsEmailPrice})</em>
              </strong>
            </p>
          </>
        )}
      {watchReturnDocsOption === Constants.ADDRESS.ADDRESS_ON_APPLICATION && (
        <>
          {constructAddress(addressOnFile)}
          <p>
            Shipping Method: {addressOnFileService.displayName}{' '}
            <strong>
              <em>{`($${addressOnFileService.price}.00)`}</em>
            </strong>
          </p>
        </>
      )}
      {watchReturnDocsOption === Constants.ADDRESS.OTHER_ADDRESS && (
        <>
          {otherRetunDocsAddress ? (
            <StyledOtherAddress>
              {constructAddress(otherRetunDocsAddress, false, context.services)}
              <button
                type="button"
                className="btn btn--blue"
                onClick={deleteAddress}
              >
                Delete Address
              </button>
            </StyledOtherAddress>
          ) : (
            <>
              <AddressForm
                register={returnDocsAddressRegister}
                errors={returnDocsAddressErrors}
                yupObjectName="mailAddress"
                company
                noLocation
                form="addReturnDocsAddressForm"
                requireZip
              />
              <SendButton
                className="btn"
                type="submit"
                form="addReturnDocsAddressForm"
              >
                Save Address
              </SendButton>
            </>
          )}
          <input
            type="hidden"
            name="returnDocsOtherAddressValidationInput"
            {...register('returnDocsOtherAddressValidationInput')}
            readOnly
            hidden
          />
          <ErrorMessage
            errors={errors}
            name="returnDocsOtherAddressValidationInput"
            as="span"
            className="error"
          />
        </>
      )}
    </>
  );
}

const SendButton = styled.button`
  margin-top: 10px;
  background-color: #14527f;
  color: white;
  transition: all ease 0.3s;
  &:hover {
    background-color: #071e2e;
  }
`;

const StyledOtherAddress = styled.div`
  button {
    margin-top: 10px;
  }
`;

const Option = styled.input`
  &:disabled {
    cursor: not-allowed;
  }
`;

const OptionLabel = styled.label`
  color: ${props => (props.isDisabled ? 'lightgrey' : 'unset')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
