import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import DashboardTemplate from './Templates/DashboardTemplate';
import { Button, FormFields } from '../shared/styles';
import TextInput from '../components/UI/FormInputs/TextInput/TextInput';

const CREATE_USER = gql`
  mutation createUser($userInput: UserCreateInput!) {
    users {
      create(user: $userInput) {
        id
      }
    }
  }
`;

const schema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name.'),
  lastName: yup.string().required('Please enter your last name.'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
});

const CreateProfilePage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [saveProfile, { data, loading, error }] = useMutation(CREATE_USER);

  const submitHandler = formData => {
    saveProfile({ variables: { userInput: { ...formData } } });
  };
  return (
    <DashboardTemplate>
      <StyledCreateProfileContainer>
        <h2>Please fill out your profile before proceeding</h2>
        <FormFields onSubmit={handleSubmit(submitHandler)}>
          <TextInput
            type="text"
            title="First Name:"
            dataRef="firstName"
            register={register}
            errors={errors}
          />
          <TextInput
            type="text"
            title="Last Name:"
            dataRef="lastName"
            register={register}
            errors={errors}
          />
          <TextInput
            type="text"
            dataRef="email"
            title="Email:"
            register={register}
            errors={errors}
          />
          <Button type="submit">Submit</Button>
        </FormFields>
        {loading && <p>Submitting...</p>}
        {error && <p>Something went wrong try again</p>}
        {data && <Navigate to="/dashboard" />}
      </StyledCreateProfileContainer>
    </DashboardTemplate>
  );
};

export default CreateProfilePage;

const StyledCreateProfileContainer = styled.div`
  h2 {
    margin-bottom: 30px;
  }
  form {
    max-width: 600px;
    margin: 0;
  }
`;
