import React from 'react';
import dayjs from 'dayjs';
import { ErrorMessage } from '@hookform/error-message';

import Formdropdown from '../FormDropdown/FormDropdown';
import InputField from '../InputField/InputField';

const DateInput = ({
  dataPrefix,
  errors,
  register,
  date,
  monthSelected,
  excludeDay,
}) => (
  <div
    className={
      excludeDay
        ? 'SF-side-by-side SF-side-by-side-2'
        : 'SF-side-by-side SF-side-by-side-3'
    }
  >
    <Formdropdown
      register={register}
      dataRef={`${dataPrefix}.month`}
      title="Month"
      errors={errors}
      content="month"
      required
      defaultValue={date?.month}
    />
    {!excludeDay && (
      <Formdropdown
        register={register}
        dataRef={`${dataPrefix}.day`}
        title="Day"
        errors={errors}
        content="days"
        defaultValue={date && dayjs(date.day).format('DD')}
        required
        monthSelected={monthSelected}
      />
    )}
    <InputField
      register={register}
      dataRef={`${dataPrefix}.year`}
      type="text"
      title="Year"
      errors={errors}
      required
      defaultValue={date && dayjs(date.year).format('YYYY')}
      noError
      placeholder="YYYY"
    />
    <div className="error-block">
      <ErrorMessage
        errors={errors}
        name={`${dataPrefix}.year`}
        as="span"
        className="error"
      />
    </div>
  </div>
);

export default DateInput;
