import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { isAdmin } from '../../shared/userFunctions';

const ProtectedAdminRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isLoading) {
    // user isn't authenticated and is required to be.
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }

    if (isAdmin()) {
      return children || <Outlet />;
    }

    // Access Denied.
    return <Navigate to="/access-denied" replace />;
  }

  return null;
};

export default ProtectedAdminRoute;
