import React from 'react';
import { useMachine } from '@xstate/react';
import styled from 'styled-components';
import { debug } from '../../../envConfig';

import ConfigureForm from './ConfigureForm';
import machine from './ConfigureSettingsMachine';

const ConfigureSettings = () => {
  const [formState, send] = useMachine(machine(), {
    devTools: debug.xstate,
  });

  const onSubmit = formData => {
    send('SAVE_FORM', formData);
  };

  let toShow;

  switch (true) {
    case formState.matches('init'):
      toShow = <p>Loading...</p>;
      break;
    case formState.matches('loadingFailed'):
      toShow = <p>loading failed please refresh page</p>;
      break;
    default:
      toShow = (
        <ConfigureForm
          formState={formState}
          onSubmit={onSubmit}
          state={formState.context}
        />
      );
      break;
  }

  return (
    <ConfigurationSettingsStyled>
      <h1>Manage Configuration Settings</h1>
      {toShow}
    </ConfigurationSettingsStyled>
  );
};

const ConfigurationSettingsStyled = styled.div`
  h1 {
    margin-bottom: 20px;
  }
`;

export default ConfigureSettings;
