import React from 'react';
import { useMachine } from '@xstate/react';

import { debug } from '../../envConfig';
import ModalXState from '../Modal/ModalXState';
import RoundedBlock from '../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../UI/RoundedBlock/BlockTable/BlockTable';
import incompleteApplicationMachine from './MachineSettings';

import IncompleteApplicationItem from './IncompleteApplicationItem';
import { Button } from '../../shared/styles';
import { ModalContent, Info } from './styles';
import Pagination from '../UI/Pagination/Pagination';
import { sortByDate, sortByName } from '../../shared/functions';

const IncompleteApplicationList = () => {
  const tableHeaders = [
    {
      title: 'CREATED ON',
      sortable: true,
      send: 'SORT_CREATED_ON',
      context: 'sortCreated',
    },
    {
      title: 'FIRST NAME',
      sortable: true,
      send: 'SORT_FIRST_NAME',
      context: 'sortFirstName',
    },
    {
      title: 'LAST NAME',
      sortable: true,
      send: 'SORT_LAST_NAME',
      context: 'sortLastName',
    },
    { title: 'STEPS', sortable: false },
    { title: 'ACTIONS', sortable: false },
  ];
  const [formState, send] = useMachine(incompleteApplicationMachine(), {
    devTools: debug.xstate,
  });
  const {
    incompleteApplications,
    allIncompleteApplicationsCount,
    pages,
    currentPage,
    itemToDelete,
    itemsToShow,
    sortCreated,
    sortFirstName,
    sortLastName,
  } = formState.context;
  let applicationsOnPage = [];

  if (incompleteApplications) {
    // SORTING
    let sortedApplications = [...incompleteApplications];
    if (sortCreated !== null) {
      sortedApplications = sortedApplications.sort((a, b) =>
        sortByDate(a, b, sortCreated, 'createdAt'),
      );
    }
    if (sortFirstName !== null) {
      sortedApplications = sortedApplications.sort((a, b) =>
        sortByName(
          b.applicantInfo?.firstName,
          a.applicantInfo?.firstName,
          sortFirstName,
        ),
      );
    }
    if (sortLastName !== null) {
      sortedApplications = sortedApplications.sort((a, b) =>
        sortByName(
          b.applicantInfo?.lastName,
          a.applicantInfo?.lastName,
          sortLastName,
        ),
      );
    }
    // WHICH APPLICATIONS TO SHOW ON THE PAGE
    switch (true) {
      case currentPage !== 1:
        applicationsOnPage = sortedApplications.slice(
          (currentPage - 1) * itemsToShow,
          currentPage * itemsToShow,
        );
        break;

      default:
        applicationsOnPage = sortedApplications.slice(0, itemsToShow);
        break;
    }
  }
  if (formState.matches('init')) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <ModalXState
        modalOpenState="confirmDelete"
        onClose={() => send('CLOSE_MODAL')}
        machineState={formState}
      >
        <ModalContent>
          <p>
            Are you sure you want to delete this application?{' '}
            <strong className="error">This can not be undone!</strong>
          </p>
          {itemToDelete && (
            <Info>
              <p>Application Info:</p>
              <p>{itemToDelete?.applicant.firstName}</p>
              <p>{itemToDelete?.applicant.lastName}</p>
            </Info>
          )}
          <Button
            type="button"
            aria-label="Delete application"
            onClick={() => send('DELETE_ITEM', { data: itemToDelete.id })}
          >
            DELETE APPLICATION
          </Button>
        </ModalContent>
      </ModalXState>
      <ModalXState
        modalOpenState={'emailConfirmed' || 'emailError'}
        onClose={() => send('CLOSE_MODAL')}
        machineState={formState}
      >
        <ModalContent>
          {formState.matches('emailConfirmed') && <p>Your email was sent!</p>}
          {formState.matches('emailError') && (
            <p>Your email was NOT sent. Try again</p>
          )}
        </ModalContent>
      </ModalXState>
      <RoundedBlock
        hasPagination
        title="Manage Incomplete Applications"
        btnText="Search"
        send={send}
        hasSearch
      >
        <BlockTable
          headers={tableHeaders}
          sortable
          send={send}
          xStateContext={formState.context}
        >
          {applicationsOnPage.length === 0 && (
            <p>There are no incomplete applications with that search</p>
          )}
          {applicationsOnPage?.map(app => {
            return (
              <IncompleteApplicationItem
                send={send}
                key={app.id}
                application={app}
              />
            );
          })}
        </BlockTable>
      </RoundedBlock>
      <Pagination
        pages={pages}
        itemsToShow={itemsToShow}
        currentPage={currentPage}
        itemsArray={incompleteApplications}
        allItemsCount={allIncompleteApplicationsCount}
        send={send}
      />
      {formState.matches('search') && <p>Searching...</p>}
    </>
  );
};

export default IncompleteApplicationList;
