import React from 'react';

const returnService = (serviceId, services) =>
  services.find(service => service.id === serviceId);

const ApplicationType = ({ formState, send }) => {
  const { services, step4 } = formState.context;
  const applicationType = returnService(step4.serviceId, services);
  return (
    <div className="section">
      <div className="section__header">
        <h2>04. Application type & fees</h2>
        <button
          aria-label="Edit Application type & fees"
          className="edit-button"
          type="button"
          onClick={() => send('STEP_FOUR')}
        >
          Edit
        </button>
      </div>
      <hr />
      <div className="section__content">
        <div className="section__content-block">
          <p>
            <strong>Service type:</strong> {applicationType.displayName}{' '}
            <strong>
              <em>&nbsp;(${applicationType.price})</em>
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApplicationType;
