function createConfig() {
  return {
    id: 'evaluationList',
    initial: 'init',
    context: {
      items: [],
      admins: [],
      take: 1000,
      currentPage: 1,
      pages: null,
      itemsToShow: 100,
      sortRef: null,
      sortDatePaid: true,
      sortName: null,
      sortAssignedTo: null,
      sortStatus: null,
      message: null,
    },

    states: {
      init: {
        invoke: {
          id: 'initialLoad',

          src: 'load',
          onDone: {
            actions: ['loadSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      waiting: {
        on: {
          NEXT_PAGE: { actions: 'nextPage' },
          UPDATE_STATUS: { target: 'updateStatus' },
          UPDATE_EVALUATOR: { target: 'updateEvaluator' },
          PREV_PAGE: { actions: 'prevPage' },
          GO_TO_PAGE: { actions: 'goToPage' },
          SORT: { actions: ['sort'] },
          SEARCH: 'search',
          INIT: 'init',
          SEARCH_MY: 'searchMy',
          DELETE_APPLICATION: 'deleteApplication',
        },
      },
      search: {
        invoke: {
          id: 'searching',
          src: 'search',
          onDone: {
            actions: ['searchSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      searchMy: {
        invoke: {
          id: 'searchingMy',
          src: 'searchMy',
          onDone: {
            actions: ['searchSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      updateStatus: {
        invoke: {
          id: 'updateStatus',

          src: 'updateStatus',
          onDone: {
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      updateEvaluator: {
        invoke: {
          id: 'updateEvaluator',

          src: 'updateEvaluator',
          onDone: {
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      deleteApplication: {
        invoke: {
          id: 'deleteApplication',
          src: 'deleteApplication',
          onDone: {
            target: 'init',
          },
          onError: 'loadingFailed',
        },
      },
      loadingFailed: {},
    },
    on: {},
  };
}

export default createConfig;
