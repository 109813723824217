import * as yup from 'yup';

const schema = yup.object().shape({
  typeOfReport: yup
    .string()
    .typeError('Please choose which type of report you want')
    .required('Please choose a report.'),
});

export default schema;
