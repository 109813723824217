import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardTemplate from './Templates/DashboardTemplate';
import ApplicationList from '../components/applicationsList/applicationsList';
import { isAdmin } from '../shared/userFunctions';

const DashboardApplicationView = () => {
  // RESETS ONBEFOREUNLOAD SO THE POPUP GOES AWAY ON REFRESH
  window.onbeforeunload = null;

  // IF THE USER IS AN ADMIN TAKE THEM TO THE ADMIN/EVALUATIONS PAGE:
  if (isAdmin()) {
    return <Navigate to="/admin/evaluations" />;
  }

  return (
    <DashboardTemplate>
      <ApplicationList />
    </DashboardTemplate>
  );
};

export default DashboardApplicationView;
