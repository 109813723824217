const step5State = {
  id: 'editingStepFive',
  initial: 'idle',
  on: {},
  states: {
    idle: {
      id: 'step5Idle',
      on: {
        SAVE_OTHER_MAIL_ADDRESS_TO_CTX: {
          actions: ['saveOtherMailAddressToCTX'],
        },
        REMOVE_OTHER_MAIL_ADDRESS_FROM_CTX: {
          actions: ['deleteOtherMailAddressfromCTX'],
        },
        ADD_ADDITIONAL_ADDRESS: {
          target: 'additionalAddressState',
        },
        SAVE_TEMP_RETURN_DOCS_OTHER_ADDRESS: {
          actions: ['saveTempReturnDocsOtherAddress'],
        },
        DELETE_OTHER_RETURN_DOCS_ADDRESS: {
          actions: ['deleteTempReturnDocsOtherAddress'],
        },
        DELETE_ADDITIONAL_REPORT_ADDRESS: {
          target: 'deletingAdditionalReportsAddress',
        },
        STEP_COMPLETE: {
          actions: ['saveStep5ToContext'],
          target: 'updateDeliveryInstructions',
        },
        PREV_STEP: '#editingStepFour',
      },
    },
    additionalAddressState: {
      initial: 'addingAddress',
      states: {
        addingAddress: {
          on: {
            SAVE_TEMP_ADDITIONAL_ADDRESS: {
              actions: ['saveTempAddressToCTX'],
              target: 'addingShipping',
            },
            CANCEL_ADDRESS: {
              actions: ['deleteTempAdditionalAddress'],
              target: '#step5Idle',
            },
          },
        },
        addingShipping: {
          on: {
            SAVE_ADDRESS_TO_DB: {
              actions: ['addShippingToTempAddressInCTX'],
              target: 'savingAddressToDB',
            },
            CANCEL_ADDRESS: {
              actions: ['deleteTempAdditionalAddress'],
              target: '#step5Idle',
            },
          },
        },
        savingAddressToDB: {
          invoke: {
            src: 'saveAdditionalAddressToDB',
            onDone: {
              actions: ['setAdditionalReportsToCTX'],
              target: '#step5Idle',
            },
            onError: {
              target: '#error',
            },
          },
        },
      },
    },
    deletingAdditionalReportsAddress: {
      invoke: {
        src: 'deleteAdditionalReportsAddress',
        onDone: {
          actions: ['setAdditionalReportsToCTX'],
          target: '#step5Idle',
        },
        onError: {
          target: '#error',
        },
      },
    },
    updateDeliveryInstructions: {
      invoke: {
        src: 'updateDeliveryInstructions',
        onDone: {
          target: '#editingReviewApplication',
        },
        onError: {
          target: '#error',
        },
      },
    },
  },
};

export default step5State;
