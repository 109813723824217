import React from 'react';
import dayjs from 'dayjs';
import { useMachine } from '@xstate/react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { debug } from '../../../envConfig';
import RoundedBlock from '../../UI/RoundedBlock/RoundedBlock';
import Machine from './MachineSettings';
import SchoolList from '../../SchoolList/SchoolList';
import { returnService, constructAddress } from '../../../shared/functions';

const ApplicationView = ({ applicationId }) => {
  let idToUse = applicationId;
  if (!applicationId) {
    const { id } = useParams();
    idToUse = id;
  }
  const [formState] = useMachine(Machine(idToUse), {
    devTools: debug.xstate,
  });
  const { application, services } = formState.context;
  const {
    applicant,
    applicantInfo,
    education,
    appDocumentsInfo,
    applicationType,
    deliveryInstructions,
  } = application;
  const rushServiceChosen =
    returnService(services, deliveryInstructions?.rushApplicationServiceID)
      ?.displayName || null;
  switch (true) {
    case formState.matches('init'):
      return <p>Loading...</p>;
    case !applicantInfo:
      return <p>This user has not completed any steps</p>;
    default:
      return (
        <>
          {/* APPLICANT INFO STEP 1 OF FORM */}
          {applicantInfo && (
            <Segment>
              <RoundedBlock title="01. Applicant information">
                <div>
                  {applicant && (
                    <p>
                      <strong>User's Name:</strong> {applicant?.firstName}{' '}
                      {applicant?.lastName.toUpperCase()}
                    </p>
                  )}
                  <p>
                    <strong>Previous Evaluation:</strong>{' '}
                    {applicantInfo?.prevEvaluation
                      ? applicantInfo?.prevEvaluationRefNumber
                      : 'No'}
                  </p>
                  {applicantInfo?.prevEvaluation && (
                    <p>
                      <strong>Previous Evaluation Date:</strong>{' '}
                      {dayjs(applicantInfo?.prevEvaluationDate).format(
                        'MM/DD/YYYY',
                      )}
                    </p>
                  )}
                  <div className="__group">
                    <p>
                      <strong>Gender:</strong> {applicantInfo?.gender || '--'}
                    </p>
                    <p>
                      <strong>Applicant's Name:</strong>{' '}
                      {applicantInfo?.firstName}{' '}
                      {applicantInfo?.lastName.toUpperCase()}
                    </p>
                  </div>
                  {applicantInfo?.differentFirstName ||
                    applicantInfo?.differentLastName ||
                    (applicantInfo?.differentMiddleName && (
                      <p>
                        <strong>
                          Name that appears on foreign educational documents:
                        </strong>
                      </p>
                    ))}
                  <hr />
                  <div className="__group">
                    <p>
                      <strong>Personal Address 1:</strong>{' '}
                      {applicantInfo?.address?.line1}
                    </p>
                    {applicantInfo?.address?.line2 && (
                      <p>
                        <strong>Personal Address 2:</strong>{' '}
                        {applicantInfo?.address?.line2}
                      </p>
                    )}
                    <p>
                      <strong>City:</strong>{' '}
                      {applicantInfo?.address?.city || '--'}
                    </p>
                    <p>
                      <strong>State:</strong>
                      {applicantInfo?.address?.state || '--'}
                    </p>
                    <p>
                      <strong>Country:</strong>
                      {applicantInfo?.address?.country || '--'}
                    </p>
                    <p>
                      <strong>Zipcode:</strong>
                      {applicantInfo?.address?.postalCode || '--'}
                    </p>
                  </div>
                  <hr />
                  <div className="__group">
                    <p>
                      <strong>Phone number:</strong>{' '}
                      {applicantInfo?.phoneNumber}
                    </p>
                    <p>
                      <strong>Email:</strong> {applicantInfo?.email}
                    </p>
                  </div>
                  <div className="__group">
                    <p>
                      <strong>Date of birth:</strong>{' '}
                      {applicantInfo?.birthday
                        ? dayjs(applicantInfo?.birthday.slice(0, -2)).format(
                            'MM/DD/YYYY',
                          )
                        : '-'}
                    </p>
                    <p>
                      <strong>Birth country:</strong>{' '}
                      {applicantInfo?.birthCountry}
                    </p>
                  </div>
                </div>
              </RoundedBlock>
            </Segment>
          )}
          {/* EDUCATION INFO STEP 2 OF FORM */}
          {education?.length > 0 && (
            <Segment>
              <RoundedBlock title="02. Educational History">
                <SchoolList schools={education} fromDB />
              </RoundedBlock>
            </Segment>
          )}
          {/* DOCUMENTS INFO STEP 3 OF FORM */}
          {appDocumentsInfo?.documentsSentBy !== '' && (
            <Segment>
              <RoundedBlock title="03. Documents">
                {appDocumentsInfo?.documents?.length > 0 && (
                  <>
                    <p>
                      <strong>Documents added:</strong>
                    </p>
                    {appDocumentsInfo?.documents.map(d => (
                      <p key={d.name}>{d.name}</p>
                    ))}
                  </>
                )}
                <p>
                  <strong>Documents submitted via:</strong>{' '}
                  {appDocumentsInfo?.documentsSentBy}
                </p>
                <div className="__group">
                  <p>
                    <strong>Total number of pages:</strong>{' '}
                    {appDocumentsInfo?.pageCount}
                  </p>
                  <p>
                    <strong>Total number of files:</strong>{' '}
                    {appDocumentsInfo?.fileCount}
                  </p>
                </div>
              </RoundedBlock>
            </Segment>
          )}
          {/* APPLICATION TYPE STEP 4 OF FORM */}
          {applicationType && (
            <Segment>
              <RoundedBlock title="04. Application type & fees">
                <p>
                  <strong>Service type:</strong> {applicationType?.displayName}
                </p>
              </RoundedBlock>
            </Segment>
          )}
          {/* DELIVERY INSTRUCTIONS STEP 5 OF FORM */}
          {deliveryInstructions?.id &&
            deliveryInstructions?.mailAddressEmail && (
              <Segment>
                <RoundedBlock title="05. Delivery Instructions">
                  <p>
                    <strong>Report Emailed to:</strong>{' '}
                    {deliveryInstructions?.mailAddressEmail}
                  </p>
                  <p>
                    <strong>Rush service</strong> {rushServiceChosen}
                  </p>
                  {deliveryInstructions?.returnDocumentsAddress && (
                    <p>
                      <strong>Return original mailed documents</strong>
                    </p>
                  )}
                  <hr />

                  <>
                    <p>
                      <em>Additional Reports</em>
                    </p>
                    {deliveryInstructions?.additionalReportsMailedAddresses
                      .length > 0 && (
                      <>
                        <p>
                          <strong>Number of Reports:</strong>{' '}
                          {
                            deliveryInstructions
                              ?.additionalReportsMailedAddresses.length
                          }
                        </p>
                        {deliveryInstructions?.additionalReportsMailedAddresses.map(
                          e => (
                            <div key={`${e.shippingMethod}${Math.random()}`}>
                              <p>
                                <strong>Mailing instructions:</strong>
                              </p>
                              {e ? constructAddress(e) : ''}
                              <p>
                                <strong>Shipping:</strong>{' '}
                                {
                                  returnService(services, e.shippingMethod)
                                    .displayName
                                }
                              </p>
                              <hr />
                            </div>
                          ),
                        )}
                      </>
                    )}
                    {deliveryInstructions?.additionalPDFEmails && (
                      <>
                        <p>
                          <strong>Number of copies:</strong>
                        </p>
                        <p>
                          <strong>Email addresses</strong>
                        </p>
                      </>
                    )}
                  </>
                </RoundedBlock>
              </Segment>
            )}
        </>
      );
  }
};

export default ApplicationView;

const Segment = styled.div`
  margin: 30px 0;
`;
