import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { GET_SERVICE_BY_TYPE } from '../../../queries_mutations/queries';

const seeIfChecked = (inputId, incomingID, firstChecked, index) => {
  if (inputId === incomingID) {
    return true;
  }
  if (firstChecked && index === 0) {
    return true;
  }
  return false;
};

const CustomRadiogroup = ({
  settings: { dataRef, sectionTitle },
  register,
  errors,
  firstChecked,
  required,
  serviceToQuery,
  defaultValue,
}) => {
  const { data, loading } = useQuery(GET_SERVICE_BY_TYPE, {
    variables: { serviceType: serviceToQuery },
  });
  let services = [];
  if (!loading) {
    services = data.services.byType;
  }

  return (
    <TempContainer className="input-group">
      <p className="input-group--title" id={`${dataRef}_group`}>
        <strong>{sectionTitle}</strong>{' '}
        {required && <span className="error">*</span>}
      </p>
      <div role="group" aria-labelledby={`${dataRef}_group`}>
        {services.map(({ id, displayName, description, price }, index) => (
          <div className="option-group" key={id + displayName}>
            <input
              id={`${dataRef}_${displayName}`}
              name={dataRef}
              type="radio"
              value={id}
              {...register(dataRef)}
              defaultChecked={seeIfChecked(
                id,
                defaultValue,
                firstChecked,
                index,
              )}
            />
            <label htmlFor={`${dataRef}_${displayName}`}>
              <strong>{displayName}: </strong> {description}{' '}
              <strong>${price}.00</strong>
            </label>
          </div>
        ))}
      </div>
      <ErrorMessage
        errors={errors}
        name={dataRef}
        as="span"
        className="error"
      />
    </TempContainer>
  );
};

export default CustomRadiogroup;

const TempContainer = styled.div`
  .option-group {
    margin: 15px 0;
    border-bottom: 1px solid #d4d4d4;
    padding: 10px 0;
    display: flex;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    input {
      margin-top: 3px;
      margin-right: 10px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
`;
