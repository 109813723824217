/* eslint-disable import/prefer-default-export */
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { redirect } from 'react-router-dom';
import { auth0Config } from '../../envConfig';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const onRedirectCallback = appState => {
    redirect(appState?.returnTo || window.location.pathname); // todo now sure about redirect here
  };

  return (
    <Auth0Provider
      {...auth0Config}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
      useFormData={false}
    >
      {children}
    </Auth0Provider>
  );
};
