import React, { useState } from 'react';
import styled from 'styled-components';
import DashboardSidebar from '../../components/DashboardSidebar/DashboardSidebar';
import ClientDashboardHeader from '../../components/client-dashboard-header/client-dashboard-header.component';

const ClientDashboard = styled.div`
  display: flex;
  width: 100%;
`;

const ClientDashboardLeft = styled.div`
  flex: 1;
  overflow-x: hidden;
  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
`;
const ComponentContainer = styled.div`
  padding: 40px;

  @media only screen and (max-width: 600px) {
    padding-inline: 30px;
  }
`;

const DashboardTemplate = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  return (
    <ClientDashboard>
      <DashboardSidebar
        className="DashboardSidebar"
        sideBarOpen={sideBarOpen}
        toggleSideBar={() => setSideBarOpen(prev => !prev)}
      />
      <ClientDashboardLeft>
        <ClientDashboardHeader
          toggleSideBar={() => setSideBarOpen(prev => !prev)}
        />
        <ComponentContainer className="ComponentContainer">
          {children}
        </ComponentContainer>
      </ClientDashboardLeft>
    </ClientDashboard>
  );
};

export default DashboardTemplate;
