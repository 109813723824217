import React from 'react';
import { useParams } from 'react-router-dom';

import DashboardTemplate from '../Templates/DashboardTemplate';
import ApplicationView from '../../components/dashboard-pages/ApplicationView/ApplicationView';

const IncompleteApplicationView = () => {
  const { id } = useParams();
  return (
    <DashboardTemplate>
      <h1>Incomplete Application</h1>
      <ApplicationView applicationId={id} />
    </DashboardTemplate>
  );
};

export default IncompleteApplicationView;
