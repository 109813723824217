import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

const TextInput = ({
  register,
  dataRef,
  type,
  placeholder,
  number,
  title,
  errors,
  required,
  defaultValue,
  form,
  rows,
}) => {
  if (type === 'text-area') {
    return (
      <>
        <div className={`input-group ${errors[dataRef] ? 'error' : ''}`}>
          <label
            className={
              number
                ? 'input-group--title input-group--bold'
                : 'input-group--title'
            }
            htmlFor={dataRef}
          >
            {number && <span className="number">{number} </span>}
            {title} {required && <span className="required">*</span>}
          </label>
          <textarea
            type={type}
            placeholder={placeholder}
            name={dataRef}
            {...register(dataRef)}
            defaultValue={defaultValue}
            form={form || ''}
            className={errors[dataRef] && 'error'}
            rows={rows || '3'}
          />
          <ErrorMessage
            errors={errors}
            name={dataRef}
            as="span"
            className="error"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={`input-group ${errors[dataRef] ? 'error' : ''}`}>
        <label
          className={
            number
              ? 'input-group--title input-group--bold'
              : 'input-group--title'
          }
          htmlFor={dataRef}
        >
          {number && <span className="number">{number} </span>}
          {title} {required && <span className="required">*</span>}
        </label>
        <input
          type={type}
          placeholder={placeholder}
          name={dataRef}
          {...register(dataRef)}
          defaultValue={defaultValue}
          form={form || ''}
          className={errors[dataRef] && 'error'}
        />
        <ErrorMessage
          errors={errors}
          name={dataRef}
          as="span"
          className="error"
        />
      </div>
    </>
  );
};

export default TextInput;
