import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import PendingCopyRequestList from '../../components/dashboard-pages/AdminCopyRequest/AdminPendingCopyRequestList/CopyRequestList';

const CopyRequestPendingPage = () => {
  return (
    <DashboardTemplate>
      <PendingCopyRequestList />
    </DashboardTemplate>
  );
};

export default CopyRequestPendingPage;
