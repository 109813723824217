import styled from 'styled-components';

const ReviewStyled = styled.div`
  max-width: 1120px;
  margin: 112px auto;
  padding: 0 30px;
  hr {
    border: #d4d4d4 solid 1px;
    background-color: #d4d4d4;
  }
  .title {
    margin-bottom: 30px;
  }
  .application-details {
    margin-bottom: 30px;
    ul {
      list-style: none;
      display: flex;
      max-width: 600px;
      flex-wrap: wrap;
      li {
        width: 50%;
        margin-bottom: 10px;
      }
    }
  }
  .section {
    background-color: #f7fafc;
    border-radius: 2px;
    margin-bottom: 40px;
    overflow-x: scroll;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 25px;
      h2 {
        color: #14527f;
        font-size: 16px;
        font-weight: 700;
      }
    }
    &__content {
      padding: 0 60px;
    }
    &__content-block {
      padding: 35px 0;
      p {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        strong {
          margin-right: 5px;
        }
        .checkbox_icon {
          fill: #14527f;
          margin-right: 5px;
        }
      }
      .address {
        padding: 20px 0;
        hr {
          width: 50%;
          margin: 0 auto 30px;
          display: block;
        }
      }
    }
    &__content-group {
      display: flex;
      flex-wrap: wrap;
      p {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__terms-checkbox {
      margin-bottom: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      input {
        margin-right: 10px;
      }
    }
  }
  .edit-button {
    border: solid 2px #14527f;
    padding: 12px;
    min-width: ${props => (props.long ? '200px' : '100px')};
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: #14527f;
    background-color: transparent;
    transition: all ease 0.3s;
    cursor: pointer;
    &:hover,
    &:focus {
      color: white;
      background-color: #14527f;
    }
  }
  .anim-flashing-Button {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
      content: '';
      border-radius: 10px;
      width: 110%;
      height: 110%;
      background: radial-gradient(
        circle,
        rgba(104, 182, 236, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: flashingButton 2s infinite;
      z-index: -1;
    }
  }
  @keyframes flashingButton {
    from {
      transform: translate(-50%, -50%) scale(0.1);
      opacity: 0.8;
    }
    to {
      transform: translate(-50%, -50%) scale(3);
      opacity: 0;
    }
  }
`;

export default ReviewStyled;
