import React, { useState, useEffect } from 'react';

import FormDirections from '../../FormDirections/FormDirections';
import SchoolForm from './SchoolForm/SchoolForm';
import { ReactComponent as IconEdit } from '../../../../assets/edit.svg';
import { ReactComponent as Trash } from '../../../../assets/trashicon.svg';
// STYLING
import { StyledForm } from '../../FormComponents.styles';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';

import SchoolList from './styles';

const Step2 = ({ onSubmit, previousStep, state, send, stepComplete }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [collapseForm, setCollapseForm] = useState(true);
  const [editSchool, setEditSchool] = useState({});

  useEffect(() => {}, [state]);

  const toggleCollapse = () => {
    setCollapseForm(prev => !prev);
  };

  const addSchool = data => {
    setEditSchool({});
    send('ADD_SCHOOL', { data });
    toggleCollapse();
  };

  const deleteItem = id => {
    setEditSchool({});
    send('DELETE_SCHOOL', { id });
  };
  const addOrCancelSchoolButton = () => {
    setEditSchool({});
    toggleCollapse();
  };
  const editItemOpen = id => {
    const schoolToEdit = state.filter(school => school.id === id);
    setEditSchool(prev => {
      return { ...prev, ...schoolToEdit[0], id };
    });
    toggleCollapse();
  };

  const editItem = data => {
    send('EDIT_SCHOOL', { data });
    toggleCollapse();
  };

  const formSubmit = data => {
    if (Object.keys(editSchool).length !== 0) {
      const schoolToEdit = { ...data, id: editSchool.id };
      editItem(schoolToEdit);
    } else {
      addSchool(data);
    }
  };

  const cancelForm = () => {
    toggleCollapse();
  };

  return (
    <StyledForm>
      <div className="temp-width">
        <FormDirections
          title="Educational History"
          content="Click “Add Education” to input information. Then click “Submit Education” to save information. Repeat steps for multiple entries."
        />
        {!collapseForm && (
          <SchoolForm
            submitHandle={formSubmit}
            cancelForm={cancelForm}
            formValues={editSchool}
          />
        )}
        <button
          className="btn btn--blue"
          type="button"
          onClick={addOrCancelSchoolButton}
        >
          {collapseForm ? 'Add Education' : 'Cancel Add Education'}
        </button>
      </div>
      <SchoolList>
        <h2 className="SF-segment-title">Saved Education</h2>
        <table>
          <thead>
            <tr>
              <th>country</th>
              <th>school/institution</th>
              <th>area of study</th>
              <th>attendance</th>
              <th>degree/diploma</th>
              <th>award date</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {state?.map(
              ({
                country,
                name,
                areaOfStudy,
                startDate,
                endDate,
                awardDate,
                degree,
                id,
              }) => (
                <tr className="school" key={id}>
                  <td>{country}</td>
                  <td className="school__name">{name}</td>
                  <td>{areaOfStudy}</td>
                  <td>{`${startDate.month}/${startDate.year} - ${endDate.month}/${endDate.year} `}</td>
                  <td>{degree.title ? degree.title : 'N/A'}</td>
                  <td>
                    {awardDate
                      ? `${awardDate?.month}/${awardDate?.year}`
                      : 'N/A'}
                  </td>
                  <td className="school__actions">
                    <button type="button" onClick={() => editItemOpen(id)}>
                      <IconEdit />
                      Edit
                    </button>
                    <button
                      aria-label="Delete Item"
                      type="button"
                      onClick={() => deleteItem(id)}
                    >
                      <Trash />
                    </button>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </SchoolList>
      <div className="temp-width">
        <hr />

        <NextPrevStepButtons
          prevStep={() => previousStep()}
          nextStep={() => onSubmit()}
          disabledValue={!stepComplete}
        />
      </div>
    </StyledForm>
  );
};

export default Step2;
