import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';

export const QUERY_INIT = gql`
  query querySettingsInit {
    workflow {
      all {
        id
        name
        adminId
      }
    }
    users {
      admins {
        id
        firstName
        lastName
      }
    }
    services {
      byType(service: "RUSH") {
        id
        show
      }
    }
  }
`;

export default client => {
  const queryInit = () => {
    return client
      .query({
        query: QUERY_INIT,
      })
      .then((data, error) => {
        if (error) {
          Sentry.captureException(error);
          throw new Error(error);
        }
        const rushActive = data.data.services.byType[0].show;
        const results = {
          workflow: data.data.workflow.all,
          admins: data.data.users.admins,
          rushActive,
        };
        return results;
      });
  };

  return {
    queryInit,
  };
};
