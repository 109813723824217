import React from 'react';
import DashboardTemplate from './Templates/DashboardTemplate';

const AccessDeniedPage = () => {
  return (
    <DashboardTemplate>
      <p>Access Denied</p>
    </DashboardTemplate>
  );
};

export default AccessDeniedPage;
