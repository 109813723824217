import { assign, Machine } from 'xstate';
import { useApolloClient } from '@apollo/client';

// IMPORT MACHINE CONFIG
import machineConfig from './MachineConfig';

// IMPORT QUERIES AND MUTATIONS
import queries from './queries';
import mutations from './mutations';

function createServices() {
  // GET APOLLO CLIENT TO SEND TO MUTATIONS
  const client = useApolloClient();

  // // GET QUERIES AND MUTATIONS FROM IMPORT
  const { init } = queries(client);
  const { saveNotes, updateStatusWithNotes } = mutations(client);

  return {
    guards: {},

    services: {
      load: ctx => {
        return init(ctx.copyReqeustId);
      },
      saveNotes: (_, e) => {
        return saveNotes(e);
      },
      updateStatus: (_, e) => {
        return updateStatusWithNotes(e);
      },
    },

    actions: {
      saveLoadToCTX: assign({
        application: (_, { data: { copyRequests } }) => {
          return copyRequests.application;
        },
        createdAt: (_, { data: { copyRequests } }) => {
          return copyRequests.createdAt;
        },
        status: (_, { data: { copyRequests } }) => {
          return copyRequests.status;
        },
        completed: (_, { data: { copyRequests } }) => {
          return copyRequests.completed;
        },
        addresses: (_, { data: { copyRequests } }) => {
          return copyRequests.addresses;
        },
        emails: (_, { data: { copyRequests } }) => {
          const emails = JSON.parse(copyRequests.emails);
          return emails;
        },
        copiesRequested: (_, { data: { copyRequests } }) => {
          const emailCount = JSON.parse(copyRequests.emails).length;
          const addressCount = copyRequests.addresses.length;

          return emailCount + addressCount;
        },
        services: (_, { data: { services } }) => services,
        returnDocs: (
          _,
          {
            data: {
              copyRequests: { returnDocs },
            },
          },
        ) => {
          if (returnDocs === '') return null;
          return JSON.parse(returnDocs);
        },
      }),
    },
  };
}

function create(id) {
  // CREATE ALL SERVICES FROM ABOVE
  const services = createServices();

  // RETURN MACHINE WITH CONFIG AND SERVICES
  return Machine({ ...machineConfig(id) }, { ...services });
}

export default create;
