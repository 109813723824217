import { assign } from 'xstate';
import {
  contextStep1DataToPrismaModel,
  updateStep1toModel,
  prismaModelStep1ToContext,
} from './dataTransform';
import {
  APPLICANT_INFO_CREATE,
  APPLICANT_INFO_UPDATE,
} from '../../../../queries_mutations/mutations';

function createGaurds() {
  return {
    isStep1Complete: ctx => ctx.step1.complete,
  };
}

function createServices(client) {
  return {
    createApplicantInfo: (ctx, e) => {
      const convertedData = contextStep1DataToPrismaModel(e.value);
      return client
        .mutate({
          mutation: APPLICANT_INFO_CREATE,
          variables: {
            applicationId: ctx.applicationID,
            info: convertedData,
          },
        })
        .then((data, error) => {
          if (error) throw new Error();
          const { createApplicantInfo } = data.data.applications.application;
          const applicantInfoId = createApplicantInfo.id;
          const addressId = createApplicantInfo.address.id;
          const { address } = createApplicantInfo;
          return { applicantInfoId, addressId, address };
        });
    },

    updateApplicantInfo: (ctx, e) => {
      const convertedData = updateStep1toModel(ctx.step1.id, e.value);
      // console.log('updating applicantInfo', convertedData);
      return client
        .mutate({
          mutation: APPLICANT_INFO_UPDATE,
          variables: {
            applicationId: ctx.applicationID,
            info: convertedData,
          },
        })
        .then((data, error) => {
          if (error) throw new Error();
          return {
            address:
              data.data.applications.application.updateApplicantInfo.address,
          };
        });
    },
  };
}

function createActions() {
  return {
    updateApplicantId: assign({
      step1: (ctx, e) => {
        return {
          ...ctx.step1,
          id: e.data.applicantInfoId,
        };
      },
    }),
    updateAddressID: assign({
      applicationAddressID: (_, e) => {
        return e.data.addressId;
      },
    }),

    setStep1Data: assign({
      step1: (ctx, event) => {
        // console.log('inside setStep1Data', event);
        return { ...ctx.step1, data: event.value };
      },
    }),

    loadStep1DataIntoContext: assign({
      step1: (_, event) => {
        // console.log('loading step 1 data into context', event.data);
        const step1Data = prismaModelStep1ToContext(event.data?.applicantInfo);
        const isComplete = !!event.data.applicantInfo;
        return {
          id: event.data?.applicantInfo?.id || '',
          complete: isComplete,
          data: step1Data,
        };
      },
    }),

    setStep1Complete: assign({
      step1: ctx => {
        return { ...ctx.step1, complete: true };
      },
    }),

    updateAddressOnFileToContext: assign({
      addressOnFile: (_, e) => {
        const address = { ...e.data.address, __typename: undefined };
        return address;
      },
    }),
  };
}

function create(client) {
  return {
    actions: createActions(client),
    services: createServices(client),
    guards: createGaurds(),
  };
}

export default create;
