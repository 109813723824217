import { Machine, assign } from 'xstate';
import { useApolloClient } from '@apollo/client';

// IMPORT MACHINE CONFIG
import machineConfig from './MachineConfig';

// IMPORT QUERIES AND MUTATIONS
import incompleteApplicationQueries from '../../queries_mutations/IncompleteApplications/queries';
import incompleteApplicationMutations from '../../queries_mutations/IncompleteApplications/mutations';

function createServices() {
  // GET APOLLO CLIENT TO SEND TO MUTATIONS
  const client = useApolloClient();

  // GET QUERIES AND MUTATIONS FROM IMPORT
  const { init, search, sendEmailReminder } =
    incompleteApplicationQueries(client);
  const { deleteApplication, completeApplication } =
    incompleteApplicationMutations(client);

  return {
    guards: {
      // EXAMPLE OF GUARD
      // hasMore: context => {
      //   if (context.cursor) return true;
      //   return false;
      // },
    },

    services: {
      load: ctx => {
        return init(ctx.take);
      },
      deleteItem: (_, e) => {
        return deleteApplication(e.data);
      },
      search: (ctx, e) => {
        return search(e.data, ctx.take);
      },
      sendReminder: (_, e) => {
        return sendEmailReminder(e.userID);
      },
      confirmApplication: (_, e) => {
        return completeApplication(e.applicationID);
      },
    },

    actions: {
      logError: () => {
        return 1;
      },

      loadSuccess: assign({
        allIncompleteApplicationsCount: (_, { data }) => data.allCount,
        pages: (ctx, { data }) => {
          return Math.ceil(data.applications.length / ctx.itemsToShow);
        },
        incompleteApplications: (_, { data }) => {
          return data.applications;
        },
      }),

      searchSuccess: assign({
        incompleteApplications: (ctx, { data }) => {
          // console.log('items are: ', data);
          return data;
        },
        pages: (ctx, { data }) => {
          return Math.ceil(data.length / ctx.itemsToShow);
        },
        currentPage: 1,
      }),

      updateApplicationList: assign({
        incompleteApplications: (ctx, { data }) => {
          const newArray = ctx.incompleteApplications.filter(
            i => i.id !== data,
          );
          return newArray;
        },
      }),
      nextPage: assign({
        currentPage: ctx => {
          return ctx.currentPage + 1;
        },
      }),
      prevPage: assign({
        currentPage: ctx => {
          return ctx.currentPage - 1;
        },
      }),
      goToPage: assign({
        currentPage: (_, e) => {
          return e.page;
        },
      }),
      setDeleteItem: assign({
        itemToDelete: (_, e) => {
          return e.data;
        },
      }),
      setDefaults: assign({
        itemToDelete: '',
      }),
      sort: assign({
        // incompleteApplications: (ctx, e) => {
        //   console.log('sort applications: ', e.sort);
        //   const arrayToSort = [...ctx.incompleteApplications];
        //   switch (e.sort) {
        //     case 'SORT_CREATED_ON':
        //       arrayToSort.sort((a, b) => {
        //         const da = new Date(a.createdAt);
        //         const db = new Date(b.createdAt);
        //         return da - db;
        //       });
        //       console.log('previous array: ', ctx.incompleteApplications);
        //       console.log('created on sorted: ', arrayToSort);
        //       return arrayToSort;

        //     default:
        //       return arrayToSort;
        //   }
        // },
        sortCreated: (ctx, e) => {
          if (e.sort === 'SORT_CREATED_ON') {
            if (ctx.sortCreated) {
              return false;
            }
            return true;
          }
          return null;
        },
        sortFirstName: (ctx, e) => {
          if (e.sort === 'SORT_FIRST_NAME') {
            if (ctx.sortFirstName) {
              return false;
            }
            return true;
          }
          return null;
        },
        sortLastName: (ctx, e) => {
          if (e.sort === 'SORT_LAST_NAME') {
            if (ctx.sortLastName) {
              return false;
            }
            return true;
          }
          return null;
        },
      }),
    },
  };
}

function create() {
  // CREATE ALL SERVICES FROM ABOVE
  const services = createServices();

  // RETURN MACHINE WITH CONFIG AND SERVICES
  return Machine({ ...machineConfig() }, { ...services });
}

export default create;
