import { loadStripe } from '@stripe/stripe-js';
import { stripeConfig } from '../envConfig';

let stripePromise = null;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeConfig.publicKey);
  }
  return stripePromise;
};

export default getStripe;
