import { gql } from '@apollo/client';
// import { applicationFields } from '../sharedVariables';
//
// QUERIES FOR ALL EVALUATIONS
//

const copyRequestFields = `
id
        application {
          refNumber
          applicantInfo {
            firstName
            lastName
            address {
              country
            }
            birthday
          }
        }
        applicationId
        status
        createdAt
        completed
`;

export const COPY_REQUESTS = gql`
  query copyRequestsInit($take: Int) {
    copyRequest {
      getList(take: $take) {
        ${copyRequestFields}
      }
      getCount
    }
  }
`;
export const SEARCH_COPY_REQUESTS = gql`
  query search($searchText: String, $take:Int) {
    copyRequest {
      search(searchText:$searchText, take:$take){
        ${copyRequestFields}
      }
    }
  }
`;

export default client => {
  const init = take => {
    return client
      .query({
        query: COPY_REQUESTS,
        variables: { take },
      })
      .then(({ data }) => {
        const results = {
          totalCopyRequests: data.copyRequest.getCount,
          copyRequests: data.copyRequest.getList,
        };
        return results;
      });
  };
  const search = (searchText, take) => {
    return client
      .query({
        query: SEARCH_COPY_REQUESTS,
        variables: { searchText, take },
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        // console.log('return data: ', data.data.copyRequest.search);
        const results = {
          applications: data.data.copyRequest.search,
        };
        return results;
      });
  };

  return {
    init,
    search,
  };
};
