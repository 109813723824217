function createConfig(id) {
  return {
    id: 'copyRequests',
    initial: 'init',
    context: {
      copyReqeustId: id,
    },

    states: {
      init: {
        invoke: {
          id: 'initialLoad',

          src: 'load',
          onDone: {
            actions: ['saveLoadToCTX'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      saveNotes: {
        invoke: {
          id: 'savNotes',

          src: 'saveNotes',
          onDone: {
            actions: [],
            target: 'saved',
          },
          onError: 'loadingFailed',
        },
      },
      updateStatus: {
        invoke: {
          id: 'updateStatus',

          src: 'updateStatus',
          onDone: {
            actions: [],
            target: 'saved',
          },
          onError: 'loadingFailed',
        },
      },

      saved: {
        after: {
          1000: { target: 'waiting' },
        },
      },
      waiting: {
        on: {
          SAVE_NOTES: 'saveNotes',
          UPDATE_STATUS: 'updateStatus',
        },
      },
      loadingFailed: {},
    },
    on: {},
  };
}

export default createConfig;
