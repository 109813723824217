import { assign } from 'xstate';
import { UPDATE_APPLICATION_SERVICE_TYPE } from '../../../../queries_mutations/mutations';

function createGaurds() {
  return {
    isStep4Complete: ctx => ctx.step4.complete,
  };
}

function createServices(client) {
  return {
    updateServiceStep4: (ctx, e) => {
      // console.log('fn:service:updateServiceStep4', e.value.typeOfReport);
      return client
        .mutate({
          mutation: UPDATE_APPLICATION_SERVICE_TYPE,
          variables: {
            appId: ctx.applicationID,
            serviceId: e.value.typeOfReport,
          },
        })
        .then((data, error) => {
          if (error) {
            // do we throw?
          }
          // console.log('step 4 data returned: ', data);
          return data.data.applications.application.updateApplicationType
            .applicationType.id;
        });
    },
  };
}

function createActions() {
  return {
    setStep4Data: assign({
      step4: (_, e) => {
        // console.log('fn:action:setStep4Data', e);
        return { serviceId: e.data, complete: true };
      },
    }),
    loadStep4DataIntoContext: assign({
      step4: (_, e) => {
        // console.log(
        //   'fn:action:loadStep4DataIntoContext',
        //   e.data.applicationType,
        // );
        const serviceId = e?.data?.applicationType?.id || undefined;
        const complete = !!e.data.applicationType;
        return { serviceId, complete };
      },
    }),
  };
}

function create(client) {
  return {
    actions: createActions(),
    services: createServices(client),
    guards: createGaurds(),
  };
}

export default create;

// YOU WERE TRYING TO FIND OUT WHY YOUR SETSTEP4ACTION WAS NOT FIRING..,..
