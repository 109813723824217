import { gql } from '@apollo/client';

export const DELETE_APPLICATION = gql`
  mutation deleteApplication($id: String!) {
    applications {
      application(id: $id) {
        delete {
          id
        }
      }
    }
  }
`;
export const COMPLETE_APPLICATION = gql`
  mutation completeApplication($id: String!) {
    applications {
      application(id: $id) {
        completeApplication(id: $id)
      }
    }
  }
`;

export default client => {
  const deleteApplication = id => {
    return client
      .mutate({
        mutation: DELETE_APPLICATION,
        variables: { id },
      })
      .then(({ data }) => {
        return data.applications.application.delete.id;
      });
  };
  const completeApplication = id => {
    return client.mutate({
      mutation: COMPLETE_APPLICATION,
      variables: { id },
    });
  };

  return { deleteApplication, completeApplication };
};
