import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';

import { Button, ButtonsCont } from '../../../../../../shared/styles';
import AddressForm from '../../../../AddressForm/AddressForm';
import AdditionalAddressesList from './AdditionalAddressesList';
import Constants from '../../../../../../shared/Constants';
import {
  constructAddress,
  servicesByCountry,
} from '../../../../../../shared/functions';

const AdditionalReports = ({
  toggle,
  register,
  errors,
  formId,
  send,
  additionalAddresses,
  services,
  formState,
  additionalShippingDocsErrors,
  additionalDocsShippingRegister,
  context,
}) => {
  const deleteAddressHandler = id => {
    send('DELETE_ADDITIONAL_REPORT_ADDRESS', { id });
  };
  const filteredServices = servicesByCountry(
    services,
    context.step5.tempAdditionalAddress?.country ||
      Constants.LOCATION.UNITED_STATES,
    true,
  );

  return (
    <>
      {toggle === 'Yes' && (
        <div>
          {additionalAddresses && (
            <div>
              <h2 className="SF-segment-title">Additional Reports Addresses</h2>

              <br />
              <AdditionalAddressesList
                addresses={additionalAddresses}
                deleteAddressFunction={deleteAddressHandler}
                services={services}
              />
              <br />
            </div>
          )}
          {formState.matches('editing.stepFive.idle') && (
            <Button
              type="button"
              onClick={() => {
                send('ADD_ADDITIONAL_ADDRESS');
              }}
            >
              Add Address
            </Button>
          )}
          {formState.matches(
            'editing.stepFive.additionalAddressState.addingAddress',
          ) && (
            <div>
              <AddressForm
                register={register}
                errors={errors}
                company
                yupObjectName="mailAddress"
              />
              <ButtonsCont>
                <Button type="submit" form={formId}>
                  Choose Shipping
                </Button>
                <Button type="button" onClick={() => send('CANCEL_ADDRESS')}>
                  Cancel Address
                </Button>
              </ButtonsCont>
            </div>
          )}
          {formState.matches(
            'editing.stepFive.additionalAddressState.addingShipping',
          ) && (
            <StyledShippingMethod className="styledShippingMethod">
              {context.step5.tempAdditionalAddress && (
                <StyledAddress>
                  <h2 className="SF-segment-title">
                    Additional Address Being Added:
                  </h2>
                  {constructAddress(context.step5.tempAdditionalAddress)}
                </StyledAddress>
              )}
              <div
                role="group"
                aria-labelledby="deliveryOptionApplicationServiceID_group"
                className="__options"
              >
                <p
                  className="input-group--title input-group--bold"
                  id="rushApplicationServiceID_group"
                >
                  What Shipping Method do you prefer?{' '}
                  <span className="required">*</span>
                </p>
                {filteredServices.map(service => {
                  return (
                    <StyledMailOptions key={service.id}>
                      <input
                        id={`deliveryOptionApplicationServiceID_${service.id}`}
                        name="shippingMethod"
                        type="radio"
                        value={service.id}
                        {...additionalDocsShippingRegister(`shippingMethod`)}
                        defaultChecked={filteredServices.length < 2}
                      />
                      <label
                        htmlFor={`deliveryOptionApplicationServiceID_${service.id}`}
                      >
                        {service.displayName}{' '}
                        <strong>
                          <em>{`($${service.price}.00)`}</em>
                        </strong>
                      </label>
                    </StyledMailOptions>
                  );
                })}
                <ErrorMessage
                  errors={additionalShippingDocsErrors}
                  name="shippingMethod"
                  as="span"
                  className="error"
                />
              </div>
              <ButtonsCont>
                <Button type="submit" form="addShippingToAdditionalAddressForm">
                  Save Address
                </Button>
                <Button type="button" onClick={() => send('CANCEL_ADDRESS')}>
                  Cancel Address
                </Button>
              </ButtonsCont>
            </StyledShippingMethod>
          )}
        </div>
      )}
    </>
  );
};

export default AdditionalReports;

const StyledMailOptions = styled.div`
  input {
    margin-right: 10px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`;

const StyledAddress = styled.div`
  .SF-segment-title {
    padding-bottom: 3px;
  }
  margin-bottom: 10px;
`;

const StyledShippingMethod = styled.div`
  ${ButtonsCont} {
    margin-top: 25px;
  }
  .__options {
    margin-top: 30px;
  }
`;
