import React from 'react';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as GearIcon } from '../../assets/gear.svg';
import { ReactComponent as PageIcon } from '../../assets/file-earmark-check-fill.svg';
import { ReactComponent as FolderIcon } from '../../assets/sticky-note.svg';
import { ReactComponent as XIcon } from '../../assets/x-circle-fill.svg';

export const ClientSidebarData = [
  {
    title: 'My Applications',
    path: '/dashboard',
    icon: <FileIcon />,
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: <GearIcon />,
  },
];

export const AdminSidebarData = [
  {
    title: 'Evaluations',
    path: '/dashboard/evaluations',
    icon: <PageIcon />,
    subNav: [
      {
        title: 'All Evaluations',
        path: '/admin/evaluations',
        icon: '',
      },
      {
        title: 'Assigned to Me',
        path: '/admin/my-evaluations',
        icon: '',
      },
    ],
  },
  {
    title: 'Copy Requests',
    path: '/admin/copy-requests',
    icon: <FolderIcon />,
  },
  {
    title: 'Pending Copy Requests',
    path: '/admin/pending-copy-requests',
    icon: <FolderIcon />,
  },
  {
    title: 'Incomplete Applications',
    path: '/admin/incomplete-applications',
    icon: <XIcon />,
  },
  {
    title: 'Manage Settings',
    path: '/dashboard',
    icon: <GearIcon />,
    subNav: [
      // {
      //   title: 'Admin Users',
      //   path: '/settings/adminUsers',
      //   icon: '',
      // },
      // {
      //   title: 'Applicant Users',
      //   path: '/settings/manage-users',
      //   icon: '',
      // },
      // {
      //   title: 'Email Templates',
      //   path: '/settings/email-templates',
      //   icon: '',
      // },
      {
        title: 'Configuration Settings',
        path: '/admin/settings/configure',
        icon: '',
      },
      {
        title: 'Services',
        path: '/admin/settings/services',
        icon: '',
      },
    ],
  },
];
