import React from 'react';
import styled from 'styled-components';

import { Button } from '../../../shared/styles';

const NextPrevStepButtons = ({
  nextStep,
  prevStep,
  submitButton,
  altText,
  firstStep,
  disabledValue,
  titleText,
}) => (
  <FormButtons>
    {!firstStep && (
      <Button className="previous" type="button" onClick={prevStep}>
        Previous Step
      </Button>
    )}
    {submitButton ? (
      <Button
        className="next"
        type="submit"
        disabled={disabledValue}
        title={titleText}
      >
        {altText || 'Next Step'}
      </Button>
    ) : (
      <Button
        className="next"
        type="button"
        disabled={disabledValue}
        onClick={nextStep}
        title={titleText}
      >
        {altText || 'Next Step'}
      </Button>
    )}
  </FormButtons>
);

export default NextPrevStepButtons;

const FormButtons = styled.div`
  display: flex;
  box-sizing: border-box;
`;
