import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import {
  paymentConfig,
  StyledToastMessage,
} from '../../../../shared/notifications/toast';
import Payment from '../../../Payment/Payment';

const ReviewPayment = ({ send, formState }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onPaid = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Your application has been submitted!</h2>
        <p>
          Thank you for submitting your application! Your application will be
          processed.
        </p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('STEP_COMPLETE');
    navigate('/dashboard');
  };

  const onCancelled = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Payment was cancelled.</h2>
        <p>
          You have canceled your payment. Please click "View Payment Summary" to
          complete your application and payment.
        </p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('PREV_STEP');
  };

  return (
    <ReviewStyled>
      <button
        aria-label="Edit Applicant Information"
        className="edit-button"
        type="button"
        onClick={onCancelled}
      >
        Back to application
      </button>

      {formState.matches('editing.reviewPayment.awaitingPayment') && (
        <>
          <Payment
            cart={formState.context.cart}
            onPaid={onPaid}
            onCancelled={onCancelled}
          />
        </>
      )}
    </ReviewStyled>
  );
};

export default ReviewPayment;

const ReviewStyled = styled.div`
  max-width: 1120px;
  margin: 62px auto 112px;
  padding: 0 30px;
  .title {
    font-size: 25px;
    margin-bottom: 36px;
  }
  .edit-button {
    position: absolute;
    top: 40px;
    right: 100px;
    margin-left: auto;
    border: solid 2px #14527f;
    padding: 12px;
    min-width: ${props => (props.long ? '200px' : '100px')};
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: #14527f;
    background-color: transparent;
    transition: all ease 0.3s;
    cursor: pointer;
    &:hover,
    &:focus {
      color: white;
      background-color: #14527f;
    }
  }
`;
