import React, { useEffect } from 'react';
// import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FormCheckbox from '../../../../FormCheckbox/FormCheckbox';
import { returnServiceByType } from '../../../../../../shared/functions';
import Constants from '../../../../../../shared/Constants';
import { ReactComponent as XIcon } from '../../../../../../assets/x-circle-fill.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/trashicon.svg';

export default function AdditionalEmailPDFS({
  register,
  context,
  watch,
  errors,
  control,
}) {
  const {
    fields: emailFields,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    control,
    name: 'emailArray',
    shouldUnregister: false,
  });

  const watchEmailPDF = watch('additionalPDFOption');

  // IF THERE ARE EMAILS ALREADY IN CONTEXT:
  const { additionalPDFEmails, additionalPDFOption } = context.step5;
  const emailService = returnServiceByType(
    context.services,
    Constants.SERVICE.ADDITIONAL_EMAIL,
  );
  useEffect(() => {
    if (additionalPDFEmails) {
      additionalPDFEmails.map(({ email }) => {
        return emailAppend({ email });
      });
    }
    return () => {
      emailRemove();
    };
  }, []);
  useEffect(() => {}, [emailFields]);
  return (
    <>
      <FormCheckbox
        register={register}
        name="additionalPDFOption"
        id="emailPDF"
        number="04."
        title=" Do you need an additional official PDF sent via email?"
        checkboxText={{
          text: 'PDF copy sent via email.',
          boldText: `$${emailService[0].price}.00 per email`,
        }}
        checked={additionalPDFOption || false}
      />
      {watchEmailPDF && (
        <>
          {emailFields.map(({ id, email }, index) => (
            <div key={id} className="input-group additional-address">
              <label htmlFor={`emailArray.${index}.email`}>
                Email <span className="required" />
              </label>

              <input
                type="text"
                name={`emailArray.${index}.email`}
                {...register(`emailArray.${index}.email`)}
                defaultValue={email}
              />
              <ErrorMessage
                errors={errors}
                name={`emailArray.${index}.email`}
                as="span"
                className="error"
              />
            </div>
          ))}
          <button
            className="additional-address--button"
            type="button"
            onClick={() => {
              emailAppend({ email: '' });
            }}
            aria-label="add another email address"
            title="Add another email"
          >
            <XIcon /> Add another email
          </button>
          {emailFields.length > 0 && (
            <button
              className="additional-address--button additional-address__remove"
              type="button"
              onClick={() => {
                emailRemove();
              }}
              aria-label="clear all email addresses"
              title="Clear all emails"
            >
              <TrashIcon /> Clear emails
            </button>
          )}
        </>
      )}
    </>
  );
}
