import { assign, Machine } from 'xstate';
import { useApolloClient } from '@apollo/client';

import dayjs from 'dayjs';
import rootMachineConfig from '../../../Machines/EvaluationReviewMachineConfig';
import evaluationReviewQueries from '../../../queries_mutations/EvaluationReview/queries';
import evaluationReviewMutations from '../../../queries_mutations/EvaluationReview/mutations';
import docMutation from '../../../queries_mutations/Documents/mutations';
import docQueries from '../../../queries_mutations/Documents/queries';
import { readFile, camelCase } from '../../../shared/functions';

function createServices() {
  const client = useApolloClient();
  // EVALUATION QUERIES/MUTATIONS
  const { getApplication, downloadDocument } = evaluationReviewQueries(client);
  const {
    saveOverview,
    saveApplicationDetails,
    saveEvaluationDetails,
    updateAssignedTo,
    signAndCompleteEvaluation,
    saveSchool,
    deleteSchool,
  } = evaluationReviewMutations(client);
  // DOCUMENT MUTATIONS
  const { createDocumentUploadUrl, saveURLToDB, deleteDocument } =
    docMutation(client);
  const { fetchDocuments } = docQueries(client);
  return {
    guards: {
      checkFileTypes: (_, e) => {
        let unsafeToUpload = false;
        const badFileTypes = ['exe', 'pif', 'bat', 'js', 'cmd', 'sh'];
        const { files } = e;
        files.map(f => {
          const badExtBool = badFileTypes.includes(f.name.split('.').pop());
          if (badExtBool) unsafeToUpload = true;
          return badExtBool;
        });
        return unsafeToUpload;
      },
      checkForDuplicateFileNames: (ctx, e) => {
        const { files } = e;
        const { documents } = ctx.application.appDocumentsInfo;
        let duplicateFileNames = false;
        files.map(f => {
          const duplicate = documents.some(
            d => d.name.toLowerCase() === f.name.toLowerCase(),
          );
          if (duplicate) duplicateFileNames = true;
          return duplicate;
        });
        return duplicateFileNames;
      },
    },

    services: {
      load: ctx => {
        return getApplication(ctx.applicationInReview);
      },
      saveOverview: (_, e) => {
        return saveOverview(e.data);
      },
      stateSaveApplicationDetails: (_, e) => {
        return saveApplicationDetails(e.formData);
      },
      saveEvaluationDetails: (_, e) => saveEvaluationDetails(e.formData),
      saveEvaluationDetailsComplete: async (ctx, e) => {
        // when user hits "save and mail" on evaluation
        // save the evaluation details to db
        const details = await saveEvaluationDetails(e.formData);
        // get admin assiged for mail Handling
        const adminForCopyRequests = ctx.workflow.find(
          f => f.name === 'mailAdminHandler',
        );

        // update admin assigned to application
        const assignedTo = await updateAssignedTo(
          ctx.applicationInReview,
          adminForCopyRequests.adminId,
        );
        return { details, assignedTo };
      },
      saveEvaluationDetailsSignAndComplete: async (ctx, e) => {
        const details = await saveEvaluationDetails(e.formData);
        await signAndCompleteEvaluation({
          appID: ctx.applicationInReview,
        });
        return { details };
      },
      downloadDocument: (ctx, e) => {
        return (
          downloadDocument(ctx, e)
            // TAKE THE BLOB AND MAKE A HIDDEN LINK TO DOWNLOAD THE FILE FROM BROWSER MEMORY TO DISK
            .then(fileBlob => {
              const blob = new Blob([fileBlob]);
              const oneTimeUrl = URL.createObjectURL(blob);

              const link = document.createElement('a');
              link.setAttribute('href', oneTimeUrl);
              link.setAttribute('download', e.name);
              link.style.visibility = 'hidden';

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
        );
      },
      uploadDocument: async (ctx, { files }) => {
        const apiCalls = await Promise.all(
          // GO OVER EACH FILE
          files.map(async f => {
            // console.log('file: ', f);
            // GET URL FOR UPLOAD TO BUCKET
            const { data } = await createDocumentUploadUrl({
              variables: {
                fullPathName: `applications/${ctx.applicationInReview}/${f.name}`,
                contentType: f.type,
                applicationId: ctx.applicationInReview,
              },
            });

            // SET RETURN DATA TO URL VARIABLE
            const { createUploadUrl: url } =
              data.applications.me.application.appDocumentsInfo;
            // READ THE FILE UPLOADED AND SET TO BODY
            const body = await readFile(f);

            // UPLOAD FILE TO GCP
            return fetch(url, {
              method: 'PUT',
              headers: {
                'Content-Type': f.type,
              },
              body,
            })
              .then(() => {
                // SAVE URL TO DATABASE TIED TO  DOCUMENTS MODEL
                return saveURLToDB({
                  variables: {
                    document: {
                      url: `applications/${ctx.applicationInReview}/${f.name}`,
                      name: f.name,
                      applicationDocumentsId:
                        ctx.application.appDocumentsInfo.id,
                    },
                    applicationId: ctx.applicationInReview,
                  },
                });
              })
              .then(() => {
                // GET ALL DOCUMENTS TIED TO APPLICATION
                return fetchDocuments({
                  variables: {
                    applicationId: ctx.applicationInReview,
                  },
                });
              });
          }),
        );
        return apiCalls;
      },
      deleteDocument: (ctx, e) => {
        return deleteDocument({
          variables: {
            applicationId: ctx.applicationInReview,
            document: { id: e.fileToBeDeleted.id, url: e.fileToBeDeleted.url },
          },
        }).then(() => {
          return fetchDocuments({
            variables: {
              applicationId: ctx.applicationInReview,
            },
          });
        });
      },
      deleteSchoolFromDB: (ctx, e) => {
        return deleteSchool(e.id, ctx.applicationInReview);
      },
      saveSchoolToDB: (ctx, e) => {
        // CLEANSE FORM DATA TO MATCH INPUT NEEDED FOR GRAPHQL
        const school = {
          country: e.country,
          degree: e.degree,
          degreeDate: e.degreeDateTime === 0 ? null : dayjs(e.degreeDateTime),
          degreeNA: e.degreeNA,
          endDate: dayjs(e.endDateTime),
          school: e.school,
          startDate: dayjs(e.startDateTime),
          study: e.study,
          id: ctx.editDeleteSchool,
          applicationId: ctx.applicationInReview,
        };

        return saveSchool(school, ctx.applicationInReview);
      },
    },

    actions: {
      logError: assign({
        errorMessage: (_, e) => {
          // eslint-disable-next-line
          console.log('from loading failed: ', e);
        },
      }),
      setInitData: assign({
        application: (_, e) => {
          return {
            ...e.data.application,
            applicant: {
              ...e.data.application.applicant,
              firstName:
                camelCase(e.data.application.applicant.firstName) || '',
            },
            applicantInfo: {
              ...e.data.application.applicantInfo,
              firstName:
                camelCase(e.data?.application?.applicantInfo?.firstName) || '',
            },
          };
        },
        services: (_, e) => e.data.services,
        admins: (_, e) => e.data.admins,
        workflow: (_, e) => e.data.workflow,
      }),
      saveOverviewToContext: assign({
        application: (_, e) => {
          return e.data.application;
        },
      }),
      saveDocumentsToContext: assign({
        application: (ctx, e) => {
          return {
            ...ctx.application,
            appDocumentsInfo: {
              ...ctx.application.appDocumentsInfo,
              documents: e.data[0],
            },
          };
        },
      }),
      // CANT USE ABOVE FUNCTION FOR THIS BECAUSE IT GETS APICALLS WHICH IS AN ARRAY AND THIS RETURN DATA IS JUST AN ARRAY
      saveDocumentsToContextAfterDelete: assign({
        application: (ctx, e) => {
          return {
            ...ctx.application,
            appDocumentsInfo: {
              ...ctx.application.appDocumentsInfo,
              documents: e.data,
            },
          };
        },
      }),

      setEditDeleteSchool: assign({
        editDeleteSchool: (_, e) => {
          return e.id;
        },
      }),
      removeEditDeleteSchool: assign({
        editDeleteSchool: () => {
          return undefined;
        },
      }),
      updateSchoolListInContext: assign({
        application: (ctx, e) => {
          return { ...ctx.application, education: e.data };
        },
      }),
    },
  };
}

function create(applicationId) {
  const services = createServices();
  return Machine({ ...rootMachineConfig(applicationId) }, { ...services });
}

export default create;
