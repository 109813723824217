import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormDirections from '../../FormDirections/FormDirections';
import ServicesRadioGroup from '../../ServicesRadioGroup/ServicesRadioGroup';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';
import { StyledForm } from '../../FormComponents.styles';

import schema from './Step4ValidationSchema';

const Step4Form = ({ onSubmit, previousStep, state }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: state,
  });

  return (
    <StyledForm>
      <div className="temp-width">
        <FormDirections title="APPLICATION TYPE & FEES" time />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ServicesRadioGroup
            settings={{
              dataRef: 'typeOfReport',
              sectionTitle: 'Type of report',
            }}
            register={register}
            errors={errors}
            required
            evaluationServices
            serviceToQuery="APPLICATION_SERVICE"
            defaultValue={state}
          />
          <hr />
          <NextPrevStepButtons submitButton prevStep={previousStep} />
        </form>
      </div>
    </StyledForm>
  );
};

export default Step4Form;
