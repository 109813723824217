import React from 'react';

import DashboardTemplate from './Templates/DashboardTemplate';
import CopyRequestForm from '../components/dashboard-pages/CopyRequestForm/CopyRequestForm';

const CopyRequestPage = () => {
  return (
    <DashboardTemplate>
      <CopyRequestForm />
    </DashboardTemplate>
  );
};

export default CopyRequestPage;
