import React from 'react';

const Documents = ({ formState, send }) => {
  const { documentsSentBy, pageCount, fileCount, documents } =
    formState.context.step3.data;

  return (
    <div className="section">
      <div className="section__header">
        <h2>03. Documents</h2>
        <button
          aria-label="Edit Documents"
          className="edit-button"
          type="button"
          onClick={() => send('STEP_THREE')}
        >
          Edit
        </button>
      </div>
      <hr />
      <div className="section__content">
        <div className="section__content-block">
          {documents.length > 0 && (
            <>
              <p>
                <strong>Documents:</strong>
              </p>
              {documents.map(d => (
                <p key={Math.random()}>{d.name}</p>
              ))}
            </>
          )}
          <p>
            <strong>Documents submitted via:</strong> {documentsSentBy}
          </p>
          <div className="section__content-group">
            {pageCount !== 0 && (
              <p>
                <strong>Total number of pages:</strong> {pageCount}
              </p>
            )}
            {fileCount !== 0 && (
              <p>
                <strong>Total number of files:</strong> {fileCount}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Documents;
