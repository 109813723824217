import { gql } from '@apollo/client';
// import { applicationFields } from '../sharedVariables';

export const UPDATE_NOTES = gql`
  mutation updateInternalNotes($notes: String, $appID: String!) {
    applications {
      application(id: $appID) {
        updateInternalNotes(notes: $notes) {
          id
        }
      }
    }
  }
`;

export const UPDATE_STATUS_WITH_NOTES = gql`
  mutation copyRequestUpdateStatus(
    $status: String
    $id: String
    $notes: String
    $appID: String!
  ) {
    copyRequest {
      updateStatus(status: $status, id: $id) {
        id
      }
    }
    applications {
      application(id: $appID) {
        updateInternalNotes(notes: $notes) {
          id
        }
      }
    }
  }
`;

export default client => {
  const saveNotes = incomingData => {
    const { appID, notes } = incomingData;
    return client
      .mutate({
        mutation: UPDATE_NOTES,
        variables: { notes, appID },
      })
      .then(({ data }) => {
        const results = { ...data };
        return results;
      });
  };

  const updateStatusWithNotes = incomingData => {
    const { status, id, notes, appID } = incomingData;
    return client
      .mutate({
        mutation: UPDATE_STATUS_WITH_NOTES,
        variables: { status, id, notes, appID },
      })
      .then(({ data }) => {
        const results = { ...data };
        return results;
      });
  };

  return {
    saveNotes,
    updateStatusWithNotes,
  };
};
