import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import CopyRequestList from '../../components/dashboard-pages/AdminCopyRequest/AdminCopyRequestList/CopyRequestList';

const CopyRequestPage = () => {
  return (
    <DashboardTemplate>
      <CopyRequestList />
    </DashboardTemplate>
  );
};

export default CopyRequestPage;
