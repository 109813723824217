function createConfig() {
  return {
    id: 'emailTemplateMachine',
    initial: 'init',
    context: {},

    states: {
      init: {
        id: 'init',
        invoke: {
          src: 'load',
          onDone: {
            actions: ['loadInitialContext'],
            target: 'waiting',
          },
          onError: {
            target: 'loadingFailed',
          },
        },
      },
      itemUpdate: {
        initial: 'idle',
        states: {
          idle: {
            entry: ['setFormData'],
            on: {
              SAVE_ITEM: {
                target: '#saving',
              },
              SAVE_NEW_ITEM: {
                target: '#savingNew',
              },
            },
          },
          saving: {
            id: 'saving',
            invoke: {
              src: 'saveItem',
              onDone: {
                actions: [],
                target: 'saved',
              },
              onError: {
                target: '#loadingFailed',
              },
            },
          },
          savingNew: {
            id: 'savingNew',
            invoke: {
              src: 'saveItemNew',
              onDone: {
                actions: [],
                target: 'saved',
              },
              onError: {
                target: '#loadingFailed',
              },
            },
          },
          saved: {
            after: {
              2000: {
                target: '#init',
              },
            },
          },
        },
        on: {
          CLOSE_MODAL: {
            target: '#waiting',
          },
        },
      },
      deleteItem: {
        invoke: {
          src: 'deleteItem',
          onDone: {
            actions: ['loadInitialContext'],
            target: 'waiting',
          },
          onError: {
            target: '#loadingFailed',
          },
        },
      },
      waiting: {
        id: 'waiting',
      },
      loadingFailed: {
        id: 'loadingFailed',
      },
    },
    on: {
      EDIT_ITEM: {
        target: 'itemUpdate',
      },
      ADD_ITEM: {
        target: 'itemUpdate',
      },
      DELETE_ITEM: {
        target: 'deleteItem',
      },
    },
  };
}

export default createConfig;
