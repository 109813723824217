import { toast, Bounce } from 'react-toastify';
import styled from 'styled-components';

export const popConfig = {
  icon: false,
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Bounce,
};

export const paymentConfig = {
  icon: false,
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Bounce,
};

export const errorConfig = {
  icon: true,
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Bounce,
};

export const StyledToastMessage = styled.div`
  max-width: 500px;
  h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  p {
    line-height: 2;
  }
`;

function pop(message) {
  toast.info(message, popConfig);
}

export default { pop };
