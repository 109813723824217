import React from 'react';
import styled from 'styled-components';

import { clipboardCopy } from '../../../shared/functions';
import { ReactComponent as StickyNote } from '../../../assets/sticky-note.svg';

export default function CopyAbleText({ text, id }) {
  return (
    <CopyableTextStyled>
      <p id={id}>{text}</p>{' '}
      <div>
        <button
          title="copy to clipboard"
          type="button"
          onClick={() => clipboardCopy(id)}
          aria-label="Copy to clipboard"
        >
          <StickyNote />
        </button>
        <p id={`${id}-copied`} className="__copied">
          Copied to clipboard
        </p>
      </div>
    </CopyableTextStyled>
  );
}

const CopyableTextStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  p {
    margin-bottom: 0 !important;
  }
  div {
    .__copied {
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: -20px;
      transition: opacity 0.3s ease;
      color: red;
      &.active {
        opacity: 1;
      }
    }
    button {
      display: flex;
      justify-content: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0 10px;
      svg {
        transition: all ease 0.3s;
      }
      &:hover {
        svg {
          fill: green;
        }
      }
    }
  }
`;
