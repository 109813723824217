import React from 'react';

import DashboardTemplate from '../Templates/DashboardTemplate';
import ReviewEvaluation from './ReviewEvaluation';

const ApplicationReviewPage = () => {
  return (
    <DashboardTemplate>
      <ReviewEvaluation />
    </DashboardTemplate>
  );
};

export default ApplicationReviewPage;
