import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import Button from '../../../UI/Button';

import { statusOptions } from '../../../../queries_mutations/sharedVariables';
import BlockTable from '../../../UI/RoundedBlock/BlockTable/BlockTable';
import AuditList from './AuditList/AuditList';

function toggleClass() {
  const auditBlock = document.querySelector('.__auditBlock .__content');

  auditBlock.classList.toggle('expanded');
}

const Overview = ({ services, application, admins, send, formState }) => {
  const {
    applicant,
    auditHistory,
    createdAt,
    paid,
    applicationType,
    applicantInfo,
    deliveryInstructions,
    status,
    education,
    refNumber,
    internalNotes,
    id: applicationId,
    evaluator,
    appDocumentsInfo: { documents },
    addedRush,
  } = application;
  const previousApplicationBool =
    applicant?.applications.filter(app => app.paid) || false;
  const onSaveHandler = appID => {
    const evalStatus = document?.getElementById('evaluation-status').value;
    const assignedTo = document?.getElementById('evaluator-assigned').value;
    const evalNotes = document?.getElementById('internal-notes').value;

    send('SAVE_OPTIONS', {
      data: {
        status: evalStatus,
        evaluator: assignedTo,
        notes: evalNotes,
        appID,
      },
    });
  };

  return (
    <>
      <Header>
        <p>Evaluation review details and options</p>
        <div className="__buttons">
          <Button
            onClick={() => send('APPLICATION_DETAILS')}
            text="View Application Details"
          />
          <Button
            onClick={() => send('EVALUATION_DETAILS')}
            text="View Evaluation Details"
          />
        </div>
      </Header>
      <Segment>
        <RoundedBlock title="Account details">
          <div className="__container">
            <p>
              <strong>User's Name: </strong>
              {applicant?.firstName} {applicant?.lastName.toUpperCase()}
            </p>
          </div>
        </RoundedBlock>
        <RoundedBlock title="Applicant details">
          <ApplicantDetails>
            <div className="__container">
              <p>
                <strong>Name: </strong>
                {applicantInfo?.firstName}{' '}
                {applicantInfo?.lastName.toUpperCase()}
              </p>
              <p>
                <strong>Date/Time: </strong>
                {dayjs(createdAt).format('M/DD/YYYY hh:mm:ss a')}
              </p>
              {addedRush && (
                <p>
                  <strong>Added rush: </strong>
                  {dayjs(addedRush).format('M/DD/YYYY hh:mm:ss a')}
                </p>
              )}
              <p>
                <strong>Processing method: </strong>
                {paid ? 'Received' : 'Waiting'}
              </p>
              <p>
                <strong>Processing method: </strong>
                {deliveryInstructions?.rushApplicationServiceID
                  ? services?.find(
                      service =>
                        service?.id ===
                        deliveryInstructions?.rushApplicationServiceID,
                    )?.displayName
                  : 'No rush service'}
              </p>
              <p>
                <strong>Order options: </strong>
                {applicationType?.id
                  ? services?.find(service => service.id === applicationType.id)
                      .name
                  : '--'}
              </p>
            </div>
            <div className="__container __actions">
              <div>
                <p>
                  <strong>Status</strong>
                </p>
                <select
                  name="status"
                  id="evaluation-status"
                  defaultValue={status}
                >
                  {statusOptions?.map(({ id: optionId, option }) => (
                    <option key={optionId} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p>
                  <strong>Assigned To</strong>
                </p>
                <select
                  name="assigned"
                  id="evaluator-assigned"
                  defaultValue={evaluator.id}
                >
                  {admins?.map(({ id: optionId, firstName, lastName }) => (
                    <option key={optionId} value={optionId}>
                      {firstName} {lastName.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </ApplicantDetails>
        </RoundedBlock>
      </Segment>
      <InternalNotes>
        <p>
          <strong>Internal Notes</strong>
        </p>
        <textarea
          name="notes"
          id="internal-notes"
          cols="30"
          rows="10"
          defaultValue={internalNotes || 'Please enter a note'}
        />
      </InternalNotes>
      <Button
        blue
        text="Save me"
        onClick={() => onSaveHandler(applicationId)}
      />
      <Segment>
        <RoundedBlock title="Evaluation details">
          <EvaluationDetails>
            <p>
              <strong>Number of foreign academic credentials entered: </strong>
              {education?.length || '--'}
            </p>
            <p>
              <strong>Number of uploaded documents: </strong>
              {documents?.length || '--'}
            </p>
            <p>
              <strong>GCE evaluation reference #: </strong>
              {refNumber || 'not assigned yet'}
            </p>
          </EvaluationDetails>
        </RoundedBlock>
      </Segment>

      <Segment half>
        <AllApplications className="testHere">
          <RoundedBlock title="All applications">
            {previousApplicationBool ? (
              <BlockTable headers={['REFERENCE #', 'DATE', 'ASSIGNED TO']}>
                {applicant?.applications.map(
                  ({
                    paid: prevPaid,
                    refNumber: prevRef,
                    paidDate,
                    evaluator: appEvaluator,
                    id,
                  }) => {
                    if (prevPaid) {
                      return (
                        <tr key={id}>
                          <td>
                            {prevRef === refNumber &&
                              prevRef != null &&
                              'Viewing '}
                            {prevRef || '--'}
                          </td>
                          <td className="__center">
                            {dayjs(paidDate).format('MM/DD/YYYY') || '--'}
                          </td>
                          <td>
                            {appEvaluator.firstName}{' '}
                            {appEvaluator.lastName.toUpperCase()}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  },
                )}
              </BlockTable>
            ) : (
              <p>no other applications</p>
            )}
          </RoundedBlock>
        </AllApplications>
      </Segment>

      <Segment className="__auditBlock">
        <RoundedBlock
          title="Audit Trail"
          addButton={{ text: 'Expand', click: toggleClass }}
        >
          {auditHistory ? (
            <BlockTable headers={['Admin', 'Changes']} audit>
              <AuditList auditHistory={auditHistory} />
            </BlockTable>
          ) : (
            <p>no audit history at this time</p>
          )}
        </RoundedBlock>
      </Segment>

      {formState.matches('overview.saving') && <p>Saving....</p>}
    </>
  );
};

export default Overview;

const Segment = styled.div`
  margin: 30px 0;
  width: ${props => (props.half ? '50%' : '100%')};
  &.__auditBlock {
    tr {
      border-bottom: solid #d4d4d4 1px;
    }
    .__content {
      display: grid;
      grid-template-rows: 1fr;
      overflow-y: scroll;
      max-height: 193px;
      &.expanded {
        max-height: 1000px;
      }
    }
  }
`;

const Header = styled.div`
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .__buttons {
    display: flex;
    button {
      margin-right: 10px;
    }
  }
`;

const ApplicantDetails = styled.div`
  display: flex;
  .__container {
    width: 50%;
  }
  .__actions {
    display: flex;
    div {
      p {
        margin-bottom: 5px;
      }
      margin-right: 10px;
    }
    select {
      padding: 10px;
      background-color: #edf2f7;
      font-size: 18px;
      cursor: pointer;
    }
  }
`;
const EvaluationDetails = styled.div`
  .__container {
  }
`;

const InternalNotes = styled.div`
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
  }
  textarea {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    font-size: 16px;
  }
`;
const AllApplications = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;
