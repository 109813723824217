import React from 'react';
import { useMachine } from '@xstate/react';

import { debug } from '../../../../envConfig';

import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../../../UI/RoundedBlock/BlockTable/BlockTable';
import copyRequestMachine from './MachineSettings';
// import { copyRequestStatusOptions } from '../../../../queries_mutations/sharedVariables';
import Pagination from '../../../UI/Pagination/Pagination';
import {
  sortByDate,
  sortByName,
  camelCase,
} from '../../../../shared/functions';

const IncompleteApplicationList = () => {
  const [formState, send] = useMachine(copyRequestMachine(), {
    devTools: debug.xstate,
  });
  if (formState.matches('init')) {
    return <p>Loading</p>;
  }
  const tableHeaders = [
    {
      title: 'Application ID',
    },
    {
      title: 'NAME',
    },
    {
      title: 'ACTIONS',
    },
  ];

  const {
    copyRequests,
    allCopyRequestsCount,
    pages,
    currentPage,
    itemsToShow,
    sortRef,
    sortDatePaid,
    sortName,
    sortStatus,
  } = formState.context;
  let copyRequestsToShow = [];

  if (copyRequests) {
    // SORTING
    let sortedCopyRequests = [...copyRequests];
    if (sortRef !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByName(a.application.refNumber, b.application.refNumber, sortRef),
      );
    }
    if (sortDatePaid !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByDate(a, b, sortDatePaid, 'createdAt'),
      );
    }
    if (sortName !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByName(
          a.application.applicantInfo.lastName,
          b.application.applicantInfo.lastName,
          sortName,
        ),
      );
    }
    if (sortStatus !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByName(b.status, a.status, sortStatus),
      );
    }
    // WHICH APPLICATIONS TO SHOW ON THE PAGE
    switch (true) {
      case currentPage !== 1:
        copyRequestsToShow = sortedCopyRequests.slice(
          (currentPage - 1) * itemsToShow,
          currentPage * itemsToShow,
        );
        break;

      default:
        copyRequestsToShow = sortedCopyRequests.slice(0, itemsToShow);
        break;
    }
  }
  return (
    <>
      <RoundedBlock title="Pending Copy Requests">
        {copyRequests?.length > 0 ? (
          <BlockTable
            headers={tableHeaders}
            sortable
            send={send}
            xStateContext={formState.context}
          >
            {copyRequests?.length > 0 &&
              copyRequestsToShow?.map(
                ({
                  id,
                  application: {
                    applicantInfo: { firstName, lastName },
                  },
                }) => {
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        {camelCase(firstName)} {lastName.toUpperCase()}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => send('APPROVE_COPY_REQUEST', { id })}
                        >
                          Approve
                        </button>
                      </td>
                    </tr>
                  );
                },
              )}
          </BlockTable>
        ) : (
          <p>There are no copy requests at this time</p>
        )}
      </RoundedBlock>
      <Pagination
        pages={pages}
        itemsToShow={itemsToShow}
        currentPage={currentPage}
        itemsArray={copyRequests}
        allItemsCount={allCopyRequestsCount}
        send={send}
      />
    </>
  );
};

export default IncompleteApplicationList;
