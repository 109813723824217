import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';

import { ProfileContext } from '../components/auth/ValidateProfileAddContext';

import DashboardTemplate from './Templates/DashboardTemplate';
import {
  Button,
  FormFields,
  ProfileFormContainer,
  SectionTitle,
} from '../shared/styles';
import TextInput from '../components/UI/FormInputs/TextInput/TextInput';

const CREATE_USER = gql`
  mutation createUser($userInput: UserUpdateInput!) {
    users {
      me {
        updateInfo(user: $userInput) {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const schema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name.'),
  lastName: yup.string().required('Please enter your last name.'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
});

const UserSettingsPage = () => {
  const userContext = useContext(ProfileContext);

  const formDefaults = {
    firstName: userContext?.firstName,
    lastName: userContext?.lastName,
    email: userContext?.email,
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...formDefaults,
    },
  });

  const [saveProfile, { data, loading, error }] = useMutation(CREATE_USER);

  const submitHandler = formData => {
    saveProfile({
      variables: { userInput: { ...formData } },
    });
  };

  return (
    <DashboardTemplate>
      <SectionTitle className="SectionTitle">Account Settings</SectionTitle>
      <ProfileFormContainer>
        <FormFields
          className="FormFields"
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className="group">
            <TextInput
              type="text"
              title="First Name:"
              dataRef="firstName"
              register={register}
              errors={errors}
            />
            <TextInput
              type="text"
              title="Last Name:"
              dataRef="lastName"
              register={register}
              errors={errors}
            />
          </div>
          <TextInput
            type="text"
            dataRef="email"
            title="Email:"
            register={register}
            errors={errors}
          />
          <Button disabled={!isDirty} type="submit">
            Save Changes
          </Button>
        </FormFields>
        {loading && <p>Submitting...</p>}
        {error && <p>Something went wrong try again</p>}
        {data && <p>Saved</p>}
      </ProfileFormContainer>
    </DashboardTemplate>
  );
};

export default UserSettingsPage;
