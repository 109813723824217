import React from 'react';
import styled from 'styled-components';

import Button from '../../../UI/Button';
import { constructSchool } from '../../../../shared/functions';

export default function DeleteSchool({ id, send, education }) {
  let school = null;
  if (id) {
    school = education.find(obj => {
      return obj.id === id;
    });
  }
  return (
    <StyledDeleteSchool>
      <h2>Are you sure you want to delete education history:</h2>
      <div className="school">{constructSchool(school)}</div>
      <Button
        text="Confirm Delete"
        type="button"
        onClick={() => send('CONFIRM_DELETE_SCHOOL', { id })}
      />
    </StyledDeleteSchool>
  );
}

const StyledDeleteSchool = styled.div`
  h2 {
    margin-bottom: 10px;
  }
  button {
    margin-top: 10px;
  }
  .school {
    p {
      margin-bottom: 5px;
    }
  }
`;
