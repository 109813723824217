import { gql } from '@apollo/client';
// import { applicationFields } from '../../../queries_mutations/sharedVariables';

export const GET_APPLICATION = gql`
  query getApplication($serviceType: String) {
    services {
      byType(service: $serviceType) {
        id
        name
        displayName
        description
        price
      }
      emailService: byType(service: "ADDITIONAL_EMAIL") {
        id
        name
        displayName
        description
        price
      }
      returnDocsService: byType(service: "RETURNING_DOCUMENTS") {
        id
        name
        displayName
        description
        price
      }
    }
  }
`;

export default client => {
  const getServices = id => {
    return client
      .query({
        query: GET_APPLICATION,
        variables: { id, serviceType: 'ADDITIONAL_REPORT_SHIPPING' },
      })
      .then((data, error) => {
        if (error) {
          // do we throw?
        }
        return {
          services: data.data.services.byType,
          emailService: data.data.services.emailService[0],
          returnDocsServices: data.data.services.returnDocsService,
        };
      });
  };

  return {
    getServices,
  };
};
