import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import EvaluationsList from '../../components/dashboard-pages/EvaluationsList/EvaluationsList';
import Key from '../../components/UI/Key/Key';

const AdminEvaluationsListMy = () => {
  return (
    <DashboardTemplate>
      <EvaluationsList showMy />
      <Key rush addedRush />
    </DashboardTemplate>
  );
};

export default AdminEvaluationsListMy;
