import React from 'react';
// import Button from '../../../../UI/Button';

const ApplicantInformation = ({ formState, send }) => {
  const {
    PrevEvaluation,
    gender,
    name,
    foreignName,
    address,
    phoneNumber,
    emailAddress,
    birthday,
    birthCountry,
  } = formState.context.step1.data;
  return (
    <div className="section">
      <div className="section__header">
        <h2>01. Applicant information</h2>
        <button
          aria-label="Edit Applicant Information"
          className="edit-button"
          type="button"
          onClick={() => send('STEP_ONE')}
        >
          Edit
        </button>
      </div>
      <hr />
      <div className="section__content">
        <div className="section__content-block">
          <p>
            <strong>Previous evaluation:</strong> {PrevEvaluation?.option}
          </p>
          {PrevEvaluation.option.toLowerCase() === 'yes' && (
            <div className="section__content-group">
              <p>
                <strong>Previous Evaluation Number:</strong>{' '}
                {PrevEvaluation.number}
              </p>
              <p>
                <strong>Previous Evaluation Date:</strong>{' '}
                {PrevEvaluation?.date}
              </p>
            </div>
          )}
          <div className="section__content-group">
            <p>
              <strong>Gender:</strong> {gender}
            </p>
            <p>
              <strong>Name:</strong> {`${name.first} ${name.last}`}
            </p>
          </div>
          <p>
            <strong>Name on foreign educational documents:</strong>{' '}
            {foreignName.option === 'Same'
              ? `${name.first} ${name.last}`
              : `${foreignName.first} ${foreignName.last}`}
          </p>
        </div>
        <hr />
        <div className="section__content-block section__content-group">
          <p>
            <strong>Personal address 1:</strong> {address?.line1}
          </p>
          {address?.line2 && (
            <p>
              <strong>Address Line 2:</strong> {address?.line2}
            </p>
          )}
          <p>
            <strong>City:</strong> {address?.city}
          </p>
          {address?.state && (
            <p>
              <strong>State/Provence:</strong> {address?.state}
            </p>
          )}
          <p>
            <strong>Country:</strong> {address?.country}
          </p>
          <p>
            <strong>Zip/Postal code:</strong> {address?.postalCode}
          </p>
        </div>
        <hr />
        <div className="section__content-block">
          <div className="section__content-group">
            <p>
              <strong>Phone number:</strong> {phoneNumber}
            </p>
            <p>
              <strong>Email:</strong> {emailAddress}
            </p>
          </div>
          <div className="section__content-group">
            <p>
              <strong>Date of birth:</strong>{' '}
              {`${birthday?.month} ${birthday?.day}, ${birthday?.year}`}
            </p>
            <p>
              <strong>Birth Country:</strong> {birthCountry}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantInformation;
