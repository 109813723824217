import { gql } from '@apollo/client';

import { applicationFields } from '../sharedVariables';

//
// QUERIES FOR ALL EVALUATIONS
//

export const QUERY_GET_APPLICATION = gql`
  query getApplication($id: String!) {
    applications {
      application(id:$id){
        ${applicationFields}
      }
    }
    services {
      all {
        price
        id
        name
        description
        displayName
        serviceType
      }
    }
    users{
      admins{
        firstName
        lastName
        id
      }
    }
    workflow {
    all {
      id
      name
      adminId
    }
  }
  }
`;

export const GET_URL = gql`
  query Query($applicationId: String!, $docId: String) {
    applications {
      me {
        application(id: $applicationId) {
          appDocumentsInfo {
            getOneTimeDownload(docId: $docId)
          }
        }
      }
    }
  }
`;

export default client => {
  const getApplication = applicationId => {
    const variables = { id: applicationId };
    return client
      .query({
        query: QUERY_GET_APPLICATION,
        variables,
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error();
        }
        const results = {
          application: data.applications.application,
          services: data.services.all,
          admins: data.users.admins,
          workflow: data.workflow.all,
        };
        return results;
      });
  };
  const downloadDocument = (ctx, { id }) => {
    // GET ONE TIME URL FROM API
    return (
      client
        .query({
          query: GET_URL,
          variables: { applicationId: ctx.application.id, docId: id },
        })
        // STRIP DOWN RESULT TO JUST THE URL
        .then((data, error) => {
          if (error) {
            // do we throw?
          }
          const { getOneTimeDownload } =
            data.data.applications.me.application.appDocumentsInfo;
          // todo handle null....
          return getOneTimeDownload;
        })
        // WE MANUALLY FETCH THE URL
        .then(url => {
          return fetch(url, {
            method: 'GET',
            // headers: {
            //   'Content-Type': 'application/pdf',
            // },
          });
        })
        // WE JUST RETURN THE BLOB FROM THE REQUEST
        .then(r => r.blob())
    );
  };

  return {
    getApplication,
    downloadDocument,
  };
};
