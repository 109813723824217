import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParse from 'dayjs/plugin/customParseFormat';
import { camelCase } from '../../../../shared/functions';

dayjs.extend(utc);
dayjs.extend(customParse);
export const contextStep1DataToPrismaModel = data => {
  // console.log('contextStep1DataToPrismaModel', data);

  const prevEvalOption = data.PrevEvaluation.option === 'Yes';
  const birthday = dayjs(
    `${data.birthday.month} ${data.birthday.day}, ${data.birthday.year}`,
  )
    .utc(true)
    .format();
  const prevEvalDate = data.PrevEvaluation.date
    ? dayjs(data.PrevEvaluation.date).format()
    : null;
  const applicantInfo = {
    prevEvaluation: prevEvalOption,
    prevEvaluationRefNumber: data.PrevEvaluation.number,
    prevEvaluationDate: prevEvalDate,
    gender: data.gender,
    firstName: camelCase(data.name.first),
    middleName: data.name.middle,
    lastName: data.name.last.toUpperCase(),
    differentFirstName: data.foreignName.first,
    differentMiddleName: data.foreignName.middle,
    differentLastName: data.foreignName.last,
    phoneNumber: data.phoneNumber,
    email: data.emailAddress,
    birthday,
    birthCountry: data.birthCountry,
    address: {
      ...data.address,
      __typename: undefined,
    },
  };
  return applicantInfo;
};
export const updateStep1toModel = (id, data) => {
  const convertedData = contextStep1DataToPrismaModel(data);
  return { ...convertedData, id };
};
export const prismaModelStep1ToContext = data => {
  // console.log('prismaModelStep1ToContext:functionCall:', data);
  // PREV EVALUATION OPTION SETTER
  const prevEvaluationOption = data?.prevEvaluation ? 'Yes' : 'No';

  // FOREIGN NAME OPTION SETTER
  let foreignNameOption = 'Same';
  if (
    data?.differentFirstName ||
    data?.differentMiddleName ||
    data?.differentLastName
  ) {
    foreignNameOption = 'Different';
  }
  // BIRTHDAY TRANSFORM
  const dataBirthday = data?.birthday.slice(0, -2) || '';
  const birthday = data
    ? {
        month: dayjs(dataBirthday).utc(true).format('MMM'),
        day: dayjs(dataBirthday).utc(true).format('D'),
        year: dayjs(dataBirthday).utc(true).format('YYYY'),
      }
    : { month: '', day: '', year: '' };

  // PREV EVALUATION DATE TRANSFORM
  const prevEvaluationDate = data?.prevEvaluationDate
    ? dayjs(data?.prevEvaluationDate).format('MM/DD/YYYY')
    : '';

  return {
    PrevEvaluation: {
      option: prevEvaluationOption,
      number: data?.prevEvaluationRefNumber,
      date: prevEvaluationDate,
    },
    gender: data?.gender,
    name: {
      first: data?.firstName,
      middle: data?.middleName,
      last: data?.lastName,
    },
    foreignName: {
      option: foreignNameOption,
      first: data?.differentFirstName,
      middle: data?.differentMiddleName,
      last: data?.differentLastName,
    },
    address: {
      ...data?.address,
    },
    phoneNumber: data?.phoneNumber,
    emailAddress: data?.email,
    birthday: { ...birthday },
    birthCountry: data?.birthCountry,
  };
};
