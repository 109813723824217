import React from 'react';
import styled from 'styled-components';
import { ReactComponent as MagnifyerIcon } from '../../assets/zoom-out.svg';

function Button({
  text,
  onClick,
  blue,
  type,
  disabled,
  searchIcon,
  id,
  title,
  classes,
}) {
  return (
    <ButtonCont
      id={id}
      disabled={disabled}
      name={text}
      blue={blue}
      onClick={onClick}
      type={type || 'button'}
      title={title}
      className={classes}
    >
      {searchIcon && <MagnifyerIcon />}
      {text}
    </ButtonCont>
  );
}

export default Button;
const ButtonCont = styled.button`
  border: solid 2px #14527f;
  padding: 12px;
  min-width: ${props => (props.long ? '200px' : '150px')};
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  color: ${props => (props.blue ? 'white' : '#14527f')};
  transition: all ease 0.3s;
  cursor: pointer;
  background: ${props => (props.blue ? '#14527f' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${props => (props.blue ? 'white' : '#14527f')};
    margin-right: 3px;
    transition: all ease 0.3s;
  }
  @media print {
    display: none;
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    background-color: ${props => (props.blue ? '#033659' : '#14527f')};
    svg {
      fill: white;
    }
  }
`;
