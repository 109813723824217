import styled from 'styled-components';

const Address = styled.div`
  .__header {
    span {
      color: red;
    }
  }
  .__address {
    padding: 10px;
  }
  button {
    margin-top: 10px;
  }
  hr {
    margin: 30px 0;
  }
`;

export default Address;
