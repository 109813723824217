import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useMutation, gql } from '@apollo/client';
import * as Sentry from '@sentry/react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import getStripe from '../../shared/stripe';
import InvoiceSummary from './InvoiceSummary';
import PaymentForm from './PaymentForm';

const CREATE_AND_FINALIZE = gql`
  mutation createAndFinalizeMutation($applicationId: String!) {
    applications {
      application(id: $applicationId) {
        invoices {
          createAndFinalize {
            id
            finalized
          }
        }
      }
    }
  }
`;

export default function CollectPayment({ cart, onPaid, onCancelled }) {
  const [clientSecret, setClientSecret] = useState('');
  const [finalizedInvoice, setFinalizedInvoice] = useState(undefined);
  const [error, setError] = useState(null);

  const stripe = getStripe();

  const [createAndFinalize, { loading, data }] =
    useMutation(CREATE_AND_FINALIZE);

  useEffect(() => {
    const finalize = async () => {
      try {
        const results = await createAndFinalize({
          variables: {
            applicationId: cart.applicationId,
          },
        });

        const finalizedInvoiceResults =
          results.data.applications.application.invoices.createAndFinalize;

        setFinalizedInvoice(finalizedInvoiceResults.finalized);
        setClientSecret(
          finalizedInvoiceResults.finalized.payment_intent.client_secret,
        );
      } catch (err) {
        Sentry.withScope(scope => {
          scope.setTag('function', 'createAndFinalize');
          scope.setTag('applicationId', cart.applicationId);
          Sentry.captureException(err);
        });

        setError(err);
      }
    };

    finalize();
  }, []);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  if (error) {
    throw error;
  }

  if (loading)
    return (
      <>
        <h2 className="title">Loading Payment Summary</h2>
        <Skeleton height={600} baseColor="#F7FAFC" />
      </>
    );

  if (data)
    return (
      <>
        {clientSecret && (
          <>
            <h2 className="title">Payment Summary</h2>
            <InvoiceSummary finalizedInvoice={finalizedInvoice} cart={cart} />
            <Elements options={options} stripe={stripe}>
              <PaymentForm
                finalizedInvoice={finalizedInvoice}
                onPaid={onPaid}
                onCancelled={onCancelled}
              />
            </Elements>
          </>
        )}
      </>
    );
}
