import { gql } from '@apollo/client';
import gqlc from '../../../shared/gqlc';

export const GET_APPLICATION_AND_SERVICES = gql`
  query getApplication($appId: String!) {
    applications {
      me {
        application(id: $appId) {
          deliveryInstructions {
            id
            emailAddressToReturn
            mailOption
            mailAddress {
              id
              line1
              line2
              city
              state
              postalCode
              country
            }
          }
        }
      }
    }
    services {
      all {
        id
        name
        displayName
        description
        price
        serviceType
      }
    }
  }
`;

const CREATE_CART = gql`
  query CREATE_CART($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          cart {
            rush(serviceId: $serviceId) {
              applicationId
              total
              billTo {
                firstName
                lastName
                company
                phoneNumber
                address {
                  line1
                  city
                  state
                  postalCode
                  country
                }
              }
              lineItems {
                name
                id
                displayName
                description
                price
                serviceType
                quantity
              }
            }
          }
        }
      }
    }
  }
`;

export default client => {
  const { query } = gqlc(client);

  function getServices(id) {
    return query(GET_APPLICATION_AND_SERVICES, { appId: id }).then(data => {
      return {
        services: data.services.all,
        address:
          data.applications.me.application.deliveryInstructions.mailAddress,
      };
    });
  }

  function createCart({ applicationId, serviceId }) {
    return query(CREATE_CART, {
      applicationId,
      serviceId,
    }).then(data => {
      return data.applications.me.application.cart.rush;
    });
  }

  return {
    getServices,
    createCart,
  };
};
