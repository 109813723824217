import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import CopyRequestReview from '../../components/dashboard-pages/AdminCopyRequest/AdminCopyRequestReview/AdminCopyRequestReview';

const CopyRequestReviewPage = () => {
  return (
    <DashboardTemplate>
      <CopyRequestReview />
    </DashboardTemplate>
  );
};

export default CopyRequestReviewPage;
