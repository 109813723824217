import React from 'react';
import DashboardTemplate from '../Templates/DashboardTemplate';
import EmailTemplates from '../../components/dashboard-pages/EmailTemplates/EmailTemplates';

const AdminEmailTemplatesPage = () => {
  return (
    <DashboardTemplate>
      <EmailTemplates />
    </DashboardTemplate>
  );
};

export default AdminEmailTemplatesPage;
