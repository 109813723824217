import React from 'react';
import styled from 'styled-components';

const InfoPage = styled.div`
  padding: 10px 120px;
  max-width: 430px;
  box-sizing: content-box;

  @media only screen and (max-width: 1000px) {
    padding-inline: 0;
  }
  h2 {
    font-size: 25px;
    margin-bottom: 40px;
    font-weight: 700;
  }
  .question {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 1.8;
    a {
      &::after {
        content: '';
        height: 2px;
        background-color: #14527f;
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0;
        transition: all ease 0.2s;
      }
      &:focus,
      &:hover,
      &:active {
        &::after {
          background-color: #68b6ec;
        }
      }
    }
  }
  .contact {
    position: relative;
    display: flex;
    padding-top: 15px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    &__segment {
      margin-right: 90px;

      @media only screen and (max-width: 600px) {
        margin: 0;
        margin-bottom: 30px;
      }
      p {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      a {
        font-size: 18px;
      }
    }
  }
`;
const ContactPage = () => (
  <InfoPage className="infoPage">
    <h2>Contact Us!</h2>
    <p className="question">
      Have a question? Check out our{' '}
      <a href="https://www.gceus.com/faq/" target="_blank" rel="noreferrer">
        FAQ
      </a>{' '}
      or call our friendly staff.
    </p>
    <div className="contact">
      <div className="contact__segment">
        <p>PHONE</p>
        <a href="tel:18007070979">800.707.0979</a>
      </div>
      <div className="contact__segment">
        <p>INTERNATIONAL</p>
        <a href="tel:3014214581">301.421.4581</a>
      </div>
    </div>
  </InfoPage>
);

export default ContactPage;
