function createConfig() {
  return {
    id: 'incompleteApplications',
    initial: 'init',
    context: {
      items: [],
      cursor: null,
      previousCursor: null,
      take: 20,
      currentPage: 1,
      pages: null,
      itemsToShow: 10,
      sortCreated: true,
      sortFirstName: null,
      sortLastName: null,
    },

    states: {
      init: {
        invoke: {
          id: 'initialLoad',

          src: 'load',
          onDone: {
            actions: ['loadSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      sendReminder: {
        invoke: {
          id: 'sendReminder',
          src: 'sendReminder',
          onDone: {
            actions: [],
            target: 'emailConfirmed',
          },
          onError: 'emailError',
        },
      },
      deleteItem: {
        invoke: {
          id: 'deleteItem',

          src: 'deleteItem',
          onDone: {
            actions: ['updateApplicationList', 'setDefaults'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      confirmDelete: {
        on: {
          DELETE_ITEM: 'deleteItem',
          CLOSE_MODAL: 'waiting',
        },
      },
      search: {
        invoke: {
          id: 'searching',
          src: 'search',
          onDone: {
            actions: ['searchSuccess'],
            target: 'waiting',
          },
          onError: 'loadingFailed',
        },
      },
      waiting: {
        on: {
          NEXT_PAGE: { actions: 'nextPage' },
          PREV_PAGE: { actions: 'prevPage' },
          GO_TO_PAGE: { actions: 'goToPage' },
          DELETE_ITEM: { target: 'confirmDelete', actions: 'setDeleteItem' },
          SORT: { actions: ['sort'] },
          SEARCH: 'search',
          INIT: 'init',
          SEND_REMINDER: 'sendReminder',
          CONFIRM_APPLICATION: 'confirmApplication',
        },
      },
      loadingFailed: {},
      emailConfirmed: {
        after: {
          // after 3 seconds, transition to waiting
          3000: { target: 'waiting' },
        },
      },
      emailError: {
        after: {
          // after 3 seconds, transition to waiting
          3000: { target: 'waiting' },
        },
      },
      confirmApplication: {
        invoke: {
          id: 'searching',
          src: 'confirmApplication',
          onDone: {
            target: 'init',
          },
          onError: 'loadingFailed',
        },
      },
    },
    on: {},
  };
}

export default createConfig;
