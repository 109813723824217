import { gql } from '@apollo/client';
import { applicationFields } from '../../../queries_mutations/sharedVariables';

export const GET_APPLICATION = gql`
  query getApplication($id: String!) {
    applications {
      application(id:$id){
        ${applicationFields}
      }
    }
    services{
      all{
        id
        name
        displayName
        description
        price
      }
    }
  }`;

export default client => {
  const getApplication = id => {
    return client
      .query({
        query: GET_APPLICATION,
        variables: { id },
      })
      .then((data, error) => {
        if (error) {
          // do we throw?
        }
        return {
          application: data.data.applications.application,
          services: data.data.services.all,
        };
      });
  };

  return {
    getApplication,
  };
};
