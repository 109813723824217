import React from 'react';

const SelectInput = ({
  errors,
  dataRef,
  title,
  required,
  register,
  defaultValue,
  data,
}) => {
  return (
    <>
      <div className={`input-group ${errors.dataRef ? 'error' : ''}`}>
        <label htmlFor={dataRef}>
          {title}
          <span className="required">{required && '*'}</span>
        </label>
        <select
          name={`${dataRef}`}
          {...register(dataRef)}
          defaultValue={defaultValue}
        >
          {data.map(({ id, name }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default SelectInput;
