import styled from 'styled-components';

const DeleteServiceContent = styled.div`
  p {
    margin-bottom: 5px;
  }
  button {
    margin-top: 30px;
  }
`;

export default DeleteServiceContent;
