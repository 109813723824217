function createConfig() {
  return {
    id: 'configureSettings',
    initial: 'init',
    context: {},

    states: {
      init: {
        invoke: {
          src: 'loadContext',
          onDone: {
            actions: ['loadContext'],
            target: 'waiting',
          },
          onError: {
            target: 'loadingFailed',
            actions: ['logError'],
          },
        },
      },
      waiting: {},
      saved: {
        after: {
          2000: {
            target: 'waiting',
          },
        },
      },
      submitting: {
        invoke: {
          src: 'saveForm',
          onDone: {
            actions: ['updateContext'],
            target: 'saved',
          },
          onError: {
            target: 'loadingFailed',
          },
        },
      },
      loadingFailed: {},
    },
    on: {
      SAVE_FORM: 'submitting',
    },
  };
}

export default createConfig;
