import React, { useRef, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { AdminSidebarData, ClientSidebarData } from './SidebarData';
import Submenu from './Submenu';

const DashboardSidebar = ({ sideBarOpen, toggleSideBar }) => {
  const { user } = useAuth0();
  let role = null;
  if (user) {
    [role] = user['https://gceus-api-usmkrvnr3q-uc.a.run.app/roles'];
  }

  const sideBar = useRef();
  const titleRef = useRef();
  const escFunction = useCallback(event => {
    if (event.keyCode === 27 && !sideBarOpen) {
      toggleSideBar();
    }
  }, []);
  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  const closeModal = e => {
    if (sideBar.current === e.target) {
      toggleSideBar();
    }
  };

  let logoLink;
  if (user?.email_verified === true) {
    logoLink = '/dashboard';
  } else if (user?.email_verified === false) {
    logoLink = '/verify-email';
  } else {
    logoLink = '/create-profile';
  }
  return (
    <StyledMenu
      className={sideBarOpen && '__open'}
      onClick={closeModal}
      ref={sideBar}
    >
      <Sidebar className={sideBarOpen && '__open'} ref={titleRef}>
        <div className="dashboard-links-cont">
          <NavLink className="link" to={logoLink}>
            GLOBAL CREDENTIAL EVALUATORS
          </NavLink>
          {role === 'ADMIN' &&
            AdminSidebarData.map(item => {
              return <Submenu key={item.path} item={item} />;
            })}
          {role === 'USER' &&
            user.email_verified &&
            ClientSidebarData.map(item => {
              return <Submenu key={item.path} item={item} />;
            })}
          {role === 'USER' && (
            <nav className="bottom-links">
              <div className="dashboard-links">
                <NavLink to="/contact">Contact Us</NavLink>

                <NavLink to="/terms">Terms of Use</NavLink>
              </div>
            </nav>
          )}
        </div>
      </Sidebar>
    </StyledMenu>
  );
};

export default DashboardSidebar;
const StyledMenu = styled.div`
  width: 25%;
  max-width: 300px;
  @media print {
    display: none;
  }
  @media only screen and (max-width: 1300px) {
    max-width: unset;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    &.__open {
      pointer-events: all;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(100, 100, 100, 0.7);
        display: block;
        cursor: pointer;
      }
    }
  }
`;

const Sidebar = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: #14527f;
  display: flex;
  flex-direction: column;
  transition: all ease 0.3s;
  @media print {
    display: none;
  }
  @media only screen and (max-width: 1300px) {
    max-width: 250px;
    position: absolute;
    top: 0;
    left: -500px;
    width: auto;
    &.__open {
      left: 0;
    }
  }
  .dashboard-links-cont {
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .link {
    padding: 20px;
    color: white;
    text-decoration: none;
    font-size: 26px;
    width: 100%;
    display: block;
    margin-bottom: 80px;
    font-weight: 700;
    letter-spacing: 1.4px;
    line-height: 1.1;
  }
  .bottom-links {
    margin-top: auto;
    margin-bottom: 30px;
  }
  .dashboard-links {
    list-style: none;
    margin: 0;
    margin-top: auto;

    a {
      font-size: 14px;
      font-weight: 700;
      color: white;
      transition: all ease 0.3s;
      display: block;
      padding: 12px 24px;
      &:hover,
      &.active {
        background-color: #0c4973;
        position: relative;
        &:before {
          width: 3px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #68b6ec;
          content: '';
        }
      }
    }
  }
`;
