import { gql } from '@apollo/client';

export const QUERY_INIT = gql`
  query queryEmailTemplates {
    emailTemplates {
      getAll {
        id
        name
        subject
        templateID
      }
    }
  }
`;

export default client => {
  const queryInit = () => {
    return client
      .query({
        query: QUERY_INIT,
      })
      .then(({ data }, error) => {
        if (error) {
          throw new Error();
        }
        return data.emailTemplates.getAll;
      });
  };

  return {
    queryInit,
  };
};
