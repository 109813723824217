import { gql } from '@apollo/client';
import { QUERY_INIT } from './queries';

export const UPDATE_FORM = gql`
  mutation configureSettingsSave(
    $newAppAdminUserId: String
    $copyRequestAdminUserId: String
    $mailAdminUserId: String
    $completedFraudId: String
    $rushBool: Boolean
  ) {
    services {
      toggleRushService(rushBool: $rushBool)
    }
    workflow {
      newAppHandler(adminId: $newAppAdminUserId) {
        adminId
      }
      copyRequestHandler(adminId: $copyRequestAdminUserId) {
        adminId
      }
      mailAdminHandler(adminId: $mailAdminUserId) {
        adminId
      }
      completedFraudHandler(adminId: $completedFraudId) {
        adminId
      }
    }
  }
`;

export default client => {
  const submitForm = formData => {
    const variables = {
      newAppAdminUserId: formData.newAppHandler,
      copyRequestAdminUserId: formData.copyRequestHandler,
      mailAdminUserId: formData.mailAdminHandler,
      completedFraudId: formData.completedFraud,
      rushBool: formData.enableRushService === 'Yes',
    };
    return client
      .mutate({
        mutation: UPDATE_FORM,
        variables,
        refetchQueries: [
          {
            query: QUERY_INIT,
          },
        ],
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        const results = {
          copyRequestHandler: data.data.workflow.copyRequestHandler.adminId,
          mailAdminHandler: data.data.workflow.mailAdminHandler.adminId,
          newAppHandler: data.data.workflow.newAppHandler.adminId,
          enableRushService: data.data.services.toggleRushService,
        };
        return results;
      });
  };

  return {
    submitForm,
  };
};
