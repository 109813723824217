import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalWrapper = styled.div`
  position: relative;
  max-height: 90vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: scroll;
  .__content {
    width: 100%;
    padding: 90px 136px;
  }
  .__closeModal {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: #14527f;
    color: white;
    border: none;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all ease 0.3s;
    &:hover,
    &:focus,
    &:active {
      background-color: #033659;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    background-clip: padding-box;
    width: 14px;
  }
`;
