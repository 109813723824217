import React, { useState, useEffect } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import LoadingSpinner from '../UI/Loading';
import { Button } from '../../shared/styles';
import { errorConfig, paymentConfig } from '../../shared/notifications/toast';

export default function CheckoutForm({
  finalizedInvoice,
  onPaid,
  onCancelled,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          toast.success('Payment succeeded!', paymentConfig);
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleCancel = e => {
    e.preventDefault();

    toast.dismiss();
    onCancelled();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setMessage(null);
    toast.dismiss();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    setIsLoading(false);

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }

      toast.error(message, errorConfig);
    } else {
      onPaid();
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        name: 'auto',
      },
    },
  };

  return (
    <>
      <PaymentContainer>
        <PaymentSection>
          {/* <ExpressCheckoutElement /> */}

          <h2>Choose how you'd like to pay</h2>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />

            <p className="disclaimers">
              By confirming your payment, you allow Global Credential Evaluators
              to charge you for this payment and save your payment information
              in accordance with their terms.
            </p>

            <div className="action-buttons">
              <Button id="submit" disabled={isLoading || !stripe || !elements}>
                <span id="button-text">
                  {isLoading ? (
                    <div className="spinner">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    // <div className="spinner" id="spinner" />
                    `Pay $${finalizedInvoice.amount_due / 100}`
                  )}
                </span>
              </Button>
              <Button
                disabled={isLoading || !stripe || !elements}
                onClick={handleCancel}
              >
                <span id="button-text">Cancel</span>
              </Button>
            </div>

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>

          <PaymentFooter>
            <a href="https://stripe.com" target="_blank" rel="noreferrer">
              Powered by <span className="bold">Stripe</span>
            </a>
          </PaymentFooter>
        </PaymentSection>
      </PaymentContainer>
    </>
  );
}

const PaymentContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 500px;
  align-self: center;
`;

const PaymentSection = styled.div`
  border-radius: 4px;
  padding: 40px 30px 0px 30px;
  box-shadow:
    0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  #payment-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .disclaimers {
    font-size: 12px;
    color: #333;
    line-height: 1.5;
  }

  .actionButtons {
    display: flex;
  }

  .spinner {
    width: 25px;
    height: 25px;
  }
`;

const PaymentFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 12px;
  color: #333;

  .bold {
    font-weight: 700;
  }
`;
