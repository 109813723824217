import styled from 'styled-components';
import { ReactComponent as Map } from '../../assets/Map.svg';

export const FRONTEND_HEADER = styled.div`
  background-color: #14527f;
  padding: 80px 110px 80px;
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1000px) {
    padding-inline: 30px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  .blue-break {
    display: block;
    width: 52px;
    height: 2px;
    background-color: #68b6ec;
    margin-bottom: 15px;
  }
`;
export const APPLICATION_INFORMATION = styled.div`
  max-width: 700px;
  color: white;
  text-align: left;
  padding: 45px 0;
  margin-right: 150px;
  @media only screen and (max-width: 1450px) {
    margin-right: 60px;
  }
  @media only screen and (max-width: 800px) {
    margin-right: 0;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  p {
    font-size: 24px;
    line-height: 1.5;
  }
`;

export const MAP_CONTAINER = styled(Map)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-70%, -47%);
  max-height: 947px;
  height: 100%;
  max-width: 100% !important ;
  z-index: 0;
`;

export const QUESTION_CONTAINER = styled.div`
  background-color: #114d78;
  position: relative;
  padding: 40px 110px 60px;
  color: white;
  text-align: left;
  max-width: 700px;
  @media only screen and (max-width: 1450px) {
    padding: 60px;
    align-self: center;
  }
  @media only screen and (max-width: 800px) {
    padding: 60px 30px;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }
  a {
    color: #68b6ec;
    transition: all ease 0.3s;
    &:focus,
    &:hover,
    &:active {
      color: white;
    }
  }
  .contact-info {
    display: flex;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
    &__block {
      &:first-child {
        margin-right: 90px;
        @media only screen and (max-width: 800px) {
          margin-right: auto;
        }
        @media only screen and (max-width: 500px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
    &__header {
      font-size: 18px;
      font-weight: bold;
    }
    &__number {
      font-size: 18px;
      text-decoration: none;
      color: white;
      &:hover,
      &:focus {
        color: #68b6ec;
      }
    }
  }
`;

export const USER_NAV_STYLED = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .userNav {
    margin-left: auto;
  }
`;
