import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

function ValidationErrors({ errors }) {
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return (
    <div className="error-summary">
      {Object.keys(errors).map(fieldName => (
        <ErrorMessage
          errors={errors}
          name={fieldName}
          render={({ message }) => <p className="error">{message}</p>}
          key={fieldName}
        />
      ))}
    </div>
  );
}

export default ValidationErrors;
