import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { returnService, constructAddress } from '../../../../shared/functions';

import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import Button from '../../../UI/Button';
import SchoolList from '../../../SchoolList/SchoolList';

import CopyAbleText from '../../../UI/CopyAbleText/CopyAbleText';
import Termsofservice from '../../../form-components/FormSteps/ReviewApplication/Terms/TermsOfService';
// import { ReactComponent as IconCopy } from '../../../../assets/pencil-square.svg';

// send, services, application, admins

const ApplicantInfo = ({ send, application, services }) => {
  const {
    paidDate,
    paid,
    status,
    applicationType,
    deliveryInstructions,
    applicantInfo,
    education,
    termsAgreement,
    termsSignedDate,
    appDocumentsInfo,
    refNumber,
  } = application;

  const {
    prevEvaluation,
    prevEvaluationRefNumber,
    prevEvaluationDate,
    gender,
    firstName,
    middleName,
    lastName,
    differentFirstName,
    differentMiddleName,
    differentLastName,
    address: { line1, line2, city, state, postalCode, country },
    phoneNumber,
    email,
    birthday,
    birthCountry,
  } = applicantInfo;

  const {
    rushApplicationServiceID,
    returnDocumentsAddress,
    additionalReportsMailedAddresses,
    additionalPDFEmails,
    emailAddressToReturn,
    returnDocumentsEmail,
  } = deliveryInstructions;

  // RETURNING THE SERVICE TO THE CORRECT VARIABLE
  const appType = returnService(services, applicationType.id);

  const rushServiceType = returnService(services, rushApplicationServiceID);

  const additionalEmailsArray = additionalPDFEmails
    ? JSON.parse(additionalPDFEmails)
    : [];

  const emailService = services.find(service =>
    service.displayName.includes('Additional Email'),
  );
  return (
    <ApplicantInformationPage>
      <PrinterOnlyInfo>
        <p>
          Applicant & Reference Number:{' '}
          <span>
            {firstName} {lastName} - {refNumber || 'Ref number not set yet'}
          </span>
        </p>
      </PrinterOnlyInfo>
      <Header>
        <div className="__buttons">
          <Button
            id="__header-btn"
            text="Back to Overview"
            onClick={() => send('OVERVIEW')}
          />
          <Button
            id="__edit-btn"
            text="Edit"
            onClick={() => send('EDIT_APPLICATION')}
          />
        </div>
      </Header>
      <h1 className="pageHeader">
        <strong>View applicant information</strong>
      </h1>
      <HeaderInfo>
        <p className="__title">
          <strong>Paid date:</strong>{' '}
          {dayjs(paidDate).format('MM/DD/YYYY hh:mm:ss a')}{' '}
        </p>
        <p>
          <strong>Payment Status:</strong> {paid ? 'Received' : 'Not Received'}
        </p>
        <p>
          <strong>Status:</strong> {status}
        </p>
        <p>
          <strong>Order type:</strong> {applicationType && appType.name}
        </p>
      </HeaderInfo>
      <Segment>
        <RoundedBlock title="01. Applicant information">
          <ApplicantDetails>
            <p>
              <strong>Previous Evaluation:</strong>{' '}
              {prevEvaluation ? prevEvaluationRefNumber : 'No'}
            </p>
            {prevEvaluation && (
              <p>
                <strong>Previous Evaluation Date:</strong>{' '}
                {dayjs(prevEvaluationDate).format('MM/DD/YYYY')}
              </p>
            )}
            <div className="__sbs">
              <p>
                <strong>Gender:</strong>
                {gender}
              </p>
              <p>
                <strong>Name:</strong>
                {firstName} {middleName} {lastName?.toUpperCase()}
              </p>
            </div>
            {(differentFirstName ||
              differentLastName ||
              differentMiddleName) && (
              <p>
                <strong>
                  Name that appears on foreign educational documents:
                </strong>
                {differentFirstName} {differentMiddleName}{' '}
                {differentLastName?.toUpperCase()}
              </p>
            )}
            <hr />
            <div className="__sbs print__address">
              <p>
                <strong>Personal Address 1:</strong>
                {line1}
              </p>
              {line2 && (
                <p>
                  <strong>Personal Address 2:</strong>
                  {line2}
                </p>
              )}
              <p>
                <strong>City:</strong>
                {city}
              </p>
              <p>
                <strong>State:</strong>
                {state}
              </p>
              <p>
                <strong>Country:</strong>
                {country}
              </p>
              <p>
                <strong>Zipcode:</strong>
                {postalCode}
              </p>
            </div>
            <hr />
            <div className="__sbs">
              <p>
                <strong>Phone number:</strong> {phoneNumber}
              </p>
              <p>
                <strong>Email:</strong> {email}
              </p>
            </div>
            <div className="__sbs">
              <p>
                <strong>Date of birth:</strong>{' '}
                {dayjs(birthday.slice(0, -2)).format('MM/DD/YYYY')}
              </p>
              <p>
                <strong>Birth country:</strong> {birthCountry}
              </p>
            </div>
          </ApplicantDetails>
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="02. Educational History">
          <SchoolList schools={education} fromDB />
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="03. Documents">
          <p>
            <strong>Documents submitted via:</strong>{' '}
            {appDocumentsInfo.documentsSentBy}
          </p>
          <div>
            <p>
              <strong>Total number of pages:</strong>{' '}
              {appDocumentsInfo.pageCount}
            </p>
            <p>
              <strong>Total number of files:</strong>{' '}
              {appDocumentsInfo.fileCount}
            </p>
          </div>
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="04. Application type & fees">
          <p>
            <strong>Service type:</strong> {appType.displayName}{' '}
            <strong>
              <em>
                (<span>$</span>
                {appType.price})
              </em>
            </strong>
          </p>
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="05. Delivery instructions">
          <div>
            <p>
              <strong>Email to send report: </strong>{' '}
              <span>{emailAddressToReturn}</span>
            </p>
          </div>
          {rushServiceType && (
            <p>
              <strong>Rush Service:</strong> {rushServiceType?.displayName}{' '}
              <strong>
                <em>
                  &nbsp;(<span>$</span>
                  {rushServiceType.price})
                </em>
              </strong>
            </p>
          )}
          {returnDocumentsAddress && (
            <div>
              <strong>Return original mailed documents:</strong>{' '}
              {constructAddress(returnDocumentsAddress, true, services)}{' '}
            </div>
          )}
          {returnDocumentsEmail && (
            <div className="sideBySide">
              <p>
                <strong>Email original documents:</strong>{' '}
              </p>
              <CopyAbleText text={returnDocumentsEmail} id="returnDocsEmail" />
            </div>
          )}
          {/* ADDITIONAL REPORTS SECTION WILL SHOW ONLY IF THERE ARE MORE REPORTS ORDERED */}
          {additionalReportsMailedAddresses.length > 0 && (
            <div>
              <hr />
              <p>
                <strong>
                  <em>Additional reports</em>
                </strong>
              </p>
              <p>
                <strong>Number of reports:</strong>{' '}
                {additionalReportsMailedAddresses.length}
              </p>

              {additionalReportsMailedAddresses.map((address, index) => {
                let hr = false;
                if (index !== 0) {
                  hr = true;
                }
                const {
                  price: addReportsPrice,
                  description: addReportsDescription,
                } = returnService(services, address.shippingMethod);
                return (
                  <div key={address.id}>
                    {hr && <hr />}
                    <p>
                      <strong>Mailing instructions:</strong>{' '}
                      {constructAddress(address, true, services)}
                    </p>
                    <p>
                      <strong>Shipping:</strong> {addReportsDescription}{' '}
                      <strong>
                        <em>
                          (<span>$</span>
                          {addReportsPrice})
                        </em>
                      </strong>
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {/* ADDITIONAL EMAILED PDFS WILL ONLY SHOW IF THERE ARE ADDITIONAL EMAILS ADDED. */}
          {additionalEmailsArray.length > 0 && (
            <div>
              <hr />
              <p>
                <strong>
                  <em>Official emailed PDF</em>
                </strong>
              </p>
              <p>
                <strong>Number of copies:</strong>{' '}
                {additionalEmailsArray.length}{' '}
                <strong>
                  <em>
                    &nbsp;(<span>$</span>
                    {emailService.price} each)
                  </em>
                </strong>
              </p>
              <div className="__addedEmails">
                <p>
                  <strong>Email addresses:</strong>
                </p>
                <div className="__emails-cont">
                  {additionalEmailsArray.map((addEmail, index) => (
                    <CopyAbleText
                      id={`addedEmail_${index + 1}`}
                      text={
                        additionalEmailsArray.length !== index + 1
                          ? addEmail.email
                          : `${addEmail.email},`
                      }
                      key={Math.random()}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </RoundedBlock>
      </Segment>
      <Segment>
        <RoundedBlock title="06. Terms and submission">
          <Termsofservice agreed />
          <p>
            <strong>Initials:</strong> {termsAgreement || 'not signed yet'}
          </p>
          <p>
            <strong>Affirmation Date:</strong>{' '}
            {termsSignedDate
              ? dayjs(termsSignedDate).format('MM/DD/YYYY hh:mm:ss a')
              : 'not signed yet'}
          </p>
        </RoundedBlock>
      </Segment>
      <Button blue text="Print" onClick={() => window.print()} />
    </ApplicantInformationPage>
  );
};

export default ApplicantInfo;

const Header = styled.div`
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .__buttons {
    display: flex;
    margin-bottom: 30px;
    button {
      margin-right: 10px;
    }
  }
  #__edit-btn {
    background: #14527f;
    color: #fff;
    &:hover {
      background-color: #033659;
    }
  }
`;

const Segment = styled.div`
  margin: 30px 0;
  p {
    strong {
      margin-right: 10px;
    }
  }
  .__sbs {
    display: flex;
    p {
      margin-right: 22px;
    }
  }
  .__addedEmails {
    display: flex;
    .__emails-cont {
      display: flex;
      margin-bottom: 15px;
    }
  }
  @media print {
    margin: 0 0 10px;
    padding: 0;

    .__title {
      font-size: 18px !important;
    }
  }
`;
const ApplicantDetails = styled.div`
  hr {
    margin: 20px 0;
  }

  @media print {
    .print__address {
      display: block;
      p {
        margin-bottom: 5px;
      }
    }
    .__sbs {
      display: block;
    }
  }
`;
const ApplicantInformationPage = styled.div`
  h1 {
    margin-bottom: 30px;
  }
  @media print {
    div::-webkit-scrollbar {
      display: none !important;
    }
    p {
      font-size: 12px !important;
      margin: 0 !important;
      margin-bottom: 10px !important ;
      padding: 0 !important;
    }
    .pageHeader {
      display: none;
    }
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    margin-right: 30px;
    margin-bottom: 10px;
    width: 30%;
  }
  @media print {
    p {
      width: 50%;
      margin: 0;
      padding: 0;
    }
  }
`;

const PrinterOnlyInfo = styled.div`
  display: none;
  margin-bottom: 10px;
  p {
    font-weight: 700;
    margin-right: 30px;
    span {
      font-weight: 400;
    }
  }
  @media print {
    display: flex;
    margin-bottom: 0;
  }
`;
