import { assign } from 'xstate';
import {
  contextStep2DataToPrismaModel,
  modelSchoolToStep2DataContext,
} from './dataTransform';
import {
  CREATE_SCHOOL,
  DELETE_SCHOOL,
  UPDATE_SCHOOL,
} from '../../../../queries_mutations/mutations';

function createGaurds() {
  return {
    isStep2Complete: ctx => ctx.step2.complete,
  };
}

function createServices(client) {
  return {
    addNewSchool: (ctx, e) => {
      const schoolToPrismaModel = contextStep2DataToPrismaModel(e.data);

      return client
        .mutate({
          mutation: CREATE_SCHOOL,
          variables: {
            appId: ctx.applicationID,
            schoolInfo: { ...schoolToPrismaModel },
          },
        })
        .then((data, error) => {
          if (error) {
            // do we throw?
          }
          return data.data.applications.application.createSchoolInfo;
        });
    },
    editSchool: (ctx, e) => {
      const schoolToPrismaModel = contextStep2DataToPrismaModel(e.data);
      // console.log('fn:service:editSchool', schoolToPrismaModel);
      return client
        .mutate({
          mutation: UPDATE_SCHOOL,
          variables: {
            schoolInfo: schoolToPrismaModel,
            appId: ctx.applicationID,
          },
        })
        .then((data, error) => {
          if (error) {
            throw new Error();
          }
          return data.data.applications.application.updateSchoolInfo;
        });
    },
    deleteSchool: (ctx, e) => {
      return client
        .mutate({
          mutation: DELETE_SCHOOL,
          variables: {
            id: e.id,
            appId: ctx.applicationID,
          },
        })
        .then((data, error) => {
          if (error) {
            throw new Error();
          }
          return data.data.applications.application.deleteSchoolInfo.id;
        });
    },
  };
}

function createActions() {
  return {
    loadStep2DataIntoContext: assign({
      step2: (_, event) => {
        // console.log('loading step 2 data into context', event.data.education);
        const dataArray = [];
        event.data.education.map(school =>
          dataArray.push(modelSchoolToStep2DataContext(school)),
        );
        const isComplete = dataArray.length > 0;
        return {
          complete: isComplete,
          data: dataArray,
        };
      },
    }),

    setStep2Complete: assign({
      step2: ctx => {
        const isComplete = ctx.step2?.data?.length > 0;
        return { ...ctx.step2, complete: isComplete };
      },
    }),

    addSchoolToContext: assign({
      step2: (ctx, e) => {
        // console.log('fn:action:addSchoolToContext');
        const schoolToAdd = modelSchoolToStep2DataContext(e.data);
        const schools = ctx.step2.data;
        schools.push(schoolToAdd);
        return { ...ctx.step2, data: schools };
      },
    }),

    updateSchoolToContext: assign({
      step2: (ctx, e) => {
        // console.log('fn:action:updateSchoolTocontext', e);
        const schools = ctx.step2.data;
        const updatedSchoolIndex = schools.findIndex(
          school => school.id === e.data.id,
        );
        schools[updatedSchoolIndex] = modelSchoolToStep2DataContext(e.data);
        return { ...ctx.step2, data: schools };
      },
    }),

    removeSchoolfromContext: assign({
      step2: (ctx, e) => {
        // console.log('fn:action:removeSchoolFromContext');
        const schoolToRemove = e.data;
        const schools = ctx.step2.data;
        const updatedSchools = schools.filter(
          school => school.id !== schoolToRemove,
        );
        return { ...ctx.step2, data: updatedSchools };
      },
    }),
  };
}

function create(client) {
  return {
    actions: createActions(client),
    services: createServices(client),
    guards: createGaurds(),
  };
}

export default create;
