import RoundedBlock from '../../UI/RoundedBlock/RoundedBlock';
import { returnService, constructAddress } from '../../../shared/functions';

export default function InvoiceSummary({ formState }) {
  const {
    addresses,
    services,
    emailPDFS,
    emailService,
    totalPrice,
    returnDocs,
    returnDocsServices,
    returnDocsEmailService,
  } = formState.context;

  return (
    <RoundedBlock title="Order Information">
      <div className="__block __table-header">
        <div>
          <p>SERVICE</p>
        </div>
        <div>
          <p>PRICE</p>
        </div>
      </div>
      {addresses.length > 0 && (
        <>
          {addresses.map((address, index) => (
            <div className="__block __service" key={Math.random()}>
              <div>
                <h3>Address {index + 1}</h3>
                <div className="__address">
                  {constructAddress(address)}
                  Shipping Method:{' '}
                  {returnService(services, address.shippingMethod).displayName}
                </div>
              </div>
              <div>
                <p>
                  <span>$</span>
                  {returnService(services, address.shippingMethod).price}.00
                </p>
              </div>
            </div>
          ))}
        </>
      )}
      {emailPDFS.length > 0 &&
        emailPDFS.map((email, index) => (
          <div className="__block __service" key={Math.random()}>
            <div>
              <h3>Email {index + 1}</h3>
              <p>{email.email}</p>
            </div>
            <div>
              <p>
                <span>$</span>
                {emailService.price}.00
              </p>
            </div>
          </div>
        ))}
      {returnDocs?.returnEmail && (
        <div className="__block __service" key={Math.random()}>
          <div>
            <h3>Return Docs Email</h3>
            <p>{returnDocs.returnEmail}</p>
          </div>
          <div>
            <p>
              <span>$</span>
              {returnDocsEmailService.price}.00
            </p>
          </div>
        </div>
      )}
      {returnDocs?.shipping_method && (
        <div className="__block __service" key={Math.random()}>
          <div>
            <h3>Return Documents Address</h3>
            <div>{constructAddress(returnDocs)}</div>
          </div>
          <div>
            <p>
              <span>$</span>
              {
                returnService(returnDocsServices, returnDocs.shipping_method)
                  .price
              }
              .00
            </p>
          </div>
        </div>
      )}
      <div className="__block __service __total">
        <div>
          <h3>Total:</h3>
        </div>
        <div>
          <h3>
            <span>$</span>
            {totalPrice}.00
          </h3>
        </div>
      </div>
    </RoundedBlock>
  );
}
