import { Machine, assign } from 'xstate';
import { useApolloClient } from '@apollo/client';

import createPagingConfig from '../../../Machines/EvaluationsListMachineConfig';
import evaluationApi from '../../../queries_mutations/Evaluation/queries';
import evaluationMutationApi from '../../../queries_mutations/Evaluation/mutations';

function createServices(myEvaluations) {
  const client = useApolloClient();
  const { queryPaidEvaluations, queryMyPaidEvaluationsInit, search, searchMy } =
    evaluationApi(client);
  const { updateAssignedTo, updateStatusTo, deleteApplication } =
    evaluationMutationApi(client);

  return {
    guards: {},

    services: {
      load: ctx => {
        if (myEvaluations) {
          return queryMyPaidEvaluationsInit(ctx.take);
        }
        return queryPaidEvaluations(ctx.take);
      },

      search: (ctx, e) => {
        return search(e.data, ctx.take);
      },
      searchMy: (ctx, e) => {
        return searchMy(e.data, ctx.take);
      },

      updateEvaluator: (_, { data }) =>
        updateAssignedTo(data.id, data.evaluator),

      updateStatus: (_, { data }) => updateStatusTo(data.id, data.status),
      deleteApplication: (_, { data }) => deleteApplication(data.id),
    },

    actions: {
      // initail load success
      loadSuccess: assign({
        allEvaluationsCount: (_, { data }) => {
          return data.count;
        },
        items: (_, { data }) => {
          return data.applications;
        },
        pages: (ctx, { data }) => {
          return Math.ceil(data.applications.length / ctx.itemsToShow);
        },
        admins: (_, { data }) => {
          return data.admins;
        },
        services: (_, { data }) => {
          return data.services;
        },
        message: () => null,
      }),

      searchSuccess: assign({
        items: (ctx, { data }) => {
          // console.log('items are: ', data.applications);
          return data.applications;
        },
        pages: (ctx, { data }) => {
          return Math.ceil(data.applications.length / ctx.itemsToShow);
        },
        message: (ctx, { data }) => {
          return data.applications?.length > 0
            ? null
            : 'No results found with search.';
        },
        currentPage: 1,
      }),
      // PAGING ACTIONS
      nextPage: assign({
        currentPage: ctx => {
          return ctx.currentPage + 1;
        },
      }),
      prevPage: assign({
        currentPage: ctx => {
          return ctx.currentPage - 1;
        },
      }),
      goToPage: assign({
        currentPage: (_, e) => {
          return e.page;
        },
      }),

      // SORTING
      sort: assign({
        sortRef: (ctx, e) => {
          if (e.sort === 'SORT_REF_NUM') {
            return !ctx.sortRef;
          }
          return null;
        },
        sortDatePaid: (ctx, e) => {
          if (e.sort === 'SORT_DATE_PAID') {
            return !ctx.sortDatePaid;
          }
          return null;
        },
        sortName: (ctx, e) => {
          if (e.sort === 'SORT_NAME') {
            return !ctx.sortName;
          }
          return null;
        },
        sortAssignedTo: (ctx, e) => {
          if (e.sort === 'SORT_ASSIGNED_TO') {
            return !ctx.sortAssignedTo;
          }
          return null;
        },
        sortStatus: (ctx, e) => {
          if (e.sort === 'SORT_STATUS') {
            return !ctx.sortStatus;
          }
          return null;
        },
      }),
    },
  };
}

function create(myEvaluations) {
  const services = createServices(myEvaluations);
  return Machine({ ...createPagingConfig() }, { ...services });
}

export default create;
