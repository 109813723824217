import { gql } from '@apollo/client';
// import { applicationFields } from '../sharedVariables';
//
// QUERIES FOR ALL EVALUATIONS
//

const copyRequestFields = `
id
        application {
          refNumber
          applicantInfo {
            firstName
            lastName
            address {
              country
            }
            birthday
          }
        }
        applicationId
        status
        createdAt
        completed
`;

export const COPY_REQUESTS = gql`
  query copyRequestsInit {
    copyRequest {
      getPending{
        ${copyRequestFields}
      }
    }
  }
`;

export default client => {
  const init = take => {
    return client
      .query({
        query: COPY_REQUESTS,
        variables: { take },
      })
      .then(({ data }) => {
        const results = {
          copyRequests: data.copyRequest.getPending,
        };
        return results;
      });
  };

  return {
    init,
  };
};
