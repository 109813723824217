import styled from 'styled-components';

const SchoolList = styled.div`
  background-color: #f7fafc;
  border-radius: 15px;
  margin: 40px auto;
  padding: 50px 0 40px;
  overflow-x: scroll;
  .SF-segment-title {
    padding: 0;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  table {
    width: 100%;
    border-top: solid #d4d4d4 1px;
    border-collapse: collapse;

    th {
      color: #14527f;
      text-transform: uppercase;
      font-size: 15px;
      text-align: left;
      padding: 10px 5px 10px 20px;
      border: solid 1px #d4d4d4;
      border-bottom: none;
      border-top: none;
      min-width: 100px;
      @media only screen and (max-width: 1000px) {
        padding: 10px;
      }
      &:first-child,
      &:last-child {
        border-right: none;
        border-left: none;
      }
    }
    tr {
      td {
        padding-left: 20px;
      }
    }
    .school {
      td {
        padding: 10px 5px 10px 20px;
        border: solid 1px #d4d4d4;
        border-bottom: none;
        border-top: none;
        &:first-child,
        &:last-child {
          border-right: none;
          border-left: none;
        }
      }
      &__name {
        font-weight: 700;
      }
      &__actions {
        display: flex;
        @media only screen and (max-width: 1150px) {
          flex-direction: column;
        }
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          margin-bottom: 10px;
          padding: 0 5px;
          border-right: solid 1px #d4d4d4;
          display: flex;
          align-items: center;
          transition: all ease 0.3s;
          &:first-child {
            svg {
              margin-right: 3px;
            }
          }
          &:last-child {
            border-right: none;
          }
          svg {
            fill: #2e2e2e;
          }
          &:hover {
            color: #14527f;
            svg {
              transition: all ease 0.3s;
              fill: #14527f !important;
            }
          }
        }
      }
    }
  }
`;

export default SchoolList;
