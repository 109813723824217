import { gql } from '@apollo/client';
// import { QUERY_PAID_APPLICATIONS_INITAL } from './queries';

export const UPDATE_EVALUATOR = gql`
  mutation updateEvaluator($appId: String!, $evaluatorId: ID!) {
    applications {
      application(id: $appId) {
        updateEvaluator(evaluatorId: $evaluatorId) {
          id
        }
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation updateStatus($appId: String!, $status: String!) {
    applications {
      application(id: $appId) {
        updateStatus(status: $status) {
          id
        }
      }
    }
  }
`;

export const DELETE_APPLICATION = gql`
  mutation deleteApplication($appId: String!) {
    applications {
      application(id: $appId) {
        delete {
          id
        }
      }
    }
  }
`;

export default client => {
  const updateAssignedTo = (appId, evaluatorId) => {
    const variables = { appId, evaluatorId };
    return client
      .mutate({
        mutation: UPDATE_EVALUATOR,
        variables,
      })
      .then((data, error) => {
        if (error) {
          // do we throw?
        }
        return data;
      });
  };
  const updateStatusTo = (appId, status) => {
    const variables = { appId, status };
    return client
      .mutate({
        mutation: UPDATE_STATUS,
        variables,
      })
      .then((data, error) => {
        if (error) {
          // do we throw?
        }
        return data;
      });
  };

  const deleteApplication = appId => {
    const variables = { appId };
    return client
      .mutate({
        mutation: DELETE_APPLICATION,
        variables,
      })
      .then(data => {
        return data;
      });
  };

  return {
    updateAssignedTo,
    updateStatusTo,
    deleteApplication,
  };
};
