import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { debug } from '../../envConfig';
import FormStepIndicator from './FormStepIndicator/FormStepIndicator';
import Step1Form from './FormSteps/Step1/Step1';
import Step2Form from './FormSteps/Step2/Step2';
import Step3Form from './FormSteps/Step3/Step3';
import Step4Form from './FormSteps/Step4/Step4';
import Step5Form from './FormSteps/Step5/Step5';
import createFormMachine from './FormMachine';
import ReviewApplication from './FormSteps/ReviewApplication/ReviewApplication';
import ReviewPayment from './FormSteps/ReviewPayment/ReviewPayment';

const WholeForm = () => {
  const {
    state: { isNewApplication, applicationId, skipToPayStep },
  } = useLocation();
  const client = useApolloClient();
  const [formState, send] = useMachine(
    createFormMachine(applicationId, isNewApplication, skipToPayStep, client),
    {
      devTools: debug.xstate,
    },
  );

  let showStepTracker = true;

  let activeStep;

  const step1Data = formState?.context.step1.data;
  const step2Data = formState?.context.step2.data;
  const step4Data = formState?.context.step4.serviceId;
  const step5Data = formState?.context.step5.data;

  window.onbeforeunload = event => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ''; // Legacy method for cross browser support
    }
    // console.log('canceled refresh');
    return ''; // Legacy method for cross browser support
  };

  switch (true) {
    case formState.matches('loading'):
      activeStep = (
        <StyledInfoText>
          <h2>Loading Form...</h2>
        </StyledInfoText>
      );
      break;

    case formState.matches('loadingApplication'):
      activeStep = (
        <StyledInfoText>
          <h2>Loading Previous Application...</h2>
        </StyledInfoText>
      );
      break;

    case formState.matches('editing.stepOne'):
      showStepTracker = true;
      activeStep = (
        <Step1Form
          state={step1Data}
          onSubmit={value => send('STEP_COMPLETE', { value })}
        />
      );
      break;

    case formState.matches('editing.stepTwo'):
      showStepTracker = true;
      activeStep = (
        <Step2Form
          state={step2Data}
          stepComplete={formState?.context.step2.complete}
          previousStep={value => send('PREV_STEP', { value })}
          onSubmit={() => send('STEP_COMPLETE')}
          send={send}
        />
      );
      break;

    case formState.matches('editing.stepThree'):
      showStepTracker = true;
      activeStep = (
        <Step3Form
          state={formState.context.step3?.data}
          onSubmit={data => send('STEP_COMPLETE', { data })}
          previousStep={() => send('PREV_STEP')}
          formState={formState}
          send={send}
        />
      );
      break;

    case formState.matches('editing.stepFour'):
      showStepTracker = true;
      activeStep = (
        <Step4Form
          state={step4Data}
          onSubmit={value => send('UPDATE_SERVICE', { value })}
          previousStep={() => send('PREV_STEP')}
        />
      );
      break;

    case formState.matches('editing.stepFive'):
      showStepTracker = true;
      activeStep = (
        <Step5Form
          step5Data={step5Data}
          context={formState.context}
          onSubmit={data => {
            send('STEP_COMPLETE', { data });
          }}
          previousStep={() => send('PREV_STEP')}
          send={send}
          formState={formState}
        />
      );
      break;

    case formState.matches('editing.reviewApplication'):
      showStepTracker = false;
      activeStep = (
        <ReviewApplication
          formState={formState}
          onSubmit={() => send('STEP_COMPLETE')}
          previousStep={() => send('PREV_STEP')}
          send={send}
        />
      );
      break;

    // todo: need to rework this a bit.
    case formState.matches('editing.reviewPayment'):
      showStepTracker = false;
      activeStep = (
        <ReviewPayment
          formState={formState}
          onSubmit={() => send('STEP_COMPLETE')}
          previousStep={() => send('PREV_STEP')}
          send={send}
        />
      );
      break;

    case formState.matches('paying'):
      showStepTracker = false;
      activeStep = (
        <StyledInfoText>
          <h2>Redirecting to payment form</h2>
        </StyledInfoText>
      );
      break;

    case formState.matches('error'):
      activeStep = (
        <StyledInfoText>
          <h2>There was an error on the form, please refresh and try again</h2>
        </StyledInfoText>
      );
      break;

    default:
      break;
  }
  return (
    <StyledContainer>
      {showStepTracker && (
        <FormStepIndicator send={send} formState={formState} />
      )}
      {activeStep}
    </StyledContainer>
  );
};

export default WholeForm;

const StyledContainer = styled.div`
  padding-top: 50px;
  position: relative;
`;

const StyledInfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
