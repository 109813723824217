import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { ProfileContext } from '../../../auth/ValidateProfileAddContext';
import TextInput from '../../../UI/FormInputs/TextInput/TextInput';
import RadioGroup from '../../../UI/FormInputs/RadioGroup/RadioGroup';
import { Radiogroup as SimpleRadioGroup } from '../../../form-components/RadioGroup/RadioGroup';
// import ServicesRadioGroup from '../../../form-components/ServicesRadioGroup/ServicesRadioGroup';
import DateInput from '../../../form-components/DateInput/DateInput';
import FormDropdown from '../../../form-components/FormDropdown/FormDropdown';
import { FormFields } from '../../../../shared/styles';
import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import Button from '../../../UI/Button';
import SchoolList from '../../../SchoolList/SchoolList';
import ModalXState from '../../../Modal/ModalXState';
import EditSchool from './EditSchool';
import DeleteSchool from './DeleteSchool';

const schema = yup.object().shape({
  prevEvaluation: yup.string().required('Please choose an option'),
  pageCount: yup
    .number()
    .min(1)
    .typeError('please enter numbers only')
    .required('please enter a number '),
  fileCount: yup
    .number()
    .min(1)
    .typeError('please enter numbers only')
    .required('please enter a number '),
});

const EditApplicationDetails = ({ application, send, formState }) => {
  const userContext = useContext(ProfileContext);
  const {
    appDocumentsInfo,
    applicationType,
    id: applicationId,
    applicantInfo,
    education,
  } = application;

  const {
    prevEvaluation,
    prevEvaluationRefNumber,
    prevEvaluationDate,
    gender,
    firstName,
    middleName,
    lastName,
    differentFirstName,
    differentMiddleName,
    differentLastName,
    address: { id, line1, line2, city, state, country, postalCode },
    phoneNumber,
    email,
    birthday,
    birthCountry,
  } = applicantInfo;
  const { id: serviceId } = applicationType;
  const {
    // rushApplicationServiceID: rushId,
    emailAddressToReturn,
  } = application.deliveryInstructions;

  const { appDocsId, fileCount, pageCount, documentsSentBy } = appDocumentsInfo;

  // FORM DEFAULT VALUES
  const formDefaultValues = {
    prevEvaluation: String(prevEvaluation),
    prevEvaluationRefNumber,
    prevEvaluationDate: {
      month:
        prevEvaluationDate != null
          ? dayjs(prevEvaluationDate).format('MMM')
          : '',
      day:
        prevEvaluationDate != null ? dayjs(prevEvaluationDate).format('D') : '',
      year:
        prevEvaluationDate != null
          ? dayjs(prevEvaluationDate).format('YYYY')
          : '',
    },
    gender,
    firstName,
    middleName,
    lastName: lastName.toUpperCase(),
    differentFirstName,
    differentMiddleName,
    differentLastName: differentLastName?.toUpperCase(),
    id,
    line1,
    line2,
    city,
    state,
    country,
    postalCode,
    phoneNumber,
    email,
    birthday: {
      month: dayjs(birthday.slice(0, -2)).format('MMM'),
      day: dayjs(birthday.slice(0, -2)).format('D'),
      year: dayjs(birthday.slice(0, -2)).format('YYYY'),
    },
    birthCountry,
    serviceId,
    appDocsId,
    fileCount,
    pageCount,
    reqDocsSendingType: documentsSentBy,
    emailAddressToReturn,
  };

  // FORM INITIATE
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
  });

  // SUBMIT FUNCTION FOR FORM
  const onSubmit = formData => {
    const formDataUpdate = {
      ...formData,
      applicationId,
      id,
      appDocs: {
        id: appDocumentsInfo.id,
        fileCount: parseInt(formData.fileCount),
        pageCount: parseInt(formData.pageCount),
      },
      userID: userContext?.id || null,
    };
    send('SAVE_DETAILS', { formData: formDataUpdate });
  };

  // SAVING TEXT
  let savingText = '';
  switch (true) {
    case formState.matches('editApplicationDetails.savingDetails'):
      savingText = 'Saving Content';
      break;
    default:
      break;
  }

  const watchRefNum = watch('prevEvaluation');
  const watchDOBMonth = watch('birthday.month');
  const watchPrevEvalMonth = watch('prevEvaluationDate.month');

  return (
    <ApplicantInformationPage>
      <ModalXState
        machineState={formState}
        modalOpenState="editApplicationDetails.editSchool"
        onClose={() => send('IDLE_STATE')}
      >
        {formState.context.application.education && (
          <EditSchool
            school={formState.context.application.education.find(school => {
              return school.id === formState.context.editDeleteSchool;
            })}
            send={send}
            education={education}
            fromDB
          />
        )}
      </ModalXState>
      <ModalXState
        machineState={formState}
        modalOpenState="editApplicationDetails.addSchool"
        onClose={() => send('IDLE_STATE')}
      >
        <EditSchool send={send} education={education} />
      </ModalXState>
      <ModalXState
        machineState={formState}
        modalOpenState="editApplicationDetails.deleteSchool"
        onClose={() => send('IDLE_STATE')}
      >
        <DeleteSchool
          id={formState.context.editDeleteSchool}
          send={send}
          education={education}
        />
      </ModalXState>
      <Button
        text="Back to Overview"
        blue
        type="button"
        onClick={() => send('BACK_TO_OVERVIEW')}
      />
      <FormFields onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <Segment>
            <RoundedBlock title="01. Applicant information">
              <ApplicantDetails>
                <RadioGroup
                  register={register}
                  title="Have you had a previous evaluation?"
                  dataRef="prevEvaluation"
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  errors={errors}
                  required
                  stackedOptions
                />
                {watchRefNum === 'true' && (
                  <>
                    <StyledAppBlock>
                      <TextInput
                        register={register}
                        dataRef="prevEvaluationRefNumber"
                        type="text"
                        title="Reference Number:"
                        errors={errors}
                        required
                      />
                    </StyledAppBlock>
                    <p>Previous Evaluation Date:</p>
                    <div className="__sbs">
                      <DateInput
                        dataPrefix="prevEvaluationDate"
                        register={register}
                        errors={errors}
                        date={formDefaultValues?.prevEvaluationDate}
                        monthSelected={watchPrevEvalMonth}
                      />
                    </div>
                  </>
                )}
                <StyledAppBlock>
                  <SimpleRadioGroup
                    settings={{
                      dataRef: 'gender',
                      options: ['Male', 'Female', 'Decline to answer'],
                      title: 'Gender:',
                    }}
                    register={register}
                    errors={errors}
                    firstChecked
                    stackedOptions
                  />
                </StyledAppBlock>
                <p>
                  <strong>Name:</strong>
                </p>
                <div className="__sbs">
                  <TextInput
                    register={register}
                    dataRef="firstName"
                    type="text"
                    title="First Name:"
                    errors={errors}
                    required
                  />
                  <TextInput
                    register={register}
                    dataRef="middleName"
                    type="text"
                    title="Middle Name:"
                    errors={errors}
                  />
                  <TextInput
                    register={register}
                    dataRef="lastName"
                    type="text"
                    title="Last Name:"
                    errors={errors}
                    required
                  />
                </div>
                <p>
                  <strong>
                    Name that appears on foreign educational documents:
                  </strong>
                </p>
                <div className="__sbs">
                  <TextInput
                    register={register}
                    dataRef="differentFirstName"
                    type="text"
                    title="First Name:"
                    errors={errors}
                  />
                  <TextInput
                    register={register}
                    dataRef="differentMiddleName"
                    type="text"
                    title="Middle Name:"
                    errors={errors}
                  />
                  <TextInput
                    register={register}
                    dataRef="differentLastName"
                    type="text"
                    title="Last Name:"
                    errors={errors}
                  />
                </div>
                <hr />
                <p>
                  <strong>Address:</strong>
                </p>
                <StyledAppBlock>
                  <TextInput
                    register={register}
                    dataRef="line1"
                    type="text"
                    title="Personal Address 1:"
                    errors={errors}
                    required
                  />

                  <TextInput
                    register={register}
                    dataRef="line2"
                    type="text"
                    title="Personal Address 2:"
                    errors={errors}
                  />

                  <div className="__sbs print__address">
                    <TextInput
                      register={register}
                      dataRef="city"
                      type="text"
                      title="City:"
                      errors={errors}
                      required
                    />

                    <TextInput
                      register={register}
                      dataRef="state"
                      type="text"
                      title="State:"
                      errors={errors}
                      required
                    />
                  </div>

                  <TextInput
                    register={register}
                    dataRef="country"
                    type="text"
                    title="Country:"
                    errors={errors}
                    required
                  />

                  <TextInput
                    register={register}
                    dataRef="postalCode"
                    type="text"
                    title="Zipcode:"
                    errors={errors}
                  />
                </StyledAppBlock>
                <hr />
                <StyledAppBlock>
                  <TextInput
                    register={register}
                    dataRef="phoneNumber"
                    type="text"
                    title="Phone number:"
                    errors={errors}
                  />
                  <TextInput
                    register={register}
                    dataRef="email"
                    type="text"
                    title="Email:"
                    errors={errors}
                  />
                </StyledAppBlock>
                <p>
                  <strong>Date of birth:</strong>
                </p>
                <div className="__sbs">
                  <DateInput
                    dataPrefix="birthday"
                    register={register}
                    errors={errors}
                    date={formDefaultValues?.birthday}
                    monthSelected={watchDOBMonth}
                  />
                </div>
                <StyledAppBlock>
                  <FormDropdown
                    dataRef="birthCountry"
                    register={register}
                    errors={errors}
                    title="Birth country:"
                    content="country"
                    required
                  />
                </StyledAppBlock>
              </ApplicantDetails>
            </RoundedBlock>
          </Segment>
          <Segment>
            <RoundedBlock
              title="02. Educational History"
              addButton={{
                text: 'Add School',
                click: () => send('ADD_SCHOOL_BUTTON'),
              }}
            >
              <SchoolList schools={education} fromDB editable send={send} />
              {formState.matches(
                'editApplicationDetails.confirmDeleteSchool',
              ) && <p>Deleting School</p>}
            </RoundedBlock>
          </Segment>
          <Segment>
            <RoundedBlock title="03. Documents">
              <SimpleRadioGroup
                settings={{
                  dataRef: 'reqDocsSendingType',
                  options: ['With application', 'Mail', 'Email'],
                }}
                register={register}
                errors={errors}
                firstChecked
                stackedOptions
              />
              <StyledAppBlock>
                <div className="__sbs">
                  <TextInput
                    register={register}
                    dataRef="pageCount"
                    type="text"
                    title="Total number of pages:"
                    errors={errors}
                  />
                  <TextInput
                    register={register}
                    dataRef="fileCount"
                    type="text"
                    title="Total number of files:"
                    errors={errors}
                  />
                </div>
              </StyledAppBlock>
            </RoundedBlock>
          </Segment>
          {/* <Segment>
            <RoundedBlock title="04. Application type & fees">
              <StyledAppBlock>
                <ServicesRadioGroup
                  settings={{
                    dataRef: 'serviceId',
                    sectionTitle: 'Type of report',
                  }}
                  register={register}
                  errors={errors}
                  required
                  evaluationServices
                  serviceToQuery="APPLICATION_SERVICE"
                  defaultValue={serviceId}
                />
              </StyledAppBlock>
            </RoundedBlock>
          </Segment> */}
          <Segment>
            <RoundedBlock title="05. Delivery Instructions">
              <StyledAppBlock>
                <p>
                  <strong>Email for Delivery:</strong>
                </p>
                <TextInput
                  register={register}
                  dataRef="emailAddressToReturn"
                  type="text"
                  title=""
                  errors={errors}
                />
              </StyledAppBlock>
              {/* <hr />
              <ServicesRadioGroup
                settings={{
                  dataRef: 'rushId',
                  sectionTitle: 'Rush Service:',
                }}
                register={register}
                errors={errors}
                required
                evaluationServices
                serviceToQuery="RUSH"
                defaultValue={rushId}
              /> */}
            </RoundedBlock>
          </Segment>
        </FormContainer>
        <ButtonGroup>
          <Button text="Save and Continue" blue type="submit" />
        </ButtonGroup>
        <p>{savingText}</p>
      </FormFields>
    </ApplicantInformationPage>
  );
};

export default EditApplicationDetails;

const ApplicantInformationPage = styled.div`
  #__header-btn {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 30px;
  }
  @media print {
    p {
      font-size: 13px !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .pageHeader {
      display: none;
    }
  }
`;

const FormContainer = styled.div`
  margin-top: 30px;
  .__dateCompleted {
    margin: 10px 0;
  }
`;

const Segment = styled.div`
  margin: 30px 0;

  p {
    strong {
      margin-right: 10px;
    }
  }
  .__sbs {
    display: flex;
    .input-group {
      margin-right: 22px;
      &:last-child {
        margin-right: 0;
      }
    }
    .SF-side-by-side {
      display: flex;
    }
  }
  .__addedEmails {
    display: flex;
    .__emails-cont {
      display: flex;
      margin-bottom: 15px;
    }
  }
  @media print {
    margin: 0 0 10px;
    padding: 0;
  }
`;

const ApplicantDetails = styled.div`
  hr {
    margin: 20px 0;
  }

  @media print {
    .print__address {
      display: block;
      p {
        margin-bottom: 5px;
      }
    }
  }
`;

const ButtonGroup = styled.div`
  margin: 30px -10px;
  display: flex;
  button {
    margin: 0 10px;
  }
`;

const StyledAppBlock = styled.div`
  max-width: 500px;
`;
