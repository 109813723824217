import { gql } from '@apollo/client';
import { QUERY_INIT } from './queires';

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($dataObject: EmailTemplateInput!) {
    emailTemplate {
      update(data: $dataObject) {
        id
        name
        subject
        templateID
      }
    }
  }
`;

export const NEW_TEMPLATE = gql`
  mutation newTemplate($dataObject: EmailNewTemplateInput!) {
    emailTemplate {
      add(data: $dataObject) {
        id
        name
        subject
        templateID
      }
    }
  }
`;
export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($templateID: String!) {
    emailTemplate {
      delete(templateID: $templateID) {
        id
        name
        subject
        templateID
      }
    }
  }
`;

export default client => {
  const saveItem = formData => {
    const mutationVariables = {
      name: formData.name,
      subject: formData.subject,
      templateID: formData.templateID,
      id: formData.id,
    };
    return client
      .mutate({
        mutation: UPDATE_TEMPLATE,
        variables: {
          dataObject: mutationVariables,
        },
        refetchQueries: [
          {
            query: QUERY_INIT,
          },
        ],
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }

        return data;
      });
  };
  const saveNewItem = formData => {
    const mutationVariables = {
      name: formData.name,
      subject: formData.subject,
      templateID: formData.templateID,
    };
    return client
      .mutate({
        mutation: NEW_TEMPLATE,
        variables: {
          dataObject: mutationVariables,
        },
        refetchQueries: [
          {
            query: QUERY_INIT,
          },
        ],
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        return data;
      });
  };
  const deleteItem = deletingItemID => {
    return client
      .mutate({
        mutation: DELETE_TEMPLATE,
        variables: {
          templateID: deletingItemID,
        },
        refetchQueries: [
          {
            query: QUERY_INIT,
          },
        ],
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        return data;
      });
  };

  return {
    saveItem,
    saveNewItem,
    deleteItem,
  };
};
