import { gql } from '@apollo/client';

export const FETCH_DOCUMENTS = gql`
  query fetchDocuments($applicationId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          appDocumentsInfo {
            documents {
              id
              name
              url
            }
          }
        }
      }
    }
  }
`;

export default client => {
  const fetchDocuments = ({ variables }) => {
    return client
      .query({
        query: FETCH_DOCUMENTS,
        variables,
      })
      .then((data, error) => {
        if (error) {
          throw new Error();
        }
        return data.data.applications.me.application.appDocumentsInfo.documents;
      });
  };

  return {
    fetchDocuments,
  };
};
