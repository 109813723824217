import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

const AuditListItem = ({ title, beforeValue, afterValue, name = false }) => {
  return (
    <StyledAuditListItem>
      <span>{title}</span>
      {beforeValue && (
        <span className="__output">
          {name ? 'Name:' : 'Before:'} {beforeValue}
        </span>
      )}
      {afterValue && <span className="__output">After: {afterValue}</span>}
    </StyledAuditListItem>
  );
};

const AuditList = ({ auditHistory }) => {
  const sortedAudits = [...auditHistory];
  sortedAudits.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return (
    <>
      {sortedAudits.map(({ before, createdAt, notes }) => {
        const {
          prevAdmin,
          updatedAdmin,
          prevAppStatus,
          updatedAppStatus,
          prevInternalNotes,
          updatedInternalNotes,
          prevName,
          updatedName,
          prevDiffName,
          updatedDiffName,
          prevRefNum,
          updatedRefNumber,
          prevEvaluatorNotes,
          updatedEvaluatorNotes,
          prevEvaluationCompleteDate,
          updatedEvaluationCompleteDate,
          addedDocument,
          deletedDocument,
          updatedApplication,
          editAddSchool,
        } = JSON.parse(notes);
        return (
          <StyledAuditListEntry key={Math.random()}>
            {/* Admin name and date changed */}
            <td className="__admin">
              <p>{before}</p>
              <p>{dayjs(createdAt).format('MM/DD/YYYY hh:mm') || '--'}</p>
            </td>
            <td>
              <ul>
                {prevAdmin && (
                  // IF SOMEONE CHANGED THE ASSIGNED TO PROPERTY
                  <AuditListItem
                    title="Updated Assigned Status"
                    beforeValue={`${
                      prevAdmin?.firstName
                    } ${prevAdmin?.lastName.toUpperCase()}`}
                    afterValue={`${
                      updatedAdmin?.firstName
                    } ${updatedAdmin?.lastName.toUpperCase()}`}
                  />
                )}
                {prevAppStatus && (
                  // IF SOMEONE Updated THE STATUS PROPERTY
                  <AuditListItem
                    title="Updated status:"
                    beforeValue={prevAppStatus}
                    afterValue={updatedAppStatus}
                  />
                )}
                {prevInternalNotes && (
                  // IF SOMEONE CHANGES THE INTERNAL NOTES
                  <AuditListItem
                    title="Updated Notes:"
                    beforeValue={prevInternalNotes}
                    afterValue={updatedInternalNotes}
                  />
                )}
                {prevName && (
                  // IF SOMEONE CHANGES THE APPLICANTS NAME FIELDS
                  <AuditListItem
                    title="Updated Applicants name:"
                    beforeValue={prevName}
                    afterValue={updatedName}
                  />
                )}
                {prevDiffName && (
                  // IF SOMEONE CHANGES THE APPLICANTS OTHER NAME FIELDS
                  <AuditListItem
                    title="Updated Applicants Other name:"
                    beforeValue={prevDiffName}
                    afterValue={updatedDiffName}
                  />
                )}

                {(prevRefNum || prevRefNum === '' || prevRefNum === null) && (
                  // IF SOMEONE CHANGES THE APPLICANTS REF NUMBER
                  <AuditListItem
                    title="Updated Reference Number:"
                    beforeValue={prevRefNum || '--'}
                    afterValue={updatedRefNumber || '--'}
                  />
                )}
                {prevEvaluatorNotes && (
                  // IF SOMEONE CHANGES THE EVALUATOR NOTES
                  <AuditListItem
                    title="Updated Evaluator Notes:"
                    beforeValue={prevEvaluatorNotes}
                    afterValue={updatedEvaluatorNotes}
                  />
                )}
                {updatedEvaluationCompleteDate && (
                  // IF SOMEONE HITS THE SAVE AND COMPLETE BUTTON AGAIN
                  <AuditListItem
                    title="Hit Save & Mail Button:"
                    beforeValue={
                      prevEvaluationCompleteDate
                        ? dayjs(prevEvaluationCompleteDate).format(
                            'MM/DD/YYYY hh:mm',
                          )
                        : '--'
                    }
                    afterValue={dayjs(updatedEvaluationCompleteDate).format(
                      'MM/DD/YYYY hh:mm',
                    )}
                  />
                )}
                {addedDocument && (
                  // IF SOMEONE UPLOADS A NEW FILE
                  <AuditListItem
                    title="Added a document:"
                    beforeValue={addedDocument}
                    name
                  />
                )}
                {deletedDocument && (
                  // IF SOMEONE UPLOADS A NEW FILE
                  <AuditListItem
                    title="Deleted a document:"
                    beforeValue={deletedDocument}
                    name
                  />
                )}
                {updatedApplication && (
                  // IF SOMEONE UPDATED THE APPLICATION
                  <AuditListItem title="Updated the Application" name />
                )}
                {editAddSchool && (
                  // WHEN SOMEONE ADDS OR EDITS A SCHOOL ON THE APPLICATION
                  <AuditListItem title={editAddSchool} />
                )}
              </ul>
            </td>
          </StyledAuditListEntry>
        );
      })}
    </>
  );
};

export default AuditList;

const StyledAuditListEntry = styled.tr`
  ul {
    list-style: none;
    font-size: 16px;
  }
`;
const StyledAuditListItem = styled.li`
  margin-bottom: 10px;
  span {
    display: block;
    text-align: left;
    font-size: 14px;
    &:nth-child(1) {
      font-weight: 700;
    }
    &.__output {
      margin-left: 10px;
      padding-bottom: 3px;
    }
  }
`;
