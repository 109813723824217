import React from 'react';
import { useMachine } from '@xstate/react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { debug } from '../../../../envConfig';

import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../../../UI/RoundedBlock/BlockTable/BlockTable';
import copyRequestMachine from './MachineSettings';
import { copyRequestStatusOptions } from '../../../../queries_mutations/sharedVariables';
import Pagination from '../../../UI/Pagination/Pagination';
import {
  sortByDate,
  sortByName,
  camelCase,
} from '../../../../shared/functions';

import { ReactComponent as EyeIcon } from '../../../../assets/eye.svg';

const IncompleteApplicationList = () => {
  const navigate = useNavigate();
  const [formState, send] = useMachine(copyRequestMachine(), {
    devTools: debug.xstate,
  });
  if (formState.matches('init')) {
    return <p>Loading</p>;
  }
  const tableHeaders = [
    {
      title: 'REF #',
      sortable: true,
      send: 'SORT_REF_NUM',
      context: 'sortRef',
    },
    {
      title: 'DATE PAID',
      sortable: true,
      send: 'SORT_DATE_PAID',
      context: 'sortDatePaid',
    },
    {
      title: 'NAME',
      sortable: true,
      send: 'SORT_NAME',
      context: 'sortName',
    },
    { title: 'COUNTRY NAME', sortable: false },
    { title: 'DOB', sortable: false },
    {
      title: 'STATUS',
      sortable: true,
      send: 'SORT_STATUS',
      context: 'sortStatus',
    },
    { title: 'ACTION', sortable: false },
  ];

  const {
    copyRequests,
    allCopyRequestsCount,
    pages,
    currentPage,
    itemsToShow,
    sortRef,
    sortDatePaid,
    sortName,
    sortStatus,
    message,
  } = formState.context;
  let copyRequestsToShow = [];

  const searchMessage = message || 'There are no copy requests at this time';

  if (copyRequests) {
    // SORTING
    let sortedCopyRequests = [...copyRequests];
    if (sortRef !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByName(a.application.refNumber, b.application.refNumber, sortRef),
      );
    }
    if (sortDatePaid !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByDate(a, b, sortDatePaid, 'createdAt'),
      );
    }
    if (sortName !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByName(
          a.application.applicantInfo.lastName,
          b.application.applicantInfo.lastName,
          sortName,
        ),
      );
    }
    if (sortStatus !== null) {
      sortedCopyRequests = sortedCopyRequests.sort((a, b) =>
        sortByName(b.status, a.status, sortStatus),
      );
    }
    // WHICH APPLICATIONS TO SHOW ON THE PAGE
    switch (true) {
      case currentPage !== 1:
        copyRequestsToShow = sortedCopyRequests.slice(
          (currentPage - 1) * itemsToShow,
          currentPage * itemsToShow,
        );
        break;

      default:
        copyRequestsToShow = sortedCopyRequests.slice(0, itemsToShow);
        break;
    }
  }
  return (
    <>
      <RoundedBlock
        hasSearch
        hasPagination
        title="Copy Requests"
        btnText="Search"
        send={send}
      >
        {copyRequests?.length > 0 ? (
          <BlockTable
            headers={tableHeaders}
            sortable
            send={send}
            xStateContext={formState.context}
          >
            {copyRequests?.length > 0 &&
              copyRequestsToShow?.map(
                ({
                  id,
                  createdAt,
                  application: {
                    refNumber,
                    applicantInfo: { firstName, lastName, address, birthday },
                  },
                  status,
                }) => {
                  return (
                    <tr key={id}>
                      <td>{refNumber || '--'}</td>
                      <td>{dayjs(createdAt).format('MM/DD/YYYY')}</td>
                      <td>
                        {camelCase(firstName)} {lastName.toUpperCase()}
                      </td>
                      <td>{address.country}</td>
                      <td>{dayjs(birthday).format('MM/DD/YYYY')}</td>
                      <td>
                        <select
                          name="status"
                          id="request-status"
                          onChange={e => {
                            send({
                              type: 'UPDATE_STATUS',
                              value: { status: e.target.value, id },
                            });
                          }}
                          defaultValue={status}
                        >
                          {copyRequestStatusOptions.map(
                            ({ id: optionId, option }) => (
                              <option key={optionId} value={option}>
                                {option}
                              </option>
                            ),
                          )}
                        </select>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => navigate(`/admin/copy-request/${id}`)}
                        >
                          <EyeIcon /> Review
                        </button>
                      </td>
                    </tr>
                  );
                },
              )}
          </BlockTable>
        ) : (
          <p>{searchMessage}</p>
        )}
      </RoundedBlock>
      <Pagination
        pages={pages}
        itemsToShow={itemsToShow}
        currentPage={currentPage}
        itemsArray={copyRequests}
        allItemsCount={allCopyRequestsCount}
        send={send}
      />
    </>
  );
};

export default IncompleteApplicationList;
