import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';

import Constants from '../../../../../../shared/Constants';
import {
  returnServiceByType,
  orderByPrice,
} from '../../../../../../shared/functions';

export default function RushServices({ context, register, errors }) {
  const { services } = context;
  const rushServices = orderByPrice(
    returnServiceByType(services, Constants.SERVICE.RUSH),
  );
  const rushEnabled = rushServices[0]?.show;
  const noRushOption = rushServices.find(service => service.price === 0);

  return (
    <>
      <div className="input-group">
        <div className="input-group--title">
          <p className=" input-group--bold" id="rushApplicationServiceID_group">
            <span className="number">01. </span>
            Do you need rush service? <span className="required">*</span>
          </p>
          {!rushEnabled && (
            <p>Rush services have been disabled during peak times.</p>
          )}
        </div>

        <div
          role="group"
          aria-labelledby="deliveryOptionApplicationServiceID_group"
        >
          {rushEnabled ? (
            rushServices.map(service => {
              return (
                <StyledMailOptions
                  clasName="StyledMailOptions"
                  key={service.id}
                >
                  <input
                    id={`deliveryOptionApplicationServiceID_${service.id}`}
                    name="rushApplicationServiceID"
                    type="radio"
                    value={service.id}
                    {...register('rushApplicationServiceID')}
                  />
                  <label
                    htmlFor={`deliveryOptionApplicationServiceID_${service.id}`}
                  >
                    {service.displayName}{' '}
                    <strong>
                      <em>{`($${service.price}.00)`}</em>
                    </strong>
                  </label>
                </StyledMailOptions>
              );
            })
          ) : (
            <StyledMailOptions
              clasName="StyledMailOptions"
              key={noRushOption.id}
            >
              <input
                id={`deliveryOptionApplicationServiceID_${noRushOption.id}`}
                name="rushApplicationServiceID"
                type="radio"
                value={noRushOption.id}
                {...register('rushApplicationServiceID')}
                defaultChecked
              />
              <label
                htmlFor={`deliveryOptionApplicationServiceID_${noRushOption.id}`}
              >
                {noRushOption.displayName}{' '}
                <strong>
                  <em>{`($${noRushOption.price}.00)`}</em>
                </strong>
              </label>
            </StyledMailOptions>
          )}
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name="rushApplicationServiceID"
        as="span"
        className="error"
      />
    </>
  );
}

const StyledMailOptions = styled.div`
  input {
    margin-right: 10px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`;
