import React from 'react';
import dayjs from 'dayjs';
import { ErrorMessage } from '@hookform/error-message';

import Formdropdown from '../../../../../form-components/FormDropdown/FormDropdown';
import InputField from '../../../../../form-components/InputField/InputField';

// I COPIED THE OTHER DATE INPUT COMPONENT TO THIS ONE FOR THE USE ON THE ADD SCHOOL FOR THE ADMIN PANEL. THE ORIGINAL TAKES DATE OBJECT WITH THE DATE BROKEN DOWN AND WAS CONFUSING. I RATHER JUST PASS THE STRING DATE FROM THE DB AND HAVE DAYJS FORMAT AND READ.

const DateInput = ({ dataPrefix, errors, register, date, excludeDay }) => (
  <div
    className={
      excludeDay
        ? 'SF-side-by-side SF-side-by-side-2'
        : 'SF-side-by-side SF-side-by-side-3'
    }
  >
    <Formdropdown
      register={register}
      dataRef={`${dataPrefix}.month`}
      title="Month"
      errors={errors}
      content="month"
      required
      defaultValue={date && dayjs(date).format('MMM')}
    />
    <InputField
      register={register}
      dataRef={`${dataPrefix}.year`}
      type="text"
      title="Year"
      errors={errors}
      required
      defaultValue={date && dayjs(date).format('YYYY')}
      noError
      placeholder="YYYY"
    />
    <div className="error-block">
      <ErrorMessage
        errors={errors}
        name={`${dataPrefix}.year`}
        as="span"
        className="error"
      />
    </div>
  </div>
);

export default DateInput;
