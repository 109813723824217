import React from 'react';

import DashboardTemplate from '../Templates/DashboardTemplate';
import ConfigureSettings from '../../components/dashboard-pages/ConfigureSettings/ConfigureSettings';

const AdminConfigureSettings = () => {
  return (
    <DashboardTemplate>
      <ConfigureSettings />
    </DashboardTemplate>
  );
};

export default AdminConfigureSettings;
