import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendMail, shippingMethod, step5Schema } from './step5ValidationSchema';
// STEPS
import RushServices from './InnerSteps/RushServices/RushServices';
import AdditionalAddresses from './InnerSteps/AdditionalAddresses/AdditionalAddresses';
import ReturnDocuments from './InnerSteps/ReturnDocuments/ReturnDocuments';
import AdditionalEmailPDFS from './InnerSteps/AdditionalEmailPDFS/AdditionalEmailPDFS';
// COMPONENTS
import FormDirections from '../../FormDirections/FormDirections';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';
import InputField from '../../InputField/InputField';
import ValidationErrors from '../../ValidationErrors/ValidationErrors';

// STYLES
import { StyledForm } from '../../FormComponents.styles';
import {
  servicesByCountry,
  returnServiceByType,
} from '../../../../shared/functions';
import Constants from '../../../../shared/Constants';

const Step5Form = ({ previousStep, onSubmit, context, send, formState }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      ...context.step5,
      returnDocsOtherAddressValidationInput:
        context.step5.otherRetunDocsAddress?.country || '',
    },
    resolver: yupResolver(step5Schema),
  });

  // ADDITIONAL DOCS ADDRESS FORM INITIANTION
  const {
    register: additionalDocsRegister,
    handleSubmit: additionalDocsHandleSubmit,
    reset: resetAdditonalDocsForm,
    formState: { errors: additionalDocserrors },
  } = useForm({ resolver: yupResolver(sendMail) });

  // ADDITIONAL DOCS SHIPPING METHOD INITIATION
  const {
    register: additionalDocsShippingRegister,
    handleSubmit: additionalDocsShippingHandleSubmit,
    formState: { errors: additionalShippingDocsErrors },
  } = useForm({
    resolver: yupResolver(shippingMethod),
  });
  // ADDITIONAL DOCS SHIPPING METHOD INITIATION
  const {
    register: returnDocsAddressRegister,
    handleSubmit: returnDocsAddressHandleSubmit,
    formState: { errors: returnDocsAddressErrors },
    // watch: returnDocsWatch,
  } = useForm({
    resolver: yupResolver(sendMail),
  });

  const watchAdditionalReportsMailedOption = watch(
    'additionalReportsMailedOption',
  );

  // useEffect(() => {
  //   const sub = watch(data => console.log(data));
  //   return () => sub.unsubscribe();
  // }, [watch]);

  // UPDATES THE ADDITIONAL REPORTS FIELD IN THE FORM WHEN CONTEXT CHANGES
  useEffect(() => {
    setValue(
      'additionalReportsMailedAddresses',
      context.step5.additionalReportsMailedAddresses,
    );
  }, [context.step5.additionalReportsMailedAddresses]);

  return (
    <StyledForm>
      <div className="temp-width">
        <FormDirections title="Delivery Instructions" time />

        <form
          onSubmit={additionalDocsHandleSubmit(data => {
            send('SAVE_TEMP_ADDITIONAL_ADDRESS', data);
            resetAdditonalDocsForm();
          })}
          id="additionalReportsForm"
        />
        <form
          onSubmit={additionalDocsShippingHandleSubmit(data => {
            send('SAVE_ADDRESS_TO_DB', data);
          })}
          id="addShippingToAdditionalAddressForm"
        />
        <form
          onSubmit={returnDocsAddressHandleSubmit(data => {
            const returnShippingAddressMethod = servicesByCountry(
              returnServiceByType(
                context.services,
                Constants.SERVICE.RETURNING_DOCUMENTS,
              ),
              data.mailAddress.country,
            )[0].id;
            const dataWithShipping = {
              ...data.mailAddress,
              shippingMethod: returnShippingAddressMethod,
            };
            setValue(
              'returnDocsOtherAddressValidationInput',
              data.mailAddress.country,
            );
            setValue('otherRetunDocsAddress', dataWithShipping);
            send('SAVE_TEMP_RETURN_DOCS_OTHER_ADDRESS', data);
          })}
          id="addReturnDocsAddressForm"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* where to send evaluation */}
          <hr />
          <div className="input-group">
            <p
              className="input-group--title input-group--bold"
              id="deliveryOptionApplicationServiceID_group"
            >
              {/* <span className="number">01. </span> */}
              Where would you like your report emailed?{' '}
              <span className="required">*</span>
            </p>
            <InputField
              register={register}
              dataRef="emailAddressToReturn"
              type="text"
              placeholder=""
              title="Email:"
              errors={errors}
            />
          </div>
          <hr />
          <h2 className="SF-segment-title SF-segment-title--big">
            Additional services
          </h2>
          <RushServices context={context} register={register} errors={errors} />
          <hr />
          <ReturnDocuments
            register={register}
            errors={errors}
            context={context}
            send={send}
            watch={watch}
            returnDocsAddressRegister={returnDocsAddressRegister}
            returnDocsAddressErrors={returnDocsAddressErrors}
            deleteAddress={() => {
              setValue('otherRetunDocsAddress', null);
              setValue('returnDocsOtherAddressValidationInput', '');
              send('DELETE_OTHER_RETURN_DOCS_ADDRESS');
            }}
          />
          <hr />
          <AdditionalAddresses
            register={register}
            errors={errors}
            watchItem={watchAdditionalReportsMailedOption}
            context={context}
            additionalDocsRegister={additionalDocsRegister}
            additionalDocserrors={additionalDocserrors}
            additionalDocsShippingRegister={additionalDocsShippingRegister}
            additionalShippingDocsErrors={additionalShippingDocsErrors}
            formState={formState}
            send={send}
          />
          <hr />
          <AdditionalEmailPDFS
            register={register}
            errors={errors}
            watch={watch}
            context={context}
            control={control}
          />
          <hr />
          <ValidationErrors errors={errors} />
          <hr />

          <NextPrevStepButtons submitButton prevStep={previousStep} />
        </form>
      </div>
    </StyledForm>
  );
};

export default Step5Form;
