import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

const RadioGroup = ({
  number,
  dataRef,
  title,
  required,
  stackedOptions,
  options,
  register,
  firstChecked,
  errors,
}) => {
  return (
    <div className="input-group">
      <p
        className="input-group--title input-group--bold"
        id={`${dataRef}_group`}
      >
        {number && <span className="number">{number} </span>}
        {title} {required && <span className="required">*</span>}
      </p>
      <div
        className={stackedOptions && 'input-group--stacked'}
        role="group"
        aria-labelledby={`${dataRef}_group`}
      >
        {options.map(({ label, value }, index) => (
          <div key={label}>
            <input
              id={`${dataRef}_${label}`}
              name={dataRef}
              type="radio"
              value={value !== '' ? value : label}
              {...register(dataRef)}
              defaultChecked={firstChecked && index === 0 && true}
            />
            <label
              className="radio-label"
              htmlFor={`${dataRef}_${label}`}
              title={title}
            >
              {label}
            </label>
          </div>
        ))}
      </div>
      <ErrorMessage
        errors={errors}
        name={dataRef}
        as="span"
        className="error"
      />
    </div>
  );
};

export default RadioGroup;
