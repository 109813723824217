import styled from 'styled-components';
import Block from '../../../UI/RoundedBlock/styles.RoundedBlock';
import { ButtonsCont } from '../../../../shared/styles';

export const CopyRequestStyled = styled.div`
  .__copyReq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 25px;
  }
  .__copyReq-details {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    margin-bottom: 15px;
    p {
      width: 50%;
      margin-bottom: 10px;
    }
  }
  .__copyReq-block-details {
    hr {
      margin: 20px 0;
    }
    p {
      margin-bottom: 10px;
    }
  }
  ${Block} {
    margin-bottom: 30px;
  }
  ${ButtonsCont} {
    margin-top: 30px;
    margin-left: -10px;
    button {
      min-width: 200px;
    }
  }
`;

export const EmailStyled = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0 !important;
  }
`;

export const AddressStyled = styled.div`
  padding: 20px 0;
`;
export default CopyRequestStyled;
