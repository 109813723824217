import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Modal from '../../Modal/Modal';
import RoundedBlock from '../../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../../UI/RoundedBlock/BlockTable/BlockTable';

import EditServiceForm from '../../form-components/EditServiceForm/EditServiceForm';
import DeleteServiceForm from './DeleteServiceContent';
import {
  GET_ALL_SERVICES,
  SERVICE_TYPES,
} from '../../../queries_mutations/queries';

import { ReactComponent as EditIcon } from '../../../assets/bolt.svg';
import { ReactComponent as TrashIcon } from '../../../assets/trashicon.svg';

const compare = (a, b) => {
  if (a.serviceType < b.serviceType) {
    return -1;
  }
  if (a.serviceType > b.serviceType) {
    return 1;
  }
  return 0;
};

const ServicesPage = () => {
  const { data, loading, errors } = useQuery(GET_ALL_SERVICES);
  const { data: serviceEnums, loading: serviceEnumsLoading } =
    useQuery(SERVICE_TYPES);
  let services = data ? [...data.services.all] : null;
  // SORTS SERVICES BY SERVICE TYPE
  if (services) {
    services = services.sort(compare);
  }
  const serviceDescriptions = serviceEnums?.ServiceType.enumValues;

  const [modalOpen, setModalOpen] = useState(false);
  const [addNewModalShow, setAddNewModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [deleteService, setDeleteService] = useState(null);

  const headerButtonHandler = () => {
    setAddNewModalShow(true);
  };
  const editService = id => {
    const serviceToEdit = {
      ...services.filter(service => service.id === id)[0],
    };
    setCurrentService(serviceToEdit);
    setModalOpen(true);
  };
  const deleteServiceHandler = id => {
    const serviceToDelete = {
      ...services.filter(service => service.id === id)[0],
    };
    setDeleteService(serviceToDelete);
    setDeleteModalShow(true);
  };
  const tableHeaders = ['SERVICE NAME', 'PRICE', 'SERVICE TYPE', 'ACTION'];
  return (
    <RoundedBlock
      title="Manage Services"
      btnHandler={headerButtonHandler}
      btnText="Add Service"
    >
      {errors && <p>error occured</p>}
      {modalOpen && (
        <Modal
          setModal={setModalOpen}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <h2>Change service info</h2>
          <EditServiceForm
            setModalOpen={setModalOpen}
            currentService={currentService}
            serviceEnum={serviceDescriptions}
          />
        </Modal>
      )}
      {deleteModalShow && (
        <Modal
          setModal={setDeleteModalShow}
          open={deleteModalShow}
          onClose={() => setDeleteModalShow(false)}
        >
          <DeleteServiceForm
            deleteService={deleteService}
            serviceDescriptions={serviceDescriptions}
            setShowHideModal={setDeleteModalShow}
          />
        </Modal>
      )}
      {addNewModalShow && (
        <Modal
          setModal={setAddNewModalShow}
          open={addNewModalShow}
          onClose={() => setAddNewModalShow(false)}
        >
          <h2>Add service</h2>
          <EditServiceForm
            setModalOpen={setAddNewModalShow}
            serviceEnum={serviceDescriptions}
            newForm
          />
        </Modal>
      )}
      <BlockTable headers={tableHeaders}>
        {loading || serviceEnumsLoading ? (
          <tr>
            <td>Loading...</td>
          </tr>
        ) : (
          services.map(({ displayName, id, price, serviceType }) => {
            return (
              <tr key={displayName + serviceType}>
                <td>{displayName}</td>
                <td className="__price">&#36;{price}</td>
                <td>
                  {
                    serviceDescriptions.find(
                      service => service.name === serviceType,
                    ).description
                  }
                </td>
                <td className="__actions">
                  <button
                    type="button"
                    aria-label="Delete Service"
                    onClick={() => deleteServiceHandler(id)}
                  >
                    <TrashIcon />
                  </button>
                  <button
                    type="button"
                    aria-label="Edit Service"
                    onClick={() => editService(id)}
                  >
                    <EditIcon />
                    Edit
                  </button>
                </td>
              </tr>
            );
          })
        )}
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td />

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td />

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td />

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td />
        </tr>
      </BlockTable>
    </RoundedBlock>
  );
};

export default ServicesPage;
