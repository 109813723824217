export default {
  paymentNotice: (
    <div>
      Due to a temporary problem with our payment system, some applications are
      not being seen upon submission. Please contact{' '}
      <a href="mailto:mwr@gceus.com?subject=Application%20Payment%20Verification">
        mwr@gceus.com
      </a>{' '}
      after you have completed payment, in order to ensure that your application
      is fully processed.
    </div>
  ),
  serviceUnavailable: (
    <div>
      We're currently not accepting rush applications. If you have questions
      please contact{' '}
      <a href="mailto:gce@gceus.com?subject=Rush%20Application%20Request">
        gce@gceus.com
      </a>{' '}
      . Thank you for your patience.
    </div>
  ),
};
