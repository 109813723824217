import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import InputField from '../../../InputField/InputField';
import termsSchema from './termsValidationSchema';
import TermsOfService from './styles.termsOfService';

import { Button } from '../../../../../shared/styles';

const Termsofservice = ({ send, agreed, setIsOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(termsSchema),
  });
  return (
    <TermsOfService>
      <p className="__title">Terms of Service</p>
      <p className="__bolded">
        You, the applicant, must read and agree to the terms of service.
      </p>
      <p>
        <span className="__number">01.</span> <strong>I certify</strong> I have
        read ALL of the instructions and I accept the terms and conditions
        stated therein.
      </p>
      <p>
        <span className="__number">02.</span> <strong>I certify</strong> all of
        the information provided in this application is complete and correct.
      </p>
      <p>
        <span className="__number">03.</span> <strong>I certify</strong> all
        required documents, as outlined on the website, have been submitted in
        the native language with English translations.
      </p>
      <p>
        <span className="__number">04.</span> <strong>I understand</strong>{' '}
        Global Credentials Evaluators, Inc. will <strong>NOT</strong> contact
        applicant for additional documentation.
      </p>
      <p>
        <span className="__number">05.</span> <strong>I authorize</strong>{' '}
        Global Credentials Evaluators, Inc. to verify all submitted educational
        credentials in order to conduct a thorough and professional credential
        evaluation.
      </p>
      <p>
        <span className="__number">06.</span> <strong>I may </strong> cancel my
        application within 5 business days of submission for a refund of 50% of
        the total cost.{' '}
        <strong>After 6 business days, there are no refunds.</strong>
      </p>
      <p>
        <span className="__number">07.</span> <strong>I understand</strong>{' '}
        Global Credential Evaluators, Inc. evaluation reports are ADVISORY ONLY
        and are NOT binding on any agency, institution, or organization.
      </p>
      <p>
        <span className="__number">08.</span> <strong>I release</strong> Global
        Credential Evaluators, Inc. from any liability for damages incurred by
        me, by my representative, or by any third party, resulting from the use
        of an evaluation report by me or any third party.
      </p>
      <p>
        <span className="__number">09.</span> <strong>I understand</strong> a
        request for review using documents submitted with the original
        application, must be requested in writing within 45 business days of
        completion of the evaluation.
      </p>
      <p>
        <span className="__number">10.</span> <strong>I understand</strong> the
        original application and academic records will be available for three
        (3) years.
      </p>
      <p className="__underText">
        You, the applicant, are required to submit the application completed in
        full with the appropriate fee and all required documentation for
        education being evaluated.
      </p>
      <hr />
      {!agreed && (
        <>
          <p className="__bolded">
            By initialing below and submitting this form, I acknowledge reading
            the application instructions in full and adhere to the regulations
            and requirements within the application and website.
          </p>
          <form
            onSubmit={handleSubmit(data => {
              send('ACCEPT_TERMS', data);
              setIsOpen(false);
            })}
          >
            <InputField
              register={register}
              dataRef="initials"
              errors={errors}
              required
              title="Initials"
              type="text"
            />
            <hr />
            <Button type="submit">Agree</Button>
          </form>
        </>
      )}
    </TermsOfService>
  );
};

export default Termsofservice;
