import React from 'react';
import { Navigate } from 'react-router-dom';

// import ProfileRedirect from '../components/auth/ProfileRedirect';

const LoginSuccess = () => {
  // return <ProfileRedirect />;
  return <Navigate to="/dashboard" />;
};

export default LoginSuccess;
