import React from 'react';
import { useParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { debug } from '../../../envConfig';
import evaluationReviewMachine from './EvaluationRootMachine';
import Overview from './Overview/Overview';
import ApplicantInfo from './ApplicantInfo/ApplicantInfo';
import EditApplicationDetails from './EditApplicationDetails/EditApplicationDetails';
import EditEvaluationDetails from './EditEvaluationDetails/EditEvaluationDetails';

const EvaluationReview = () => {
  const { id } = useParams();
  const [formState, send] = useMachine(evaluationReviewMachine(id), {
    devTools: debug.xstate,
  });
  const {
    context: { services, application, admins },
  } = formState;
  let activeStep;
  switch (true) {
    case formState.matches('overview'):
      activeStep = (
        <Overview
          services={services}
          application={application}
          admins={admins}
          send={send}
          formState={formState}
        />
      );
      break;
    case formState.matches('applicantInfo'):
      activeStep = (
        <ApplicantInfo
          services={services}
          application={application}
          admins={admins}
          send={send}
        />
      );
      break;
    case formState.matches('editApplicationDetails'):
      activeStep = (
        <EditApplicationDetails
          application={application}
          send={send}
          formState={formState}
        />
      );
      break;
    case formState.matches('editEvaluationDetails'):
      activeStep = (
        <EditEvaluationDetails
          application={application}
          send={send}
          formState={formState}
        />
      );
      break;
    case formState.matches('init'):
      activeStep = <p>Loading Evaluation...</p>;
      break;
    default:
      activeStep = <p>There has been error. Please reload and try again</p>;
      break;
  }
  return <>{activeStep}</>;
};

export default EvaluationReview;
