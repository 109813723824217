/* eslint-disable import/prefer-default-export */
import { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ME } from '../queries_mutations/user/queries';

export function useUser() {
  const [user, setUser] = useState(null);
  const { data, loading } = useQuery(GET_ME);

  const contextValue = useMemo(() => {
    if (loading) return null;

    setUser(data?.users.me === null ? undefined : data.users.me);

    return user;
  }, [data, user]);

  return contextValue;
}
