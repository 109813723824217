import * as yup from 'yup';

const upgradeRushSchema = yup.object().shape({
  shippingMethod: yup
    .string()
    .typeError('Please select a shipping method')
    .required('Please select a shipping method'),
});

export default upgradeRushSchema;
