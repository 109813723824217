import React from 'react';
import styled from 'styled-components';

const Pagination = ({
  pages,
  itemsToShow,
  currentPage,
  itemsArray,
  allItemsCount,
  send,
}) => {
  if (pages <= 1) {
    return null;
  }
  return (
    <PaginationStyled>
      <p className="__showing">
        showing {itemsToShow * currentPage - (itemsToShow - 1)} -{' '}
        {currentPage === pages ? itemsArray.length : currentPage * itemsToShow}{' '}
        of {allItemsCount}
      </p>
      <div className="__buttons">
        <button
          type="button"
          onClick={() => send('PREV_PAGE')}
          disabled={currentPage === 1}
          className="__prev"
        >
          Previous
        </button>
        {[...Array(pages)].map((_, i) => (
          <button
            type="button"
            key={Math.random()}
            onClick={() => send('GO_TO_PAGE', { page: i + 1 })}
            className={currentPage === i + 1 ? 'active __page' : '__page'}
          >
            {i + 1}
          </button>
        ))}
        <button
          type="button"
          onClick={() => send('NEXT_PAGE')}
          disabled={currentPage === pages}
          className="__next"
        >
          Next
        </button>
      </div>
    </PaginationStyled>
  );
};

export default Pagination;

const PaginationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 30px;
  background-color: #edf2f9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  .__showing {
    font-size: 15px;
    font-style: italic;
  }
  .__buttons {
    button {
      background-color: transparent;
      border: none;
      transition: all ease 0.3s;
      cursor: pointer;
      font-size: 15px;
      margin: 0 5px;
      &:hover,
      &:active,
      &:focus {
        color: #14527f;
      }
      &.__prev,
      &.__next {
        font-style: italic;
      }
      &.__page {
        background-color: #edf2f7;
        border-radius: 2px;
        width: 20px;
        height: 23px;
        &.active,
        &:hover,
        &:active,
        &:focus {
          background-color: #14527f;
          color: white;
        }
      }
    }
  }
`;
