import React from 'react';
// import styled from 'styled-components';
import InputField from '../InputField/InputField';
import FormDropdown from '../FormDropdown/FormDropdown';
// import RadioGroup from '../RadioGroup/RadioGroup';

const AddressForm = ({
  register,
  errors,
  countryValue,
  yupObjectName,
  company,
  form,
}) => {
  return (
    <>
      {company && (
        <>
          <InputField
            register={register}
            dataRef={`${yupObjectName}.company`}
            type="text"
            placeholder=""
            title="Company"
            errors={errors}
            form={form}
          />
          <InputField
            register={register}
            dataRef={`${yupObjectName}.contact`}
            type="text"
            placeholder=""
            title="Contact"
            errors={errors}
            required
            form={form}
          />
        </>
      )}
      <InputField
        register={register}
        dataRef={`${yupObjectName}.line1`}
        type="text"
        placeholder=""
        title="Personal address (line 1)"
        errors={errors}
        required
        form={form}
      />
      <InputField
        register={register}
        dataRef={`${yupObjectName}.line2`}
        type="text"
        title="Personal address (line 2)"
        errors={errors}
        form={form}
      />
      <div className="sbs">
        <InputField
          register={register}
          dataRef={`${yupObjectName}.city`}
          type="text"
          title="City"
          errors={errors}
          required
          form={form}
        />
        <InputField
          register={register}
          dataRef={`${yupObjectName}.state`}
          type="text"
          title="State/Province (U.S. Required)"
          errors={errors}
          form={form}
        />
      </div>
      <div className="sbs">
        <InputField
          register={register}
          dataRef={`${yupObjectName}.postalCode`}
          type="text"
          title="Zip/Postal (U.S. Required)"
          errors={errors}
          form={form}
        />
        <FormDropdown
          dataRef={`${yupObjectName}.country`}
          register={register}
          errors={errors}
          title="Country"
          content="country"
          required
          defaultValue={countryValue}
          form={form}
        />
      </div>
    </>
  );
};

export default AddressForm;
