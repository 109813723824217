import styled from 'styled-components';

export const Hr = styled.hr`
  margin: 30px 0;
`;

export const ListBreak = styled.hr`
  margin: 30px auto;
  max-width: 75%;
`;

export const Address = styled.div``;
