import React, { useState } from 'react';
import {
  DropdownLink,
  DropdownOption,
  LinkIcon,
  LinkText,
  SubMenu,
  OptionContainer,
  SubLinkBar,
  SidebarLink,
} from './styles';

const Submenu = ({ item }) => {
  const [showSubNav, setShowSubNav] = useState(false);
  const toggleSubNav = () => {
    setShowSubNav(!showSubNav);
  };
  if (item.subNav) {
    return (
      <DropdownOption
        className={showSubNav ? `DropDownOption active` : `DropDownOption`}
        onClick={() => toggleSubNav()}
        showSubNav={showSubNav}
      >
        <OptionContainer className="OptionContainer">
          <LinkIcon className="LinkIcon">{item.icon}</LinkIcon>
          <LinkText className="LinkText">{item.title}</LinkText>
        </OptionContainer>

        <SubMenu className="SubMenu" showSubNav={showSubNav}>
          {showSubNav &&
            item.subNav.map(dropdownItem => {
              return (
                <DropdownLink
                  className="DropDownLink"
                  key={dropdownItem.path}
                  to={dropdownItem.path}
                >
                  <OptionContainer className="OptionContainer">
                    <SubLinkBar className="SubLinkBar" />
                    <LinkText className="LinkText">
                      {dropdownItem.title}
                    </LinkText>
                  </OptionContainer>
                </DropdownLink>
              );
            })}
        </SubMenu>
      </DropdownOption>
    );
  }
  return (
    <SidebarLink className="SidebarLink" to={item.path}>
      <LinkIcon>{item.icon}</LinkIcon>
      <LinkText>{item.title}</LinkText>
    </SidebarLink>
  );
};

export default Submenu;
