import React from 'react';
import { useMachine } from '@xstate/react';
import { debug } from '../../../envConfig';
import createEvaluationListMachine from './EvaluationsListMachineServices';

import RoundedBlock from '../../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../../UI/RoundedBlock/BlockTable/BlockTable';
import EvaluationRowItem from './EvaluationRowItem';
import Pagination from '../../UI/Pagination/Pagination';

// functions
import { sortByDate, sortByName } from '../../../shared/functions';

const AllEvaluations = showMy => {
  const tableHeaders = [
    {
      title: 'REF #',
      sortable: true,
      send: 'SORT_REF_NUM',
      context: 'sortRef',
    },
    {
      title: 'DATE PAID',
      sortable: true,
      send: 'SORT_DATE_PAID',
      context: 'sortDatePaid',
    },
    {
      title: 'NAME',
      sortable: true,
      send: 'SORT_NAME',
      context: 'sortName',
    },
    { title: '# OF APPLICATIONS', sortable: false },
    { title: 'DOB', sortable: false },
    {
      title: 'ASSIGNED TO',
      sortable: true,
      send: 'SORT_ASSIGNED_TO',
      context: 'sortAssignedTo',
    },
    {
      title: 'STATUS',
      sortable: true,
      send: 'SORT_STATUS',
      context: 'sortStatus',
    },
    { title: 'ACTION', sortable: false },
  ];

  const { showMy: showMyBool } = showMy;

  const [formState, send] = useMachine(
    createEvaluationListMachine(showMyBool),
    {
      devTools: debug.xstate,
    },
  );

  // SEARCH FUNCTION

  const {
    items: evaluationsArray,
    admins,
    pages,
    itemsToShow,
    currentPage,
    allEvaluationsCount,
    sortRef,
    sortDatePaid,
    sortName,
    sortAssignedTo,
    sortStatus,
    services,
    message,
  } = formState.context;

  // Message if there are no evaluations on load or if search result is 0
  const outputMessage = message || 'There are evaluations at this time.';
  const noRushService = services?.filter(s =>
    s.displayName.toLowerCase().includes('no rush'),
  );
  // SORTING

  // create array of items that will be in the list
  let itemsDisplayed = [];
  // SORTING
  let sortedItemArray = [...evaluationsArray];
  if (sortRef !== null) {
    sortedItemArray = sortedItemArray.sort((a, b) =>
      sortByName(a.refNumber, b.refNumber, sortRef),
    );
  }
  if (sortDatePaid !== null) {
    sortedItemArray = sortedItemArray.sort((a, b) =>
      sortByDate(a, b, sortDatePaid, 'paidDate'),
    );
  }
  if (sortName !== null) {
    sortedItemArray = sortedItemArray.sort((a, b) =>
      sortByName(a.applicantInfo.lastName, b.applicantInfo.lastName, sortName),
    );
  }
  if (sortAssignedTo !== null) {
    sortedItemArray = sortedItemArray.sort((a, b) =>
      sortByName(a.evaluator.lastName, b.evaluator.lastName, sortAssignedTo),
    );
  }
  if (sortStatus !== null) {
    sortedItemArray = sortedItemArray.sort((a, b) =>
      sortByName(a.status, b.status, sortStatus),
    );
  }
  // WHICH APPLICATIONS TO SHOW ON THE PAGE

  switch (true) {
    case currentPage !== 1:
      itemsDisplayed = sortedItemArray.slice(
        (currentPage - 1) * itemsToShow,
        currentPage * itemsToShow,
      );
      break;

    default:
      itemsDisplayed = sortedItemArray.slice(0, itemsToShow);
      break;
  }

  if (formState.matches('loadingFailed')) {
    return (
      <RoundedBlock
        title={showMyBool ? 'My Evaluations' : 'All Evaluations'}
        btnText="Search"
        hasSearch
        hasPagination
        send={send}
        customSearch={showMyBool && 'SEARCH_MY'}
      >
        <p>Loading failed...</p>
      </RoundedBlock>
    );
  }
  if (formState.matches('init')) {
    return (
      <RoundedBlock
        title={showMyBool ? 'My Evaluations' : 'All Evaluations'}
        btnText="Search"
        hasSearch
        hasPagination
        send={send}
        customSearch={showMyBool && 'SEARCH_MY'}
      >
        <p>Loading...</p>
      </RoundedBlock>
    );
  }
  if (formState.matches('loadingFailed')) {
    return (
      <RoundedBlock
        title={showMyBool ? 'My Evaluations' : 'All Evaluations'}
        btnText="Search"
        hasSearch
        hasPagination
        send={send}
        customSearch={showMyBool && 'SEARCH_MY'}
      >
        <p>There was a problem loading data</p>
      </RoundedBlock>
    );
  }
  return (
    <>
      <RoundedBlock
        title={showMyBool ? 'My Evaluations' : 'All Evaluations'}
        btnText="Search"
        hasSearch
        hasPagination
        send={send}
        customSearch={showMyBool && 'SEARCH_MY'}
      >
        {!(evaluationsArray.length > 0) ? (
          <p>{outputMessage}</p>
        ) : (
          <BlockTable
            headers={tableHeaders}
            sortable
            send={send}
            xStateContext={formState.context}
          >
            {itemsDisplayed.map(
              ({
                paidDate,
                applicantInfo,
                applicant,
                evaluator,
                status,
                refNumber,
                id,
                deliveryInstructions,
                addedRush,
              }) => {
                const e = {
                  paidDate,
                  applicant,
                  applicantInfo,
                  evaluator,
                  status,
                  refNumber,
                  id,
                  deliveryInstructions,
                  addedRush,
                };
                return (
                  <EvaluationRowItem
                    key={id}
                    admins={admins}
                    item={e}
                    send={send}
                    noRushServiceId={noRushService[0]?.id || []}
                  />
                );
              },
            )}
          </BlockTable>
        )}
        {formState.matches('updateEvaluator') && <p>Saving...</p>}
        {formState.matches('updateStatus') && <p>Saving...</p>}
      </RoundedBlock>
      <Pagination
        pages={pages}
        itemsToShow={itemsToShow}
        currentPage={currentPage}
        itemsArray={evaluationsArray}
        allItemsCount={allEvaluationsCount}
        send={send}
      />
    </>
  );
};

export default AllEvaluations;
