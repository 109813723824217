import React from 'react';
import { useMutation } from '@apollo/client';
import DeleteServiceContent from './styles';
import { Button } from '../../../shared/styles';

import { DELETE_SERVICE } from '../../../queries_mutations/mutations';
import { GET_ALL_SERVICES } from '../../../queries_mutations/queries';

const DeleteServiceForm = ({
  deleteService,
  serviceDescriptions,
  setShowHideModal,
}) => {
  const [deleteServiceFunc] = useMutation(DELETE_SERVICE);

  const deleteServiceHandler = id => {
    setShowHideModal(false);
    deleteServiceFunc({
      variables: {
        serviceId: id,
      },
      refetchQueries: [
        {
          query: GET_ALL_SERVICES,
        },
      ],
    });
  };
  return (
    <DeleteServiceContent>
      <h2>Are you sure you want to delete this service?</h2>
      <p>
        Name: <span>{deleteService.displayName}</span>
      </p>
      <p>
        Price: <span>{`$${deleteService.price}`}</span>
      </p>
      <p>
        Service Type:{' '}
        <span>
          {
            serviceDescriptions.find(
              service => service.name === deleteService.serviceType,
            ).description
          }
        </span>
      </p>
      <Button
        className="btn btn--blue"
        type="button"
        onClick={() => deleteServiceHandler(deleteService.id)}
      >
        Confirm Delete
      </Button>
    </DeleteServiceContent>
  );
};

export default DeleteServiceForm;
