import React, { useState } from 'react';
import dayjs from 'dayjs';
import Button from '../../../../UI/Button';
import Modal from '../../../../Modal/Modal';
import Termsofservice from './TermsOfService';
import { ReactComponent as CheckMarkIcon } from '../../../../../assets/checkbox.svg';

const Terms = ({ send, formState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { termsAgreementInitials, termsSignedDate } = formState.context;
  return (
    <div className="section">
      <Modal
        setModal={setIsOpen}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        modalTitle="Terms of service"
      >
        <Termsofservice
          send={send}
          agreed={termsSignedDate && true}
          setIsOpen={setIsOpen}
        />
      </Modal>
      <div className="section__header">
        <h2>06. Terms and submission</h2>
        <Button
          classes={!termsSignedDate && 'anim-flashing-Button'}
          onClick={() => {
            setIsOpen(true);
          }}
          text="Terms"
        />
      </div>
      <hr />
      <div className="section__content">
        <div className="section__content-block">
          {termsSignedDate ? (
            <p>
              <CheckMarkIcon className="checkbox_icon" />I have read and agree
              to the terms.
            </p>
          ) : (
            <p>You must read and agree to the terms.</p>
          )}
          <p>
            <strong>Initials</strong> {termsAgreementInitials || '--'}
          </p>
          <p>
            <strong>Affirmation date</strong>
            {termsSignedDate
              ? dayjs(termsSignedDate).format('MM/DD/YYYY h:mm:ss A')
              : '--'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
