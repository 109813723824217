import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Need to have indicator check state machine for number of steps to load dynamically

const FormStepIndicator = ({ formState, send }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const {
    step1: { complete: step1Complete },
    step2: { complete: step2Complete },
    step3: { complete: step3Complete },
    step4: { complete: step4Complete },
    step5: { complete: step5Complete },
  } = formState.context;

  useEffect(() => {
    switch (true) {
      case formState.matches('editing.stepOne'):
        setCurrentStep(1);
        break;

      case formState.matches('editing.stepTwo'):
        setCurrentStep(2);
        break;

      case formState.matches('editing.stepThree'):
        setCurrentStep(3);
        break;

      case formState.matches('editing.stepFour'):
        setCurrentStep(4);
        break;

      case formState.matches('editing.stepFive'):
        setCurrentStep(5);
        break;
      default:
        break;
    }
  }, [formState.value]);

  const steps = 5;
  const createStepButtons = () => {
    const content = [];
    for (let i = 1; i <= steps; i += 1) {
      let sendTo = '';
      let stepComplete;
      switch (i) {
        case 1:
          sendTo = 'STEP_ONE';
          stepComplete = step1Complete;
          break;
        case 2:
          sendTo = 'STEP_TWO';
          stepComplete = step2Complete;
          break;
        case 3:
          sendTo = 'STEP_THREE';
          stepComplete = step3Complete;
          break;
        case 4:
          sendTo = 'STEP_FOUR';
          stepComplete = step4Complete;
          break;
        case 5:
          sendTo = 'STEP_FIVE';
          stepComplete = step5Complete;
          break;

        default:
          break;
      }
      content.push(
        <button
          key={i}
          className={`dash ${currentStep === i ? 'active' : ''} ${
            stepComplete && 'completed'
          }`}
          step={`${i}`}
          type="button"
          aria-label={`step ${i}`}
          onClick={() => send(sendTo)}
          disabled={!stepComplete}
        />,
      );
    }
    return content;
  };
  const buttons = createStepButtons();
  return (
    <FormSteps className="form-step-indicator">
      <p className="numbers">
        Step <span>0{currentStep}</span>/0{steps}
      </p>
      <div className="dashes">{buttons.map(button => button)}</div>
    </FormSteps>
  );
};

export default FormStepIndicator;

const FormSteps = styled.div`
  display: flex;
  align-items: center;
  max-width: 430px;
  margin: 0 auto;
  max-width: 550px;
  justify-content: center;
  @media only screen and (max-width: 500px) {
    padding: 30px;
  }
  .numbers {
    margin-right: 20px;
    color: #14527f;
    font-weight: 700;
    font-size: 25px;
    span {
      color: #68b6ec;
    }
  }
  .dashes {
    display: flex;
    align-items: center;
    .dash {
      width: 43px;
      height: 30px;
      margin: 0 5px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      &:before {
        display: flex;
        margin: 0 auto;
        content: '';
        width: 100%;
        height: 5px;
        background-color: #e2e8f0;
        outline: solid #e2e8f0 1px;
      }
      &.completed:before {
        background-color: #36c55e;
        outline: #36c55e;
      }
      &.active:before {
        background-color: #68b6ec;
        outline: solid #68b6ec 1px;
      }
      &:focus:before {
        outline: solid #68b6ec 1px;
      }
    }
  }
`;
