import { assign, Machine } from 'xstate';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';

import rootMachineConfig from '../../../Machines/ConfigureSettingsMachineConfig';
import configureSettingsQueries from '../../../queries_mutations/ConfigureSettings/queries';
import configureSettingsMutations from '../../../queries_mutations/ConfigureSettings/mutations';

import { constructSelectObject } from '../../../shared/functions';

function createServices() {
  const client = useApolloClient();
  const { queryInit } = configureSettingsQueries(client);
  const { submitForm } = configureSettingsMutations(client);

  return {
    guards: {},

    services: {
      loadContext: () => {
        return queryInit();
      },
      saveForm: (ctx, data) => {
        return submitForm(data, ctx.workflow);
      },
    },

    actions: {
      loadContext: assign({
        workflow: (_, e) => e.data.workflow,
        admins: (_, e) => constructSelectObject(e.data.admins),
        newAppHandler: (_, e) =>
          e.data.workflow.find(x => x.name === 'newAppHandler')?.adminId || '',
        copyRequestHandler: (_, e) =>
          e.data.workflow.find(x => x.name === 'copyRequestHandler')?.adminId ||
          '',
        mailAdminHandler: (_, e) =>
          e.data.workflow.find(x => x.name === 'mailAdminHandler')?.adminId ||
          '',
        completedFraud: (_, e) =>
          e.data.workflow.find(x => x.name === 'completedFraud')?.adminId || '',
        enableRushService: (_, e) => e.data.rushActive,
      }),
      updateContext: assign({
        newAppHandler: (_, { data }) => data.newAppHandler || '',
        copyRequestHandler: (_, { data }) => data.copyRequestHandler || '',
        mailAdminHandler: (_, { data }) => data.mailAdminHandler || '',
        completedFraud: (_, { data }) => data.completedFraud || '',
        enableRushService: (_, e) => e.data.enableRushService,
      }),

      logError: (_, e) => {
        Sentry.captureException(new Error(e.data));
      },
    },
  };
}

function create() {
  const services = createServices();
  return Machine({ ...rootMachineConfig() }, { ...services });
}

export default create;
