import React from 'react';
import styled from 'styled-components';

const Group = styled.div`
  label {
    display: flex !important;
    align-items: center;
  }
  input {
    width: unset;
    margin-right: 10px;
  }
  .bold {
    font-weight: 700;
  }
`;

const FormCheckbox = ({ register, name, title, id, number, checkboxText }) => (
  <Group className="input-group">
    <p className="input-group--title input-group--bold">
      {number && <span className="number">{number}</span>}
      {title}
    </p>
    <label htmlFor={id}>
      <input {...register(name)} type="checkbox" id={id} name={name} />
      {checkboxText && (
        <p>
          {checkboxText.text}{' '}
          <span className="bold">{checkboxText.boldText}</span>
        </p>
      )}
    </label>
  </Group>
);

export default FormCheckbox;
