import { assign } from 'xstate';
import {
  ADD_ADDITIONAL_REPORT_ADDRESS,
  DELETE_ADDITIONAL_REPORT_ADDRESS,
  UPDATE_DELIVERY_INSTRUCTIONS,
} from '../../../../queries_mutations/mutations';
import {
  servicesByCountry,
  returnServiceByType,
} from '../../../../shared/functions';
import Constants from '../../../../shared/Constants';

function createGaurds() {
  return {};
}

function createServices(client) {
  return {
    updateDeliveryInstructions: (ctx, e) => {
      const {
        emailAddressToReturn,
        rushApplicationServiceID,
        returnDocumentsOption,
        additionalPDFOption,
        emailArray: additionalPDFEmails,
        returnDocumentsEmail,
      } = e.data;
      let returnDocsEmailAddressCheck = returnDocumentsEmail;
      // IF RETURNDOCUMENTSOPTION IS "NOT REQUIRED" THEN RETURNDOCUMENTSEMAIL SHOULD BE "NULL"
      if (returnDocumentsOption !== Constants.ADDRESS.EMAIL) {
        returnDocsEmailAddressCheck = null;
      }
      // SETTING RETURN DOCS MAIL ADDRESS BY THE OPTION CHOSEN IN ADDITIONAL SERVICES STEP 2
      let returnDocsMailAddress;
      const addressOnFileShippingLocation = servicesByCountry(
        returnServiceByType(
          ctx.services,
          Constants.SERVICE.RETURNING_DOCUMENTS,
        ),
        ctx.addressOnFile.country,
      )[0].id;
      switch (returnDocumentsOption) {
        case Constants.ADDRESS.ADDRESS_ON_APPLICATION:
          returnDocsMailAddress = {
            ...ctx.addressOnFile,
            shippingMethod: addressOnFileShippingLocation,
          };
          break;
        case Constants.ADDRESS.OTHER_ADDRESS:
          returnDocsMailAddress = { ...ctx.step5.otherRetunDocsAddress };
          break;

        default:
          returnDocsMailAddress = undefined;
          break;
      }

      // RETURN DOCUMENTS BY EMAIL:
      const mutationDataObject = {
        id: ctx.step5.id,
        emailAddressToReturn,
        rushApplicationServiceID,
        returnDocumentsOption,
        returnDocumentsAddress: returnDocsMailAddress,
        additionalPDFOption,
        additionalPDFEmails: JSON.stringify(additionalPDFEmails) || undefined,
        addressOnFileID: ctx.addressOnFile.id,
        returnDocumentsEmail: returnDocsEmailAddressCheck || null,
      };

      return client
        .mutate({
          mutation: UPDATE_DELIVERY_INSTRUCTIONS,
          variables: {
            appId: ctx.applicationID,
            data: mutationDataObject,
          },
        })
        .then((data, error) => {
          if (error) {
            // do we throw?
          }
          return data.data.applications.application.updateDeliveryInstructions
            .id;
        });
    },
    saveAdditionalAddressToDB: (ctx, e) => {
      const mailAddress = {
        ...ctx.step5.tempAdditionalAddress,
        shippingMethod: e.shippingMethod,
      };
      return client
        .mutate({
          mutation: ADD_ADDITIONAL_REPORT_ADDRESS,
          variables: {
            appId: ctx.applicationID,
            address: mailAddress,
            instructionsId: ctx.step5.id,
          },
        })
        .then(data => {
          return data.data.applications.application.addAdditionalReportAddress;
        });
    },
    deleteAdditionalReportsAddress: (ctx, e) => {
      return client
        .mutate({
          mutation: DELETE_ADDITIONAL_REPORT_ADDRESS,
          variables: {
            appId: ctx.applicationID,
            addressId: e.id,
            instructionsId: ctx.step5.id,
          },
        })
        .then(data => {
          return data.data.applications.application
            .deleteAdditionalReportAddress;
        });
    },
  };
}
// --------------------------------ACTIONS-------------------------------------

function createActions() {
  return {
    loadStep5DataIntoContext: assign({
      step5: (_, e) => {
        const {
          additionalReportsMailedAddresses,
          id,
          emailAddressToReturn,
          returnDocumentsAddress,
          returnDocumentsOption,
          rushApplicationServiceID,
          additionalPDFOption,
          additionalPDFEmails,
          returnDocumentsEmail,
        } = e.data.deliveryInstructions;

        let otherRetunDocsAddress;
        if (returnDocumentsOption === Constants.ADDRESS.OTHER_ADDRESS) {
          otherRetunDocsAddress = {
            ...returnDocumentsAddress,
            __typename: undefined,
          };
        }

        // SET COMPLETE TO TRUE IF ALL FIELDS HAVE BEEN SET ON DELIVERY INSTRUCTIONS. THIS WILL ALLOW THE STEP TO BE COMPLETED
        let complete = false;
        if (rushApplicationServiceID && returnDocumentsOption) complete = true;

        return {
          id: id || '',
          additionalReportsMailedOption:
            additionalReportsMailedAddresses.length > 0 ? 'Yes' : 'No',
          additionalReportsMailedAddresses:
            additionalReportsMailedAddresses || undefined,
          emailAddressToReturn,
          otherRetunDocsAddress,
          returnDocumentsOption,
          rushApplicationServiceID,
          additionalPDFOption,
          additionalPDFEmails: additionalPDFEmails
            ? JSON.parse(additionalPDFEmails)
            : [],
          complete,
          returnDocumentsEmail: returnDocumentsEmail || '',
        };
      },
    }),
    saveOtherMailAddressToCTX: assign({
      step5: (ctx, { data }) => {
        return {
          ...ctx.step5,
          otherMailAddress: data.mailAddress,
        };
      },
    }),
    deleteOtherMailAddressfromCTX: assign({
      step5: ctx => {
        return {
          ...ctx.step5,
          otherMailAddress: undefined,
        };
      },
    }),

    // ADDITIONAL ADDRESSES ACTIONS
    saveTempAddressToCTX: assign({
      step5: (ctx, { mailAddress }) => {
        return {
          ...ctx.step5,
          tempAdditionalAddress: mailAddress,
        };
      },
    }),
    deleteTempAdditionalAddress: assign({
      step5: ctx => {
        return {
          ...ctx.step5,
          tempAdditionalAddress: undefined,
        };
      },
    }),
    addShippingToTempAddressInCTX: assign({
      step5: (ctx, { shippingMethod }) => {
        return {
          ...ctx.step5,
          tempAdditionalAddress: {
            ...ctx.step5.tempAdditionalAddress,
            shippingMethod,
          },
        };
      },
    }),

    saveTempReturnDocsOtherAddress: assign({
      step5: (ctx, data) => {
        const shippingMethod = servicesByCountry(
          returnServiceByType(
            ctx.services,
            Constants.SERVICE.RETURNING_DOCUMENTS,
          ),
          data.mailAddress.country,
        )[0].id;
        return {
          ...ctx.step5,
          otherRetunDocsAddress: {
            ...data.mailAddress,
            shippingMethod,
          },
        };
      },
    }),
    deleteTempReturnDocsOtherAddress: assign({
      step5: ctx => {
        return {
          ...ctx.step5,
          otherRetunDocsAddress: undefined,
        };
      },
    }),
    setAdditionalReportsToCTX: assign({
      step5: (ctx, e) => {
        return {
          ...ctx.step5,
          additionalReportsMailedAddresses: e.data,
          tempAdditionalAddress: {},
        };
      },
    }),
    saveStep5ToContext: assign({
      step5: (ctx, e) => {
        return {
          ...ctx.step5,
          complete: true,
          ...e.data,
          additionalPDFEmails: e.data?.emailArray || [],
          emailArray: undefined, // set email array back to zero
        };
      },
    }),
  };
}
function create(client) {
  return {
    actions: createActions(),
    services: createServices(client),
    guards: createGaurds(),
  };
}

export default create;
