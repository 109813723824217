import React from 'react';

import { StyledForm } from '../../../form-components/FormComponents.styles';
import SchoolForm from './SchoolForm/SchoolForm';

// THIS COMPONENT USES A DIFFERENT (COPIED) SCHOOL FORM THAN THE ONE ON THE FRONT END PLEASE TAKE NOTE

export default function EditSchool({ school, send }) {
  return (
    <StyledForm>
      <SchoolForm
        formValues={school || {}}
        submitHandle={data => send('SUBMIT_SCHOOL', { ...data })}
      />
    </StyledForm>
  );
}
