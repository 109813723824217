import dayjs from 'dayjs';

export const contextStep2DataToPrismaModel = data => {
  // THE DAY ISNT COLLECTED IN THE DATA SO WE SET IT TO 1

  const startDate = data.startDate
    ? dayjs(`${data.startDate.month} 1, ${data.startDate.year}`).format()
    : null;
  const endDate = data.endDate
    ? dayjs(`${data.endDate.month} 1, ${data.endDate.year}`).format()
    : null;
  let degreeDate = null;
  if (data.awardDateTime !== 0) {
    degreeDate = dayjs(
      `${data.awardDate?.month} 1, ${data.awardDate?.year}`,
    ).format();
  }
  const degreeNA = data.degree.notApplicable;

  const object = {
    country: data.country,
    school: data.name,
    study: data.areaOfStudy,
    startDate,
    endDate,
    degree: data.degree.title,
    degreeNA,
    degreeDate,
    id: data.id,
  };
  // console.log('context step2data to prisma model: data sending to db:', object);
  return object;
};

export const modelSchoolToStep2DataContext = data => {
  // console.log('model to step2Data context', data);
  const startDate = data.startDate
    ? {
        month: dayjs(data.startDate).format('MMM'),
        day: dayjs(data.startDate).format('D'),
        year: dayjs(data.startDate).format('YYYY'),
      }
    : null;
  const endDate = data.endDate
    ? {
        month: dayjs(data.endDate).format('MMM'),
        day: dayjs(data.endDate).format('D'),
        year: dayjs(data.endDate).format('YYYY'),
      }
    : null;
  const awardDate = data.degreeDate
    ? {
        month: dayjs(data.degreeDate).format('MMM'),
        day: dayjs(data.degreeDate).format('D'),
        year: dayjs(data.degreeDate).format('YYYY'),
      }
    : null;

  return {
    country: data.country,
    name: data.school,
    areaOfStudy: data.study,
    startDate,
    endDate,
    awardDate,
    degree: {
      title: data.degree,
      notApplicable: data.dggreeNA,
    },
    id: data.id,
  };
};
