import React from 'react';

import ClientDashboardHeader from '../client-dashboard-header/client-dashboard-header.component';
import {
  APPLICATION_INFORMATION,
  FRONTEND_HEADER,
  MAP_CONTAINER,
  QUESTION_CONTAINER,
  USER_NAV_STYLED,
} from './client-header-styles';

const FrontendHeader = () => (
  <FRONTEND_HEADER>
    <USER_NAV_STYLED>
      <ClientDashboardHeader onApplication />
    </USER_NAV_STYLED>
    <MAP_CONTAINER />
    <APPLICATION_INFORMATION>
      <h2>Applicant Information</h2>
      <p>
        Be sure to fill out every field correctly. Corrections must be submitted
        via email which will delay the process.{' '}
      </p>
    </APPLICATION_INFORMATION>
    <QUESTION_CONTAINER>
      <h2>
        Have a question? Check out our{' '}
        <a href="https://www.gceus.com/faq/" target="_blank" rel="noreferrer">
          FAQ
        </a>{' '}
        or call our friendly staff.
      </h2>
      <div className="blue-break" />
      <div className="contact-info">
        <div className="contact-info__block">
          <p className="contact-info__header">PHONE</p>
          <a href="tel:18007070979" className="contact-info__number">
            800.707.0979
          </a>
        </div>
        <div className="contact-info__block">
          <p className="contact-info__header">INTERNATIONAL</p>
          <a href="tel:3014214581" className="contact-info__number">
            301.421.4581
          </a>
        </div>
      </div>
    </QUESTION_CONTAINER>
  </FRONTEND_HEADER>
);

export default FrontendHeader;
