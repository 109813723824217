import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';

import { ProfileContext } from '../auth/ValidateProfileAddContext';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { UserMenu } from './styles';

const UserDropdownMenu = ({ onApplication }) => {
  const userContext = useContext(ProfileContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const outSideMenuClickHandler = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };
  document.addEventListener('mousedown', outSideMenuClickHandler);

  const dashboardButtonHandler = () => {
    navigate('/dashboard');
  };

  const logoutButtonHandler = () => {
    posthog.reset();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  let userMenu = (
    <li>
      <button type="button" onClick={loginWithRedirect}>
        Login
      </button>
    </li>
  );

  if (isAuthenticated) {
    userMenu = (
      <>
        <li>
          <button type="button" onClick={dashboardButtonHandler}>
            Dashboard
          </button>
        </li>
        <hr />
        <li>
          <button type="button" onClick={logoutButtonHandler}>
            Logout
          </button>
        </li>
      </>
    );
  }

  return (
    <UserMenu className="userNav" ref={menuRef} menu={menuOpen.toString()}>
      <button
        className={
          onApplication ? 'menuButton menuButton--application' : 'menuButton'
        }
        type="button"
        onClick={() => setMenuOpen(prev => !prev)}
      >
        {userContext?.firstName} {userContext?.lastName}
        <Arrow />
      </button>
      {menuOpen && <ul className="dropdown">{userMenu}</ul>}
    </UserMenu>
  );
};

export default UserDropdownMenu;
