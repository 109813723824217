import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../../shared/styles';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <Button type="button" onClick={() => loginWithRedirect()}>
        Log In / Sign up
      </Button>
    )
  );
};

export default LoginButton;
