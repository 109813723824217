import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  &.auditTable {
    border-collapse: collapse;
  }
  th {
    text-align: left;
    border-right: 1px solid #d4d4d4;
    padding: 30px 15px 15px 15px;
    color: #14527f;
    font-size: 14px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: none;
    }
  }
  tr {
    transition: background-color ease 0.3s;
    &.rush {
      background-color: #f7936f;
    }
    &.paid {
      background-color: #68b6ec;
    }
    &.reEvaluation {
      background-color: #dfb0fc;
    }
    &:hover {
      background-color: #edf2f7;
    }
  }
  td {
    padding: 15px;
    border-right: 1px solid #d4d4d4;

    &:last-child {
      border-right: none;
    }
    &.__price {
      text-align: center;
    }
    &.__actions {
      display: flex;
      button {
        padding: 10px;
        &:first-child {
          border-right: 1px solid #d4d4d4;
        }
      }
    }
    select {
      padding: 5px;
      cursor: pointer;
      background-color: #edf2f7;
    }
    &.__center {
      text-align: center;
    }
  }
  .button-cont {
    display: flex;
    button {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #d4d4d4;
      &:last-child {
        border: none;
      }
    }
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease 0.3s;
    svg {
      fill: #2e2e2e;
      transition: all ease 0.3s;
    }

    &:hover,
    &:active,
    &:focus {
      color: #14527f;
      svg {
        fill: #14527f;
      }
    }
    &:last-child {
      svg {
        margin-right: 3px;
      }
    }
  }
  .rushApp {
    background-color: #ffb195;
  }
  .addedRush {
    background-color: #f1a9ff;
  }
`;
export const SortButton = styled.button`
  font-size: 14px;
  color: #14527f;
  font-weight: 700;
  .__sort-icon {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    svg {
      &:last-child {
        transform: rotate(180deg);
      }
      &.active {
        fill: chartreuse;
      }
    }
  }
`;

export default Table;
