import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import DashboardTemplate from '../Templates/DashboardTemplate';
import Button from '../../components/UI/Button';

const Content = styled.div`
  p {
    margin-top: 2rem;
    font-size: 18px;
    max-width: 50rem;
    line-height: 1.5;
  }
  button {
    margin-top: 2rem;
  }
`;

const ApplicationError = () => {
  const navigate = useNavigate();

  return (
    <DashboardTemplate>
      <Content>
        <h2>We’re Sorry, Something Went Wrong</h2>
        <p>
          Well that didn't go as planned. We've logged the error and notified
          the team who will look into it.
        </p>
        <p>
          In the meantime, please return to the dashboard and try again. If this
          error persists, please <a href="/contact">contact us</a>.
        </p>

        <Button
          title="Dashboard"
          text="Return to Dashboard"
          onClick={() => navigate('/dashboard')}
        />
      </Content>
    </DashboardTemplate>
  );
};

export default ApplicationError;
