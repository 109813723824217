import { assign, Machine } from 'xstate';
import { useApolloClient } from '@apollo/client';

import emailTemplateQueries from '../../../queries_mutations/EmailTemplates/queires';
import emailTemplateMutations from '../../../queries_mutations/EmailTemplates/mutations';
import rootMachineConfig from './EmailTemplateMachineConfig';

function createServices() {
  const client = useApolloClient();
  const { queryInit } = emailTemplateQueries(client);
  const { saveItem, saveNewItem, deleteItem } = emailTemplateMutations(client);

  return {
    guards: {},

    services: {
      load: () => {
        return queryInit();
      },
      saveItem: (_, formData) => {
        return saveItem(formData);
      },
      saveItemNew: (_, formData) => {
        return saveNewItem(formData);
      },
      deleteItem: (_, deletingTemplate) => {
        return deleteItem(deletingTemplate.id).then(() => {
          return queryInit();
        });
      },
    },

    actions: {
      loadInitialContext: assign({
        emailTemplates: (_, e) => {
          return e.data;
        },
      }),
      setFormData: assign({
        formDefaults: (_, e) => e,
      }),
    },
  };
}

function create() {
  const services = createServices();
  return Machine({ ...rootMachineConfig() }, { ...services });
}

export default create;
