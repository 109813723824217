import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';

export const Radiogroup = ({
  settings: { dataRef, options, number, title },
  register,
  errors,
  stackedOptions,
  firstChecked,
  required,
  otherReturnDocsAddress,
}) => (
  <div className="input-group">
    <p className="input-group--title input-group--bold" id={`${dataRef}_group`}>
      {number && <span className="number">{number} </span>}
      {title} {required && <span className="required">*</span>}
    </p>
    <div
      className={stackedOptions && 'input-group--stacked'}
      role="group"
      aria-labelledby={`${dataRef}_group`}
    >
      {options.map((option, index) => (
        <div key={option}>
          <Option
            id={`${dataRef}_${option}`}
            name={dataRef}
            type="radio"
            value={option}
            {...register(dataRef)}
            defaultChecked={firstChecked && index === 0}
            disabled={
              !!otherReturnDocsAddress &&
              (option === 'Address on application' || option === 'Not required')
            }
          />
          <OptionLabel
            isDisabled={
              !!otherReturnDocsAddress &&
              (option === 'Address on application' || option === 'Not required')
            }
            htmlFor={`${dataRef}_${option}`}
            title={
              otherReturnDocsAddress &&
              (option === 'Address on application' || option === 'Not required')
                ? 'Please delete other address'
                : undefined
            }
          >
            {option}
          </OptionLabel>
        </div>
      ))}
    </div>
    <ErrorMessage errors={errors} name={dataRef} as="span" className="error" />
  </div>
);

export default Radiogroup;

const Option = styled.input`
  &:disabled {
    cursor: not-allowed;
  }
`;

const OptionLabel = styled.label`
  color: ${props => (props.isDisabled ? 'lightgrey' : 'unset')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
