import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, FormFields } from '../../../shared/styles';
import TextInput from '../../UI/FormInputs/TextInput/TextInput';

const schema = yup.object().shape({
  name: yup.string(),
  subject: yup.string(),
  templateID: yup.string(),
});

const EmailTemplateForm = ({ send, state, formState }) => {
  let formDefaults = {};
  if (state.formDefaults) {
    formDefaults = state.formDefaults;
  }
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formDefaults,
  });

  const formSubmit = formData => {
    if (state.formDefaults.type === 'EDIT_ITEM') {
      send('SAVE_ITEM', { ...formData, id: state.formDefaults.id });
    } else {
      send('SAVE_NEW_ITEM', formData);
    }
  };

  return (
    <FormFields onSubmit={handleSubmit(formSubmit)}>
      <TextInput
        title="Name"
        dataRef="name"
        register={register}
        errors={errors}
      />
      <TextInput
        title="Subject"
        dataRef="subject"
        register={register}
        errors={errors}
      />
      <TextInput
        title="Postmark Template Id"
        dataRef="templateID"
        register={register}
        errors={errors}
      />

      <Button type="Submit" disabled={!formState.matches('itemUpdate.idle')}>
        Save
      </Button>
    </FormFields>
  );
};

export default EmailTemplateForm;
