import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    margin-top: 50px;
    display: block;
  }
`;
export const Info = styled.div`
  margin-top: 50px;
`;
export default ModalContent;
