import * as yup from 'yup';

const yupSchema = yup.object().shape({
  reqDocsSendingType: yup
    .string()
    .typeError('Please choose an option')
    .required('Please choose delivery method'),
  totalPages: yup
    .number()
    .moreThan(0, 'Must be greater than 0')
    .typeError('Must be a number')
    .required('Please enter how many pages your documents are.'),
  totalFiles: yup
    .number()
    .nullable()
    .when('reqDocsSendingType', {
      is: doc => doc !== 'Mail',
      then: schema =>
        schema
          .moreThan(0, 'Must be greater than 0')
          .typeError('Must be a number')
          .required('Please enter how many pages your documents are.'),
      otherwise: schema => schema.notRequired(),
    }),
});

export default yupSchema;
