import React, { useState } from 'react';
import Block from './styles.RoundedBlock';
import Button from '../Button';

const BlueRoundedBlock = ({
  title,
  btnText,
  children,
  hasPagination,
  footer,
  send,
  hasSearch,
  customSearch,
  btnHandler,
  fileInputHandler,
  addButton,
}) => {
  const [searchActive, setSearchActive] = useState(false);

  // SEARCH FUNCTION

  const search = e => {
    e.preventDefault();
    const searchText = document.querySelector('#searchBox').value;
    if (searchText === '') {
      return;
    }
    if (customSearch) {
      send(customSearch, { data: searchText });
    } else {
      send('SEARCH', { data: searchText });
    }
  };
  // CLEAR SEARCH FUNCTION
  const clearSearch = () => {
    setSearchActive(false);
    document.querySelector('#searchBox').value = '';
    send('INIT');
  };

  return (
    <Block className="block-container">
      <div className="__header">
        <p className="__title">{title}</p>
        {addButton && (
          <Button
            type="button"
            text={addButton.text}
            onClick={addButton.click}
            id="addButton"
          />
        )}
        {hasSearch && (
          <form className="__search-group">
            <input
              id="searchBox"
              type="text"
              className={
                searchActive
                  ? `__search-input __search-input--active`
                  : '__search-input'
              }
            />

            {searchActive ? (
              <>
                <Button
                  type="submit"
                  text={btnText}
                  id="searchbutton"
                  onClick={e => search(e)}
                  searchIcon
                />
                <Button
                  type="button"
                  text="Clear Search"
                  onClick={() => clearSearch()}
                />
              </>
            ) : (
              <Button
                type="button"
                text={btnText}
                onClick={() => setSearchActive(true)}
                searchIcon
              />
            )}
          </form>
        )}
        {btnHandler && (
          <Button type="button" text={btnText} onClick={() => btnHandler()} />
        )}
        {fileInputHandler && (
          <label htmlFor="documentUpload" className="fileInput btn">
            {btnText}
            <input
              id="documentUpload"
              multiple
              name="profileImage"
              type="file"
              onChange={e => fileInputHandler(e)}
            />
          </label>
        )}
      </div>
      <hr id="__header-hr" />
      <div className="__content">{children}</div>
      {hasPagination && <hr />}
      {!hasPagination && footer && <div className="__footer" />}
    </Block>
  );
};

export default BlueRoundedBlock;
