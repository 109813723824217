import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProfileContext } from '../../../auth/ValidateProfileAddContext';
import AddressForm from '../../AddressForm/AddressForm';
import NextPrevStepButtons from '../../NextPrevStepButtons/NextPrevStepButtons';
import FormDirections from '../../FormDirections/FormDirections';
import RadioGroup from '../../RadioGroup/RadioGroup';
import InputField from '../../InputField/InputField';
import schema from './Step1ValidationSchema';

// STYLING
import { StyledForm } from '../../FormComponents.styles';
import FormDropdown from '../../FormDropdown/FormDropdown';
import DateInput from '../../DateInput/DateInput';

const Step1 = ({ onSubmit, state }) => {
  const userContext = useContext(ProfileContext);
  const updatedState = { ...state };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: updatedState,
  });
  const watchRefNum = watch('PrevEvaluation.option');
  const watchDiffName = watch('foreignName.option');
  const watchDOBMonth = watch('birthday.month');
  return (
    <StyledForm>
      <div className="temp-width">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormDirections title="Applicant Information" />

          {/* Prior Reference Number */}
          <RadioGroup
            register={register}
            settings={{
              dataRef: 'PrevEvaluation.option',
              title: 'Have you had a previous evaluation?',
              options: ['No', 'Yes'],
            }}
            errors={errors}
            firstChecked
            required
            stackedOptions
          />
          {watchRefNum === 'Yes' && (
            <div className="sbs">
              <InputField
                register={register}
                dataRef="PrevEvaluation.number"
                type="text"
                placeholder=""
                title="Reference number"
                errors={errors}
                required
              />
              <InputField
                register={register}
                dataRef="PrevEvaluation.date"
                type="text"
                title="Date"
                errors={errors}
                required
                defaultValue={updatedState?.PrevEvaluation?.date}
              />
            </div>
          )}

          {/* GENDER */}
          <RadioGroup
            register={register}
            settings={{
              dataRef: 'gender',
              title: 'Gender?',
              options: ['Male', 'Female', 'Decline to answer'],
            }}
            errors={errors}
            required
            stackedOptions
          />

          {/* APPLICANT NAME */}
          <h2 className="SF-segment-title">Applicant name</h2>
          <div className="sbs">
            <InputField
              register={register}
              dataRef="name.first"
              type="text"
              placeholder=""
              title="Given first name"
              errors={errors}
              required
            />
            <InputField
              register={register}
              dataRef="name.middle"
              type="text"
              placeholder=""
              title="Middle name"
              errors={errors}
            />
          </div>
          <InputField
            register={register}
            dataRef="name.last"
            type="text"
            placeholder=""
            title="Last/Family name"
            errors={errors}
            required
          />

          {/* APPLICANT FOREIGN NAME */}
          <h2 className="SF-segment-title">
            Name as it appears on foreign educational documents
          </h2>
          <RadioGroup
            register={register}
            settings={{
              dataRef: 'foreignName.option',
              options: ['Same', 'Different'],
            }}
            errors={errors}
            firstChecked
            stackedOptions
          />
          {watchDiffName === 'Different' && (
            <>
              <div className="sbs">
                <InputField
                  register={register}
                  dataRef="foreignName.first"
                  type="text"
                  placeholder=""
                  title="Given first name"
                  errors={errors}
                  required
                />
                <InputField
                  register={register}
                  dataRef="foreignName.middle"
                  type="text"
                  placeholder=""
                  title="Middle name"
                  errors={errors}
                />
              </div>
              <InputField
                register={register}
                dataRef="foreignName.last"
                type="text"
                placeholder=""
                title="Last/Family name"
                errors={errors}
                required
              />
            </>
          )}
          <hr />
          <h2 className="SF-segment-title">Date of birth</h2>
          <DateInput
            dataPrefix="birthday"
            register={register}
            errors={errors}
            date={updatedState?.birthday}
            monthSelected={watchDOBMonth}
          />
          <FormDropdown
            dataRef="birthCountry"
            register={register}
            errors={errors}
            title="Birth country"
            content="country"
            required
          />
          <hr />
          {/* ADDRESS */}
          <h2 className="SF-segment-title">Address</h2>
          <AddressForm
            register={register}
            errors={errors}
            countryValue={updatedState?.address?.country}
            yupObjectName="address"
            noLocation
            watch={watch}
          />
          <hr />

          {/* PHONE NUMBER EMAIL AND BIRTHDAY */}
          <InputField
            register={register}
            dataRef="phoneNumber"
            type="text"
            title="Phone number"
            errors={errors}
            required
          />
          <InputField
            register={register}
            dataRef="emailAddress"
            type="email"
            title="Email address"
            errors={errors}
            required
            defaultValue={userContext?.email}
          />
          {/* {JSON.stringify(errors)} */}
          <NextPrevStepButtons submitButton firstStep />
        </form>
      </div>
    </StyledForm>
  );
};

export default Step1;
