import React from 'react';

import UpgradeRush from '../components/dashboard-pages/UpgradeRush/UpgradeRush';
import DashboardTemplate from './Templates/DashboardTemplate';

const UpgradeRushPage = () => {
  return (
    <DashboardTemplate>
      <UpgradeRush />
    </DashboardTemplate>
  );
};

export default UpgradeRushPage;
