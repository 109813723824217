import React from 'react';

// STYLED COMPONENTS
import Table, { SortButton } from './styles.BlockTable';

import { ReactComponent as Triangle } from '../../../../assets/triangle-fill.svg';

const BlockTable = ({
  headers,
  children,
  sortable,
  send,
  xStateContext,
  audit,
}) => {
  return (
    <div className="BlockTable">
      <Table className={audit && 'auditTable'}>
        <tbody>
          <tr>
            {sortable
              ? headers.map(header => {
                  if (header.sortable) {
                    return (
                      <th key={header.title}>
                        <SortButton
                          type="button"
                          onClick={() => send('SORT', { sort: header.send })}
                        >
                          {header.title}{' '}
                          {xStateContext && (
                            <span className="__sort-icon">
                              <Triangle
                                className={
                                  xStateContext[header.context] === false
                                    ? 'active'
                                    : ''
                                }
                              />{' '}
                              <Triangle
                                className={
                                  xStateContext[header.context] ? 'active' : ''
                                }
                              />
                            </span>
                          )}
                        </SortButton>
                      </th>
                    );
                  }
                  return <th key={header.title}>{header.title}</th>;
                })
              : headers.map(header => <th key={header}>{header}</th>)}
          </tr>
          {children}
        </tbody>
      </Table>
    </div>
  );
};

export default BlockTable;
