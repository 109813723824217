import React from 'react';
import Address from './styles.additionalAddressesList';
import { Button } from '../../../../../../shared/styles';
import { returnService } from '../../../../../../shared/functions';

const AdditionalAddressesList = ({
  addresses,
  deleteAddressFunction,
  services,
}) => {
  return (
    <div className="addresses">
      {addresses && addresses.length > 0
        ? addresses.map(
            (
              {
                id,
                line1,
                line2,
                city,
                state,
                country,
                postalCode,
                contact,
                company,
                shippingMethod,
              },
              index,
            ) => {
              const shippingMethodService = returnService(
                services,
                shippingMethod,
              );
              return (
                <Address key={id}>
                  <p className="__header">
                    <strong>
                      Address for additional report #{index + 1} <span>*</span>
                    </strong>
                  </p>
                  <div className="__address">
                    {company && <p>{company}</p>}
                    {contact && <p>{contact}</p>}
                    <p>{line1}</p>
                    {line2 && <p>{line2}</p>}
                    <p>
                      {city}, {state} {postalCode}{' '}
                    </p>
                    <p>{country}</p>
                    <p>
                      Shipping Method: {shippingMethodService.displayName}{' '}
                      <strong>
                        <em>{`($${shippingMethodService.price}.00)`}</em>
                      </strong>
                    </p>
                    <Button
                      type="button"
                      onClick={() => deleteAddressFunction(id)}
                    >
                      Delete Address
                    </Button>
                    <hr />
                    <br />
                  </div>
                </Address>
              );
            },
          )
        : 'No addresses yet. Please click add address'}
    </div>
  );
};

export default AdditionalAddressesList;
