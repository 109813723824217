import styled from 'styled-components';

const Block = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow-x: scroll;
  background-color: #f7fafc;
  border-bottom-right-radius: ${({ hasPagination }) =>
    hasPagination ? '10px' : '0px'};
  border-bottom-left-radius: ${({ hasPagination }) =>
    hasPagination ? '10px' : '0px'};

  .__header,
  .__footer {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    @media print {
      padding: 10px 0;
    }
  }

  .__search-group {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    .__search {
      &-input {
        margin-right: 10px;
        width: 0;
        transition: width ease 0.3s;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 10px;
        height: 100%;
        &--active {
          max-width: 200px;
          width: 100%;
          border: solid black 1px;
          background-color: white;
        }
      }
    }
    button {
      margin: 0 10px;
    }
  }
  hr {
    width: 100%;
    height: 1px;
    border-width: 0;
    color: #d4d4d4;
    background-color: #d4d4d4;
    @media print {
      display: none;
    }
  }
  .__title {
    color: #2e2e2e;
    font-size: 24px;
    font-weight: 700;
    @media only screen and (max-width: 600px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    @media print {
      font-size: 18px;
    }
  }
  .__content {
    padding: 30px;
    hr {
      margin: 30px 0;
    }
    p {
      margin-bottom: 15px;
    }
    @media print {
      padding: 0;
      margin: 0;
    }
  }
  .fileInput {
    padding: 12px;
    color: #14527f;
    background-color: transparent;
    border: solid 2px #14527f;
    cursor: pointer;
    transition: all ease 0.3s;
    &:hover,
    &:focus {
      color: white;
      background-color: #14527f;
    }

    input[type='file'] {
      display: none;
    }
  }
`;

export default Block;
