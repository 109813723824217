import React from 'react';
import IncompleteApplicationList from '../../components/IncompleteApplicationList/IncompleteApplicationList';
import DashboardTemplate from '../Templates/DashboardTemplate';

const IncompleteApplications = () => {
  return (
    <DashboardTemplate>
      <IncompleteApplicationList />
    </DashboardTemplate>
  );
};

export default IncompleteApplications;
