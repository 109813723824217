const Constants = {
  ADDRESS: {
    ADDRESS_ON_APPLICATION: 'Address on application',
    OTHER_ADDRESS: 'Other address',
    NOT_REQUIRED: 'Not required',
    EMAIL: 'Email',
  },
  SERVICE: {
    MAILING_OPTION: 'MAILING_OPTION',
    APPLICATION_SERVICE: 'APPLICATION_SERVICE',
    RETURNING_DOCUMENTS: 'RETURNING_DOCUMENTS',
    ADDITIONAL_REPORT_SHIPPING: 'ADDITIONAL_REPORT_SHIPPING',
    RUSH: 'RUSH',
    ADDITIONAL_EMAIL: 'ADDITIONAL_EMAIL',
    NO_RUSH: 'No rush service',
  },
  LOCATION: {
    UNITED_STATES: 'United States',
    INTERNATIONAL: 'International',
  },
  FORM_ERROR_MESSAGES: {
    SELECTION: 'Please select an option',
    ALT_ADDRESS: 'Please enter an alternate address',
    VALID_EMAIL: 'Please enter a valid email',
    EMAIL: 'Please enter an email',
  },
};

export default Constants;
