import { gql } from '@apollo/client';
import {
  applicationFields,
  address,
} from '../../../../queries_mutations/sharedVariables';
//
// QUERIES FOR ALL EVALUATIONS
//

export const COPY_REQUESTS = gql`
  query copyRequestsInit($id: String) {
    services{
      all{
        displayName
        price
        id
      }
    }
    copyRequest {
      get(id: $id) {
        id
        createdAt
        application{
          ${applicationFields}
        }
        status
        completed
        addresses{
          ${address}
        }
        emails
        returnDocs
      }
    }
  }
`;

export default client => {
  const init = id => {
    return client
      .query({
        query: COPY_REQUESTS,
        variables: { id },
      })
      .then(({ data }) => {
        return {
          copyRequests: data.copyRequest.get,
          services: data.services.all,
        };
      });
  };

  return {
    init,
  };
};
