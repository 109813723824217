import React from 'react';
import SchoolList from '../../../../SchoolList/SchoolList';

const EducationalHistory = ({ formState, send }) => {
  const schools = formState.context.step2.data;
  return (
    <div className="section">
      <div className="section__header">
        <h2>02. Education History</h2>
        <button
          aria-label="Edit Education History"
          className="edit-button"
          type="button"
          onClick={() => send('STEP_TWO')}
        >
          Edit
        </button>
      </div>
      <hr />
      <SchoolList schools={schools} />
    </div>
  );
};

export default EducationalHistory;
